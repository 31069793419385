<template>
  <div class="container">
    <v-card class="pa-5 custom-card" outlined>
      <v-card-title class="text-h5">Enter UID of the User</v-card-title>

      <v-text-field
        v-model="input_uid"
        label="UID"
        outlined
        dense
        clearable
        class="mt-3"
      />

      <div class="button-group mt-4">
        <v-btn
          :loading="isLoading"
          :disabled="isLoading"
          color="primary"
          @click="submitUID"
          >Submit</v-btn
        >
        <v-btn
          color="grey"
          @click="clearData"
          class="ml-2"
          :disabled="isLoading"
          >Clear</v-btn
        >
        <div v-if="token" class="link-group flex justify-end px-6">
          <div class="mr-2 flex items-center justify-end">
            <p class="hover-link" @click="redirectTo('uat')">UAT</p>
            <v-icon class="ml-1 cursor-pointer" @click.stop="copyLink('uat')"
              >mdi-content-copy</v-icon
            >
          </div>
          <div class="flex items-center justify-end">
            <p class="hover-link" @click="redirectTo('app')">APP</p>
            <v-icon class="ml-1 cursor-pointer" @click.stop="copyLink('app')"
              >mdi-content-copy</v-icon
            >
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { auth, colUsers, db, functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import { getDoc, doc } from "firebase/firestore";

export default {
  data() {
    return {
      input_uid: "",
      token: null,
      isLoading: false,
    };
  },
  methods: {
    async submitUID() {
      this.token = null;

      const trimmedUID = this.input_uid.trim();
      if (!trimmedUID) {
        this.$snackbar.show("Please enter a UID.");
        return;
      }
      this.isLoading = true;

      try {
        const userRef = doc(db, "users", trimmedUID);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
          this.$snackbar.show(
            "Please make sure the UID is correct. Unable to find user."
          );
          this.isLoading = false;
          return;
        }

        const payload = { uid: trimmedUID };
        const fetchToken = httpsCallable(functions, "generateCustomToken");
        const tokenResponse = await fetchToken(payload);

        if (tokenResponse && tokenResponse.data && tokenResponse.data.token) {
          this.token = tokenResponse.data.token;
        } else {
          this.$snackbar.show("Unable to generate token.");
        }
      } catch (error) {
        console.error("Error during token generation: ", error);
        this.$snackbar.show("An unexpected error occurred.");
      } finally {
        this.isLoading = false;
      }
    },
    redirectTo(env) {
      const baseUrl =
        env === "uat"
          ? "https://dev-app.fly-social.com"
          : "https://app.fly-social.com";
      window.open(
        `${baseUrl}/custom-token-login?token=${this.token}`,
        "_blank"
      );
    },
    clearData() {
      this.input_uid = "";
      this.token = null;
    },
    async checkAdminStatus() {
      try {
        let userRef = doc(db, "users", this.currentUID);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          if (!("super_admin" in userData && userData.super_admin)) {
            this.$snackbar.show("Direct access is prohibited");
            this.$router.push("/calendar");
          }
        }
      } catch (err) {
        console.error("Error checking admin status ", err);
        this.$snackbar.show("Direct access is prohibited");
        this.$router.push("/calendar");
      }
    },
    copyLink(env) {
      const baseUrl =
        env === "uat"
          ? "https://uat-app.fly-social.com"
          : "https://app.fly-social.com";

      const fullUrl = `${baseUrl}/custom-token-login?token=${this.token}`;

      navigator.clipboard
        .writeText(fullUrl)
        .then(() => {
          this.$snackbar.show(`Link copied for ${env.toUpperCase()}`);
        })
        .catch((err) => {
          console.error("Clipboard copy failed: ", err);
          this.$snackbar.show("Failed to copy the link.");
        });
    },
  },
  mounted() {
    this.checkAdminStatus();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.custom-card {
  width: 600px;
}

.button-group {
  display: flex;
  align-items: center;
}

.link-group {
  display: flex;
  align-items: center;
}

.hover-link {
  color: #1976d2;
  cursor: pointer;
  margin: 5px 0;
  transition: color 0.3s;
  font-weight: 500;
}
.hover-link:hover {
  color: #0d47a1;
  text-decoration: underline;
}
</style>
