<template>
  <div>
    <v-dialog
      max-width="1000px"
      v-model="dialog"
      scrollable
      class="absolute z-[99999]"
    >
      <template v-slot:activator="{ on }">
        <div class="flex-col items-start gap-4 d-flex">
          <div class="flex-col gap-2 md:justify-center md:items-center d-flex">
            <!-- <v-btn v-if="type != 'image'"
              depressed
              :loading="isLoading"
              class="w-full gallery-picker-btn"
              v-on="on"
              color="white"
              :disabled="disableFlyGalleryButton"
            > -->
              <!-- <template v-if="type == 'image'">
                <div
                  class="flex py-2 pl-4 pr-5 md:-ml-4 bg-slate-100 rounded-xl"
                >
                  <img
                    src="@/assets/img/upimage.svg"
                    alt="translate"
                    width="22px"
                    height="22px"
                    class="mr-3"
                  />
                  <span class="text-xs md:text-lg">
                    Upload from Fly Gallery</span
                  >
                </div>
              </template> -->
              <!-- <template>
                <div
                  class="flex py-2 pl-4 pr-5 md:-ml-4 bg-slate-100 rounded-xl"
                >
                  <img
                    src="@/assets/img/video.svg"
                    alt="translate"
                    width="22px"
                    height="22px"
                    class="mr-3"
                  />
                  <span class="text-xs md:text-lg"
                    >Upload from Fly Gallery</span
                  >
                </div>
              </template> -->
            <!-- </v-btn> -->

            <v-btn
              depressed
              :loading="isImageLoading"
              v-if="type == 'image'"
              class="justify-start w-full bg-slate-100 rounded-xl"
              color="#F2F6FA"
              @click="initCloudinaryWidget()"
              :disabled="isUploading" 
            >
              <img
                src="@/assets/img/upimage.svg"
                alt="translate"
                width="22px"
                height="22px"
                class="mr-3"
              />
              <span class="text-xs md:text-lg"
                >Upload from Device 
              </span>
            </v-btn>

            <v-btn
              depressed
              :loading="isVideoLoading"
              v-else
              class="bg-slate-100 rounded-xl"
              color="#F2F6FA "
              @click="$refs.video.click()"
              :disabled="disableDeviceGalleryButton"
            >
              <img
                src="@/assets/img/video.svg"
                alt="translate"
                width="22px"
                height="22px"
                class="mr-3"
              />
              <span class="text-xs md:text-lg">Upload from Device </span>
            </v-btn>

            <!-- <input
              accept="image/*,.heic,.heif"
              type="file"
              class="d-none"
              ref="image"
              @change="onImageChange"
              multiple
            /> -->
            <input
              accept="video/*"
              type="file"
              class="d-none"
              ref="video"
              id="video"
              @change="onVideoChange"
            />
          </div>
          <p
            class="text-sm text-red-500"
            v-if="disableDeviceGalleryButton || disableFlyGalleryButton"
          >
            Only one video is allowed!
          </p>
        </div>
      </template>

      <dialog-card
        :isSubmit="false"
        :title="title"
        source="galleryPicker"
        @assetsSelected="handleSelectedAssets"
        @clearSelection="clearSelection"
      >
        <please-wait class="mt-4" v-if="isLoading" />
        <v-row>
          <v-col
            v-for="n in list"
            :key="n.id"
            class="mb-4"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card class="pa-2" outlined>
              <v-row class="justify-center">
                <v-col cols="12">
                  <v-img
                    @click="toggleSelection(n)"
                    v-if="n.type === 'image'"
                    :src="n.thumbnail"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <video-auto-play
                    v-else
                    :playOnHover="false"
                    :autoplay="false"
                    class="z-10"
                    :videoSrc="n.export || n.url"
                    @toggle-selection="toggleSelection(n)"
                  />
                  <v-checkbox
                    v-model="tempSelected"
                    @change="onCheckboxChange(n)"
                    :value="n"
                    class="z-50 mt-0"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p class="my-2 text-base">
                    {{ isFileName(n) ? `${n.type} name` : n.name }}
                  </p>
                  <div v-if="n.size">
                    <p class="text-caption">
                      Size: {{ formatFileSize(n.size) }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </dialog-card>
    </v-dialog>
    <upload-progress-dialog ref="progress" />
  </div>
</template>

<script>
import { getDocs, orderBy, query, where } from "firebase/firestore";
import DialogCard from "../ui/DialogCard.vue";
import { colGallery, storage } from "@/utils/firebase.utils";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import PleaseWait from "../ui/PleaseWait.vue";
import { getDate } from "@/utils/common.utils";
import VideoAutoPlay from "@/components/public/VideoAutoPlay.vue";
import { compressImage, MAX_IMAGE_SIZE } from "@/utils/image.utils";
import UploadProgressDialog from "../UploadProgressDialog.vue";
import heic2any from "heic2any";
import { Cloudinary } from "@cloudinary/url-gen";

export default {
  components: {
    DialogCard,
    PleaseWait,
    VideoAutoPlay,
    UploadProgressDialog,
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      default: "image",
    },
    isEditing: Boolean,
    isRepost: Boolean,
    isVideo: Boolean,
  },
  data() {
    return {
      dialog: false,
      list: [],
      isLoading: false,
      isImageLoading: false,
      isVideoLoading: false,
      tempSelected: [],
      isUploading: false,
    };
  },
  computed: {
    title() {
      return this.type == "image" ? "Select Image" : "Select Video";
    },
    disableFlyGalleryButton() {
      // Disable the "Fly Gallery" button when one video is selected.
      return this.isVideo || (this.type === "video" && this.value.length > 0);
    },
    disableDeviceGalleryButton() {
      // Disable the "Device Gallery" button when one video is selected.
      return this.isVideo || (this.type === "video" && this.value.length > 0);
    },
  },
  watch: {
    currentUID() {
      this.fetchGallery();
    },
    WID() {
      this.fetchGallery();
    },
  },
  methods: {
    async fetchGallery() {
      const vm = this;
      try {
        vm.list = [];
        vm.isLoading = true;
        let q = query(
          colGallery,
          orderBy("updated_at", "desc"),
          where("uid", "==", vm.currentUID),
          where("wid", "==", vm.WID),
          where("type", "==", vm.type)
        );
        let list = (await getDocs(q)).docs.map((i) => ({
          ...i.data(),
          id: i.id,
        }));
        // console.log("list:", list);
        if (vm.type == "video") {
          list = list.map((i) => ({
            ...i,
            url: i.export || i.url,
          }));
        }
        vm.list = list;
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    isFileName(n) {
      const file_name = n.name;
      if (file_name) {
        return (
          // file_name.startsWith(this.WID) || file_name.startsWith(n.currentUID)
          file_name.startsWith(n.uid)
        );
      }
    },
    onCheckboxChange(n) {
      if (this.type == "video" && this.tempSelected.length > 1) {
        this.tempSelected = this.tempSelected.filter((i) => i == n);
      }
    },
    loadCloudinaryWidget() {
      const script = document.createElement("script");
      script.src = "https://upload-widget.cloudinary.com/global/all.js";
      script.onload = () => this.initCloudinaryWidget();
      document.head.appendChild(script);
    },

    initCloudinaryWidget() {
      if (this.isUploading) return; // Prevent further clicks if already uploading

this.isUploading = true; // Set to true to disable further clicks

      let vm = this;
      console.log("inside", vm.currentUID);
      let uid = this.currentUID || this.uid;
      const myWidget = window.cloudinary.createUploadWidget(
        {
          cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
          uploadPreset: "demo_preset",
          sources: [
            "local",
            "camera",
            "url",
            "dropbox",
            "image_search",
            "shutterstock",
            "gettyimages",
            "istock",
            "unsplash",
            "google_drive",
          ],
          accept: 'image/*',
          clientAllowedFormats: ['png', 'jpg', 'jpeg', 'heic', 'heif', 'webp'],
          multiple: true,
          maxImageFileSize: 20000000,
          tags: [`${uid}_${vm.WID}`],
          folder: `users/${vm.currentUID}/${vm.WID}`,
        },
        (error, result) => {
          // if (!error && result && result.event === "close") {
          //   this.$emit("onImageSelected");
          // }
          if (!error && result && result.event === "queues-end") {
            // console.log('result:', result)
            if (
              result.info.files.length === 1 &&
              result.info.files[0].uploadInfo === null
            ) {
              console.log(
                "Upload did not complete successfully, skipping success handler."
              );
            } else {
              this.handleUploadSuccess(result.info.files);
              this.$emit("onImageSelected");
            }
            this.isUploading = false; 
            myWidget.close();
          }
             if (!error && result && result.event === "close") {
            this.isUploading = false; // Reset uploading flag when widget closes without upload
            console.log("Widget closed without uploading anything.");
          }
          if (!error && result && result.event === "success") {
            // console.log("Done! Here is the image info: ", result.info);
          }
        }
      );
      console.log("done");

      myWidget.open();
    },
    // Handle successful uploads (save to your component's data, etc.)
    handleUploadSuccess(uploadResult) {
      // console.log("uploadResult", uploadResult);
      uploadResult.forEach((file) => {
        const uploadInfo = file.uploadInfo;
        if (uploadInfo && uploadInfo.thumbnail_url) {
          let thumbnailUrl = uploadInfo.thumbnail_url;
          thumbnailUrl = thumbnailUrl
            .replace("h_60", "h_320")
            .replace("w_90", "w_320");
          this.$emit("thumbnailUrl", thumbnailUrl);
          this.$emit("onNewGalleryImage", uploadInfo.url);
        }
      });
    },
    modifyAndEmitThumbnailUrl(uploadInfo) {
      const baseUrl = uploadInfo.secure_url.split("/upload/")[0];
      const path = uploadInfo.path.split("/").slice(1).join("/"); // Removes the initial version part

      // Replace the existing transformation string with the new dimensions
      const newThumbnailUrl = `${baseUrl}/upload/w_320,h_320/${path}`;

      // Emit the new URL
      this.$emit("updatedThumbnailUrl", newThumbnailUrl);
    },

    async onImageChange(e) {
      const vm = this;
      if (!window.cloudinary) {
        this.loadCloudinaryWidget();
      } else {
        this.initCloudinaryWidget();
      }
    },
    uploadFile(storageRef, file) {
      return new Promise((resolve, reject) => {
        this.$refs.progress.progress = 0;
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (!isNaN(progress)) {
              this.$refs.progress.progress = progress.toFixed(1);
            }
          },
          (error) => {
            reject(error);
            // Handle unsuccessful uploads
          },
          () => {
            this.$refs.progress.progress = 100;
            resolve();
          }
        );
      });
    },
    async onVideoChange(e) {
      const vm = this;
      let video = e.target.files[0];
      vm.$refs.video.value = null;
      if (video) {
        try {
          const MAX_SIZE = 1024 * 1024 * 1024;
          if (video.size > MAX_SIZE) {
            vm.$alert.show("Video size cannot exceed 1 GB.");
            return;
          }
          vm.$refs.progress.total = 1;
          vm.$refs.progress.currentIndex = 1;
          vm.$refs.progress.progress = 0;
          vm.$refs.progress.loader = true;
          vm.isVideoLoading = true;
          this.$emit("upload-start");
          let duration = await vm.getVideoDuration(video);
          let thumbnail = await vm.createVideoThumbnail(video);
          let id = new Date().getTime().toString();
          let data = {
            created_at: getDate(),
            updated_at: getDate(),
            type: "video",
            uid: vm.currentUID,
            wid: vm.WID,
            json: {},
            size: video.size,
          };
          let videoRef;
          let thumbnailRef;
          if (this.WID === "default") {
            videoRef = ref(storage, `gallery/${vm.currentUID}/${id}.mp4`);
            thumbnailRef = ref(
              storage,
              `gallery/${vm.currentUID}/${id}_thumb.jpeg`
            );
          } else {
            videoRef = ref(
              storage,
              `gallery/${vm.currentUID}/${vm.WID}/${id}.mp4`
            );
            thumbnailRef = ref(
              storage,
              `gallery/${vm.currentUID}/${vm.WID}/${id}_thumb.jpeg`
            );
          }
          await vm.uploadFile(videoRef, video);
          await uploadString(thumbnailRef, thumbnail, "data_url");
          data.url = await getDownloadURL(videoRef);
          data.thumbnail = await getDownloadURL(thumbnailRef);
          await setDoc(doc(colGallery, id), data);
          let selectedArr = [];
          selectedArr.push({ ...data, id });
          this.$emit("input", [...this.value, ...selectedArr]);
          this.$emit("duration", duration);
          this.$emit("closemenu");
          vm.fetchGallery();
          vm.isVideoLoading = false;
          this.$emit("upload-finish");
          vm.$refs.progress.loader = false;
        } catch (error) {
          vm.$refs.progress.loader = false;
          vm.isVideoLoading = false;
          this.$emit("upload-finish");
          vm.handleError(error);
        }
      }
    },
    getVideoDuration(video) {
      return new Promise((resolve) => {
        const videoElement = document.createElement("video");
        videoElement.src = URL.createObjectURL(video);
        videoElement.load();
        videoElement.addEventListener("loadedmetadata", () => {
          const duration = videoElement.duration;
          resolve(duration);
          console.log("Device Video Duration:", duration);
        });
      });
    },
    async handleSelectedAssets() {
      let selected = [...this.value];
      console.log(selected);
      for (let i = 0; i < this.tempSelected.length; i++) {
        let dat = this.tempSelected[i];
        selected.push({ ...dat, cropStatus: false });
        if (dat.type === "video") {
          const resetVideoElement = () => {
            if (videoElement) {
              videoElement.src = "";
              videoElement.load();
            }
          };
          const videoElement = document.createElement("video");
          resetVideoElement();
          videoElement.src = dat.url; // Assuming dat.url contains the video source
          const duration = await new Promise((resolve) => {
            videoElement.addEventListener("loadedmetadata", () => {
              resolve(videoElement.duration);
            });
          });
          this.$emit("duration", duration);
        }
      }
      this.$emit("input", selected);
      if (this.isEditing || this.isRepost) {
        this.$emit("newlyselected");
        this.$emit("closemenu");
      } else {
        this.$emit("closemenu");
      }
      this.tempSelected = []; // Clear the temporary selection
      this.dialog = false; // Close the dialog
      this.$emit("onImageSelected");
    },
    toggleSelection(item) {
      if (this.tempSelected.includes(item)) {
        // If the item is already in the temporary selection, remove it
        this.tempSelected = this.tempSelected.filter(
          (selectedItem) => selectedItem !== item
        );
      } else {
        if (this.type == "video") {
          this.tempSelected = [];
        }

        // If the item is not in the temporary selection, add it
        this.tempSelected.push(item);
      }
    },
    clearSelection() {
      this.tempSelected = []; // Clear the temporary selection
      this.dialog = false; // Close the dialog
      this.$emit("closemenu");
    },
    async createVideoThumbnail(videoFile) {
      return new Promise((resolve, reject) => {
        try {
          const videoElement = document.createElement("video");
          videoElement.src = URL.createObjectURL(videoFile);
          videoElement.addEventListener("loadedmetadata", () => {
            const canvas = document.createElement("canvas");
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            const context = canvas.getContext("2d");
            context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
            const thumbnail = canvas.toDataURL("image/jpeg");
            URL.revokeObjectURL(videoElement.src);
            resolve(thumbnail);
          });
          videoElement.addEventListener("error", (error) => {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  mounted() {
    // var zohoChatScript = document.createElement("script");
    // zohoChatScript.type = "text/javascript";
    // clarityScript.src = 'https://widget.cloudinary.com/v2.0/global/all.js'

    this.fetchGallery();
  },
};
</script>

<style lang="scss">
.gallery-picker-btn .v-btn__content {
  display: flex !important;
  justify-content: flex-start !important;
}
.gallery-item {
  position: relative;

  .v-input--checkbox {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>