var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1"},[_c('p',{staticClass:"text-sm font-medium text-gray-700 whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm.metricName)+" "),_c('tool-tip',{staticClass:"tooltip-container",attrs:{"message":_vm.tooltipMessage}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information")])],1)],1),_c('div',{staticClass:"text-lg font-bold whitespace-nowrap"},[(!_vm.isLoading && _vm.metricsData?.length > 0)?_c('div',{staticClass:"flex gap-2 text-2xl font-bold whitespace-nowrap items-center"},[(typeof _vm.metricData === 'number' && !isNaN(_vm.metricData))?_c('span',[_vm._v(" "+_vm._s(_vm.metricData)+" ")]):(isNaN(_vm.metricData))?_c('span',[_vm._v(" -- ")]):_vm._e(),(
          _vm.previousMonthAggregatedDataLoading && _vm.selectedDate?.months !== 12
        )?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":16,"width":3}}):_vm._e(),(
          !_vm.previousMonthAggregatedDataLoading &&
          _vm.metricChange &&
          _vm.selectedDate?.months !== 12 &&
          typeof _vm.metricChange === 'number' &&
          !isNaN(_vm.metricChange)
        )?_c('span',{staticClass:"text-sm font-semibold",class:{
          'text-green-500': _vm.metricChange >= 0,
          'text-red-500': _vm.metricChange < 0,
        }},[_c('v-icon',{attrs:{"small":"","color":_vm.metricChange >= 0 ? 'green' : 'red'}},[_vm._v(_vm._s(_vm.metricChange == 0 ? "mdi-minus" : _vm.metricChange > 0 ? "mdi-arrow-up" : "mdi-arrow-down"))]),_vm._v(" "+_vm._s(_vm.metricChange)+"% ")],1):_vm._e()],1):_vm._e(),(_vm.isLoading)?_c('span',{staticClass:"skeleton-loader"}):(
        !_vm.isLoading &&
        !_vm.aggregatedData &&
        !(_vm.metricsData?.length > 0 || _vm.chartData?.length > 0)
      )?_c('span',[_vm._v("--")]):_vm._e()]),(_vm.months != 12 && !_vm.isLoading)?_c('div',[_c('p',{staticClass:"text-xs text-gray-500 font-medium"},[_vm._v(_vm._s(_vm.months == 1 ? 'vs Last Month' : `vs Last ${_vm.months} Months`))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }