<template>
  <div class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]">
    <section class="flex flex-col items-center w-full justify-center xl:w-[1285px]">
      <div class="w-full mx-auto">
        <div class="bg-white rounded-lg shadow-sm p-4 md:p-6 mb-6 min-h-[80vh]">
          <!-- Existing filter and button section -->
          <div class="flex flex-col gap-4">
            <div class="flex flex-col md:flex-row md:items-center justify-between gap-4">
              <div class="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 w-full">
                <div class="w-full sm:w-auto flex-1">
                  <div class="location-dropdown relative md:max-w-[200px]">
                    <v-btn @click="toggleStoreDropdown($event)" outlined color="primary">
                      {{ selectedWIDs?.length || 0 }} Selected Locations
                    </v-btn>
                    <LocationDropdown ref="locationDropdown" :stores="filteredStores" :reviewReport="true"
                      :showStoreDropdown="showStoreDropdown" :totalAverageRating="totalAverageRating"
                      v-model="selectedWIDs" @change="handleLocationChange" />
                  </div>
                </div>
                <div class="w-full sm:w-auto flex-1">
                  <v-select v-model="selectedRatingRange" :items="ratingRanges" label="Filter by Locations Rating"
                    class="rating-filter mt-2" dense :disabled="isGenerating" outlined />
                </div>
                <v-btn color="primary" @click="generateReport" :loading="isGenerating"
                  class="px-6 h-[40px] w-full sm:w-auto">
                  <v-icon left>mdi-file-chart</v-icon> Generate Last 6 Months Report
                </v-btn>
              </div>
            </div>
            <div v-if="hasData" class="flex flex-col sm:flex-row gap-4 py-4 border-t border-gray-200">
              <v-btn color="secondary" @click="downloadReport" :loading="isDownloading"
                class="px-6 h-[40px] w-full sm:w-auto">
                <v-icon left>mdi-download</v-icon> Download Report
              </v-btn>
              <div v-if="lastGeneratedAt" class="text-center flex items-center justify-end">
                <p class="text-sm text-gray-500">Generated {{ lastGeneratedAt }}</p>
              </div>
            </div>
          </div>

          <!-- Report Data -->
          <div v-if="hasData" class="space-y-6">
            <!-- Desktop Table -->
            <div class="bg-white rounded-lg shadow-sm overflow-hidden">
              <div class="overflow-x-auto">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Month</th>
                      <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Reviews</th>
                      <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Average Rating</th>
                      <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Positive Feedback</th>
                      <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Negative Feedback</th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <template v-for="(data, month) in reportData.data">
                      <tr>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ formatMonth(month) }}</td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ data.aggregated?.totalReviews }}</td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <span class="text-sm font-medium text-gray-900 mr-2">
                              {{ calculateAverageRating(data.aggregated.ratingDistribution).toFixed(1) }}
                            </span>
                            <v-icon v-for="i in 5" :key="i" small
                              :color="i <= calculateAverageRating(data.aggregated.ratingDistribution) ? 'amber' : 'grey lighten-2'">
                              mdi-star
                            </v-icon>
                          </div>
                        </td>
                        <td class="px-6 py-4">
                          <ul v-if="data.aggregated?.feedback?.positive?.length > 0"
                            class="list-disc pl-4 text-sm text-green-600 space-y-1 max-w-xs">
                            <li v-for="(point, index) in limitFeedback(data.aggregated?.feedback?.positive)" :key="index">{{ point }}</li>
                            <li v-if="data.aggregated?.feedback?.positive?.length > 5" class="cursor-pointer text-blue-600 mt-2 list-none">
                              <span @click="showAllFeedback(data.aggregated?.feedback?.positive, 'positive', month)">
                                Show {{ data.aggregated?.feedback?.positive?.length - 5 }} more...
                              </span>
                            </li>
                          </ul>
                          <span v-else class="text-sm text-gray-500">No positive feedback</span>
                        </td>
                        <td class="px-6 py-4">
                          <ul v-if="data.aggregated?.feedback?.negative?.length > 0"
                            class="list-disc pl-4 text-sm text-red-600 space-y-1 max-w-xs">
                            <li v-for="(point, index) in limitFeedback(data.aggregated?.feedback?.negative)" :key="index">{{ point }}</li>
                            <li v-if="data.aggregated?.feedback?.negative?.length > 5" class="cursor-pointer text-blue-600 mt-2 list-none">
                              <span @click="showAllFeedback(data.aggregated?.feedback?.negative, 'negative', month)">
                                Show {{ data.aggregated?.feedback?.negative?.length - 5 }} more...
                              </span>
                            </li>
                          </ul>
                          <span v-else class="text-sm text-gray-500">No negative feedback</span>
                        </td>
                      </tr>
                      <tr v-if="data.aggregated.keywords.length > 0">
                        <td colspan="5" class="px-6 py-4 bg-gray-50">
                          <div class="flex flex-wrap gap-2 items-center">
                            <span class="text-sm font-medium text-gray-600 mr-2">Key Insights:</span>
                            <template v-for="type in ['positive', 'neutral', 'negative']">
                              <span v-for="(kw, idx) in limitKeywords(data.aggregated.keywords.filter(k => k.type === type))" :key="`${type}-${idx}`"
                                :class="['inline-flex items-center px-2 py-1 rounded-full text-sm cursor-pointer', getKeywordClass(kw.type)]"
                                @click="showLocationsForKeyword(kw, month)">
                                {{ kw.text }} ({{ kw.count }})
                              </span>
                            </template>
                            <span v-if="data.aggregated.keywords.length > 10" 
                              class="inline-flex items-center px-2 py-1 rounded-full text-sm cursor-pointer bg-gray-100 text-gray-800"
                              @click="showAllKeywords(data.aggregated.keywords, month)">
                              Show {{ data.aggregated.keywords.length - 10 }} more...
                            </span>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Mobile View - Update mobile cards with limited feedback and keywords -->
            <div v-if="reportData?.data" class="md:hidden space-y-6">
              <div v-for="(data, month) in reportData.data" :key="month" class="bg-white rounded-lg shadow-sm p-4">
                <div class="mb-4">
                  <h3 class="text-lg font-semibold text-gray-800">{{ formatMonth(month) }}</h3>
                  <div class="flex items-center gap-4 mt-2">
                    <!-- Existing header code -->
                  </div>
                </div>
                <div class="space-y-4">
                  <div class="p-3 bg-green-50 rounded-lg">
                    <div class="font-medium text-green-700 mb-2">Positive Feedback</div>
                    <ul v-if="data.aggregated?.feedback?.positive?.length > 0"
                      class="list-disc pl-4 text-sm text-green-600 space-y-1">
                      <li v-for="(point, index) in limitFeedback(data.aggregated?.feedback?.positive)" :key="index">{{ point }}</li>
                      <li v-if="data.aggregated?.feedback?.positive?.length > 5" class="cursor-pointer text-blue-600 mt-2 list-none">
                        <span @click="showAllFeedback(data.aggregated?.feedback?.positive, 'positive', month)">
                          Show {{ data.aggregated?.feedback?.positive?.length - 5 }} more...
                        </span>
                      </li>
                    </ul>
                    <span v-else class="text-sm text-gray-500">No positive feedback</span>
                  </div>
                  <div class="p-3 bg-red-50 rounded-lg">
                    <div class="font-medium text-red-700 mb-2">Negative Feedback</div>
                    <ul v-if="data.aggregated?.feedback?.negative?.length > 0"
                      class="list-disc pl-4 text-sm text-red-600 space-y-1">
                      <li v-for="(point, index) in limitFeedback(data.aggregated?.feedback?.negative)" :key="index">{{ point }}</li>
                      <li v-if="data.aggregated?.feedback?.negative?.length > 5" class="cursor-pointer text-blue-600 mt-2 list-none">
                        <span @click="showAllFeedback(data.aggregated?.feedback?.negative, 'negative', month)">
                          Show {{ data.aggregated?.feedback?.negative?.length - 5 }} more...
                        </span>
                      </li>
                    </ul>
                    <span v-else class="text-sm text-gray-500">No negative feedback</span>
                  </div>
                  <div class="p-3 bg-blue-50 rounded-lg">
                    <div class="font-medium text-blue-700 mb-2">Keywords</div>
                    <div class="flex flex-wrap gap-2">
                      <template v-for="type in ['positive', 'neutral', 'negative']">
                        <span v-for="(kw, idx) in limitKeywords(data.aggregated.keywords.filter(k => k.type === type))" 
                          :key="`${type}-${idx}`"
                          :class="['inline-flex items-center px-2 py-1 rounded-full text-sm cursor-pointer', getKeywordClass(kw.type)]"
                          @click="showLocationsForKeyword(kw, month)">
                          {{ kw.text }} ({{ kw.count }})
                        </span>
                      </template>
                      <span v-if="data.aggregated.keywords.length > 10" 
                        class="inline-flex items-center px-2 py-1 rounded-full text-sm cursor-pointer bg-gray-100 text-gray-800"
                        @click="showAllKeywords(data.aggregated.keywords, month)">
                        Show {{ data.aggregated.keywords.length - 10 }} more...
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Additional modals -->
    <v-dialog v-model="showAllKeywordsModal" max-width="600px">
      <v-card>
        <v-card-title class="text-md grey lighten-2 d-flex justify-space-between align-center sticky top-0 z-10">
          <div class="flex-grow-1">All Keywords - {{ selectedMonth ? formatMonth(selectedMonth) : '' }}</div>
          <v-btn icon @click="showAllKeywordsModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-4">
          <div class="flex flex-wrap gap-2">
            <template v-for="type in ['positive', 'neutral', 'negative']">
              <span v-for="(kw, idx) in allKeywords.filter(k => k.type === type)" 
                :key="`${type}-${idx}`"
                :class="['inline-flex items-center px-2 py-1 rounded-full text-sm cursor-pointer mb-2', getKeywordClass(kw.type)]"
                @click="showLocationsForKeyword(kw, selectedMonth); showAllKeywordsModal = false;">
                {{ kw.text }} ({{ kw.count }})
              </span>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAllFeedbackModal" max-width="600px">
      <v-card>
        <v-card-title class="text-md grey lighten-2 d-flex justify-space-between align-center sticky top-0 z-10">
          <div class="flex-grow-1">
            {{ feedbackType === 'positive' ? 'Positive' : 'Negative' }} Feedback - {{ selectedMonth ? formatMonth(selectedMonth) : '' }}
          </div>
          <v-btn icon @click="showAllFeedbackModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-4">
          <ul :class="[
            'list-disc pl-4 text-sm space-y-1 max-w-full', 
            feedbackType === 'positive' ? 'text-green-600' : 'text-red-600'
          ]">
            <li v-for="(point, index) in allFeedback" :key="index">{{ point }}</li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Original location modal for a specific keyword -->
    <v-dialog v-model="showLocationModal" max-width="600px" scrollable>
      <v-card>
        <v-card-title class="text-md grey lighten-2 d-flex justify-space-between align-center sticky top-0 z-10">
          <div class="flex-grow-1">Locations for "{{ selectedKeyword?.text }}"</div>
          <div class="text-caption text-grey-darken-1">({{ filteredLocations.length }} locations)</div>
        </v-card-title>
        
        <v-card-text class="pt-4">
          <!-- Search input with new variable name -->
          <v-text-field
            v-model="keywordLocationSearch"
            dense
            outlined
            placeholder="Search locations..."
            prepend-inner-icon="mdi-magnify"
            class="mb-4"
            hide-details
          ></v-text-field>

          <v-list dense class="location-list">
            <v-list-item v-for="(locationId, index) in paginatedLocations" :key="index">
              <v-list-item-content class="py-2">
                <div class="d-flex justify-space-between align-center gap-4">
                  <div class="flex-grow-1 min-w-0">
                    <div class="text-subtitle-2 font-weight-medium break-words">
                      {{ findLocationName(locationId.id) }}
                    </div>
                    <div class="text-caption text-grey-darken-1 break-words">
                      {{ findLocationAddress(locationId.id) }}
                    </div>
                  </div>
                  <div class="text-caption font-weight-medium whitespace-nowrap">
                    ({{ locationId.count }})
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            
            <v-list-item v-if="filteredLocations.length === 0">
              <v-list-item-content>
                <v-list-item-title class="text-sm text-gray-500 text-center">
                  {{ keywordLocationSearch ? 'No matching locations found' : 'No locations found' }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <!-- Pagination -->
          <div class="d-flex justify-center mt-4">
            <v-pagination
              v-if="filteredLocations.length > itemsPerPage"
              v-model="currentPage"
              :length="Math.ceil(filteredLocations.length / itemsPerPage)"
              :total-visible="5"
            ></v-pagination>
          </div>
        </v-card-text>

        <v-card-actions class="sticky bottom-0 bg-white border-t">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showLocationModal = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { collection, onSnapshot, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import LocationDropdown from '@/components/LocationDropdown.vue';

export default {
  components: {
    LocationDropdown
  },
  name: 'GmbReviewsReport',

  data() {
    return {
      selectedDate: null,
      dateOptions: [],
      selectedWIDs: [],
      isLoadingDates: false,
      isGenerating: false,
      reportData: null,
      stores: [],
      showStoreDropdown: false,
      selectedWID: null,
      reviewsCount: null,
      selectedStore: null,
      locationSearch: "",
      isDownloading: false,
      isSending: false,
      locationRatings: null,
      isLoadingRatings: false,
      selectedRatingRange: null,
      reportFetched: false,
      ratingRanges: [
        { text: 'All Ratings', value: null },
        { text: 'Above 4 Stars', value: '4' },
        { text: '3-4 Stars', value: '3' },
        { text: '2-3 Stars', value: '2' },
        { text: 'Below 2 Stars', value: '0' },
      ],
      storesToShow: 20,
      totalAverageRating: '0',
      lastGeneratedAt: null,
      // New properties for the modal
      showLocationModal: false,
      selectedLocations: [],
      selectedKeyword: null,
      keywordLocationSearch: '',
      currentPage: 1,
      itemsPerPage: 10,
      showAllKeywordsModal: false,
      selectedAllKeywords: [],
      selectedKeywordMonth: '',
      feedbackType: null,
      selectedAllFeedback: [],
      
      // Add these new properties
      showAllKeywordsModal: false,
      allKeywords: [],
      selectedMonth: null,
      
      showAllFeedbackModal: false,
      allFeedback: [],
    }
  },

  computed: {
    filteredStores() {
      const searchTerm = this.locationSearch.toLowerCase();
      return this.stores.filter(store => {
        const matchesSearch = store.name?.toLowerCase().includes(searchTerm) ||
          store.sub_locality?.toLowerCase().includes(searchTerm) ||
          store.city?.toLowerCase().includes(searchTerm);

        const matchesRating = this.selectedRatingRange ?
          (this.selectedRatingRange === '0' ? store.averageRating < 2 :
            store.averageRating >= Number(this.selectedRatingRange) &&
            store.averageRating < Number(this.selectedRatingRange) + 1) : true;

        return matchesSearch && matchesRating;
      });
    },

    paginatedStores() {
      return this.filteredStores.slice(0, this.storesToShow);
    },

    hasData() {
      return this.reportData?.data && Object.keys(this.reportData.data).length > 0;
    },

    currentMonthData() {
      if (!this.reportData?.data) return null;
      // Get the first month's data if no specific month is selected
      const monthKey = Object.keys(this.reportData.data)[0];
      return this.reportData.data[monthKey];
    },

    isLocationDropdownDisabled() {
      return this.selectedRatingRange !== null;
    },

    canGenerateReport() {
      return !this.isLoadingRatings && (!this.selectedRatingRange || this.locationRatings);
    },

    filteredLocations() {
      if (!this.keywordLocationSearch) {
        return this.selectedLocations;
      }
      const searchTerm = this.keywordLocationSearch.toLowerCase();
      return this.selectedLocations.filter(location => {
        const name = this.findLocationName(location.id)?.toLowerCase() || '';
        const address = this.findLocationAddress(location.id)?.toLowerCase() || '';
        return name.includes(searchTerm) || address.includes(searchTerm);
      });
    },

    paginatedLocations() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredLocations.slice(start, end);
    },
  },

  methods: {
    findLocationAddress(locationId) {
      const location = this.stores.find(item => 
        item.locationId === locationId || 
        item.placeId === locationId
      );
      return `${location?.sub_locality||location?.city}`;
    },
    findLocationName(locationId) {
      const location = this.stores.find(item => 
        item.locationId === locationId || 
        item.placeId === locationId
      );
      return `${location?.name}`;
    },
    showLocationsForKeyword(keyword, month) {
      // We'll need the month to access the correct keyword map
      const currentMonth = month || Object.keys(this.reportData.data)[0]; // Default to first month if not provided
      const key = `${keyword.text}-${keyword.type}`;
      
      this.selectedLocations = (this.reportData?.keywordLocationMap && 
                               this.reportData.keywordLocationMap[currentMonth] && 
                               this.reportData.keywordLocationMap[currentMonth][key]) 
        ? this.reportData.keywordLocationMap[currentMonth][key] 
        : [];
      
      this.selectedKeyword = keyword;
      this.selectedKeywordMonth = month;
      this.showLocationModal = true;
    },
    async handleLocationChange(profile) {
      this.selectedWIDs = this.stores
        .filter(item => item.checked) // Get checked items
        .map(item => item.id); // Extract their IDs

      // If selectedWIDs is empty, push all item IDs
      if (this.selectedWIDs.length === 0) {
        this.selectedWIDs = this.stores.map(item => item.id);
      }

      this.selectedWID = profile.wid;
    },
    async generateReport() {
      this.isGenerating = true;
      try {
        const generateReviewsReport = httpsCallable(functions, 'gmbReviewsReport');
        let payload = {
          action: 'generate_report',
          uid: this.currentUID,
        };

        let storesForReport =
          this.selectedWIDs.length > 0
            ? this.stores.filter((store) => this.selectedWIDs.includes(store.wid))
            : this.stores;
        if (this.selectedRatingRange) {
          const minRating = Number(this.selectedRatingRange);
          const maxRating = minRating + 1;
          storesForReport = storesForReport.filter((store) => {
            const storeRating = store.averageRating || 0;
            return minRating === 0
              ? storeRating < 2
              : storeRating >= minRating && storeRating < maxRating;
          });
        }

        const locations = storesForReport.map((store) => ({
          locationId: store.locationId || store.placeId,
          accountId: store.gmbAccountId || null,
        })).filter((loc) => loc.locationId);

        if (locations.length === 0) {
          throw new Error('No locations match the selected criteria');
        }

        payload = { ...payload, locations, ratingRange: this.selectedRatingRange };
        const result = await generateReviewsReport(payload);

        if (result.data?.success) {
          // Aggregate data across locations
          const aggregatedData = {};
          const keywordLocationMap = {}; // We'll now organize by month-keyword

          Object.entries(result.data.data).forEach(([locationId, locationData]) => {
            Object.entries(locationData).forEach(([month, stats]) => {
              if (!aggregatedData[month]) {
                aggregatedData[month] = {
                  aggregated: {
                    totalReviews: 0,
                    ratingDistribution: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0 },
                    repliedCount: 0,
                    unrepliedCount: 0,
                    feedback: {
                      positive: [],
                      negative: [],
                      neutral: [],
                      keywords: [],
                    },
                    keywords: [],
                  },
                };
              }
              
              // Make sure month exists in keywordLocationMap
              if (!keywordLocationMap[month]) {
                keywordLocationMap[month] = {};
              }
              
              const monthAggregated = aggregatedData[month].aggregated;
              monthAggregated.totalReviews += stats.totalReviews || 0;
              Object.entries(stats.ratingDistribution || {}).forEach(([rating, count]) => {
                monthAggregated.ratingDistribution[rating] = (monthAggregated.ratingDistribution[rating] || 0) + count;
              });
              monthAggregated.repliedCount += stats.repliedCount || 0;
              monthAggregated.unrepliedCount += stats.unrepliedCount || 0;

              // Ensure stats.feedback exists before trying to access its properties
              const statsFeedback = stats.feedback || {};
              
              // Aggregate feedback (unique points) with defensive coding
              monthAggregated.feedback.positive = [
                ...new Set([...monthAggregated.feedback.positive, ...(statsFeedback.positive || [])]),
              ];
              monthAggregated.feedback.negative = [
                ...new Set([...monthAggregated.feedback.negative, ...(statsFeedback.negative || [])]),
              ];
              monthAggregated.feedback.neutral = [
                ...new Set([...monthAggregated.feedback.neutral, ...(statsFeedback.neutral || [])]),
              ];

              // Aggregate keywords and map to location IDs with counts
              (stats.keywords || []).forEach((kw) => {
                const key = `${kw.text}-${kw.type}`; // Unique key for each keyword
                
                // Make sure keyword exists in this month's map
                if (!keywordLocationMap[month][key]) {
                  keywordLocationMap[month][key] = [];
                }
                
                // Check if this location already exists in the map for this month and keyword
                const existingLocation = keywordLocationMap[month][key].find(item => item.id === locationId);
                if (existingLocation) {
                  existingLocation.count += kw.count; // Increment count if exists
                } else {
                  keywordLocationMap[month][key].push({ id: locationId, count: kw.count }); // Add new location with count
                }

                const existingKw = monthAggregated.keywords.find(
                  (k) => k.text === kw.text && k.type === kw.type
                );
                if (existingKw) {
                  existingKw.count += kw.count;
                } else {
                  monthAggregated.keywords.push({ ...kw });
                }
              });
            });
          });

          // Sort location lists by count in descending order for each month and keyword
          Object.keys(keywordLocationMap).forEach(month => {
            Object.keys(keywordLocationMap[month]).forEach(key => {
              keywordLocationMap[month][key].sort((a, b) => b.count - a.count);
            });
          });

          this.reportData = { data: aggregatedData, keywordLocationMap };
          sessionStorage.setItem('gmbreviewReportSelectedLocations', JSON.stringify(this.selectedWIDs));
          sessionStorage.setItem('gmbreviewReportSelectedRatingRange', this.selectedRatingRange);
          sessionStorage.setItem('gmbreviewReportData', JSON.stringify(this.reportData));
          const timestamp = new Date().toISOString();
          sessionStorage.setItem('gmbreviewReportLastGeneratedAt', timestamp);
          this.lastGeneratedAt = this.formatTimeAgo(timestamp);
        } else {
          throw new Error(result.data?.error || 'Failed to generate report');
        }
      } catch (error) {
        console.error('Error generating report:', error);
        this.$alert.show(error.message || 'Failed to generate report');
      } finally {
        this.isGenerating = false;
        this.reportFetched = true;
      }
    },

    formatTimeAgo(timestamp) {
      const now = new Date();
      const past = new Date(timestamp);
      const diffInSeconds = Math.floor((now - past) / 1000);

      const intervals = [
        { label: 'year', seconds: 31536000 },
        { label: 'month', seconds: 2592000 },
        { label: 'day', seconds: 86400 },
        { label: 'hour', seconds: 3600 },
        { label: 'minute', seconds: 60 },
        { label: 'second', seconds: 1 }
      ];

      for (const interval of intervals) {
        const count = Math.floor(diffInSeconds / interval.seconds);
        if (count > 0) {
          return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
        }
      }
      return 'just now';
    },

    async fetchStores() {
      try {
        if (!this.currentUID || !this.WID) {
          console.log('Waiting for user data...');
          return;
        }

        // Create a batch query for stores without real-time listener
        const storeRef = collection(db, "users", this.currentUID, "fly_workspaces");
        const storeQuery = query(storeRef, where("admin", "==", this.WID), where("storeWorkspace", "==", true));

        // Fetch stores once instead of setting up a real-time listener
        const querySnapshot = await getDocs(storeQuery);
        const storesDocs = querySnapshot.docs;

        const storesArray = storesDocs.map(storeDoc => {
          const storeData = storeDoc.data();
          this.selectedWIDs.push(storeDoc.id);
          return {
            ...storeData,
            wid: storeDoc.id,
            type: "store",
            averageRating: storeData.averageRating || 0, // Ensure averageRating is fetched correctly
            checked: true,
            isReview: true,
            locationId: storeData.gmbLocationId || storeData.placeId,
            reviewCount: storeData.reviewCount || 0 // Fetch reviewCount
          };
        });


        this.stores = storesArray; // Store all fetched stores
        this.storesToShow = this.stores.slice(0, this.storesToShow); // Initialize visible stores
        // Calculate total average rating correctly
        const totalRatings = this.stores.reduce((sum, store) => {
          const storeRating = store.averageRating || 0; // Ensure averageRating is fetched correctly
          const storeReviewCount = store.reviewCount || 0; // Use reviewCount or default to 0
          return sum + (storeRating * storeReviewCount); // Calculate weighted rating
        }, 0);

        const totalCount = this.stores.reduce((sum, store) => sum + (store.reviewCount || 0), 0); // Sum of all review counts
        this.reviewsCount = totalCount;
        this.totalAverageRating = totalCount > 0 ? (totalRatings / totalCount).toFixed(1) : 0;

      } catch (error) {
        console.error('Error fetching stores:', error);
        this.$alert?.show('Failed to fetch stores: ' + error.message);
      }
    },

    calculateAverageRating(ratingDistribution) {
      if (!ratingDistribution) return 0;

      const totalRatings = Object.entries(ratingDistribution).reduce(
        (sum, [rating, count]) => sum + (Number(rating) * count), 0
      );
      const totalCount = Object.values(ratingDistribution).reduce((sum, count) => sum + count, 0);

      return totalCount > 0 ? totalRatings / totalCount : 0;
    },

    calculateResponseRate(monthData) {
      if (!monthData?.totalReviews) return 0;
      return ((monthData.repliedCount / monthData.totalReviews) * 100).toFixed(0);
    },

    getSentimentColor(type) {
      const colors = {
        positive: 'bg-green-50 text-green-700',
        negative: 'bg-red-50 text-red-700',
        neutral: 'bg-blue-50 text-blue-700'
      };
      return colors[type] || 'bg-gray-50 text-gray-700';
    },

    toggleStoreDropdown(event) {
      // if (this.isLocationDropdownDisabled) {
      //     return;
      // }
      event.stopPropagation();
      this.showStoreDropdown = !this.showStoreDropdown;
      if (this.showStoreDropdown) {
        this.locationSearch = '';
      }
    },

    handleClickOutside(event) {
      const dropdown = this.$refs.locationDropdown?.$refs.showStoreDropdown;
      const input = event.target.closest(".location-dropdown");
      if (!input && dropdown && !dropdown.contains(event.target)) {
        this.showStoreDropdown = false;
      }
    },

    selectStore(profile) {
      // console.log('Selecting store:', profile);
      this.selectedStore = profile;
      this.selectedWID = profile.wid;
      this.locationSearch = '';
      this.showStoreDropdown = false;

      // Generate report automatically when store is selected
    },

    handleSearchInput(event) {
      // If there's a selected store, clear it when user starts searching
      if (this.selectedStore && event.target.value) {
        this.selectedStore = null;
        this.selectedWID = null;
      }
      this.showStoreDropdown = true;

      // Trigger filtering when the rating range is selected
      this.selectedRatingRange && this.fetchStores(); // Ensure stores are fetched again based on the selected rating
    },

    generateDateOptions() {
      const options = [];
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();

      // Generate options for the past 12 months
      for (let i = 0; i < 12; i++) {
        const date = new Date(currentYear, currentMonth - i, 1);
        const year = date.getFullYear();
        const monthName = date.toLocaleString('default', { month: 'long' });

        options.push({
          text: `${monthName} ${year}`,
          value: {
            year: year,
            month: date.getMonth() + 1
          }
        });
      }

      this.dateOptions = options;
      this.selectedDate = null;
    },

    formatMonth(monthStr) {
      const [year, month] = monthStr.split('-');
      return new Date(year, month - 1).toLocaleString('default', {
        month: 'long',
        year: 'numeric'
      });
    },

    truncateText(text, maxLength) {
      if (!text) return '';
      return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    },

    async downloadReport() {
      this.isDownloading = true;
      try {
        const html2pdf = (await import('html2pdf.js')).default;
        const container = document.createElement('div');
        container.style.padding = '24px';
        container.style.fontFamily = 'Arial, sans-serif';
        container.style.maxWidth = '1200px';
        container.style.margin = '0 auto';

        const title = this.selectedStore
          ? `GMB Reviews Insights - ${this.selectedStore.sub_locality}`
          : 'GMB Reviews Insights - All Locations';
        container.innerHTML = `
      <div style="margin-bottom: 24px;">
        <h1 style="font-size: 24px; font-weight: 600; color: #1F2937; margin-bottom: 24px;">${title}</h1>
      </div>
    `;

        if (this.reportData?.data) {
          const tableContainer = document.createElement('div');
          tableContainer.style.backgroundColor = '#FFFFFF';
          tableContainer.style.borderRadius = '8px';
          tableContainer.style.boxShadow = '0 1px 2px 0 rgba(0, 0, 0, 0.05)';
          tableContainer.style.overflow = 'hidden';
          tableContainer.style.pageBreakInside = 'avoid';

          tableContainer.innerHTML = `
        <table style="width: 100%; border-collapse: collapse;">
          <thead style="background-color: #F9FAFB; display: table-header-group;">
            <tr>
              <th style="padding: 12px 24px; text-align: left; font-size: 12px; font-weight: 500; color: #6B7280; text-transform: uppercase; border-bottom: 1px solid #E5E7EB;">Month</th>
              <th style="padding: 12px 24px; text-align: left; font-size: 12px; font-weight: 500; color: #6B7280; text-transform: uppercase; border-bottom: 1px solid #E5E7EB;">Total Reviews</th>
              <th style="padding: 12px 24px; text-align: left; font-size: 12px; font-weight: 500; color: #6B7280; text-transform: uppercase; border-bottom: 1px solid #E5E7EB;">Average Rating</th>
              <th style="padding: 12px 24px; text-align: left; font-size: 12px; font-weight: 500; color: #6B7280; text-transform: uppercase; border-bottom: 1px solid #E5E7EB;">Positive Feedback</th>
              <th style="padding: 12px 24px; text-align: left; font-size: 12px; font-weight: 500; color: #6B7280; text-transform: uppercase; border-bottom: 1px solid #E5E7EB;">Negative Feedback</th>
            </tr>
          </thead>
          <tbody>
            ${Object.entries(this.reportData.data)
              .map(
                ([month, data]) => `
                  <tr style="page-break-inside: avoid;">
                    <td style="padding: 16px 24px; font-size: 14px; font-weight: 500; color: #111827; vertical-align: top;">
                      ${this.formatMonth(month)}
                    </td>
                    <td style="padding: 16px 24px; font-size: 14px; color: #6B7280; vertical-align: top;">
                      ${data.aggregated?.totalReviews}
                    </td>
                    <td style="padding: 16px 24px; vertical-align: top;">
                      <div style="display: flex; align-items: center;">
                        <span style="font-size: 14px; font-weight: 500; color: #111827; margin-right: 8px;">
                          ${this.calculateAverageRating(data.aggregated.ratingDistribution).toFixed(1)}
                        </span>
                        <div style="color: #F59E0B;">★★★★★</div>
                      </div>
                    </td>
                    <td style="padding: 16px 24px; vertical-align: top;">
                      ${this.formatFeedbackForPDF(data.aggregated.feedback.positive, true)}
                    </td>
                    <td style="padding: 16px 24px; vertical-align: top;">
                      ${this.formatFeedbackForPDF(data.aggregated.feedback.negative, false)}
                    </td>
                  </tr>
                  ${data.aggregated.keywords?.length > 0
                    ? `
                        <tr style="background-color: #F9FAFB; page-break-inside: avoid;">
                          <td colspan="5" style="padding: 12px 24px;">
                            <div style="display: flex; flex-wrap: wrap; gap: 8px; align-items: center;">
                              <span style="font-size: 12px; font-weight: 500; color: #6B7280; margin-right: 8px;">Review Highlights:</span>
                              ${data.aggregated.keywords
                      .map(
                        (keyword) => `
                                    <span style="
                                      display: inline-block;
                                      padding: 4px 8px 1.5% 8px;
                                      border-radius: 9999px;
                                      font-size: 12px;
                                      font-weight: 500;
                                      margin: 2px;
                                      ${keyword.type === 'positive'
                            ? 'background-color: #DEF7EC; color: #03543F;'
                            : keyword.type === 'negative'
                              ? 'background-color: #FDE8E8; color: #9B1C1C;'
                              : 'background-color: #E1EFFE; color: #1E429F;'
                          }
                                    ">
                                      ${keyword.text} (${keyword.count})
                                    </span>
                                  `
                      )
                      .join('')}
                            </div>
                          </td>
                        </tr>
                      `
                    : ''
                  }
                `
              )
              .join('')}
          </tbody>
        </table>
      `;
          container.appendChild(tableContainer);
        }

        const opt = {
          margin: [15, 15],
          filename: `gmb-reviews-report-${new Date().toISOString().split('T')[0]}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, logging: false, useCORS: true },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape', compress: true },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        };

        await html2pdf().set(opt).from(container).save();
      } catch (error) {
        console.error('Error downloading report:', error);
        this.$alert.show('Failed to download report');
      } finally {
        this.isDownloading = false;
      }
    },

    formatFeedbackForPDF(feedback, isPositive) {
      if (!Array.isArray(feedback) || feedback.length === 0) {
        return '<span style="color: #6B7280; font-size: 14px;">No feedback available</span>';
      }

      return `
                <ul style="margin: 0; padding-left: 16px; list-style-type: disc;">
                    ${feedback.map(point => `
                        <li style="color: ${isPositive ? '#059669' : '#DC2626'}; font-size: 14px; margin-bottom: 4px;">
                            ${point}
                        </li>
                    `).join('')}
                </ul>
            `;
    },

    async sendReport() {
      this.isSending = true;
      try {
        const html2pdf = (await import('html2pdf.js')).default;
        const container = document.createElement('div');
        container.style.padding = '20px';
        container.style.fontFamily = 'Arial, sans-serif';

        const title = this.selectedStore
          ? `GMB Reviews Report - ${this.selectedStore.sub_locality}`
          : 'GMB Reviews Report - All Locations';
        container.innerHTML = `
      <h1 style="color: #1a202c; margin-bottom: 20px; font-size: 24px;">${title}</h1>
      <p style="color: #4a5568; margin-bottom: 30px;">Report generated on ${new Date().toLocaleDateString()}</p>
    `;

        if (this.reportData?.data) {
          Object.entries(this.reportData.data).forEach(([month, data]) => {
            const monthSection = document.createElement('div');
            monthSection.style.marginBottom = '40px';
            monthSection.innerHTML = `
          <h2 style="color: #2d3748; margin-bottom: 20px; padding-bottom: 10px; border-bottom: 2px solid #e2e8f0; font-size: 20px;">
            ${this.formatMonth(month)}
          </h2>
          <div style="margin-bottom: 20px;">
            <p style="margin-bottom: 10px;">
              <strong>Total Reviews:</strong> ${data.aggregated?.totalReviews}
            </p>
            <p style="margin-bottom: 20px;">
              <strong>Average Rating:</strong> ${this.calculateAverageRating(data.aggregated.ratingDistribution).toFixed(1)} ⭐
            </p>
          </div>
          <div style="margin-bottom: 20px;">
            <h3 style="color: #2f855a; margin-bottom: 10px; font-size: 16px;">Positive Feedback</h3>
            ${this.formatFeedbackForPDF(data.aggregated.feedback.positive, 'green')}
          </div>
          <div style="margin-bottom: 20px;">
            <h3 style="color: #c53030; margin-bottom: 10px; font-size: 16px;">Negative Feedback</h3>
            ${this.formatFeedbackForPDF(data.aggregated.feedback.negative, 'red')}
          </div>
        `;
            container.appendChild(monthSection);
          });
        }

        const opt = {
          margin: [15, 15],
          filename: `gmb-reviews-report-${new Date().toISOString().split('T')[0]}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, logging: false, useCORS: true },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        const pdfBlob = await html2pdf().set(opt).from(container).output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);

        const shareOptions = document.createElement('div');
        shareOptions.innerHTML = `
      <div class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-6 max-w-md w-full mx-4">
          <h3 class="text-lg font-medium mb-4">Share Report</h3>
          <div class="space-y-4">
            <button class="w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center justify-center gap-2">
              <i class="mdi mdi-email"></i>
              Send via Email
            </button>
            <button class="w-full py-2 px-4 bg-green-500 text-white rounded hover:bg-green-600 flex items-center justify-center gap-2">
              <i class="mdi mdi-whatsapp"></i>
              Share via WhatsApp
            </button>
            <button class="w-full py-2 px-4 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 flex items-center justify-center gap-2">
              Cancel
            </button>
          </div>
        </div>
      </div>
    `;
        document.body.appendChild(shareOptions);

        const buttons = shareOptions.getElementsByTagName('button');
        buttons[0].onclick = () => {
          const emailSubject = encodeURIComponent(title);
          const emailBody = encodeURIComponent(
            `Please find attached the GMB Reviews Insights generated on ${new Date().toLocaleDateString()}.`
          );
          const mailtoLink = `mailto:?subject=${emailSubject}&body=${emailBody}&attachment=${pdfUrl}`;
          window.open(mailtoLink);
          document.body.removeChild(shareOptions);
        };
        buttons[1].onclick = () => {
          const whatsappText = encodeURIComponent(
            `${title}\n\nGenerated on ${new Date().toLocaleDateString()}`
          );
          const whatsappLink = `https://wa.me/?text=${whatsappText}&attachment=${pdfUrl}`;
          window.open(whatsappLink);
          document.body.removeChild(shareOptions);
        };
        buttons[2].onclick = () => {
          document.body.removeChild(shareOptions);
        };

        setTimeout(() => URL.revokeObjectURL(pdfUrl), 60000);
      } catch (error) {
        console.error('Error sharing report:', error);
        this.$alert.show('Failed to share report');
      } finally {
        this.isSending = false;
      }
    },

    clearStoreSelection() {
      this.selectedStore = null;
      this.selectedWID = null;
      this.locationSearch = '';
      this.showStoreDropdown = false;
      this.selectedRatingRange = null; // Reset rating filter
    },

    getKeywordClass(sentiment) {
      switch (sentiment?.toLowerCase()) {
        case 'positive':
          return 'bg-green-100 text-green-800';
        case 'negative':
          return 'bg-red-100 text-red-800';
        case 'neutral':
          return 'bg-blue-100 text-blue-800';
        default:
          return 'bg-gray-100 text-gray-800';
      }
    },

    loadMoreStores() {
      this.storesToShow += 20; // Increase the number of stores to show by 20
    },

    limitKeywords(keywords) {
      if (!keywords || !Array.isArray(keywords)) return [];
      return [...keywords]
        .sort((a, b) => {
          // First sort by type (positive, neutral, negative)
          const typeOrder = { positive: 1, neutral: 2, negative: 3 };
          const typeCompare = typeOrder[a.type] - typeOrder[b.type];
          
          // If same type, sort by count descending
          if (typeCompare === 0) {
            return b.count - a.count;
          }
          return typeCompare;
        })
        .slice(0, 10);
    },

    limitFeedback(feedback) {
      if (!feedback || !Array.isArray(feedback)) return [];
      return feedback.slice(0, 5);
    },

    showAllKeywords(keywords, month) {
      if (!keywords || !Array.isArray(keywords)) return;
      
      this.allKeywords = [...keywords].sort((a, b) => {
        // First sort by type (positive, neutral, negative)
        const typeOrder = { positive: 1, neutral: 2, negative: 3 };
        const typeCompare = typeOrder[a.type] - typeOrder[b.type];
        
        // If same type, sort by count descending
        if (typeCompare === 0) {
          return b.count - a.count;
        }
        return typeCompare;
      });
      
      this.selectedMonth = month;
      this.showAllKeywordsModal = true;
    },

    showAllFeedback(feedback, type, month) {
      if (!feedback || !Array.isArray(feedback)) return;
      this.allFeedback = feedback;
      this.feedbackType = type;
      this.selectedMonth = month;
      this.showAllFeedbackModal = true;
    },

  },

  async created() {
    this.generateDateOptions();
    if (this.currentUID && this.WID) {
      await this.fetchStores();
      const cachedSelectedLocations = sessionStorage.getItem('gmbreviewReportSelectedLocations');
      const cachedSelectedRatingRange = sessionStorage.getItem('gmbreviewReportSelectedRatingRange');
      const cachedReportData = sessionStorage.getItem('gmbreviewReportData');
      const cachedLastGeneratedAt = sessionStorage.getItem('gmbreviewReportLastGeneratedAt');

      if (cachedSelectedLocations) {
        this.selectedWIDs = JSON.parse(cachedSelectedLocations);
        this.stores = this.stores.map(item => {
          return this.selectedWIDs.includes(item.id) ? item : { ...item, checked: false };
        });
      }
      if (cachedSelectedRatingRange) {
        this.selectedRatingRange = JSON.parse(cachedSelectedRatingRange);
      }
      if (cachedReportData) {
        this.reportData = JSON.parse(cachedReportData);
      }
      else this.generateReport();
      if (cachedLastGeneratedAt) {
        this.lastGeneratedAt = this.formatTimeAgo(cachedLastGeneratedAt);
      }
    }
  },

  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },

  watch: {
    keywordLocationSearch() {
      this.currentPage = 1; // Reset to first page when search changes
    }
  },

}
</script>

<style scoped>
.v-select {
  max-width: none !important;
}

@media (min-width: 640px) {
  .v-select {
    max-width: 300px !important;
  }
}

/* Ensure consistent height for select inputs and button */
:deep(.v-input__control) {
  min-height: 40px !important;
}

:deep(.v-input__slot) {
  min-height: 40px !important;
}

/* Remove bottom margin from selects */
:deep(.v-text-field__details) {
  display: none;
}

/* Location dropdown styles */
.location-dropdown {
  position: relative;
}

.location-dropdown input {
  background: transparent;
}

.location-dropdown .relative:hover {
  border-color: #a0aec0;
}

.location-dropdown .relative:focus-within {
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px rgba(59, 130, 246, 0.5);
}

/* Add this to ensure proper table scroll on mobile */
.overflow-x-auto {
  -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.overflow-x-auto::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.overflow-x-auto {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@media (max-width: 768px) {
  .table-container {
    display: none;
  }
}

/* Button consistency */
:deep(.v-btn) {
  text-transform: none !important;
}

/* Ensure buttons stack properly on mobile */
@media (max-width: 640px) {
  .v-btn {
    width: 100%;
  }
}

.location-dropdown .v-progress-circular {
  margin: 0 4px;
}

.rating-filter {
  min-width: 150px;
}

/* Ensure consistent height with other filters */
:deep(.rating-filter .v-input__control) {
  min-height: 40px !important;
}

:deep(.rating-filter .v-input__slot) {
  min-height: 40px !important;
}

/* Add these new styles */
.rating-filter {
  max-width: 200px !important;
}

:deep(.rating-filter .v-input__slot) {
  cursor: pointer !important;
}

:deep(.rating-filter.v-input--is-disabled .v-input__slot) {
  cursor: not-allowed !important;
  opacity: 0.7;
}
</style>
