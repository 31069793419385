<template>
  <div
    class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]"
  >
    <section
      class="flex flex-col items-center w-full justify-center xl:w-[1285px]"
    >
      <div
        class="flex flex-col items-center justify-center w-full h-full pb-20"
      >
        <section
          class="flex flex-col items-center w-full justify-center xl:w-[100%]"
        >
          <div
            class=" w-full xl:w-[100%] flex justify-center items-center gap-4"
          >
            <div class="w-full bg-white rounded-b-xl">
              <div
                class="flex flex-col justify-between w-full px-5 py-2 mx-auto"
              >
                <div class="flex w-full items-center justify-between">
                  <v-list-item
                    @click="
                      showWelcomePopup = true;
                      showActionsMenu = false;
                    "
                  >
                    <v-list-item-title>Auto Respond Settings</v-list-item-title>
                    <v-icon>mdi-tune</v-icon>
                  </v-list-item>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>

    <!-- Location dropdown remains same -->
    <v-dialog v-model="showWelcomePopup" max-width="500px">
      <v-card>
        <v-card-title class="d-flex justify-space-between align-center">
          <span class="text-h6">Auto Respond Setting</span>
          <v-btn icon @click="closePopup">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-model="autoRespond"
            @change="updateAutoRespond"
            label="Enable Auto Respond Review"
          ></v-checkbox>

          <v-checkbox
            v-if="autoRespond"
            v-model="autoRespondNegative.value"
            @change="updateNegativeRespond"
            label="Enable Auto Respond Negative Review"
          ></v-checkbox>

          <div
            v-if="autoRespondNegative.value"
            class="ml-4 border-l-2 border-gray-300 pl-2"
          >
            <span class="font-weight-medium">Negative Reply Type:</span>
            <v-radio-group
              v-model="autoRespondNegative.type"
              @change="updateNegativeRespond"
              row
            >
              <v-radio label="AI Generated Reply" value="ai"></v-radio>
              <v-radio label="Manual Reply" value="manual"></v-radio>
            </v-radio-group>
          </div>

          <div
            v-if="
              autoRespondNegative.value && autoRespondNegative.type === 'manual'
            "
            class="ml-4 border-l-2 border-gray-300 pl-2"
          >
            <v-textarea
              v-model="autoRespondNegative.reply"
              label="Type your reply..."
              outlined
              rows="2"
              dense
            ></v-textarea>
            <v-btn
              :disabled="!autoRespondNegative.reply"
              color="primary"
              @click="submitManualReply"
            >
              Submit
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "@/utils/firebase.utils";

export default {
  data() {
    return {
      showActionsMenu: false,
      showWelcomePopup: false,
      autoRespond: false,
      autoRespondNegative: {
        value: false,
        type: "",
        reply: "",
      },
    };
  },
  methods: {
    async fetchAutoRespondStatus() {
      try {
        const workspaceRef = doc(
          db,
          "users",
          this.currentUID,
          "fly_workspaces",
          this.WID
        );
        const workspaceDoc = await getDoc(workspaceRef);
        if (workspaceDoc.exists()) {
          const workspaceData = workspaceDoc.data();
          this.autoRespond = workspaceData.gmb_auto_respond || false;
          this.autoRespondNegative = workspaceData.gmb_negative_respond || {
            value: false,
            type: "",
            reply: "",
          };
          console.log("status ", this.autoRespond, this.autoRespondNegative);
        }
      } catch (error) {
        console.error("Error fetching auto-respond status:", error);
      }
    },
    async updateAutoRespond() {
      try {
        const workspaceRef = doc(
          db,
          "users",
          this.currentUID,
          "fly_workspaces",
          this.WID
        );
        if (this.autoRespond) {
          await updateDoc(workspaceRef, {
            gmb_auto_respond: this.autoRespond,
          });
        } else {
          await updateDoc(workspaceRef, {
            gmb_auto_respond: this.autoRespond,
            gmb_negative_respond: {
              value: false,
              reply: "",
              type: "",
            },
          });
          this.autoRespondNegative = {
            value: false,
            reply: "",
            type: "",
          };
        }
        this.$snackbar.show(
          `Auto respond ${
            this.autoRespond ? "enabled" : "disabled"
          } successfully`
        );
      } catch (error) {
        console.error("Error updating auto respond setting:", error);
        this.$alert.show("Failed to update auto respond setting");
      }
    },
    async updateNegativeRespond() {
      try {
        const workspaceRef = doc(
          db,
          "users",
          this.currentUID,
          "fly_workspaces",
          this.WID
        );
        if (this.autoRespondNegative.value && this.autoRespondNegative.type) {
          this.autoRespondNegative.reply = "";
          await updateDoc(workspaceRef, {
            gmb_negative_respond: this.autoRespondNegative,
          });
          this.$snackbar.show(`AI Auto respond enabled`);
        } else {
          await updateDoc(workspaceRef, {
            gmb_negative_respond: {
              value: false,
              reply: "",
              type: "",
            },
          });
        }
      } catch (error) {
        console.error("Error updating negative respond setting:", error);
        this.$alert.show("Failed to update negative respond setting");
      }
    },
    async submitManualReply() {
      try {
        const workspaceRef = doc(
          db,
          "users",
          this.currentUID,
          "fly_workspaces",
          this.WID
        );
        await updateDoc(workspaceRef, {
          gmb_negative_respond: {
            value: true,
            reply: this.autoRespondNegative.reply,
            type: "manual",
          },
        });
        await this.closePopup();
        this.$snackbar.show("Manual reply submitted successfully");
      } catch (error) {
        console.log("error ", error);
        this.$alert.show("Failed to submit manual reply");
      }
    },
    closePopup() {
      this.showWelcomePopup = false;
    },
  },
  mounted() {
    this.fetchAutoRespondStatus();
  },
};
</script>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
}
</style>
