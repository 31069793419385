<template>
    <div class="h-[100vh]">
        <!-- Main Interface -->
        <div v-if="!editingField" class="bg-white p-4 rounded-lg shadow-sm">
            <!-- Location Dropdown -->
            <div class="location-dropdown relative">
                <v-btn @click="showStoreDropdown = !showStoreDropdown" outlined color="primary" class="mb-3">
                    {{ selectedWIDs?.length || 0 }} Selected Locations
                </v-btn>
                <LocationDropdown class="top-[2.5rem]" ref="locationDropdown" :stores="locations"
                    :show-store-dropdown="showStoreDropdown" v-model="selectedWIDs" @change="handleLocationChange" />
            </div>

            <!-- Business Name, Category & Phone Update Section -->
            <div class="mb-6">
                <h3 class="text-lg font-semibold mb-3">Business Name, Category & Phone Update</h3>
                <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div class="p-4 bg-gray-100 rounded-lg flex items-center cursor-pointer"
                        @click="openFieldEditor('businessName')">
                        <img src="@/assets/img/gmb-icons/icons8-company-64.png" alt="Business Name"
                            class="w-6 h-6 mr-3" />
                        <div>
                            <p class="text-sm font-medium">Business Name</p>
                            <p class="text-gray-600 text-xs">Change your Google profile's business name</p>
                        </div>
                    </div>
                    <div class="p-4 bg-gray-100 rounded-lg flex items-center cursor-pointer"
                        @click="openFieldEditor('primaryCategory')">
                        <img src="@/assets/img/gmb-icons/icons8-category-50.png" alt="Primary Category"
                            class="w-6 h-6 mr-3" />
                        <div>
                            <p class="text-sm font-medium">Primary Category</p>
                            <p class="text-gray-600 text-xs">Update your primary category</p>
                        </div>
                    </div>
                    <div class="p-4 bg-gray-100 rounded-lg flex items-center cursor-pointer"
                        @click="openFieldEditor('additionalCategories')">
                        <img src="@/assets/img/gmb-icons/icons8-add-properties-50.png" alt="Additional Categories"
                            class="w-6 h-6 mr-3" />
                        <div>
                            <p class="text-sm font-medium">Additional Categories</p>
                            <p class="text-gray-600 text-xs">Add additional categories based on business relevancy</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Website & Contact Information Section -->
            <div class="mb-6">
                <h3 class="text-lg font-semibold mb-3">Website & Contact Information</h3>
                <div class="grid md:grid-cols-2 gap-4">
                    <div class="p-4 bg-gray-100 rounded-lg flex items-center cursor-pointer"
                        @click="openFieldEditor('websiteUri')">
                        <img src="@/assets/img/gmb-icons/icons8-globe-48.png" alt="Website" class="w-6 h-6 mr-3" />
                        <div>
                            <p class="text-sm font-medium">Website URL</p>
                            <p class="text-gray-600 text-xs">Add or update your business website</p>
                        </div>
                    </div>
                    <div class="p-4 bg-gray-100 rounded-lg flex items-center cursor-pointer"
                        @click="openFieldEditor('phoneNumber')">
                        <img src="@/assets/img/gmb-icons/icons8-phone-50.png" alt="Phone" class="w-6 h-6 mr-3" />
                        <div>
                            <p class="text-sm font-medium">Phone Number</p>
                            <p class="text-gray-600 text-xs">Add or update your business phone number</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Business Description, Attributes, Menu & Hours Section -->
            <div class="mb-6">
                <h3 class="text-lg font-semibold mb-3">Business Description, Attributes & Hours</h3>
                <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div class="p-4 bg-gray-100 rounded-lg flex items-center cursor-pointer"
                        @click="openFieldEditor('description')">
                        <img src="@/assets/img/gmb-icons/icons8-page-50.png" alt="Description" class="w-6 h-6 mr-3" />
                        <div>
                            <p class="text-sm font-medium">Description</p>
                            <p class="text-gray-600 text-xs">Describe your business to improve profile strength</p>
                        </div>
                    </div>
                    <div class="p-4 bg-gray-100 rounded-lg flex items-center cursor-pointer"
                        @click="openFieldEditor('businessHours')">
                        <img src="@/assets/img/gmb-icons/icons8-clock-50.png" alt="Business Hours"
                            class="w-6 h-6 mr-3" />
                        <div>
                            <p class="text-sm font-medium">Business Hours</p>
                            <p class="text-gray-600 text-xs">Add opening hours of your business</p>
                        </div>
                    </div>
                    <div class="p-4 bg-gray-100 rounded-lg flex items-center transition duration-200" :class="{
                        'cursor-pointer hover:bg-gray-200': Object.keys(uniqueCategories).length === 1,
                        'cursor-not-allowed opacity-50': Object.keys(uniqueCategories).length > 1
                    }" @click="Object.keys(uniqueCategories).length === 1 && openFieldEditor('attributes')">
                        <img src="@/assets/img/gmb-icons/icons8-settings-50.png" alt="Attributes"
                            class="w-6 h-6 mr-3" />
                        <div>
                            <p class="text-sm font-medium">Attributes</p>
                            <p class="text-gray-600 text-xs">Add attributes to improve profile strength</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Update All Button -->
            <div v-if="Object.keys(uniqueCategories).length > 1" class="my-5 text-sm font-semibold max-w-[70%]">
                <div class="text-red-400 mb-3">
                    <v-icon color="red" size="18">mdi-information</v-icon>
                    Bulk updates for attributes are disabled when locations have different primary categories.
                </div>
                <span class="text-blue-500 ml-3 cursor-pointer underline" @click="categoryDialog = true">
                    Unify Categories
                </span>
            </div>
        </div>

        <!-- Field Editor Interface -->
        <div v-else class="field-editor overflow-auto bg-white">
            <div class="heading flex justify-between items-center p-4">
                <h3 class="text-xl font-bold">{{ getFieldTitle(editingField) }}</h3>
                <v-btn icon @click="handleCloseEditor">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <v-divider class="m-auto w-[98%] mb-10" />
            <h1 class="ml-5 text-ellipsis font-semibold">Complete Your Business Information & Sync</h1>
            <div class="content p-4">
                <!-- Business Name -->
                <div v-if="editingField === 'businessName'" class="form-group">
                    <label class="form-label">Business Name</label>
                    <input type="text" class="form-input" placeholder="Enter Business Name"
                        v-model="bulkUpdate.businessName" maxlength="100" />
                    <div class="text-xs text-right mt-1">
                        {{ bulkUpdate.businessName.length }}/100 characters
                    </div>
                </div>

                <!-- Website URL -->
                <div v-else-if="editingField === 'websiteUri'" class="form-group">
                    <div class="text-red-400 mb-3">
                        <v-icon color="red" size="18">mdi-information</v-icon>
                        Updating your website URL could lead to a short review process by Google to ensure accuracy
                    </div>
                    <label class="form-label">Website URL</label>
                    <input type="url" class="form-input" placeholder="https://example.com"
                        v-model="bulkUpdate.websiteUri" maxlength="256" />
                    <div class="text-xs text-right mt-1">
                        {{ bulkUpdate.websiteUri.length }}/256 characters
                    </div>
                </div>

                <!-- Phone Number -->
                <div v-else-if="editingField === 'phoneNumber'" class="form-group">
                    <div class="text-red-400 mb-3">
                        <v-icon color="red" size="18">mdi-information</v-icon>
                        Updating the phone number could trigger a brief review by Google to verify your business information.
                    </div>
                    <label class="form-label">Phone Number</label>
                    <input type="tel" class="form-input" placeholder="+911234567890" v-model="bulkUpdate.phoneNumber" />
                </div>

                <!-- Primary Category -->
                <div v-else-if="editingField === 'primaryCategory'" class="relative">
                    <div class="text-red-400 mb-3">
                        <v-icon color="red" size="18">mdi-information</v-icon>
                        Updating the primary category could trigger a brief review by Google to verify your business information.
                    </div>
                    <v-autocomplete v-model="bulkUpdate.primaryCategory" :items="categories" item-text="displayName"
                        item-value="name" placeholder="Search and add primary category"
                        :search-input.sync="bulkUpdate.primaryCategorySearchQuery" outlined dense hide-details
                        class="category-dropdown" :no-data-text="bulkUpdate.primaryCategorySearchQuery
                            ? 'No matching categories found'
                            : 'Start typing to search categories'" />
                    <v-icon v-if="bulkUpdate.primaryCategory"
                        class="cursor-pointer absolute right-8 top-1/2 transform -translate-y-1/2"
                        @click="bulkUpdate.primaryCategory = null">
                        mdi-close
                    </v-icon>
                </div>

                <!-- Additional Categories -->
                <div v-else-if="editingField === 'additionalCategories'" class="form-group relative">
                    <label class="form-label flex items-center justify-between">
                        <span>Additional Categories</span>
                        <span class="text-sm text-gray-600">({{ bulkUpdate.additionalCategories?.length || 0 }}/9)</span>
                    </label>
                    <div class="flex flex-wrap gap-2 mb-2">
                        <v-chip v-for="(category, index) in bulkUpdate.additionalCategories" :key="index" :close="true"
                            @click:close="removeBulkAdditionalCategory(index)" class="mr-2 mb-2 white category-chip-new"
                            small>
                            {{ category.displayName }}
                        </v-chip>
                    </div>
                    <div class="flex gap-2 mb-2">
                        <v-btn small color="success" @click="suggestCategories(locations[0], true)"
                            :loading="locations[0]?.suggestingCategories" :disabled="locations[0]?.suggestingCategories
                                || bulkUpdate.additionalCategories?.length == 9 || !selectedWIDs?.length">
                            <v-icon small left>mdi-lightbulb</v-icon>
                            Suggest Additional Categories
                        </v-btn>
                    </div>
                    <v-autocomplete v-model="bulkUpdate.selectedAdditionalCategory"
                        :items="filteredBulkAdditionalCategories(locations[0])" item-text="displayName"
                        item-value="name" :disabled="bulkUpdate.additionalCategories?.length >= 9"
                        placeholder="Search and add categories..."
                        :search-input.sync="bulkUpdate.additionalCategorySearchQuery" outlined dense hide-details
                        @change="addBulkAdditionalCategory" class="category-dropdown" :no-data-text="bulkUpdate.additionalCategorySearchQuery
                            ? 'No matching categories found'
                            : 'Start typing to search categories'" />
                </div>

                <!-- Description -->
                <div v-else-if="editingField === 'description'" class="form-group">
                    <label class="form-label">Description</label>
                    <div class="space-y-2">
                        <div class="flex flex-wrap gap-2 items-center mb-2">
                            <div v-for="(keyword, index) in bulkKeywords" :key="index" class="keyword-chip"
                                :style="{ width: `${keyword.length * 10 + 40}px` }">
                                <span class="text-sm truncate">{{ keyword }}</span>
                                <button @click="removeBulkKeyword(index)" class="flex-shrink-0">
                                    <v-icon small>mdi-close</v-icon>
                                </button>
                            </div>
                        </div>
                        <div class="space-y-2">
                            <div class="flex gap-2">
                                <input v-model="currentBulkKeyword" @keyup.enter="addBulkKeyword" type="text"
                                    placeholder="Add keywords and press Enter" class="form-input flex-1" />
                            </div>
                            <div class="flex gap-2">
                                <v-btn small color="secondary" @click="addBulkKeyword" :disabled="!selectedWIDs?.length">
                                    <v-icon small left>mdi-plus</v-icon>
                                    Add Keyword
                                </v-btn>
                                <v-btn small color="primary" @click="generateBulkDescription"
                                    :loading="generatingBulkDescription"
                                    :disabled="generatingBulkDescription || !locations.length || !selectedWIDs?.length">
                                    <v-icon small left>mdi-refresh</v-icon>
                                    Generate Description
                                </v-btn>
                            </div>
                        </div>
                        <textarea class="form-input" rows="7" v-model="bulkUpdate.description"
                            placeholder="Enter description or generate one automatically" maxlength="750" />
                        <div class="text-xs text-right mt-1" :class="{'text-red-500': bulkUpdate.description.length > 750}">
                            {{ bulkUpdate.description.length }}/750 characters
                        </div>
                    </div>
                </div>

                <!-- Business Hours -->
                <div v-else-if="editingField === 'businessHours'" class="space-y-6">
                    <div class="flex flex-row flex-wrap justify-between items-center gap-4">
                        <h3 class="font-semibold text-base">Business Hours</h3>
                        <div class="flex flex-wrap items-center gap-3">
                            <div class="flex items-center">
                                <span class="mr-2 text-sm text-gray-700 whitespace-nowrap">Default Hours:</span>
                                <div class="flex gap-2 items-center">
                                    <v-autocomplete 
                                        v-model="defaultOpenTime" 
                                        :items="timeSlots"
                                        dense
                                        outlined
                                        hide-details
                                        class="time-autocomplete">
                                    </v-autocomplete>
                                    <span class="mx-1 self-center">-</span>
                                    <v-autocomplete 
                                        v-model="defaultCloseTime" 
                                        :items="timeSlots"
                                        dense
                                        outlined
                                        hide-details
                                        class="time-autocomplete">
                                    </v-autocomplete>
                                </div>
                                <v-btn 
                                    small 
                                    color="primary" 
                                    class="ml-2" 
                                    @click="applyDefault" 
                                    :disabled="!isValidTimeRange(defaultOpenTime, defaultCloseTime)">
                                    Apply
                                </v-btn>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isValidTimeRange(defaultOpenTime, defaultCloseTime)" class="text-red-500 text-sm mt-1">
                        Closing time must be later than opening time
                    </div>
                    <div class="flex items-center justify-between mb-2 px-2 py-1 bg-gray-50 rounded">
                        <div class="flex items-center gap-2">
                            <span class="text-sm font-medium">Weekend Hours</span>
                            <v-switch v-model="weekendHours" dense hide-details inset @change="toggleWeekendHours"></v-switch>
                        </div>
                    </div>
                    <div v-for="day in weekDays" :key="day.value" class="border rounded-md p-2 space-y-2">
                        <div class="flex items-center justify-between">
                            <h4 class="text-sm font-medium w-28">{{ day.label }}</h4>
                            <div class="flex gap-6 items-center">
                                <div class="flex items-center gap-2">
                                    <span class="text-sm text-gray-600">Open</span>
                                    <v-switch 
                                        v-model="bulkUpdate.regularHours[day.value].isOpen" 
                                        dense 
                                        hide-details 
                                        inset>
                                    </v-switch>
                                </div>
                                <div 
                                    v-if="bulkUpdate.regularHours[day.value].isOpen" 
                                    class="flex items-center gap-2">
                                    <span class="text-sm text-gray-600">24 Hours</span>
                                    <v-switch 
                                        v-model="bulkUpdate.regularHours[day.value].is24" 
                                        dense 
                                        hide-details 
                                        inset>
                                    </v-switch>
                                </div>
                            </div>
                        </div>
                        <div 
                            v-if="bulkUpdate.regularHours[day.value].isOpen && !bulkUpdate.regularHours[day.value].is24" 
                            class="space-y-1">
                            <div 
                                v-for="(period, idx) in bulkUpdate.regularHours[day.value].periods || []" 
                                :key="idx" 
                                class="flex flex-col gap-1">
                                <div class="flex items-center gap-2">
                                    <v-autocomplete
                                        v-model="period.open"
                                        :items="getTimeOptions(period.open)"
                                        dense
                                        outlined
                                        hide-details
                                        class="time-autocomplete"
                                        :error="!isValidTimeRange(period.open, period.close)"
                                        @change="validateTimePeriod(period)">
                                    </v-autocomplete>
                                    <span>to</span>
                                    <v-autocomplete
                                        v-model="period.close"
                                        :items="getTimeOptions(period.close)"
                                        dense
                                        outlined
                                        hide-details
                                        class="time-autocomplete"
                                        :error="!isValidTimeRange(period.open, period.close)"
                                        @change="validateTimePeriod(period)">
                                    </v-autocomplete>
                                    <v-icon 
                                        small 
                                        class="cursor-pointer text-red-500" 
                                        @click="removePeriod(day.value, idx)">
                                        mdi-delete
                                    </v-icon>
                                </div>
                                <div v-if="!isValidTimeRange(period.open, period.close)" class="text-red-500 text-xs ml-2">
                                    Closing time must be later than opening time
                                </div>
                            </div>
                            <v-btn 
                                small 
                                class="mt-4" 
                                @click="addPeriod(day.value)">
                                <v-icon small left>mdi-plus</v-icon>
                                Add Time
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- Attributes -->
                <div v-else-if="editingField === 'attributes'">
                    <GMBAttributes :locations="selectedLocations" @attributes-update-done="handleCloseEditor" />
                </div>
            </div>
            <v-divider class="m-auto w-[98%] my-10" v-if="editingField !== 'attributes'" />
            <div class="actions p-4 flex justify-between items-center" v-if="editingField !== 'attributes'">
                <!-- Update Results Section -->
                <div class="flex items-center">
                    <v-chip
                        v-if="updateResults.length"
                        class="mr-2"
                        :color="hasErrors ? 'error' : 'success'"
                        outlined
                        @click="showUpdateResults = true"
                    >
                        <v-icon left size="16">
                            {{ hasErrors ? 'mdi-alert-circle' : 'mdi-check-circle' }}
                        </v-icon>
                        Update Results
                    </v-chip>
                </div>
                <!-- Update Button -->
                <div>
                    <v-btn 
                        @click="applyBulkUpdates" 
                        :loading="bulkUpdating"
                        :disabled="bulkUpdating || !checkUpdateEligibility(this.gmb_bulk_last_updated).canUpdate || !selectedWIDs?.length"
                        class="border border-blue-500 bg-white rounded-md px-4 py-2">
                        <span class="flex items-center space-x-2 text-blue-500">
                            <img src="@/assets/img/icons8-google-48.png" alt="Google Icon" class="w-4 h-4" />
                            <span class="font-bold">Update for {{ selectedWIDs.length }} locations</span>
                        </span>
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- Category Dialog -->
        <v-dialog v-model="categoryDialog" max-width="600px" max-height="80vh" persistent>
            <v-card>
                <v-card-title class="flex justify-between items-center">
                    <span class="text-h6 font-weight-bold">Update Categories</span>
                    <v-btn icon @click="categoryDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <div class="form-group relative">
                        <label class="form-label">Primary Category</label>
                        <div class="flex relative">
                            <v-autocomplete v-model="bulkUpdate.primaryCategory" :items="categories"
                                item-text="displayName" item-value="name" placeholder="Search and add primary category"
                                :search-input.sync="bulkUpdate.primaryCategorySearchQuery" outlined dense hide-details
                                @change="addBulkPrimaryCategory" class="category-dropdown"
                                :no-data-text="bulkUpdate.primaryCategorySearchQuery ? 'No matching categories found' : 'Start typing to search categories'">
                            </v-autocomplete>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click="categoryDialog = false">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" @click="applyBulkUpdates(true); categoryDialog = false">
                        Change categories
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Discard Changes Dialog -->
        <v-dialog v-model="discardChangesDialog" max-width="400px">
            <v-card>
                <v-card-title class="text-h6 font-weight-bold">Discard Changes?</v-card-title>
                <v-card-text>
                    <p>Are you sure you want to close? All unsaved changes will be lost.</p>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click="discardChangesDialog = false">No</v-btn>
                    <v-btn color="red" class="text-white" dark @click="closeFieldEditor('all')">Discard</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Update Results Dialog -->
        <v-dialog v-model="showUpdateResults" max-width="600px" scrollable>
            <v-card>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span>Update Results</span>
                    <v-btn icon @click="showUpdateResults = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="pt-4">
                    <div class="mb-4 flex gap-4">
                        <v-chip outlined color="success">
                            Success: {{ successCount }}
                        </v-chip>
                        <v-chip outlined color="error">
                            Failed: {{ failedCount }}
                        </v-chip>
                    </div>
                    <v-data-table
                        :headers="[
                            { text: 'Location', value: 'title' },
                            { text: 'Status', value: 'status' }
                        ]"
                        :items="updateResults"
                        :items-per-page="10"
                        :footer-props="{
                            'items-per-page-options': [10, 25, 50, 100],
                        }"
                        dense>
                        <template v-slot:item.status="{ item }">
                            <v-chip
                                small
                                :color="item.status === 'success' ? 'success' : 'error'"
                                text-color="white">
                                {{ item.status }}
                            </v-chip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { auth, functions, storage } from '@/utils/firebase.utils';
import { doc, updateDoc, addDoc, collection, getDoc } from 'firebase/firestore';
import { db } from '@/utils/firebase.utils';
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import LocationDropdown from '@/components/LocationDropdown.vue';
import GMBAttributes from './GMBAttributes.vue';
import GMBMenu from './GMBMenu.vue';

export default {
    components: { LocationDropdown, GMBAttributes, GMBMenu },
    props: {
        locations: { type: Array, required: true },
        categories: { type: Array, required: true },
        categoriesId: { type: Array, required: true },
        fetchWorkspaceData: { type: Function },
    },
    data() {
        return {
            editingField: null,
            updateTimeLimit: 24,
            discardChangesDialog: false,
            weekDays: [
                { label: 'Sunday', value: 'SUNDAY' },
                { label: 'Monday', value: 'MONDAY' },
                { label: 'Tuesday', value: 'TUESDAY' },
                { label: 'Wednesday', value: 'WEDNESDAY' },
                { label: 'Thursday', value: 'THURSDAY' },
                { label: 'Friday', value: 'FRIDAY' },
                { label: 'Saturday', value: 'SATURDAY' },
            ],
            bulkUpdate: {
                businessName: '',
                websiteUri: '',
                primaryCategory: null,
                additionalCategories: [],
                phoneNumber: '',
                description: '',
                additionalCategorySearchQuery: '',
                primaryCategorySearchQuery: '',
                selectedAdditionalCategory: '',
                regularHours: {
                    SUNDAY: { isOpen: false, is24: false, periods: [] },
                    MONDAY: { isOpen: false, is24: false, periods: [] },
                    TUESDAY: { isOpen: false, is24: false, periods: [] },
                    WEDNESDAY: { isOpen: false, is24: false, periods: [] },
                    THURSDAY: { isOpen: false, is24: false, periods: [] },
                    FRIDAY: { isOpen: false, is24: false, periods: [] },
                    SATURDAY: { isOpen: false, is24: false, periods: [] },
                },
                originalRegularHours: {
                    SUNDAY: { isOpen: false, is24: false, periods: [] },
                    MONDAY: { isOpen: false, is24: false, periods: [] },
                    TUESDAY: { isOpen: false, is24: false, periods: [] },
                    WEDNESDAY: { isOpen: false, is24: false, periods: [] },
                    THURSDAY: { isOpen: false, is24: false, periods: [] },
                    FRIDAY: { isOpen: false, is24: false, periods: [] },
                    SATURDAY: { isOpen: false, is24: false, periods: [] },
                },
            },
            originalBulkUpdate: {},
            categoryDialog: false,
            gmb_bulk_last_updated: null,
            bulkUpdating: false,
            bulkKeywords: [],
            currentBulkKeyword: '',
            generatingBulkDescription: false,
            selectedWIDs: this.locations.filter(item => !item.shallowConnect && !item.needs_gmb_reauth).map(item => item.workspaceId),
            selectedItem: null,
            showStoreDropdown: false,
            showUpdateResults: false,
            updateResults: [],
            timeSlots: this.generateTimeSlots(),
            defaultOpenTime: '10:00',
            defaultCloseTime: '18:00',
            weekendHours: true,
        };
    },
    created() {
        this.$refs.inputRef = {};
        this.originalBulkUpdate = JSON.parse(JSON.stringify(this.bulkUpdate));
        document.addEventListener('click', this.handleClickOutside);
        this.fetchLastUpdatedAndRefreshed();
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    computed: {
        selectedLocations() {
            return this.locations.filter(item => this.selectedWIDs.includes(item.workspaceId));
        },
        uniqueCategories() {
            const categoryCount = {};
            this.locations.forEach((location) => {
                const primaryCategory = location.categories.primaryCategory?.displayName;
                if (primaryCategory) {
                    categoryCount[primaryCategory] = (categoryCount[primaryCategory] || 0) + 1;
                }
            });
            return Object.entries(categoryCount).map(([name, count]) => ({ name, count }));
        },
        successCount() {
            return this.updateResults.filter(result => result.status === 'success').length;
        },
        failedCount() {
            return this.updateResults.filter(result => result.status === 'failed').length;
        },
        hasErrors() {
            return this.failedCount > 0;
        },
    },
    methods: {
        handleCloseEditor() {
            if (JSON.stringify(this.bulkUpdate) !== JSON.stringify(this.originalBulkUpdate)) {
                this.discardChangesDialog = true;
            } else {
                this.closeFieldEditor('all');
            }
        },
        addBulkPrimaryCategory() {
            if (!this.bulkUpdate.primaryCategory) {
                this.bulkUpdate.primaryCategory = {};
            }
            const categoryToAdd = this.categories.find(cat => cat.name === this.bulkUpdate.primaryCategory);
            delete categoryToAdd?.moreHoursTypes;
            if (categoryToAdd) {
                this.bulkUpdate.primaryCategory = categoryToAdd;
            }
            this.bulkUpdate.selectedPrimaryCategory = null;
        },
        openFieldEditor(field) {
            this.editingField = field;
            if (field === 'menu') {
                this.fetchMenu(this.locations[0], true);
            }
        },
        closeFieldEditor(type) {
            this.discardChangesDialog = false;
            if (type === 'all') this.editingField = null;
            this.clearBulkUpdate();
        },
        clearBulkUpdate() {
            this.bulkUpdate = {
                businessName: '',
                websiteUri: '',
                primaryCategory: null,
                additionalCategories: [],
                phoneNumber: '',
                description: '',
                additionalCategorySearchQuery: '',
                primaryCategorySearchQuery: '',
                selectedAdditionalCategory: '',
                regularHours: {
                    SUNDAY: { isOpen: false, is24: false, periods: [] },
                    MONDAY: { isOpen: false, is24: false, periods: [] },
                    TUESDAY: { isOpen: false, is24: false, periods: [] },
                    WEDNESDAY: { isOpen: false, is24: false, periods: [] },
                    THURSDAY: { isOpen: false, is24: false, periods: [] },
                    FRIDAY: { isOpen: false, is24: false, periods: [] },
                    SATURDAY: { isOpen: false, is24: false, periods: [] },
                },
                originalRegularHours: {
                    SUNDAY: { isOpen: false, is24: false, periods: [] },
                    MONDAY: { isOpen: false, is24: false, periods: [] },
                    TUESDAY: { isOpen: false, is24: false, periods: [] },
                    WEDNESDAY: { isOpen: false, is24: false, periods: [] },
                    THURSDAY: { isOpen: false, is24: false, periods: [] },
                    FRIDAY: { isOpen: false, is24: false, periods: [] },
                    SATURDAY: { isOpen: false, is24: false, periods: [] },
                },
            };
        },
        getFieldTitle(field) {
            const titles = {
                businessName: 'Business Name',
                websiteUri: 'Website URL',
                phoneNumber: 'Phone Number',
                primaryCategory: 'Primary Category',
                additionalCategories: 'Additional Categories',
                description: 'Description',
                businessHours: 'Business Hours',
                attributes: 'Attributes',
                menu: 'Business Menu',
            };
            return titles[field] || field;
        },
        addBulkAdditionalCategory() {
            if (!this.bulkUpdate.selectedAdditionalCategory) return;
            if (!this.bulkUpdate.additionalCategories) {
                this.bulkUpdate.additionalCategories = [];
            }
            if (this.bulkUpdate.additionalCategories.length >= 9) {
                this.$alert.show('Maximum 9 additional categories allowed');
                return;
            }
            const categoryToAdd = this.categories.find(cat => cat.name === this.bulkUpdate.selectedAdditionalCategory);
            if (categoryToAdd) {
                this.bulkUpdate.additionalCategories.push({ ...categoryToAdd });
            }
            this.bulkUpdate.selectedAdditionalCategory = null;
        },
        removeBulkAdditionalCategory(index) {
            this.bulkUpdate.additionalCategories.splice(index, 1);
        },
        addBulkKeyword() {
            const keyword = this.currentBulkKeyword.trim();
            if (keyword && !this.bulkKeywords.includes(keyword)) {
                this.bulkKeywords.push(keyword);
            }
            this.currentBulkKeyword = '';
        },
        removeBulkKeyword(index) {
            this.bulkKeywords.splice(index, 1);
        },
        async generateBulkDescription() {
            this.generatingBulkDescription = true;
            try {
                const firstLocation = this.locations[0];
                if (!firstLocation) throw new Error('No locations available');
                const primaryCategory = firstLocation?.categories?.primaryCategory?.displayName || '';
                const additionalCategories = firstLocation?.categories?.additionalCategories?.map(cat => cat.displayName) || [];
                const allCategories = [primaryCategory, ...additionalCategories].filter(Boolean).join(', ');
                const payload = {
                    uid: this.currentUID,
                    category: allCategories,
                    wid: this.WID,
                    operation: 'generategmbKeywords',
                    textContent: '',
                    businessDescription: '',
                    brandName: firstLocation?.title,
                    keywords: this.bulkKeywords,
                };
                const generateKeywords = httpsCallable(functions, 'aiActions');
                const res = await generateKeywords(payload);
                if (res?.data?.result?.output?.[0]) {
                    this.bulkUpdate.description = res.data.result.output[0];
                    this.$snackbar.show('Description generated successfully');
                } else {
                    throw new Error('Failed to generate description');
                }
            } catch (error) {
                console.error('Error generating bulk description:', error);
                this.$alert.show(error.message || 'Error generating description');
            } finally {
                this.generatingBulkDescription = false;
            }
        },
        async suggestCategories(location, isBulk) {
            location.suggestingCategories = true;
            const primaryCategory = location?.categories?.primaryCategory?.name || null;
            let additionalCategories = isBulk ? this.bulkUpdate.additionalCategories?.map(cat => cat.name)
                : location?.categories?.additionalCategories?.map(cat => cat.name) || [];
            try {
                const payload = {
                    uid: this.currentUID,
                    wid: this.WID,
                    additionalCategories,
                    primaryCategory,
                    operation: 'suggestGMBCategories',
                    allCategories: this.categoriesId,
                    businessDescription: location?.profile?.description || '',
                    brandName: location?.title,
                };
                const suggestCategories = httpsCallable(functions, 'aiActions');
                const res = await suggestCategories(payload);
                if (res?.data?.result?.output) {
                    const suggestions = res.data.result.output.suggested_categories.map(s => `categories/gcid:${s}`);
                    for (const suggestion of suggestions) {
                        const matchingCategory = this.categories.find(cat => cat.name.toLowerCase() === suggestion.toLowerCase());
                        if (
                            matchingCategory &&
                            !this.bulkUpdate.additionalCategories.some(cat => cat.name === matchingCategory.name) &&
                            this.bulkUpdate.additionalCategories.length < 9
                        ) {
                            this.bulkUpdate.additionalCategories.push(matchingCategory);
                        }
                    }
                    this.$snackbar.show('Categories suggested successfully');
                }
            } catch (error) {
                console.error('Error suggesting categories:', error);
                this.$alert.show('Error suggesting categories');
            } finally {
                location.suggestingCategories = false;
            }
        },
        checkUpdateEligibility(lastUpdated) {
            if (!lastUpdated) return { canUpdate: true, remainingTime: 0 };
            const now = new Date();
            const lastUpdate = lastUpdated instanceof Date ? lastUpdated : lastUpdated.toDate();
            const diffHours = (now - lastUpdate) / (1000 * 60 * 60);
            const remainingHours = Math.max(0, Math.ceil(this.updateTimeLimit - diffHours));
            return { canUpdate: diffHours >= this.updateTimeLimit, remainingTime: remainingHours };
        },
        async fetchLastUpdatedAndRefreshed() {
            const workspaceDoc = doc(db, `users/${this.currentUID}/fly_workspaces/${this.WID}`);
            const workspaceSnapshot = await getDoc(workspaceDoc);
            if (workspaceSnapshot.exists()) {
                this.gmb_bulk_last_updated = workspaceSnapshot.data().gmb_bulk_last_updated;
            }
        },
        formatBusinessHours(hours) {
            const periods = [];
            this.weekDays.forEach(({ value }, index) => {
                if (hours[value] && hours[value].isOpen) {
                    if (hours[value].is24) {
                        periods.push({
                            openDay: value,
                            openTime: { hours: 0, minutes: 0 },
                            closeDay: value,
                            closeTime: { hours: 24, minutes: 0 }
                        });
                    } else if (hours[value].periods && hours[value].periods.length > 0) {
                        hours[value].periods.forEach(period => {
                            if (this.isValidTimeRange(period.open, period.close)) {
                                const [openHour, openMinute] = period.open.split(':').map(n => parseInt(n, 10) || 0);
                                const [closeHour, closeMinute] = period.close.split(':').map(n => parseInt(n, 10) || 0);
                                
                                periods.push({
                                    openDay: value,
                                    openTime: { hours: openHour, minutes: openMinute },
                                    closeDay: value,
                                    closeTime: { hours: closeHour, minutes: closeMinute }
                                });
                            }
                        });
                    }
                }
            });
            return { periods };
        },
        getCategorydisplayName() {
            const category = this.categories.find(cat => cat.name === this.bulkUpdate.primaryCategory);
            return category?.displayName || '';
        },
        async uploadGmbHistoryJson(currentUID, WID, historyId, changes) {
            try {
                const filePath = `gmb_history/${currentUID}/${WID}/${historyId}.json`;
                const storageRef = ref(storage, filePath);
                const historyData = JSON.stringify(changes);
                const blob = new Blob([historyData], { type: "application/json" });
                await uploadBytes(storageRef, blob);
                console.log(`History JSON uploaded successfully: ${filePath}`);
                return filePath;
            } catch (error) {
                console.error("Error uploading history JSON:", error);
                throw error;
            }
        },
        arraysEqual(a, b) {
            if (a.length !== b.length) return false;
            for (let i = 0; i < a.length; i++) {
                if (a[i] !== b[i]) return false;
            }
            return true;
        },
        getLocationName(location) {
            return `${location.title} ${location?.storefrontAddress?.addressLines?.[0]?.split(',')[0]}, ${location?.storefrontAddress?.locality}, ${location?.storefrontAddress?.administrativeArea}`;
        },
        async applyBulkUpdates(bulkPrimary) {
            if (this.bulkUpdate.description && this.bulkUpdate.description.length > 750) {
                this.$alert.show('Description must be 750 characters or less');
                return;
            }
            let hasInvalidHours = false;
            this.weekDays.forEach(({ value }) => {
                if (this.bulkUpdate.regularHours[value].isOpen && !this.bulkUpdate.regularHours[value].is24) {
                    (this.bulkUpdate.regularHours[value].periods || []).forEach(period => {
                        if (!this.isValidTimeRange(period.open, period.close)) {
                            hasInvalidHours = true;
                        }
                    });
                }
            });
            if (hasInvalidHours) {
                this.$alert.show('Please fix invalid business hours. Closing time must be later than opening time.');
                return;
            }
            this.bulkUpdating = true;
            try {
                const changes = [];
                let flag = false;
                const updates = this.locations.map(location => {
                    const updateFields = {};
                    if (!this.selectedWIDs.includes(location.workspaceId)) return null;
                    const formattedRegularHours = this.formatBusinessHours(this.bulkUpdate.regularHours);
                    const formattedOriginalHours = this.formatBusinessHours(this.bulkUpdate.originalRegularHours);
                    if (JSON.stringify(formattedRegularHours) !== JSON.stringify(formattedOriginalHours)) {
                        updateFields.regularHours = formattedRegularHours;
                        if (!flag) {
                            changes.push({
                                id: 'regularHours',
                                field: 'Business Hours',
                                new: formattedRegularHours
                            });
                        }
                    }
                    if (this.bulkUpdate.websiteUri && this.bulkUpdate.websiteUri !== location.websiteUri) {
                        updateFields.websiteUri = this.bulkUpdate.websiteUri;
                        if (!flag) {
                            changes.push({
                                id: 'websiteUri',
                                field: 'Website URL',
                                new: this.bulkUpdate.websiteUri
                            });
                        }
                    }
                    if (this.bulkUpdate.phoneNumber && this.bulkUpdate.phoneNumber !== location.phoneNumbers?.primaryPhone) {
                        updateFields.phoneNumbers = { primaryPhone: this.bulkUpdate.phoneNumber };
                        if (!flag) {
                            changes.push({
                                id: 'phoneNumbers',
                                field: 'Phone Number',
                                new: this.bulkUpdate.phoneNumber
                            });
                        }
                    }
                    if (this.bulkUpdate.description && this.bulkUpdate.description !== location?.profile?.description) {
                        updateFields.profile = { description: this.bulkUpdate.description };
                        if (!flag) {
                            changes.push({
                                id: 'description',
                                field: 'Business Description',
                                new: this.bulkUpdate.description
                            });
                        }
                    }
                    if (this.bulkUpdate.businessName) {
                        updateFields.title = this.bulkUpdate.businessName;
                        if (!flag) {
                            changes.push({
                                id: 'title',
                                field: 'Business Name',
                                new: this.bulkUpdate.businessName
                            });
                        }
                    }
                    const originalAdditionalCategories = location.categories?.additionalCategories?.map(cat => cat.name) || [];
                    const newAdditionalCategories = this.bulkUpdate.additionalCategories?.map(cat => cat.name) || [];
                    const newAdditionalCategoriesDisplay = this.bulkUpdate.additionalCategories?.map(cat => cat.displayName) || [];
                    if (this.bulkUpdate.additionalCategories.length && !this.arraysEqual(originalAdditionalCategories, newAdditionalCategories)) {
                        if (!updateFields.categories) updateFields.categories = {};
                        updateFields.categories.additionalCategories = newAdditionalCategories;
                        updateFields.categories.primaryCategory = { name: location.categories.primaryCategory.name };
                        if (!flag) {
                            changes.push({
                                id: 'additionalCategories',
                                field: 'Additional Categories',
                                value: newAdditionalCategories,
                                new: newAdditionalCategoriesDisplay.join(", ") || '',
                            });
                        }
                    }
                    if (this.bulkUpdate.primaryCategory && location?.categories?.primaryCategory.name !== this.bulkUpdate.primaryCategory) {
                        if (!updateFields.categories) {
                            updateFields.categories = {};
                            if (originalAdditionalCategories.length) {
                                updateFields.categories.additionalCategories = originalAdditionalCategories;
                            }
                        }
                        updateFields.categories.primaryCategory = { name: this.bulkUpdate.primaryCategory };
                        if (!flag) {
                            changes.push({
                                id: 'primaryCategory',
                                field: 'Primary Category',
                                value: this.bulkUpdate.primaryCategory,
                                new: this.getCategorydisplayName(),
                            });
                        }
                    }
                    flag = true;
                    return Object.keys(updateFields).length > 0 ? {
                        locationId: location.name,
                        updateFields,
                        displayName: location.storeLocation,
                        wid: location.workspaceId,
                        accountId: location.accountId
                    } : null;
                }).filter(Boolean);
                if (updates.length === 0) {
                    this.$snackbar.show('No changes to update');
                    return;
                }
                const payload = {
                    uid: this.currentUID,
                    workspace_id: this.WID,
                    updates
                };
                const updateGMBProfile = httpsCallable(functions, "updateGMBDataFunction");
                const res = await updateGMBProfile(payload);
                if (res?.data?.results) {
                    const locationMap = {};
                    this.locations.forEach(location => {
                        locationMap[location.wid] = this.getLocationName(location);
                    });
                    this.updateResults = res.data.results.map(item => ({
                        ...item,
                        title: locationMap[item.wid] || 'Unknown Location'
                    }));
                    const failedCount = res.data.results.filter(result => result.status === "failed").length;
                    const docRef = await addDoc(
                        collection(db, "users", this.currentUID, "fly_workspaces", this.WID, "gmb-update-history"),
                        {
                            requested_by: this.currentUser.display_name || "unknown",
                            title: "Request for bulk Update",
                            type: 'multiple',
                            address: '',
                            changes,
                            updateResults: this.updateResults,
                            requested_at: new Date(),
                        }
                    );
                    if (!bulkPrimary) {
                        const currDate = new Date();
                        const workspaceRef = doc(db, `users/${this.currentUID}/fly_workspaces/${this.WID}`);
                        await updateDoc(workspaceRef, { gmb_bulk_last_updated: currDate });
                        this.gmb_bulk_last_updated = currDate;
                    }
                    await this.fetchWorkspaceData();
                    if (failedCount > 0) {
                        this.showUpdateResults = true;
                    }
                }
            } catch (error) {
                console.error('Error applying bulk updates:', error);
                this.$alert.show('Error updating locations');
            } finally {
                this.closeFieldEditor();
                this.bulkUpdating = false;
            }
        },
        filteredBulkAdditionalCategories() {
            if (!this.categories || !this.bulkUpdate.additionalCategorySearchQuery) return [];
            const searchQuery = this.bulkUpdate.additionalCategorySearchQuery.toLowerCase();
            return this.categories.filter(category => {
                const isPrimary = category.name === this.bulkUpdate.primaryCategory;
                const isAlreadySelected = this.bulkUpdate.additionalCategories.some(selected => selected.name === category.name);
                const matchesSearch = category.displayName.toLowerCase().includes(searchQuery);
                return !isPrimary && !isAlreadySelected && matchesSearch;
            });
        },
        handleLocationChange() {
            this.selectedWIDs = this.locations.filter(item => item.checked).map(item => item.workspaceId);
        },
        handleClickOutside(event) {
            const dropdown = this.$refs.locationDropdown?.$refs.showStoreDropdown;
            const input = event.target.closest('.location-dropdown');
            if (!input && dropdown && !dropdown.contains(event.target)) {
                this.showStoreDropdown = false;
            }
        },
        generateTimeSlots() {
            const slots = [];
            for (let h = 0; h < 24; h++) {
                slots.push(`${String(h).padStart(2, '0')}:00`);
                slots.push(`${String(h).padStart(2, '0')}:30`);
            }
            return slots;
        },
        addPeriod(day) {
            if (!this.bulkUpdate.regularHours[day].periods) {
                this.$set(this.bulkUpdate.regularHours[day], 'periods', []);
            }
            const newPeriod = this.isValidTimeRange(this.defaultOpenTime, this.defaultCloseTime)
                ? { open: this.defaultOpenTime, close: this.defaultCloseTime }
                : { open: '10:00', close: '18:00' };
            this.bulkUpdate.regularHours[day].periods.push(newPeriod);
        },
        removePeriod(day, index) {
            if (this.bulkUpdate.regularHours[day].periods && index >= 0) {
                this.bulkUpdate.regularHours[day].periods.splice(index, 1);
            }
        },
        applyDefault() {
            if (!this.isValidTimeRange(this.defaultOpenTime, this.defaultCloseTime)) {
                return;
            }
            this.weekDays.forEach((day) => {
                if (this.bulkUpdate.regularHours[day.value].isOpen && !this.bulkUpdate.regularHours[day.value].is24) {
                    this.$set(this.bulkUpdate.regularHours[day.value], 'periods', [{
                        open: this.defaultOpenTime,
                        close: this.defaultCloseTime
                    }]);
                }
            });
        },
        toggleWeekendHours() {
            ['SATURDAY', 'SUNDAY'].forEach((day) => {
                this.$set(this.bulkUpdate.regularHours, day, {
                    isOpen: this.weekendHours,
                    is24: false,
                    periods: this.weekendHours ? [{
                        open: this.isValidTimeRange(this.defaultOpenTime, this.defaultCloseTime) ? this.defaultOpenTime : '10:00',
                        close: this.isValidTimeRange(this.defaultOpenTime, this.defaultCloseTime) ? this.defaultCloseTime : '18:00'
                    }] : []
                });
            });
            ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'].forEach((day) => {
                if (!this.bulkUpdate.regularHours[day].isOpen) {
                    this.$set(this.bulkUpdate.regularHours, day, {
                        isOpen: true,
                        is24: false,
                        periods: [{
                            open: this.isValidTimeRange(this.defaultOpenTime, this.defaultCloseTime) ? this.defaultOpenTime : '10:00',
                            close: this.isValidTimeRange(this.defaultOpenTime, this.defaultCloseTime) ? this.defaultCloseTime : '18:00'
                        }]
                    });
                }
            });
        },
        getTimeOptions(currentTime) {
            let timeOptions = [...this.timeSlots];
            if (currentTime && !timeOptions.includes(currentTime)) {
                timeOptions.push(currentTime);
                timeOptions.sort((a, b) => {
                    const [aHours, aMinutes] = a.split(':').map(n => parseInt(n, 10));
                    const [bHours, bMinutes] = b.split(':').map(n => parseInt(n, 10));
                    if (aHours !== bHours) {
                        return aHours - bHours;
                    }
                    return aMinutes - bMinutes;
                });
            }
            return timeOptions;
        },
        isValidTimeRange(openTime, closeTime) {
            if (!openTime || !closeTime) return false;
            const [openHour, openMinute] = openTime.split(':').map(n => parseInt(n, 10) || 0);
            const [closeHour, closeMinute] = closeTime.split(':').map(n => parseInt(n, 10) || 0);
            const openTotalMinutes = openHour * 60 + openMinute;
            const closeTotalMinutes = closeHour * 60 + closeMinute;
            return closeTotalMinutes > openTotalMinutes;
        },
        validateTimePeriod(period) {
            if (!this.isValidTimeRange(period.open, period.close)) {
                const [openHour, openMinute] = period.open.split(':').map(n => parseInt(n, 10) || 0);
                let newCloseHour = openHour;
                let newCloseMinute = openMinute + 30;
                if (newCloseMinute >= 60) {
                    newCloseHour += 1;
                    newCloseMinute -= 60;
                }
                if (newCloseHour >= 24) {
                    newCloseHour = 23;
                    newCloseMinute = 59;
                }
                period.close = `${String(newCloseHour).padStart(2, '0')}:${String(newCloseMinute).padStart(2, '0')}`;
            }
        },
    },
};
</script>

<style scoped>
.field-editor {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.form-group {
    @apply space-y-2;
}

.form-label {
    @apply block text-sm font-medium text-gray-700;
}

.form-input {
    @apply w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm;
}

.category-chip-new {
    border: 1px solid #8050de !important;
    color: #8050de !important;
}

.keyword-chip {
    @apply bg-gray-100 px-2 py-1 rounded-full flex items-center justify-between gap-1;
    min-width: 60px;
    transition: width 0.2s ease;
}

.keyword-chip span {
    @apply flex-1 text-center text-sm truncate;
}

.keyword-chip button {
    @apply text-gray-500 hover:text-red-500;
}

.group-container {
    margin-bottom: 16px;
}

.group-header {
    padding: 8px 0;
}

.separator {
    height: 1px;
    background-color: #e0e0e0;
}

:deep(.v-text-field__details),
:deep(.v-messages) {
    display: none;
}
:deep(.v-switch.v-input--selection-controls) {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
:deep(.v-input--selection-controls) {
  margin-top: 0 !important;
  padding-top: 1 !important;
}

.time-autocomplete {
    width: 120px;
}
</style>