<template>
  <div class="circle-parent relative flex justify-center items-center">
    <div :class="['circle-marker', color, { 'selected-border': isSelected }]">
      <span class="circle-number" :style="{ color: textColor }" :class="{ 'pl-5': isSelected }">{{
        number
      }}</span>
    <v-icon
      v-if="cross && isSelected"
      class="absolute -top-5 -right-1 text-red-600 cursor-pointer"
      @click.stop="emitCrossClick"
      color="red"
      size="20"
    >
      mdi-close
    </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: false,
      default: "white",
    },
    number: {
      type: Number,
      required: false,
      default: 0,
    },
    cross: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitCrossClick() {
      this.$emit("cross-click"); // Emit event to parent
    },
  },
};
</script>

<style scoped>
.circle-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  border-radius: 50%; /* Makes it circular */
  border: 2px solid white; /* Optional: border for better visibility */
  position: relative; /* For positioning */
}
.selected-border {
  border: 3px solid black; /* Optional: border for better visibility */
}
.circle-number {
  color: white; /* Text color */
  font-weight: bold;
  font-size: 14px; /* Adjust font size as needed */
}
</style>