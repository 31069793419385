<template>
    <div>
        <div v-if="isFetchingAttribute" class="flex justify-center items-center">
            <v-progress-circular indeterminate color="primary" />
        </div>
        <div v-else>
            <!-- Iterate over attribute groups -->
            <div v-for="(group, gIndex) in groupedAttributes" :key="gIndex" class="mb-4">
                <v-card elevation="2" class="classic-card">
                    <v-card-title class="text-lg font-semibold bg-gray-50">
                        {{ group.groupDisplayName }}
                    </v-card-title>
                    <v-card-text class="pt-4">
                        <!-- 1) BOOL attributes in a row -->
                        <div class="mb-4 flex flex-wrap gap-2">
                            <div v-for="(attribute, bIndex) in group.attributes.filter(a => a.valueType === 'BOOL')"
                                :key="bIndex">
                                <v-chip :color="getBoolChipColor(attribute.editedValue)"
                                    :text-color="getBoolChipTextColor(attribute.editedValue)"
                                    @click="toggleBoolAttribute(attribute, group)" class="cursor-pointer">
                                    <v-icon left v-if="attribute.editedValue === true" size="medium"
                                        :color="getBoolChipTextColor(attribute.editedValue)">
                                        mdi-check
                                    </v-icon>
                                    <v-icon left v-else-if="attribute.editedValue === false" size="medium"
                                        :color="getBoolChipTextColor(attribute.editedValue)">
                                        mdi-close
                                    </v-icon>
                                    {{ attribute.displayName }}
                                </v-chip>
                            </div>
                        </div>

                        <!-- 2) ENUM attributes in individual boxes (column) -->
                        <div class="mb-4">
                            <div v-for="(attribute, eIndex) in group.attributes.filter(a => a.valueType === 'ENUM')"
                                :key="eIndex" class="mb-4">
                                <label class="block mb-2 text-sm font-semibold text-gray-700">
                                    {{ attribute.displayName }}
                                </label>
                                <div class="flex flex-wrap gap-2 p-3 border rounded">
                                    <v-chip v-for="(option, optionIndex) in attribute.valueMetadata" :key="optionIndex"
                                        :color="attribute.editedValue === option.value ? '#22c55e' : '#e5e7eb'"
                                        :text-color="attribute.editedValue === option.value ? 'white' : 'black'"
                                        @click="setEnumAttribute(attribute, option.value, group)"
                                        class="cursor-pointer">
                                        <v-icon left v-if="attribute.editedValue === option.value" color="white"
                                            size="medium">
                                            mdi-check
                                        </v-icon>
                                        {{ option.displayName }}
                                    </v-chip>
                                </div>
                            </div>
                        </div>

                        <!-- 3) REPEATED_ENUM attributes in individual boxes (column) -->
                        <div class="mb-4">
                            <div v-for="(attribute, rIndex) in group.attributes.filter(a => a.valueType === 'REPEATED_ENUM')"
                                :key="rIndex" class="mb-4">
                                <label class="block mb-2 text-sm font-semibold text-gray-700">
                                    {{ attribute.displayName }}
                                </label>
                                <div class="flex flex-wrap gap-2 p-3 border rounded">
                                    <v-chip v-for="(option, optionIndex) in attribute.valueMetadata" :key="optionIndex"
                                        :color="attribute.editedValue.includes(option.value) ? '#22c55e' : '#e5e7eb'"
                                        :text-color="attribute.editedValue.includes(option.value) ? 'white' : 'black'"
                                        @click="toggleRepeatedEnumAttribute(attribute, option.value, group)"
                                        class="cursor-pointer">
                                        <v-icon left v-if="attribute.editedValue.includes(option.value)" color="white"
                                            size="medium">
                                            mdi-check
                                        </v-icon>
                                        {{ option.displayName }}
                                    </v-chip>
                                </div>
                            </div>
                        </div>

                        <!-- 4) URL attributes in individual boxes (column) -->
                        <div>
                            <div v-for="(attribute, uIndex) in group.attributes.filter(a => a.valueType === 'URL')"
                                :key="uIndex">
                                <label class="block mb-2 text-sm font-semibold text-gray-700">
                                    {{ attribute.displayName }}
                                </label>
                                <v-text-field v-model="attribute.editedValue"
                                    @input="updateUrlAttribute(attribute, group)" placeholder="Enter URL" dense outlined
                                    hide-details class="max-w-md" />
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
            <!-- Update Button -->
            <div class="actions p-4 flex justify-end">
                <div class="flex items-center justify-end gap-4">
                    <!-- Add Update Results Chip -->
                    <v-chip
                        v-if="updateResults.length"
                        class="mr-2"
                        :color="hasErrors ? 'error' : 'success'"
                        outlined
                        @click="showUpdateResults = true"
                    >
                        <v-icon left size="16">
                            {{ hasErrors ? 'mdi-alert-circle' : 'mdi-check-circle' }}
                        </v-icon>
                        Update Results
                    </v-chip>

                    <v-btn @click="updateAttributes" :loading="isUpdating"
                        :disabled="isUpdating || !changedAttributes.length"
                        class="update-btn">
                        <span class="flex items-center space-x-2 text-blue-500">
                            <img src="@/assets/img/icons8-google-48.png" alt="Google Icon" class="w-5 h-5" />
                            <span v-if="isBulk"  class="font-bold">Update for {{ this.locations.length }} locations</span>
                            <span v-else class="font-bold">Update on Google</span>
                        </span>
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- Add Update Results Dialog -->
        <v-dialog v-model="showUpdateResults" max-width="600px" scrollable>
            <v-card>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span>Update Results</span>
                    <v-btn icon @click="showUpdateResults = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                
                <v-card-text class="pt-4">
                    <!-- Summary Stats -->
                    <div class="mb-4 flex gap-4">
                        <v-chip outlined color="success">
                            Success: {{ successCount }}
                        </v-chip>
                        <v-chip outlined color="error">
                            Failed: {{ failedCount }}
                        </v-chip>
                    </div>

                    <!-- Results Table -->
                    <v-data-table
                        :headers="[
                            { text: 'Location', value: 'title' },
                            { text: 'Status', value: 'status' }
                        ]"
                        :items="updateResults"
                        :items-per-page="10"
                        :footer-props="{
                            'items-per-page-options': [10, 25, 50, 100]
                        }"
                        dense
                    >
                        <template v-slot:item.status="{ item }">
                            <v-chip
                                small
                                :color="item.status === 'success' ? 'success' : 'error'"
                                text-color="white"
                            >
                                {{ item.status }}
                            </v-chip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { doc, updateDoc, addDoc, collection } from 'firebase/firestore';
import { functions, db } from '@/utils/firebase.utils';

export default {
    props: {
        locations: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            isFetchingAttribute: false,
            isUpdating: false,
            groupedAttributes: [],
            attributes: [],
            locationAttribute: {},
            originalAttributes: {},
            changedAttributes: [],
            showUpdateResults: false,
            updateResults: [],
        };
    },
    mounted() {
        this.fetchAttributes();
    },
    computed: {
        isBulk() {
            return this.locations.length > 1 ? true : false;
        },
        successCount() {
            return this.updateResults.filter(result => result.status === 'success').length;
        },
        failedCount() {
            return this.updateResults.filter(result => result.status === 'failed').length;
        },
        hasErrors() {
            return this.failedCount > 0;
        }
    },
    methods: {
        async fetchAttributes() {
            if (!this.locations.length) return;
            this.isFetchingAttribute = true;
            const wid = this.locations[0].workspaceId;
            const uid = this.currentUID;
            const category = this.locations[0]?.formData?.primaryCategory?.name;
            if (!category) {
                this.$snackbar.show('Add a primary business category');
                this.isFetchingAttribute = false;
                return;
            }
            try {
                const fetchGmbAttributes = httpsCallable(functions, 'fetchGmbAttributesFunction');
                const res = await fetchGmbAttributes({ wid, uid, category, locationId: this.locations[0].name });
                if(this.locations.length==1){
                    this.locationAttribute = res.data.locationAttribute;
                }
                this.attributes = res.data.allAttributes.map((attr) => {
                    const locationAttr = this.locationAttribute.attributes?.find((locAttr) => locAttr.name === attr.parent);
                    let editedVal;
                    if (attr.valueType === 'BOOL') {
                        editedVal = locationAttr?.values?.length ? locationAttr.values[0] : '';
                    } else if (attr.valueType === 'REPEATED_ENUM') {
                        editedVal = locationAttr?.repeatedEnumValue?.setValues || [];
                    } else if (attr.valueType === 'ENUM') {
                        editedVal = locationAttr?.values?.[0] || '';
                    } else if (attr.valueType === 'URL') {
                        editedVal = locationAttr?.uriValues?.[0]?.uri || '';
                    }
                    return { ...attr, editedValue: editedVal, originalValue: editedVal };
                });
                const groups = {};
                this.originalAttributes = {};
                this.attributes.forEach((attr) => {
                    if (!groups[attr.groupDisplayName]) {
                        groups[attr.groupDisplayName] = {
                            groupDisplayName: attr.groupDisplayName,
                            attributes: [],
                        };
                        this.originalAttributes[attr.groupDisplayName] = [];
                    }
                    groups[attr.groupDisplayName].attributes.push(attr);
                    this.originalAttributes[attr.groupDisplayName].push({
                        ...attr,
                        editedValue: JSON.parse(JSON.stringify(attr.editedValue)),
                    });
                });
                this.groupedAttributes = Object.values(groups);
            } catch (error) {
                console.error('Error fetching attributes:', error);
                this.$snackbar.show('Failed to fetch attributes');
            }
            this.isFetchingAttribute = false;
        },
        getBoolChipColor(value) {
            if (value === true) return '#22c55e'; // green
            if (value === false) return '#ef4444'; // red
            return '#e5e7eb'; // grey
        },
        getBoolChipTextColor(value) {
            if (value === true || value === false) return 'white';
            return 'black';
        },
        toggleBoolAttribute(attribute, group) {
            if (attribute.editedValue === true) {
                attribute.editedValue = false;
            } else if (attribute.editedValue === false) {
                attribute.editedValue = '';
            } else {
                attribute.editedValue = true;
            }
            this.updateChangedAttributes(group);
        },
        setEnumAttribute(attribute, value, group) {
            attribute.editedValue = attribute.editedValue === value ? '' : value;
            this.updateChangedAttributes(group);
        },
        toggleRepeatedEnumAttribute(attribute, value, group) {
            const index = attribute.editedValue.indexOf(value);
            if (index === -1) {
                attribute.editedValue.push(value);
            } else {
                attribute.editedValue.splice(index, 1);
            }
            this.updateChangedAttributes(group);
        },
        updateUrlAttribute(attribute, group) {
            this.updateChangedAttributes(group);
        },
        updateChangedAttributes(group) {
            const existingAttrMap = new Map(this.changedAttributes.map(attr => [attr.name, attr]));
            const originalGroupAttrs = this.originalAttributes[group.groupDisplayName] || [];
            group.attributes.forEach((attribute, index) => {
                const original = originalGroupAttrs[index];
                const changed = original && JSON.stringify(attribute.editedValue) !== JSON.stringify(original.editedValue);
                if (!changed) {
                    existingAttrMap.delete(attribute.parent);
                    return;
                }
                const change = {
                    name: attribute.parent,
                    valueType: attribute.valueType,
                    values: [],
                };
                switch (attribute.valueType) {
                    case 'REPEATED_ENUM':
                        change.values = [attribute.editedValue];
                        break;
                    case 'BOOL':
                    case 'ENUM':
                    case 'URL':
                    default:
                        change.values = attribute.editedValue === '' ? [] : [attribute.editedValue];
                        break;
                }
                existingAttrMap.set(attribute.parent, change);
            });
            this.changedAttributes = [...existingAttrMap.values()];
        },
        getLocationName(location){
            return `${location.title} ${location?.storefrontAddress?.addressLines?.[0]?.split(',')[0]},
                                                ${location?.storefrontAddress?.locality} ,
                                                ${location?.storefrontAddress?.administrativeArea}`
        },
        async updateAttributes() {
            try {
                this.isUpdating = true;
                const changes = [];
                let flag = false;
                const updates = this.locations.map(location => {
                    const updateFields = {};
                    // Check both individual and bulk update eligibility
                    // if (!this.getLocationUpdateEligibility(location).canUpdate && !bulkPrimary) {
                    //     return null;
                    // }
                    if (this.changedAttributes.length) {
                        updateFields.attributes = this.changedAttributes;
                        if (!flag) {
                            const attributeChange = this.fetchAttributeChanges();
                            changes.push({
                                id: 'attributes',
                                field: 'Location Attributes',
                                value: JSON.stringify(this.changedAttributes),
                                changes: attributeChange
                            })
                        }
                    }
                    // Only return if there are fields to update
                    flag = true;
                    return Object.keys(updateFields).length > 0 ? {
                        locationId: location.name,
                        updateFields,
                        displayName: location.storeLocation,
                        wid: location.workspaceId,
                        accountId: location.accountId
                    } : null;
                }).filter(Boolean);
                if (updates.length === 0) {
                    this.$snackbar.show('No changes to update');
                    return;
                }
                const payload = {
                    uid: this.currentUID,
                    workspace_id: this.WID,
                    updates
                };
                const updateGMBAttribute = httpsCallable(functions, 'updateGmbAttributesFunction');
                const res = await updateGMBAttribute(payload);
                if (res?.data?.results) {
                    // Create a location map for faster lookups
                    const locationMap = {};
                    this.locations.forEach(location => {
                        locationMap[location.workspaceId] = this.getLocationName(location);
                    });

                    // Map results with titles using the lookup map
                    this.updateResults = res.data.results.map(item => ({
                        ...item,
                        title: locationMap[item.wid] || 'Unknown Location'
                    }));

                    // Count successful and failed updates
                    const successCount = res.data.results.filter(result => result.status === "success").length;
                    const failedCount = res.data.results.filter(result => result.status === "failed").length;
                    
                    if (this.isBulk) {
                        await addDoc(collection(db, "users", this.currentUID, "fly_workspaces", this.WID, "gmb-update-history"),
                            {
                                requested_by: this.currentUser.display_name || "unknow",
                                title: "Request for bulk Update",
                                type: 'multiple',
                                address: ``,
                                changes,
                                updateResults: this.updateResults,
                                requested_at: new Date(),
                            }
                        )
                    }
                    else {
                        await addDoc(collection(db, 'users', this.currentUID, 'fly_workspaces', this.WID, 'gmb-update-history'), {
                            requested_by: this.currentUser.display_name || 'unknown',
                            locationId: this.locations[0].name,
                            type: 'single',
                            title: this.locations[0].title,
                            address: `${this.locations[0]?.storefrontAddress?.addressLines?.[0]?.split(',')[0]}, ${this.locations[0]?.storefrontAddress?.locality}, ${this.locations[0]?.storefrontAddress?.administrativeArea}`,
                            changes,
                            updateResults: this.updateResults,
                            requested_at: new Date(),
                            wid: this.locations[0].workspaceId,
                            accountId: this.locations[0].accountId || '',
                        });
                    }

                    this.changedAttributes = [];
                    const currDate = new Date();
                    if (this.isBulk) {
                        const workspaceRef = doc(db, `users/${this.currentUID}/fly_workspaces/${this.WID}`);
                        await updateDoc(workspaceRef, { gmb_bulk_last_updated: currDate });
                    }
                    else {
                        const workspaceRef = doc(db, `users/${this.currentUID}/fly_workspaces/${this.locations[0].workspaceId}`);
                        await updateDoc(workspaceRef, { gmb_last_updated: currDate });
                    }

                    // Show the results dialog if there are any failures
                    if (failedCount > 0) {
                        this.showUpdateResults = true;
                    } else {
                        this.$snackbar.show(`Updates successfully applied to all ${successCount} locations`);
                    }
                }
            } catch (error) {
                console.error('Error updating Attribute:', error);
                this.$alert.show('Error updating Attribute');
            } finally {
                this.isUpdating = false;
            }
        },
        fetchAttributeChanges() {
            return this.changedAttributes.map((change) => {
                let newValue = change.values[0];
                let oldValue = this.locationAttribute?.attributes?.find((item) => item.name === change.name)?.values?.[0];
                let displayName = this.attributes.find((item) => item.parent === change.name)?.displayName;
                if (typeof newValue === 'boolean') {
                    newValue = newValue ? 'Yes' : 'No';
                }
                if (typeof oldValue === 'boolean') {
                    oldValue = oldValue ? 'No' : 'Yes';
                }
                if (!newValue) newValue = 'Null';
                if (!oldValue) oldValue = 'Null';
                if (Array.isArray(newValue)) newValue = newValue.join(', ');
                if (Array.isArray(oldValue)) oldValue = oldValue.join(', ');
                return { newValue, oldValue, displayName };
            });
        },
    },
};
</script>

<style scoped>
</style>