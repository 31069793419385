<template>
  <div class="flex flex-col items-center justify-center w-full bg-[#F2F6FA]">
    <section class="flex flex-col items-center w-full justify-center">
      <div
        class="flex items-center justify-start md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full"
      >
        <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
          <img
            src="@/assets/img/sidebar/icons8-rank-64.png"
            class="cursor-pointer w-6 md:w-[28px]"
            alt="post"
          />
          <div class="flex items-center gap-1">
            <p class="text-base md:text-[22px] font-bold">
              Rank Tracker<sup class="text-sm text-gray-500 pl-2">Beta</sup>
            </p>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-start mb-3 ml-[48px] md:mb-0 px-2 md:px-0 w-full"
      >
        <div class="items-center gap-2 px-4 d-flex">
          <div class="flex items-center gap-1">
            <p class="text-lg md:text-[18px] text-gray-600">
              Track your rank amongst local competitors across top keywords
            </p>
          </div>
        </div>
      </div>
      <div class="relative w-full" style="height: calc(100vh - 200px)">
        <!-- Fixed Box -->
        <div
          class="absolute top-0 left-0 w-2/5 bg-transparent p-4 pr-8 pl-4 z-50 mb-2"
        >
          <div class="mb-4 p-4 bg-white rounded-lg shadow flex flex-col gap-2">
            <div class="flex items-center gap-2 flex-1">
              <div class="font-semibold">Location</div>
              <div class="relative flex-1">
                <v-text-field
                  v-model="locationSearch"
                  :label="
                    selectedStore ? selectedStore.sub_locality : 'Locations'
                  "
                  hide-details
                  dense
                  outlined
                  @click="toggleStoreDropdown($event)"
                  class="location-input"
                  color="rgba(0, 0, 0, 0.6)"
                  background-color="white"
                  :style="{
                    '& .v-label': {
                      top: '6px',
                      fontSize: '14px',
                    },
                  }"
                >
                  <template v-slot:append>
                    <button
                      @click="toggleStoreDropdown($event)"
                      class="absolute inset-y-0 right-0 flex items-center px-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </button>
                  </template>
                </v-text-field>
                <div
                  ref="showStoreDropdown"
                  v-if="showStoreDropdown"
                  class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-60 min-w-[300px] max-w-[800px] overflow-y-auto py-2 px-5"
                >
                  <div
                    v-for="profile in filteredStores"
                    :key="profile.wid"
                    class="platform p-2 hover:bg-gray-100 cursor-pointer"
                    @click="selectStore(profile)"
                    :class="{ 'bg-gray-200': selectedWID === profile.wid }"
                  >
                    <div class="flex items-center gap-2">
                      <div class="flex flex-col">
                        <span
                          class="font-medium text-base"
                          v-if="profile.sub_locality"
                          >{{ profile.sub_locality }}</span
                        >
                        <span class="text-sm">{{ profile.city }}</span>
                        <span class="text-sm">{{ profile.state }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="flex items-center gap-2"
              v-if="
                selectedKeyword &&
                formattedCompetitors &&
                Object.keys(formattedCompetitors).length > 0
              "
            >
              <div class="font-semibold">Keyword</div>
              <v-menu
                v-model="menu"
                offset-y
                transition="scale-transition"
                :close-on-content-click="false"
                class="keyword-menu flex-1"
              >
                <template v-slot:activator="{ on }">
                  <button
                    v-on="on"
                    class="select-keyword text-black py-2 px-4 rounded-lg max-sm:text-sm flex items-center justify-between"
                    :class="{
                      'select-keyword': true,
                      // 'bg-green-500 cursor-pointer': !isLoadingCompetitors,
                      'cursor-not-allowed': isLoadingCompetitors, // Change color when loading
                      'cursor-not-allowed': isLoadingCompetitors, // Change cursor
                      'text-black': !selectedKeyword,
                      'text-blue-500': selectedKeyword,
                    }"
                    :disabled="isLoadingCompetitors || !keywords?.length"
                    style="border: 1px solid #d1d5db"
                  >
                    <span>{{ selectedKeyword || "Select Keyword" }}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 text-black ml-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                    <v-progress-circular
                      v-if="isLoadingCompetitors"
                      indeterminate
                      color="white"
                      size="20"
                      width="2"
                      class="ml-2"
                    ></v-progress-circular>
                  </button>
                </template>

                <v-card class="min-w-[300px] w-full">
                  <v-card-title>
                    <span class="text-h6">Select Keyword</span>
                  </v-card-title>
                  <v-card-text>
                    <table class="w-full text-left">
                      <thead class="border-b-2 border-gray-200">
                        <tr class="font-bold">
                          <th class="text-sm pl-2 pb-3">Keyword</th>
                          <th class="text-sm pl-2 pb-3">
                            Avg. Monthly <br />
                            Search Volume
                          </th>
                          <th class="text-sm pl-2 pb-3">Avg. Rank</th>
                        </tr>
                      </thead>
                      <tbody class="overflow-y-auto max-h-[300px]">
                        <tr
                          v-for="(item, index) in keywordsWithRanks"
                          :key="index"
                          @click="handleKeywordChange(item.keyword)"
                          class="cursor-pointer hover:bg-gray-100"
                        >
                          <td
                            class="pl-2 py-2 max-w-[200px] break-words whitespace-normal"
                            :class="{
                              'text-blue-500':
                                item.keyword.toLowerCase() ==
                                selectedKeyword?.toLowerCase(),
                            }"
                          >
                            {{ item.keyword }}
                          </td>
                          <td class="text-center py-2">
                            {{ item.searchVolume }}
                          </td>
                          <td
                            class="text-center py-2"
                            :class="getRankClass(item.averageRank)"
                          >
                            {{
                              item.averageRank > 20 ? "20+" : item.averageRank
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>

            <div
              class="flex justify-between mt-3"
              v-if="
                selectedKeyword &&
                formattedCompetitors &&
                Object.keys(formattedCompetitors).length > 0
              "
            >
              <h3 class="text-sm font-semibold">
                Rank Across All Geopoints And Keywords
              </h3>
              <div
                class="font-bold"
                :style="{
                  color:
                    myBusinessData.avg_rank <= 5
                      ? 'green'
                      : myBusinessData.avg_rank <= 10
                      ? 'orange'
                      : 'red',
                }"
              >
                {{
                  myBusinessData.avg_rank > 20 ? "20+" : myBusinessData.avg_rank
                }}
              </div>
            </div>
            <div
              class="flex justify-between"
              v-if="
                selectedKeyword &&
                formattedCompetitors &&
                Object.keys(formattedCompetitors).length > 0
              "
            >
              <h3 class="text-sm font-semibold">
                Rank Across All Geopoints for
                <span class="text-blue-500"> "{{ selectedKeyword }}" </span>
              </h3>
              <div
                class="font-bold"
                :style="{
                  color:
                    myBusinessData.keyword_avg_rank <= 5
                      ? 'green'
                      : myBusinessData.keyword_avg_rank <= 10
                      ? 'orange'
                      : 'red',
                }"
              >
                {{
                  myBusinessData.keyword_avg_rank > 20
                    ? "20+"
                    : myBusinessData.keyword_avg_rank
                }}
              </div>
            </div>
            <div
              class="flex justify-between"
              v-if="
                selectedKeyword &&
                selectedGrid !== 'All Grids' &&
                formattedCompetitors &&
                Object.keys(formattedCompetitors).length > 0
              "
            >
              <h3 class="text-sm font-semibold">
                Rank Across Geopoint {{ selectedGrid }} for
                <span class="text-blue-500"> "{{ selectedKeyword }}" </span>
              </h3>
              <div
                class="font-bold"
                :style="{
                  color:
                    myBusinessData.rank <= 5
                      ? 'green'
                      : myBusinessData.rank <= 10
                      ? 'orange'
                      : 'red',
                }"
              >
                {{ myBusinessData.rank > 20 ? "20+" : myBusinessData.rank }}
              </div>
            </div>
          </div>
        </div>

        <!-- Scrollable Competitors Panel -->
        <div
          class="absolute top-32 mt-20 left-0 w-2/5 h-[75%] bg-transparent p-4 overflow-y-scroll z-40"
          :class="{ 'mt-28': selectedGrid !== 'All Grids' }"
          v-if="
            selectedKeyword &&
            formattedCompetitors &&
            Object.keys(formattedCompetitors).length > 0
          "
        >
          <div
            v-for="(compData, compName, index) in filteredCompetitors"
            :key="compName"
            class="mb-4 p-4 bg-white rounded-lg shadow flex flex-col gap-2"
            :class="{
              'border-2 border-blue-500': compData.isMyBusiness,
            }"
            :style="{
              backgroundColor: compData.isMyBusiness
                ? compData.avgRank <= 5 || compData.rank <= 5
                  ? '#D0E8C5'
                  : compData.avgRank <= 10 || compData.rank <= 10
                  ? '#FFF0D2'
                  : '#FDDDDD'
                : 'white',
            }"
          >
            <div class="flex items-start">
              <span class="font-semibold text-base">
                {{ selectedGrid === "All Grids" ? index + 1 : compName + 1 }}.
              </span>
              <h3
                class="text-base font-bold ml-2 mr-1"
                :class="{
                  'font-bold text-blue-500': compData.isMyBusiness,
                }"
              >
                {{ compData.title }}
              </h3>
              <span
                v-if="compData.isMyBusiness"
                class="ml-2 px-2 py-1 bg-blue-500 text-white text-xs rounded-full"
              >
                You
              </span>
            </div>
            <p class="text-sm">{{ compData.address }}</p>
            <div class="flex justify-between">
              <p class="text-sm font-semibold">
                {{
                  selectedGrid === "All Grids" ? "Avg. Rank Score:" : "Rank:"
                }}
                <span
                  class="font-bold text-base"
                  :style="{
                    color:
                      compData.avgRank <= 5 || compData.rank <= 5
                        ? 'green'
                        : compData.avgRank <= 10 || compData.rank <= 10
                        ? 'orange'
                        : 'red',
                  }"
                >
                  {{
                    selectedGrid === "All Grids"
                      ? compData.avgRank
                      : compData.rank
                  }}
                </span>
              </p>
              <div>
                <div class="flex items-center gap-1">
                  <v-icon small color="amber">mdi-star</v-icon>
                  {{ compData.average_rating }}
                  <div class="h-[5px] w-[5px] bg-blue-500 rounded-full"></div>
                  {{ compData.total_reviews }} Reviews
                </div>
              </div>
            </div>
            <div class="border-t border-gray-300 pt-2">
              <div class="flex items-center justify-between">
                <div
                  class="cursor-pointer text-blue-500"
                  @click="openCompetitorDialog(compData, compName)"
                >
                  Geopoint Ranking
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Maps -->
        <div
          class="w-full h-full"
          v-if="
            selectedKeyword &&
            formattedCompetitors &&
            Object.keys(formattedCompetitors).length > 0
          "
        >
          <gmap-map
            v-if="selectedKeyword"
            :center="center"
            :zoom="15"
            map-type-id="terrain"
            style="width: 100%; height: 100%"
            class="map-container"
          >
            <!-- Grid Points -->
            <!-- working -->
            <gmap-marker
              v-for="(marker, index) in markers"
              :key="index"
              :position="marker.position"
              :icon="generateCustomIcon(marker.rank, marker.gridId)"
              @click="showGridCompetitors(marker.gridId)"
              :class="[
                'marker-circle',
                { selected: selectedGrid === marker.gridId },
              ]"
            />

            <gmap-marker
              v-if="markers.length > 4"
              :position="markers[4].position"
              :icon="competitorIcon"
              @click="showGridCompetitors(markers[4].gridId)"
            />
          </gmap-map>
        </div>
        <div
          v-if="
            !isLoadingCompetitors &&
            (!formattedCompetitors ||
              Object.keys(formattedCompetitors).length == 0)
          "
          class="absolute top-[14%] left-0"
        >
          <span class="text-center text-gray-500 pt-3 mt-1 text-lg px-4"
            >No data available for the selected location.</span
          >
        </div>
      </div>
      <v-dialog v-model="showCompetitorDialog" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-blue-500">
              {{ selectedCompetitor?.title }}
            </span>
            &nbsp;vs&nbsp;
            <span class="text-blue-500">
              {{ myBusinessData.title || myBusiness }}
            </span>
          </v-card-title>
          <v-card-subtitle class="text-lg pt-3 font-semibold">
            Keyword:
            <span class="text-blue-500 font-normal">
              {{ selectedKeyword }}
            </span>
          </v-card-subtitle>
          <v-card-text>
            <gmap-map
              :center="mapCenter"
              :zoom="13"
              style="width: 100%; height: 400px"
            >
              <gmap-marker
                v-for="(marker, idx) in competitorMarkers"
                :key="`comp-${idx}`"
                :position="marker.position"
                :icon="generateCustomIcon(marker.rank, marker.gridId, 'dialog')"
              />
            </gmap-map>

            <div class="comparison-section mt-4">
              <h3 class="font-semibold">Comparison</h3>
              <table class="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr class="bg-gray-100 text-left">
                    <th class="py-2 px-4 border-b">Business Name</th>
                    <th class="py-2 px-4 border-b">Avg. Rank</th>
                    <th class="py-2 px-4 border-b">Rating</th>
                    <th class="py-2 px-4 border-b">Reviews</th>
                    <th class="py-2 px-4 border-b">Categories</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="selectedCompetitorData" class=" ">
                    <td class="py-2 px-4 border-b">
                      {{ selectedCompetitorData.title || myBusiness }}
                    </td>
                    <td class="py-2 px-4 border-b">
                      {{ selectedCompetitorData.avg_rank }}
                    </td>
                    <td class="py-2 px-4 border-b">
                      {{ selectedCompetitorData.average_rating }}
                    </td>
                    <td class="py-2 px-4 border-b">
                      {{ selectedCompetitorData.total_reviews }}
                    </td>
                    <td class="py-2 px-4 border-b">
                      {{ selectedCompetitorData.category }}
                    </td>
                  </tr>
                  <tr class="text-sky-500">
                    <td class="py-2 px-4 border-b">
                      {{ myBusinessData.title || myBusiness }}
                    </td>
                    <td class="py-2 px-4 border-b">
                      {{ myBusinessData.keyword_avg_rank }}
                    </td>
                    <td class="py-2 px-4 border-b">
                      {{ myBusinessData.average_rating }}
                    </td>
                    <td class="py-2 px-4 border-b">
                      {{ myBusinessData.total_reviews }}
                    </td>
                    <td class="py-2 px-4 border-b">
                      {{ myBusinessData.category }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="primary" @click="showCompetitorDialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 
      <div class="w-full mx-auto mt-6">
        <div class="bg-white rounded-lg shadow-sm p-4 md:p-6 mb-6 min-h-[80vh]">
          <div class="w-full mx-auto px-8 mb-8 report-container">
            <div class="flex justify-between">
              <div class="gap-2 w-full md:w-1/3 mr-2">
                <div class="relative">
                  <v-text-field
                    v-model="locationSearch"
                    :label="
                      selectedStore ? selectedStore.sub_locality : 'Locations'
                    "
                    hide-details
                    dense
                    outlined
                    @click="toggleStoreDropdown($event)"
                    class="location-input"
                    color="rgba(0, 0, 0, 0.6)"
                    background-color="white"
                    :style="{
                      '& .v-label': {
                        top: '6px',
                        fontSize: '14px',
                      },
                    }"
                  >
                    <template v-slot:append>
                      <button
                        @click="toggleStoreDropdown($event)"
                        class="absolute inset-y-0 right-0 flex items-center px-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>
                    </template>
                  </v-text-field>
                  <div
                    ref="showStoreDropdown"
                    v-if="showStoreDropdown"
                    class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-60 min-w-[300px] max-w-[800px] overflow-y-auto py-2 px-5"
                  >
                    <div
                      v-for="profile in filteredStores"
                      :key="profile.wid"
                      class="platform p-2 hover:bg-gray-100 cursor-pointer"
                      @click="selectStore(profile)"
                      :class="{ 'bg-gray-200': selectedWID === profile.wid }"
                    >
                      <div class="flex items-center gap-2">
                        <div class="flex flex-col">
                          <span
                            class="font-medium text-base"
                            v-if="profile.sub_locality"
                            >{{ profile.sub_locality }}</span
                          >
                          <span class="text-sm">{{ profile.city }}</span>
                          <span class="text-sm">{{ profile.state }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                !isLoadingCompetitors &&
                (!formattedCompetitors ||
                  Object.keys(formattedCompetitors).length == 0)
              "
              class="text-center text-gray-500 pt-3 mt-1 text-lg"
            >
              No data available for the selected location.
            </div>
            <div
              class="w-full"
              v-if="
                formattedCompetitors &&
                Object.keys(formattedCompetitors).length > 0
              "
            >
              <div
                class="flex rounded-lg shadow-lg w-[100%] mx-auto my-8 bg-white border border-gray-300"
              >
                <div class="flex flex-col px-4 py-2 gap-4">
                  <h2 class="text-lg font-semibold">
                    Overview - {{ generatedDate }}
                  </h2>
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div
                      class="flex justify-between items-center border border-gray-300 rounded-lg p-4"
                    >
                      <h3 class="text-base font-semibold">Total Keywords</h3>
                      <span
                        class="text-base font-semibold border border-gray-300 rounded-lg px-4 py-1 bg-gray-100 text-sky-700"
                        >{{ keywords.length }}</span
                      >
                    </div>

                    <div
                      class="flex justify-between items-center border border-gray-300 rounded-lg p-4"
                    >
                      <h3 class="text-base font-semibold">
                        Rank Across All Grids And Keywords
                      </h3>
                      <div class="flex items-center gap-2 justify-end">
                        <span
                          class="text-base font-semibold border border-gray-300 rounded-lg px-4 py-1 bg-gray-100 text-sky-700"
                          v-if="myBusinessData.avg_rank !== 'N/A'"
                          >{{ myBusinessData.avg_rank }}</span
                        >
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div
              class="flex rounded-lg shadow-lg w-[100%] mx-auto my-8 bg-white border border-gray-300"
              v-if="
                formattedCompetitors &&
                Object.keys(formattedCompetitors).length > 0
              "
            >
              <div class="px-4 my-4 flex flex-col w-full">
                <div
                  class="flex max-sm:flex-col max-sm:gap-6 justify-between items-center max-sm:items-start"
                >
                  <div
                    class="flex gap-4 max-sm:flex-col max-sm:gap-6 max-sm:items-start justify-between w-full"
                  >
                    <div class="max-sm:w-full">
                      <div class="flex items-center justify-between">
                        <div
                          class="max-sm:text-base text-lg font-semibold mr-3"
                        >
                          Results For:
                        </div>
                        <v-menu
                          v-model="menu"
                          offset-y
                          transition="scale-transition"
                          :close-on-content-click="false"
                          class="keyword-menu"
                        >
                          <template v-slot:activator="{ on }">
                            <button
                              v-on="on"
                              class="select-keyword text-black py-2 px-4 rounded-lg max-sm:text-sm border-gray-300 flex items-center justify-between"
                              :class="{
                                'select-keyword': true,
                                // 'bg-green-500 cursor-pointer': !isLoadingCompetitors,
                                'cursor-not-allowed': isLoadingCompetitors, // Change color when loading
                                'cursor-not-allowed': isLoadingCompetitors, // Change cursor
                                'text-black': !selectedKeyword,
                                'text-blue-500': selectedKeyword,
                              }"
                              :disabled="
                                isLoadingCompetitors || !keywords?.length
                              "
                              style="border: 1px solid #d1d5db"
                            >
                              <span>{{
                                selectedKeyword || "Select Keyword"
                              }}</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4 text-black ml-2"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                              <v-progress-circular
                                v-if="isLoadingCompetitors"
                                indeterminate
                                color="white"
                                size="20"
                                width="2"
                                class="ml-2"
                              ></v-progress-circular>
                            </button>
                          </template>

                          <v-card class="min-w-[300px] max-w-[450px]">
                            <v-card-title>
                              <span class="text-h6">Select Keyword</span>
                            </v-card-title>
                            <v-card-text>
                              <div
                                class="header-row flex justify-between font-bold mb-2"
                              >
                                <span class="keyword-header flex-2 text-sm pl-2"
                                  >Keyword</span
                                >
                                <span
                                  class="search-volume-header flex-1 text-sm pl-2"
                                  >Avg. <br />
                                  Monthly <br />
                                  Search Volume</span
                                >
                                <span
                                  class="avg-rank-header flex-1 text-sm pl-2"
                                  >Avg. Rank</span
                                >
                              </div>
                              <div
                                class="scrollable-list overflow-y-auto max-h-[300px]"
                              >
                                <v-list>
                                  <v-list-item-group>
                                    <v-list-item
                                      v-for="(item, index) in keywordsWithRanks"
                                      :key="index"
                                      @click="handleKeywordChange(item.keyword)"
                                    >
                                      <v-list-item-content>
                                        <div
                                          class="flex justify-between items-center w-full"
                                        >
                                          <span
                                            class="keyword-name flex-2 max-w-[200px] break-words whitespace-normal"
                                            >{{ item.keyword }}</span
                                          >
                                          <span
                                            class="search-volume text-center flex-1"
                                            >{{ item.searchVolume }}</span
                                          >
                                          <span
                                            class="avg-rank text-center flex-1"
                                            :class="
                                              getRankClass(item.averageRank)
                                            "
                                            >{{
                                              item.averageRank > 20
                                                ? "20+"
                                                : item.averageRank
                                            }}</span
                                          >
                                        </div>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list-item-group>
                                </v-list>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </div>

                    <div class="max-sm:w-full">
                      <div class="flex items-center justify-between">
                        <div
                          class="max-sm:text-base text-lg font-semibold mr-3"
                        >
                          Grid:
                        </div>
                        <v-menu
                          v-model="gridMenu"
                          offset-y
                          transition="scale-transition"
                          :close-on-content-click="false"
                          class="grid-menu max-w-[250px] bg-white z-50"
                        >
                          <template v-slot:activator="{ on }">
                            <button
                              v-on="on"
                              class="text-black py-2 px-4 rounded-lg max-sm:text-sm border-gray-300 flex items-center justify-between"
                              style="border: 1px solid #d1d5db"
                              :class="{
                                'text-black': !selectedGrid,
                                'text-blue-500': selectedGrid,
                              }"
                            >
                              {{ selectedGrid || "Select Grid" }}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4 text-black ml-2"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </button>
                          </template>

                          <v-container fluid class="max-w-[300px]">
                            <v-row>
                              <v-col cols="12 my-3">
                                <span
                                  class="text-lg font-bold px-4 cursor-pointer border border-gray-300 rounded-lg py-2 hover:bg-gray-300 text-blue-500"
                                  @click="selectGrid('All Grids')"
                                  >Select all grids</span
                                >
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col
                                v-for="grid in gridOptions"
                                :key="grid"
                                cols="4"
                              >
                                <div
                                  class="circle-marker-container flex justify-center items-center cursor-pointer"
                                  :class="{ selected: selectedGrid === grid }"
                                  @click="selectGrid(grid)"
                                >
                                  <circle-marker
                                    color="bg-green-700"
                                    textColor="#000000"
                                    :number="grid"
                                    :cross="true"
                                    :isSelected="selectedGrid === grid"
                                    @cross-click="selectGrid('All Grids')"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-menu>
                      </div>
                    </div>
                  </div>

                </div>
                <div
                  class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6"
                  v-if="
                    selectedKeyword &&
                    formattedCompetitors &&
                    Object.keys(formattedCompetitors).length > 0
                  "
                >
                  <div
                    class="flex justify-between items-center border border-gray-300 rounded-lg p-4 gap-2"
                  >
                    <h3 class="text-base font-semibold">
                      Rank Across All Grids for "{{ selectedKeyword }}"
                    </h3>
                    <span
                      class="text-base font-semibold border border-gray-300 rounded-lg px-4 py-1 bg-gray-100 text-sky-700"
                      >{{ myBusinessData.keyword_avg_rank }}
                    </span>
                  </div>

                  <div
                    class="flex justify-between items-center border border-gray-300 rounded-lg p-4 gap-2"
                    v-if="selectedGrid !== 'All Grids'"
                  >
                    <h3 class="text-base font-semibold">
                      Rank in Grid {{ selectedGrid }} for "{{
                        selectedKeyword
                      }}"
                    </h3>
                    <div class="flex items-center gap-2 justify-end">
                      <span
                        class="text-base font-semibold border border-gray-300 rounded-lg px-4 py-1 bg-gray-100 text-sky-700"
                        v-if="myBusinessData.avg_rank !== 'N/A'"
                        >{{ myBusinessData.rank }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="timeline" v-if="timelineDates.length">
                  <div class="timeline-inner">
                    <div class="timeline-line"></div>
                    <div class="timeline-points">
                      <div
                        v-for="(item, index) in timelineDates"
                        :key="index"
                        class="timeline-point"
                      >
                        <div
                          class="circle"
                          :class="getDateClass(item.date)"
                          @click="showMenu(item.date, $event)"
                        ></div>
                        <div class="date">{{ formatDate(item.date) }}</div>
                        <div>
                          <div class="">
                            <div
                              v-if="item.avgRank"
                              class="flex items-center justify-center gap-1"
                            >
                              <strong>Avg. Rank </strong
                              ><span class="text-sky-700 pl-2 font-semibold">{{
                                item.avgRank
                              }}</span>
                              <div
                                class="w-5 h-5 ml-2 border border-gray-700 rounded flex items-center justify-center flex-none cursor-pointer"
                                @click="showMenu(item.date, $event)"
                              >
                                <img
                                  src="@/assets/img/plus_c.svg"
                                  class="h-4 w-4 object-fill"
                                  alt="View"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <v-menu
                  v-model="menuVisible"
                  :position-x="menuPosition.x"
                  :position-y="menuPosition.y"
                  absolute
                  offset-y
                  :close-on-content-click="false"
                  :style="{ zIndex: 9999 }"
                >
                  <v-card v-if="myTimelineBusinessData">
                    <v-card-title>{{
                      myTimelineBusinessData.title
                    }}</v-card-title>
                    <v-card-text>
                      <p>
                        <strong>Avg. Rating:</strong>
                        {{ tooltipData.average_rating }}
                      </p>
                      <p>
                        <strong>Reviews:</strong>
                        {{ tooltipData.total_reviews }}
                      </p>
                      <div class="grid-info" v-if="myTimelineBusinessData">
                        <div>
                          <p>
                            <strong>Avg. Rank across all Grids:</strong>
                            <span class="text-sky-700 font-bold pl-3">{{
                              myTimelineBusinessData.avgRank
                            }}</span>
                          </p>
                        </div>
                        <div
                          class="grid grid-cols-3 gap-2 mt-4 justify-center items-center"
                        >
                          <div
                            v-for="gridId in Object.keys(
                              myTimelineBusinessData.gridRanks
                            )"
                            :key="gridId"
                            class="flex justify-center items-center w-12 h-12 bg-green-700 text-white rounded-full mx-auto"
                            :class="{
                              'bg-green-700':
                                myTimelineBusinessData.gridRanks[gridId] <= 5,
                              'bg-orange-600':
                                myTimelineBusinessData.gridRanks[gridId] > 5,
                              'bg-red-700':
                                myTimelineBusinessData.gridRanks[gridId] > 10,
                            }"
                            :style="{
                              backgroundColor:
                                myTimelineBusinessData.gridRanks[gridId] <= 5
                                  ? 'green'
                                  : myTimelineBusinessData.gridRanks[gridId] <=
                                    10
                                  ? 'orange'
                                  : 'red',
                            }"
                          >
                            <span>{{
                              myTimelineBusinessData.gridRanks[gridId] || "N/A"
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>

                <div
                  class="competitors-table mt-6"
                  v-if="
                    selectedKeyword &&
                    formattedCompetitors &&
                    Object.keys(formattedCompetitors).length > 0
                  "
                >
                  <div class="flex justify-between mt-3 mb-6">
                    <h2 class="text-xl font-bold">
                      Overall Top Ranking Competitors for
                      <span class="text-sky-700">{{ selectedKeyword }}</span>
                    </h2>
                    <div></div>
                  </div>
                  <div class="overflow-x-auto">
                    <table class="min-w-full bg-white border border-gray-200">
                      <thead>
                        <tr class="bg-gray-100 text-left">
                          <th class="py-2 px-4 border-b">Business Name</th>
                          <th
                            class="py-2 px-4 border-b"
                            v-if="selectedGrid === 'All Grids'"
                          >
                            Avg. Rank
                          </th>
                          <th class="py-2 px-4 border-b" v-else>Rank</th>
                          <th class="py-2 px-4 border-b">Rating</th>
                          <th class="py-2 px-4 border-b">Reviews</th>
                          <th class="py-2 px-4 border-b">Categories</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(compData, compName) in filteredCompetitors"
                          :key="compName"
                          class="hover:bg-gray-50 cursor-pointer"
                          :class="{
                            'font-bold text-blue-500': compData.isMyBusiness,
                          }"
                          @click="openCompetitorDialog(compData, compName)"
                        >
                          <td class="py-2 px-4 border-b">
                            {{ compData.title ? compData.title : compName }}
                          </td>
                          <td
                            class="py-2 px-4 border-b"
                            :class="{
                              'font-bold text-blue-500': compData.isMyBusiness,
                            }"
                          >
                            {{
                              selectedGrid === "All Grids"
                                ? compData.avgRank
                                : compData.rank
                            }}
                          </td>
                          <td
                            class="py-2 px-4 border-b"
                            :class="{
                              'font-bold text-blue-500': compData.isMyBusiness,
                            }"
                          >
                            {{ compData.average_rating }}
                          </td>
                          <td
                            class="py-2 px-4 border-b"
                            :class="{
                              'font-bold text-blue-500': compData.isMyBusiness,
                            }"
                          >
                            {{ compData.total_reviews }}
                          </td>
                          <td
                            class="py-2 px-4 border-b"
                            :class="{
                              'font-bold text-blue-500': compData.isMyBusiness,
                            }"
                          >
                            {{ compData.category }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  class="w-full min-h-[500px]"
                  v-if="
                    selectedKeyword &&
                    formattedCompetitors &&
                    Object.keys(formattedCompetitors).length > 0
                  "
                >
                  <gmap-map
                    v-if="selectedKeyword"
                    :center="center"
                    :zoom="13"
                    map-type-id="terrain"
                    style="width: 100%; height: 100%"
                    class="map-container"
                  >

                    <gmap-marker
                      v-for="(marker, index) in markers"
                      :key="index"
                      :position="marker.position"
                      :icon="generateCustomIcon(marker.rank, marker.gridId)"
                      @click="showGridCompetitors(marker.gridId)"
                    />

                    <gmap-marker
                      v-if="markers.length > 4"
                      :position="markers[4].position"
                      :icon="competitorIcon"
                      @click="showGridCompetitors(markers[4].gridId)"
                    />
                  </gmap-map>
                </div>

                <v-dialog v-model="showCompetitorDialog" max-width="800px">
                  <v-card>
                    <v-card-title>
                      {{ selectedCompetitor?.title }} vs
                      {{ myBusinessData.title || myBusiness }}
                    </v-card-title>
                    <v-card-text>
                      <gmap-map
                        :center="mapCenter"
                        :zoom="13"
                        style="width: 100%; height: 400px"
                      >
                        <gmap-marker
                          v-for="(marker, idx) in competitorMarkers"
                          :key="`comp-${idx}`"
                          :position="marker.position"
                          :icon="generateCustomIcon(marker.rank, marker.gridId)"
                        />
                      </gmap-map>

                      <div class="comparison-section mt-4">
                        <h3 class="font-semibold">Comparison</h3>
                        <table
                          class="min-w-full bg-white border border-gray-200"
                        >
                          <thead>
                            <tr class="bg-gray-100 text-left">
                              <th class="py-2 px-4 border-b">Business Name</th>
                              <th class="py-2 px-4 border-b">Avg. Rank</th>
                              <th class="py-2 px-4 border-b">Rating</th>
                              <th class="py-2 px-4 border-b">Reviews</th>
                              <th class="py-2 px-4 border-b">Categories</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="selectedCompetitorData" class=" ">
                              <td class="py-2 px-4 border-b">
                                {{ selectedCompetitorData.title || myBusiness }}
                              </td>
                              <td class="py-2 px-4 border-b">
                                {{ selectedCompetitorData.avg_rank }}
                              </td>
                              <td class="py-2 px-4 border-b">
                                {{ selectedCompetitorData.average_rating }}
                              </td>
                              <td class="py-2 px-4 border-b">
                                {{ selectedCompetitorData.total_reviews }}
                              </td>
                              <td class="py-2 px-4 border-b">
                                {{ selectedCompetitorData.category }}
                              </td>
                            </tr>
                            <tr class="text-sky-500">
                              <td class="py-2 px-4 border-b">
                                {{ myBusinessData.title || myBusiness }}
                              </td>
                              <td class="py-2 px-4 border-b">
                                {{ myBusinessData.keyword_avg_rank }}
                              </td>
                              <td class="py-2 px-4 border-b">
                                {{ myBusinessData.average_rating }}
                              </td>
                              <td class="py-2 px-4 border-b">
                                {{ myBusinessData.total_reviews }}
                              </td>
                              <td class="py-2 px-4 border-b">
                                {{ myBusinessData.category }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="showCompetitorDialog = false"
                        >Close</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogVisible" max-width="700px">
                  <v-card>
                    <v-card-title>
                      Competitors in Grid : {{ dialogCompetitors[0]?.grid_id }}
                    </v-card-title>
                    <v-card-text>
                      <table class="min-w-full bg-white border border-gray-200">
                        <thead>
                          <tr class="bg-gray-100 text-left">
                            <th class="py-2 px-4 border-b">Business Name</th>
                            <th class="py-2 px-4 border-b">Rank</th>
                            <th class="py-2 px-4 border-b">Rating</th>
                            <th class="py-2 px-4 border-b">Total Reviews</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(comp, idx) in dialogCompetitors"
                            :key="idx"
                            class="hover:bg-gray-50"
                          >
                            <td
                              class="py-2 px-4 border-b"
                              :class="{
                                'font-bold text-blue-500': comp.isMyBusiness,
                              }"
                            >
                              {{ comp.title }}
                            </td>
                            <td
                              class="py-2 px-4 border-b"
                              :class="{
                                'font-bold text-blue-500': comp.isMyBusiness,
                              }"
                            >
                              {{ comp.rank }}
                            </td>
                            <td
                              class="py-2 px-4 border-b"
                              :class="{
                                'font-bold text-blue-500': comp.isMyBusiness,
                              }"
                            >
                              {{ comp.average_rating }}
                            </td>
                            <td
                              class="py-2 px-4 border-b"
                              :class="{
                                'font-bold text-blue-500': comp.isMyBusiness,
                              }"
                            >
                              {{ comp.total_reviews }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialogVisible = false"
                        >Close</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
       -->
    </section>
  </div>
</template>

<script>
import {
  collection,
  onSnapshot,
  query,
  where,
  getDoc,
  doc,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions, colUsers, auth, db } from "@/utils/firebase.utils";
import ToolTip from "@/components/ui/ToolTip.vue";
import Chart from "chart.js/auto";
// import { GmapMap, GmapMarker, GmapInfoWindow } from "vue2-google-maps";
import _ from "lodash";
import CircleMarker from "@/components/ui/common/circleMarker.vue"; // Adjust the path as necessary
import moment from "moment-timezone";
import LocationDropdown from "@/components/LocationDropdown.vue";

export default {
  components: { ToolTip, CircleMarker, LocationDropdown },
  props: {},
  data() {
    return {
      selectedGrid: null,
      gridOptions: Array.from({ length: 9 }, (_, i) => i + 1),
      filteredCompetitors: {},
      competitorIcon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
      keywords: [],
      formattedCompetitors: {},
      uniqueCompetitors: {},
      selectedKeyword: "",
      tabularData: null,
      dialogVisible: false,
      timelineDialog: false,
      showCompetitorDialog: false,
      dialogCompetitors: [],
      competitorRanks: null,
      myBusinessMarkers: [],
      competitorMarkers: [],
      timelineData: null,
      timelineDates: [],
      menuVisible: false,
      menuPosition: { x: 0, y: 0 },
      myTimelineBusinessData: {
        avgRank: null,
        rank: null,
        gridRanks: {},
      },
      overallAvgRanks: null,
      selectedCompetitorName: null,
      selectedCompetitor: null,
      mapCenter: null,
      myBusiness: "",
      myBusinessData: {},
      averageRankData: null,
      generatedDate: null,
      shape: {
        coords: [10, 10, 10, 15, 15, 15, 15, 10],
        type: "poly",
      },
      keywordsWithRanks: null,
      keywordsWithVolume: null,
      overallRanks: null,
      selectedStore: null,
      menu: false,
      gridMenu: false,
      search: "",

      dateOptions: [],
      selectedDate: null,
      isLoadingDates: false,

      tooltipVisible: false,
      tooltipData: "",
      tooltipDate: null,
      tooltipPosition: 0,
      center: { lat: 0, lng: 0 },
      markers: [],

      stores: [],
      showStoreDropdown: false,
      selectedWID: null,
      isLoading: false,
      isLoadingCompetitors: false,
      locationSearch: "",
      myPlaceId: null,
    };
  },
  computed: {
    selectedCompetitorData() {
      // Find the competitor object based on the selectedCompetitorName
      let avgRank = 0;
      let rankSum = 0;
      this.competitorMarkers.forEach((comp) => {
        rankSum += comp.rank;
      });
      avgRank = (rankSum / this.competitorMarkers.length).toFixed(1);
      const data =
        this.competitorMarkers.find(
          (comp) => comp.place_id === this.selectedCompetitorName
        ) || null;
      if (
        data &&
        (!data.avg_rank || data.avg_rank == null || data.avg_rank == undefined)
      ) {
        data.avg_rank = avgRank;
      }
      return data;
    },
    competitorRanksForKeyword() {
      if (!this.selectedKeyword || !this.selectedWID) return {};
      return this.competitorRanks[this.selectedKeyword] || {};
    },
    filteredStores() {
      if (!this.locationSearch) {
        return this.stores;
      }

      const searchTerm = this.locationSearch.toLowerCase();
      return this.stores.filter(
        (store) =>
          store.name?.toLowerCase().includes(searchTerm) ||
          store.sub_locality?.toLowerCase().includes(searchTerm) ||
          store.city?.toLowerCase().includes(searchTerm) ||
          store.state?.toLowerCase().includes(searchTerm)
      );
    },
  },
  watch: {
    selectedKeyword: {
      handler() {
        if (
          this.formattedCompetitors &&
          Object.keys(this.formattedCompetitors).length > 0
        ) {
          this.updateTableData();
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.generateDateOptions();
  },
  methods: {
    showMenu(date, event) {
      this.computeMyBusinessRank(date);
      this.tooltipDate = date;
      const keywordData = this.timelineData[date]?.[this.selectedKeyword];

      if (keywordData) {
        const firstEntry = keywordData[1][0];
        this.tooltipData = {
          title: firstEntry.title,
          average_rating: firstEntry.average_rating,
          total_reviews: firstEntry.total_reviews,
        };
      } else {
        this.tooltipData = null;
      }
      const rect = event.target.getBoundingClientRect();
      this.menuPosition = {
        x: rect.left + rect.width / 2,
        y: rect.top - 120,
      };
      this.menuVisible = true;
    },
    handleDateClick() {},
    computeMyBusinessRank(date) {
      const keywordData = this.timelineData[date]?.[this.selectedKeyword];
      if (!keywordData) return;
      let totalRank = 0;

      let gridIds = Object.keys(keywordData); // the actual grid IDs
      let totalGrids = gridIds.length; // e.g. 9
      Object.keys(keywordData).forEach((gridId) => {
        const gridData = keywordData[gridId];
        const myBusiness = gridData.find((comp) => comp.is_my_business);
        // For testing
        // const myBusiness = gridData.find(
        //   (comp) => comp.title == "The Bake Shop"
        // );

        if (myBusiness) {
          this.myTimelineBusinessData.title = myBusiness.title;
          this.myTimelineBusinessData.gridRanks[gridId] = myBusiness.rank;
        } else {
          // Assign last rank if missing
          this.myTimelineBusinessData.gridRanks[gridId] = gridData.length + 1;
        }
        totalRank += this.myTimelineBusinessData.gridRanks[gridId];
      });

      if (this.selectedGrid === "All Grids") {
        this.myTimelineBusinessData.avgRank =
          totalRank > 0 ? (totalRank / totalGrids).toFixed(1) : "N/A";
      } else {
        this.myTimelineBusinessData.rank =
          this.myTimelineBusinessData.gridRanks[this.selectedGrid] || "N/A";
      }
      console.log("this.myTimelineBusinessData:", this.myTimelineBusinessData);
    },
    getDateClass(date) {
      // Return a class based on the date if needed
      return "green"; // Example: return 'green' for all
    },
    formatDate(date) {
      // Format the date as needed
      return new Date(date).toLocaleDateString();
    },
    async handleTimelineClick(date) {},
    selectGrid(grid) {
      this.selectedGrid = grid; // Set the selected grid
      this.gridMenu = false;
      const gridData =
        this.formattedCompetitors[this.selectedKeyword][this.selectedGrid];
      if (gridData) {
        const myBusiness = gridData.find((comp) => comp.isMyBusiness);
        this.myBusinessData.rank = myBusiness ? myBusiness.rank : "20+";
      }
      this.updateTableData();
    },
    generateCustomIcon(rank, gridId, type = null) {
      const color = rank < 5 ? "green" : rank < 10 ? "orange" : "red";
      const borderColor = this.selectedGrid === gridId ? "blue" : "white";
      const showGridText = type !== "dialog";

      const svg = `
        <svg xmlns='http://www.w3.org/2000/svg' width='90' height='90' viewBox='0 0 90 90' >
          <circle cx='20' cy='20' r='18' fill='${color}' stroke='${borderColor}' stroke-width='2'/>
           <text x='20' y='20' font-size='14' font-weight='bold' text-anchor='middle' dominant-baseline='central' fill='white'>${
             rank > 20 ? "20+" : rank
           }</text>
          ${
            showGridText
              ? `
                  <text x='62' y='6' font-size='15' font-weight='bold' text-anchor='middle' dominant-baseline='central' fill='#000000'>Geopoint</text>
                  <text x='40' y='18' font-size='17' font-weight='bold' text-anchor='middle' dominant-baseline='central' fill='#000000'>${gridId}</text>
                `
              : ""
          }
        </svg>
      `;
      return {
        url: "data:image/svg+xml;base64," + btoa(svg),
        scaledSize: { width: 95, height: 95 },
        anchor: { x: 20, y: 20 }, // Center alignment
      };
    },
    async fetchMyBusinessData() {
      console.log("this.currentUID:", this.currentUID);
      const keywordsRef = collection(
        db,
        "users",
        this.currentUID,
        "fly_workspaces",
        this.selectedWID,
        "keywords"
      );
      console.log("this.selectedWID:", this.selectedWID);

      const keywordsSnapshot = await getDocs(
        query(keywordsRef, orderBy("created_at", "desc"), limit(1))
      );

      if (!keywordsSnapshot.empty) {
        const keywordsDoc = keywordsSnapshot.docs[0].data();
        if (
          keywordsDoc.keywords_volume_data &&
          keywordsDoc.keywords_volume_data.keywords
        ) {
          const keywordsData = keywordsDoc.keywords_volume_data?.keywordsData;
          this.keywordsWithVolume = keywordsData?.map((keyword) => {
            return {
              keyword: keyword.keyword,
              searchVolume: keyword.search_volume || 0, // Assuming search_volume is an object with keyword as key
            };
          });
        }
      }
      this.keywordsWithVolume?.sort((a, b) => b.searchVolume - a.searchVolume);

      const gbpRef = doc(
        db,
        "users",
        this.currentUID,
        "fly_workspaces",
        this.selectedWID,
        "connected-apps",
        "google_business_profile"
      );
      const gbpDoc = await getDoc(gbpRef);

      if (gbpDoc.exists()) {
        const gbpData = gbpDoc.data();
        const location = gbpData.accounts[0].locations[0];
        this.myBusiness = location.title;
        this.myPlaceId = location.placeId;
      }
    },
    updateTableData() {
      if (this.selectedGrid === "All Grids") {
        // Show competitors for all grids for the selected keyword
        this.filteredCompetitors = this.competitorRanks[this.selectedKeyword];
      } else if (this.selectedGrid) {
        // Filter competitors based on selected grid
        this.filteredCompetitors =
          this.formattedCompetitors[this.selectedKeyword][this.selectedGrid] ||
          {};
      } else {
        // If no grid is selected, show aggregate data
        this.filteredCompetitors = this.competitorRanks[this.selectedKeyword];
      }
      this.loadMarkersForKeyword(this.selectedKeyword);
    },
    openCompetitorDialog(comp, title) {
      console.log("comp:", comp);
      this.selectedCompetitor = { ...comp }; // Use a spread operator to avoid direct mutation
      this.selectedCompetitor.title = comp.title;
      this.selectedCompetitorName = comp.place_id;
      this.competitorMarkers = [];
      this.myBusinessMarkers = [];

      // Grab the arrays for each grid for the selected keyword
      const gridData = this.formattedCompetitors[this.selectedKeyword];
      if (!gridData) return;

      // We'll search for the competitor and your business in each grid,
      // and create a marker for each grid they appear in

      let rankSum = 0;
      Object.keys(gridData).forEach((gridId) => {
        const compArray = gridData[gridId]; // array of competitor objects for this grid
        // Find the clicked competitor in this grid        const myBusinessRank =
        const businessRank =
          compArray.find((item) => item.place_id === comp.place_id)?.rank || 21; // Get the rank or null if not found
        const competitorObj = compArray.find(
          (x) => x.place_id === comp.place_id
        );
        const firstComp = compArray[0];

        if (competitorObj) {
          const [lat, lng] = competitorObj.geo_location.split(",").map(Number);
          if (isNaN(lat) || isNaN(lng)) {
            console.error("Invalid lat/lng:", lat, lng);
            return; // Skip this marker if invalid
          }
          rankSum += businessRank;
          this.competitorMarkers.push({
            position: { lat, lng },
            gridId,
            rank: businessRank,
            title: comp.title || title,
            place_id: comp.place_id,
            average_rating: comp.average_rating,
            total_reviews: comp.total_reviews,
            category: comp.category,
            avg_rank: comp.avgRank ? comp.avgRank : null,
          });
        } else {
          const [lat, lng] = firstComp.geo_location.split(",").map(Number);
          if (isNaN(lat) || isNaN(lng)) {
            console.error("Invalid lat/lng:", lat, lng);
            return; // Skip this marker if invalid
          }
          this.competitorMarkers.push({
            position: { lat, lng },
            gridId,
            rank: businessRank,
            title: comp.title || title,
            place_id: comp.place_id,
            average_rating: comp.average_rating,
            total_reviews: comp.total_reviews,
            category: comp.category,
            avg_rank: comp.avgRank ? comp.avgRank : null,
          });
        }

        console.log("this.competitorMarkers:", this.competitorMarkers);
      });

      // Center the map on the competitor’s first marker if available,
      // else center on your business’s first marker, else fallback
      if (this.competitorMarkers.length) {
        this.mapCenter = this.competitorMarkers[0].position;
      } else if (this.myBusinessMarkers.length) {
        this.mapCenter = this.myBusinessMarkers[0].position;
      } else {
        this.mapCenter = { lat: 18.5728, lng: 73.7859 }; // fallback
      }

      // Finally show the dialog
      this.showCompetitorDialog = true;
    },
    async fetchData() {
      this.formattedCompetitors = {};

      if (!this.selectedWID) {
        this.$snackbar.show({
          message: "Please select a store before proceeding.",
          color: "error",
        });
        return;
      }
      await this.fetchCompetitorData();
      this.loadMarkersForKeyword(this.selectedKeyword);
      this.selectedGrid = "All Grids";
      this.updateTableData();
    },
    async handleKeywordChange(keyword) {
      this.selectedKeyword = keyword;
      this.menu = false;
      this.myBusinessData.keyword_avg_rank =
        parseFloat(
          this.overallRanks[this.myPlaceId]?.keywordRanks?.[keyword]?.toFixed(1)
        ) || "21";
      this.timelineDates = Object.keys(this.timelineData).map((date) => {
        // console.log("date:::", date);
        const keywordData = this.timelineData[date]?.[keyword];
        if (!keywordData) return { date, avgRank: "N/A" };
        let totalRank = 0;
        let count = 0;

        Object.keys(keywordData).forEach((gridId) => {
          const gridData = keywordData[gridId];
          const myBusiness = gridData.find((comp) => comp.is_my_business);
          // For testing
          // const myBusiness = gridData.find(
          //   (comp) => comp.title == "The Bake Shop"
          // );
          if (myBusiness) {
            totalRank += myBusiness.rank;
          } else {
            totalRank += gridData.length + 1; // Assign last rank if missing
          }
          count++;
        });

        const avgRank = count > 0 ? (totalRank / count).toFixed(1) : "N/A";
        return { date, avgRank };
      });
    },

    async computeCompetitorRanks(competitorData) {
      let competitorRanks = {}; // per keyword
      // let overallRanks = {};    // aggregated across all keywords

      // 1) For each keyword
      Object.keys(competitorData).forEach((keyword) => {
        let keywordData = competitorData[keyword]; // e.g. { "1": [ ... ], "2": [ ... ], ... }
        let gridIds = Object.keys(keywordData); // the actual grid IDs
        let keywordCompetitors = {}; // collects data per competitor

        // 2) Build a map of (compName => { sum: 0, count: 0, gridRanks: {} })
        //    But we won't finalize the sum until we check missing grids
        //    So let's first gather a quick index by grid:
        let gridIndexById = {};

        Object.keys(keywordData).forEach((gridId) => {
          let compsInGrid = keywordData[gridId];
          // (Optional) If needed, sort compsInGrid by rank ascending
          // compsInGrid.sort((a, b) => a.rank - b.rank);

          // Build a dictionary for quick “find competitor by name”
          // so we know who is missing, and what the grid size is
          const gridMap = {};
          compsInGrid.forEach((comp) => {
            // console.log("comp:", comp);
            gridMap[comp.place_id] = comp;
          });
          // store: gridIndexById[gridId] = { map of compName => competitor }, plus size
          gridIndexById[gridId] = {
            compMap: gridMap,
            gridSize: parseInt(compsInGrid.length),
          };
        });

        // 3) We want to gather a set of all competitor names (across all grids),
        //    so we can handle missing ones too.
        let allCompNames = new Set();
        Object.keys(keywordData).forEach((gridId) => {
          let compsInGrid = keywordData[gridId];
          compsInGrid.forEach((comp) => {
            allCompNames.add(comp.place_id);
          });
        });

        // 4) Initialize data for each competitor
        allCompNames.forEach((placeId) => {
          keywordCompetitors[placeId] = {
            gridRanks: {},
            totalRank: 0, // We'll accumulate real or "missing" rank here
          };
        });

        // 5) For each grid, if competitor is found => add actual rank
        //    else => add (gridSize + 1)
        gridIds.forEach((gridId) => {
          const { compMap, gridSize } = gridIndexById[gridId];

          // For each competitor name in allCompNames
          allCompNames.forEach((placeId) => {
            if (compMap && compMap[placeId]) {
              // console.log("compMap[name]:", compMap[name]);
              // competitor is present
              let actualRank = compMap[placeId].rank; // or index-based rank if you prefer
              keywordCompetitors[placeId].gridRanks[gridId] = actualRank;
              keywordCompetitors[placeId].totalRank += actualRank;
              keywordCompetitors[placeId].average_rating =
                compMap[placeId].average_rating;
              keywordCompetitors[placeId].total_reviews =
                compMap[placeId].total_reviews;
              keywordCompetitors[placeId].category = compMap[placeId].category;
              keywordCompetitors[placeId].title = compMap[placeId].title;
              keywordCompetitors[placeId].place_id = compMap[placeId].place_id;
              keywordCompetitors[placeId].isMyBusiness =
                compMap[placeId].isMyBusiness || false;
              keywordCompetitors[placeId].address =
                compMap[placeId].address || "";
            } else {
              keywordCompetitors[placeId].gridRanks[gridId] = gridSize + 1;
              keywordCompetitors[placeId].totalRank += gridSize + 1;
            }
          });
        });

        // 6) Now each competitor has a totalRank across all grids. The # of grids is gridIds.length.
        Object.keys(keywordCompetitors).forEach((placeId) => {
          let data = keywordCompetitors[placeId];
          let gridLen = Object.keys(data.gridRanks).length;
          let avg = data.totalRank / gridLen;
          data.avgRank = avg.toFixed(1);
        });

        // **Sort competitors by avgRank in decreasing order**
        const sortedCompetitors = Object.entries(keywordCompetitors)
          .sort(([, a], [, b]) => a.avgRank - b.avgRank) // Sort in decreasing order
          .reduce((acc, [name, data]) => {
            acc[name] = data;
            return acc;
          }, {});

        competitorRanks[keyword] = sortedCompetitors;
      });

      // 7) Compute overall average ranks across all keywords (if needed)
      let overallRanks = {};
      Object.keys(competitorRanks).forEach((keyword) => {
        let keywordData = competitorRanks[keyword];

        Object.keys(keywordData).forEach((placeId) => {
          if (!overallRanks[placeId]) {
            overallRanks[placeId] = {
              keywordRanks: {},
              totalRank: 0,
              keywordCount: 0,
              isMyBusiness: false,
              // place_id: '',
            };
          }
          let avgRank = parseFloat(keywordData[placeId].avgRank);
          overallRanks[placeId].keywordRanks[keyword] = avgRank;
          overallRanks[placeId].totalRank += avgRank;
          overallRanks[placeId].keywordCount += 1;
          overallRanks[placeId].isMyBusiness =
            keywordData[placeId].isMyBusiness;
          overallRanks[placeId].place_id = keywordData[placeId].place_id;
          overallRanks[placeId].title = keywordData[placeId].title;
        });
      });

      // Finalize overall average
      Object.keys(overallRanks).forEach((placeId) => {
        let data = overallRanks[placeId];
        data.overallAvgRank = data.totalRank / data.keywordCount;
      });

      console.log("competitorRanks:", competitorRanks);
      console.log("overallRanks:", overallRanks);
      this.competitorRanks = competitorRanks;
      this.overallRanks = overallRanks;
      return { competitorRanks, overallRanks };
    },

    getRankClass(rank) {
      return rank > 5 ? "text-red-500" : "text-green-500";
    },
    getChangeClass(change) {
      return change > 0 ? "text-green-500" : "text-red-500";
    },
    showTooltip(date) {
      this.tooltipDate = date;
      // console.log("this.timelineData:", this.timelineData);
      const keywordData = this.timelineData[date]?.[this.selectedKeyword];
      // console.log("keywordData:", keywordData);
      if (keywordData) {
        const firstEntry = keywordData[1][0]; // Assuming you want the first entry
        // console.log("firstEntry:", firstEntry);
        this.tooltipData = {
          title: firstEntry.title,
          average_rating: firstEntry.average_rating,
          total_reviews: firstEntry.total_reviews,
        };
      } else {
        this.tooltipData = null;
      }
      // console.log("this.tooltipData:", this.tooltipData);
      this.tooltipVisible = true;
      const tooltip = event.target.querySelector(".tooltip");
      // if (tooltip) {
      //   tooltip.style.left = '50%';
      //   tooltip.style.bottom = '100%';
      // }
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
    generateDateOptions() {
      this.dateOptions = [
        {
          text: "Last 1 Month",
          value: {
            months: 1,
          },
        },
        {
          text: "Last 3 Months",
          value: {
            months: 3,
          },
        },
        {
          text: "Last 6 Months",
          value: {
            months: 6,
          },
        },
        {
          text: "Last 1 Year",
          value: {
            months: 12,
          },
        },
      ];
      this.selectedDate = this.dateOptions[0].value;
    },
    async selectDateRange() {},

    handleClickOutside(event) {
      const profileDropdown = this.$refs.showStoreDropdown;
      if (profileDropdown && !profileDropdown.contains(event.target)) {
        this.closeProfileDropdown();
      }
    },
    closeProfileDropdown() {
      if (this.showStoreDropdown) {
        this.showStoreDropdown = false;
      }
    },
    toggleStoreDropdown(event) {
      this.showStoreDropdown = !this.showStoreDropdown;
      event.stopPropagation();
    },
    async selectStore(profile) {
      this.selectedStore = profile; // Set the selected store
      this.keywords = [];
      this.formattedCompetitors = {};
      this.selectedWID = profile.wid; // Set the selected WID
      await this.fetchStorePosts(profile); // Fetch posts for the selected store
      this.showStoreDropdown = false; // Close the dropdown
      this.fetchData();
    },
    async fetchStorePosts() {
      if (!this.selectedWID) {
        this.$snackbar.show({
          message: "Please select a store before proceeding.",
          color: "error",
        });
        return;
      } else {
        this.formattedCompetitors = {};
        this.competitorRanks = {};
        this.overallRanks = {};
        this.myBusinessMarkers = [];
        this.filteredCompetitors = null;
        await this.fetchMyBusinessData();
      }
    },
    async fetchKeywords() {
      const uid = this.currentUID;
      const keywordsRef = collection(
        db,
        "users",
        uid,
        "fly_workspaces",
        this.selectedWID,
        "keywords"
      );
      const keywordsSnapshot = await getDocs(
        query(keywordsRef, orderBy("created_at", "desc"), limit(1))
      );
      if (!keywordsSnapshot.empty) {
        const keywordsDoc = keywordsSnapshot.docs[0].data();
        if (
          keywordsDoc.keywords_volume_data &&
          keywordsDoc.keywords_volume_data.keywords
        ) {
          const keywords = keywordsDoc.keywords_volume_data.keywords;
          const keywordsData = keywordsDoc.keywords_volume_data.keywordsData;

          this.keywordsWithRanks = keywords.map((keyword) => {
            console.log(
              "this.overallRanks[this.myBusiness:",
              this.myBusiness,
              this.overallRanks[this.myBusiness]
            );
            const avgRank =
              this.overallRanks[this.myBusiness]?.overallAvgRank || "N/A";
            // Find the search volume for the current keyword
            const keywordData = keywordsData.find(
              (data) => data.keyword === keyword
            );
            const searchVolume = keywordData ? keywordData.search_volume : 0;

            return {
              keyword,
              averageRank: avgRank.toFixed(1),
              searchVolume,
            };
          });
          console.log("this.keywordsWithRanks:", this.keywordsWithRanks);
          // this.keywordsWithRanks.sort(
          //   (a, b) => b.searchVolume - a.searchVolume
          // );
          this.selectedKeyword = this.keywordsWithRanks[0].keyword;
          this.$set(this, "selectedKeyword", keywords[0]);
        }
      }
    },
    async fetchStores() {
      const uid = this.currentUID;
      const storeRef = collection(db, "users", uid, "fly_workspaces");
      const storeQuery = query(
        storeRef,
        where("admin", "==", this.WID),
        where("storeWorkspace", "==", true)
      );
      const querySnapshot = await getDocs(storeQuery);
      querySnapshot.forEach((doc) => {
        let storeData = doc.data();
        if (
          storeData.storeWorkspace &&
          storeData.admin === this.WID &&
          (storeData.connected_apps?.includes("google_business_profile") || storeData.shallowConnect)
        ) {
          storeData.wid = doc.id;
          storeData.type = "store";
          storeData.display_name = `${storeData.sub_locality || ""} - ${
            storeData.name || ""
          }`;
          this.stores.push(storeData);
        }
      });
      if (this.stores.length > 0) {
        this.selectStore(this.stores[0]); // Select the first store
      }
    },
    loadMarkersForKeyword(keyword) {
      // If that keyword doesn't exist yet, bail out
      if (!this.formattedCompetitors[keyword]) {
        this.markers = [];
        return;
      }
      const gridData = this.formattedCompetitors[keyword];
      // We only want one marker per grid => 9 total, so we pick the first competitor
      const newMarkers = [];

      // For each of the 9 grids:
      Object.keys(gridData).forEach((gridId) => {
        const competitorList = gridData[gridId];
        if (!competitorList || competitorList.length === 0) return;

        // Use the FIRST competitor for lat/lng (all have same anyway)
        const firstComp = competitorList[0];
        const [latitude, longitude] = firstComp?.geo_location
          ?.split(",")
          .map(Number);
        const lat = latitude || 0;
        const lng = longitude || 0;
        const myBusinessRank =
          competitorList.find((comp) => {
            // Compare place_id directly
            return comp.place_id === this.myPlaceId;
          })?.rank || 21;
        newMarkers.push({
          gridId,
          position: { lat, lng },
          rank: myBusinessRank,
        });
      });

      this.markers = newMarkers;

      // console.log("this.markers:", this.markers);
      // Center on grid #5 if found
      const middleMarker = this.markers.find((m) => m.gridId === "5");
      if (middleMarker) {
        this.center = this.shiftCenter(middleMarker.position);
      } else if (this.markers.length) {
        this.center = this.shiftCenter(this.markers[0].position);
      }
      const sortedMarkers = [...this.markers].sort(
        (a, b) => parseInt(a.gridId) - parseInt(b.gridId)
      );
      const middleIndex = Math.floor(sortedMarkers.length / 2);
      this.center = this.shiftCenter(sortedMarkers[middleIndex].position);
    },
    shiftCenter(position) {
      // Adjust these values to shift the center
      const latShift = -0.001; // Shift latitude
      const lngShift = -0.01; // Shift longitude

      return {
        lat: position.lat + latShift,
        lng: position.lng + lngShift,
      };
    },
    sortCompetitorsAsscending(competitors) {
      return competitors.sort((a, b) => {
        return (a.rank || 0) - (b.rank || 0); // Sort in descending order
      });
    },
    // Called when user clicks a marker => show its grid’s competitors in dialog
    showGridCompetitors(gridId) {
      if (this.selectedGrid === gridId) {
        this.selectedGrid = "All Grids";
        this.updateTableData();
        return;
      }
      this.selectedGrid = gridId;
      // <-- ADDED or MODIFIED
      if (!this.selectedKeyword) return;
      const keywordData = this.formattedCompetitors[this.selectedKeyword] || {};
      const competitorList = keywordData[gridId] || [];
      if (competitorList) {
        const myBusiness = competitorList.find((comp) => comp.isMyBusiness);
        this.myBusinessData.rank = myBusiness ? myBusiness.rank : "20+";
      }
      this.dialogCompetitors = this.sortCompetitorsAsscending(competitorList);
      this.updateTableData();

      // this.dialogVisible = true;
    },
    async fetchCompetitorData() {
      try {
        if (!this.selectedWID) {
          return;
        }
        this.isLoadingCompetitors = true;
        this.$loader.show();
        const uid = this.currentUID;
        const payload = {
          uid,
          wid: this.selectedWID,
          source: "competitors",
        };
        const competitorsByKeyword = {};
        // await this.fetchKeywords();
        const fetchCompetitorsData = httpsCallable(
          functions,
          "fetchCompetitorAnalysisReport",
          {
            timeout: 540000,
          }
        );
        const result = await fetchCompetitorsData(payload);
        console.log("result:", result.data);
        this.uniqueCompetitors = new Set(
          result.data.data.map((item) => item.place_id)
        );
        let geoLocations = new Map();

        if (result.data) {
          this.timelineData = result.data.dateWiseData;
          // this.timelineDates = Object.keys(result.data.dateWiseData);

          result.data.data?.forEach((item) => {
            const {
              keyword,
              grid_id,
              average_rating,
              categories,
              geo_location,
              location_id,
              place_id,
              rank,
              title,
              total_reviews,
              uid,
              wid,
              date,
              business_name,
              is_my_business,
              address,
            } = item;

            if (!competitorsByKeyword[keyword]) {
              competitorsByKeyword[keyword] = {};
            }
            if (!competitorsByKeyword[keyword][grid_id]) {
              competitorsByKeyword[keyword][grid_id] = [];
            }

            let isMyBusiness = place_id === this.myPlaceId;
            competitorsByKeyword[keyword][grid_id].push({
              business_name,
              title,
              average_rating,
              category: categories,
              grid_id,
              geo_location,
              location_id,
              uid,
              wid,
              rank,
              total_reviews,
              place_id,
              date,
              isMyBusiness: is_my_business || isMyBusiness,
              address,
            });
            competitorsByKeyword[keyword][grid_id].sort(
              (a, b) => a.rank - b.rank
            );

            if (isMyBusiness || is_my_business) {
              this.myBusinessData.average_rating = average_rating || "N/A";
              this.myBusinessData.total_reviews = total_reviews || "N/A";
              this.myBusinessData.category = categories || "N/A";
              this.myBusinessData.title = title || "N/A";
              // console.log("this.myBusinessData:", this.myBusinessData);
            }
          });
        }
        // console.log("Formatted markers:", this.markers);
        this.formattedCompetitors = competitorsByKeyword;
        await this.computeCompetitorRanks(competitorsByKeyword);
        // await this.fetchKeywords();
        // Extract unique keywords for dropdown/filtering
        this.keywords = Object.keys(competitorsByKeyword);
        // console.log('this.myBusiness,:', this.myBusiness, this.overallRanks[this.myBusiness])
        this.keywordsWithRanks = this.keywords.map((keyword) => {
          this.myBusinessData.avg_rank = parseFloat(
            this.overallRanks[this.myPlaceId]?.overallAvgRank?.toFixed(1) ||
              "21"
          );

          const avgRank = parseFloat(
            this.overallRanks[this.myPlaceId]?.keywordRanks?.[keyword]?.toFixed(
              1
            ) || "21"
          );

          // Find the search volume for the current keyword
          const keywordData = this.keywordsWithVolume.find(
            (data) => data.keyword === keyword
          );
          const searchVolume = keywordData ? keywordData.searchVolume : 0;
          // const avgRank = this.overallRanks[this.myBusiness]?.overallAvgRank || "N/A"; // Use "N/A" if no rank found
          return {
            keyword,
            averageRank: parseFloat(avgRank),
            searchVolume,
          };
        });
        console.log("this.keywordsWithRanks:", this.keywordsWithRanks);
        this.selectedKeyword = this.keywordsWithRanks[0].keyword;
        this.myBusinessData.keyword_avg_rank = parseFloat(
          this.overallRanks[
            Object.keys(this.overallRanks).find(
              (placeId) =>
                this.overallRanks[placeId].place_id === this.myPlaceId
            )
          ]?.keywordRanks?.[this.selectedKeyword]?.toFixed(1) || "21"
        );

        console.log("Processed Competitor Data:", this.formattedCompetitors);

        this.timelineDates = Object.keys(result.data.dateWiseData).map(
          (date) => {
            const keywordData = this.timelineData[date]?.[this.selectedKeyword];
            if (!keywordData) return { date, avgRank: "N/A" };

            let totalRank = 0;
            let count = 0;

            Object.keys(keywordData).forEach((gridId) => {
              const gridData = keywordData[gridId];
              const myBusiness = gridData.find((comp) => comp.is_my_business);

              if (myBusiness) {
                totalRank += myBusiness.rank;
              } else {
                totalRank += gridData.length + 1; // Assign last rank if missing
              }
              count++;
            });

            const avgRank = count > 0 ? (totalRank / count).toFixed(1) : "N/A";
            return { date, avgRank };
          }
        );
        // console.log("this.timelineData:", this.timelineData);
        // console.log("this.timelineDates:", this.timelineDates);
      } catch (error) {
        console.error("Error fetching competitor data:", error);
        // this.competitors = []; // Set empty array on error
      } finally {
        this.isLoadingCompetitors = false;
        this.$loader.hide();
      }
    },
  },

  async mounted() {
    await this.fetchStores();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.text-xs {
  font-size: 0.75rem;
}

:deep(.v-text-field.location-input) {
  .v-input__slot {
    min-height: 40px !important;
  }

  .v-text-field__placeholder {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }

  input {
    font-size: 14px;
  }

  fieldset {
    border-color: rgba(0, 0, 0, 0.6) !important;
  }
}

.text-center {
  text-align: center;
}

img {
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.hover\:bg-gray-100:hover {
  background-color: #f7fafc;
}

.leading-tight {
  line-height: 1.25;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.skeleton-loader {
  display: inline-block;
  width: 50px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite;
}

.overflow-x-auto {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}

/* Optional: Custom scrollbar styling */
.overflow-x-auto::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tooltip-container {
  position: relative;
  z-index: 40;
}

.v-tooltip__content {
  z-index: 60 !important;
  position: fixed !important;
}

.v-icon {
  z-index: 40;
  position: relative;
}

.showStoreDropdown {
  background-color: white;
  /* Ensure solid background */
  z-index: 50;
  position: relative;
}

.date-select {
  position: relative;
  z-index: 40;
}

button.tabs {
  border-width: 2px;
  border-style: solid;
  border-color: #8056de;
}

button.tabs.active {
  background-color: #8056de;
  color: white;
}

.metrics-select {
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* This ensures the v-select dropdown menu appears above everything */
:deep(.v-menu__content) {
  z-index: 60 !important;
}

.report-container {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.overview {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
}

.hide-panel {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  float: right;
}

.stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
}

.stat-item {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  flex: 1 1 45%;
  margin: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.up {
  color: green;
  margin-left: 5px;
}

.down {
  color: red;
}

.results {
  margin-top: 30px;
}

.select-keyword {
  /* background-color: #4caf50; */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.keyword-menu {
  z-index: 1000;
  /* Ensure the menu is above other elements */
}

.keyword-name {
  flex: 1;
  /* Allow the keyword name to take available space */
  text-align: left;
  /* Align text to the left */
}

.avg-rank,
.change {
  margin-left: 10px;
  /* Add some space between elements */
  min-width: 50px;
  /* Ensure a minimum width for alignment */
  text-align: right;
  /* Align text to the right */
}

.header-row {
  border-bottom: 1px solid #e0e0e0;
  /* Add a bottom border for separation */
  padding-bottom: 5px;
  /* Add some padding */
}

.keyword-header {
  flex: 1;
  text-align: left;
}

.keyword-name {
  flex: 1;
  text-align: left;
}

.search-volume,
.avg-rank {
  flex: 1;
  text-align: right;
}

.scrollable-list {
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Prevent line breaks */
}

/* Timeline Styles */
.timeline {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;
  /* allow horizontal scrolling */
  margin-top: 20px;
}

/* The inner wrapper expands to fit all timeline-point items. */
.timeline-inner {
  position: relative;
  width: 100%;
  /* inline-block ensures its width = total width of children, 
     so the line can match the full width of the points. */
}

/* Absolutely position the line inside timeline-inner so it stretches 
   behind all points, not just across the visible container. */
.timeline-line {
  position: absolute;
  top: 18px;
  /* adjust as needed */
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #ccc;
  z-index: 1;
  /* sit behind the points */
}

.timeline-points {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: 10px 0;
  width: 100%;
}

.timeline-point {
  flex: 1;
  text-align: center;
  position: relative;
  z-index: 2;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #4caf50;
  /* default */
  margin: 0 auto;
  /* center horizontally */
}

.circle.orange {
  background-color: #ff9800;
}

.date {
  margin-top: 5px;
  white-space: nowrap;
  /* prevent date from wrapping */
}

.tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  z-index: 1000;
  white-space: nowrap;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform: translate(120%, -120%);
  /* Position above the point */
  margin-top: -10px;
}

.map-container {
  width: 100%;
  height: 400px;
  /* Adjust height as per your requirement */
  max-width: 100%;
  overflow: hidden;
  border-radius: 8px;
  /* Optional for rounded corners */
}

.map-wrapper {
  width: 100%;
  overflow: hidden;
}

.grid-menu {
  background-color: white;
  /* Solid white background */
}

.v-menu__content {
  background-color: white !important;
  /* Set solid white background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Optional: add shadow for depth */
}

.selected .circle-parent .circle-marker {
  border: 2px solid blue !important;
  /* Border color for selected grid */
  border-radius: 50%;
  /* Optional: rounded corners */
  padding: 5px;
  /* Optional: padding for better visibility */
}
.marker-circle {
  width: 40px;
  height: 40px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid transparent;
  position: relative;
}

.marker-circle.selected {
  border-color: blue;
}
</style>