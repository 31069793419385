import { render, staticRenderFns } from "./AcceptInvite.vue?vue&type=template&id=6c2d6abf&scoped=true"
import script from "./AcceptInvite.vue?vue&type=script&lang=js"
export * from "./AcceptInvite.vue?vue&type=script&lang=js"
import style0 from "./AcceptInvite.vue?vue&type=style&index=0&id=6c2d6abf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c2d6abf",
  null
  
)

export default component.exports