<template>
    <div class="flex flex-col items-center justify-center w-full pb-20 ">
        <section class="flex flex-col items-center w-full xl:w-[1285px]">
            <!-- <div class="flex items-center justify-between md:mt-5 mb-2 px-2 md:px-0 w-full">
                <div class="flex flex-row max-w-[20%] items-center gap-3 px-4 mt-5">
                    <button
                        class="flex justify-center w-10 h-10 items-center rounded-lg bg-white hover:bg-gray-300 transition-all"
                        @click="handleGoBack">
                        <svg class="w-5 h-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <p class="text-lg md:text-xl font-bold">Change History</p>
                </div>
            </div> -->

            <div class="container mx-auto w-full ">
                <div v-for="entry in updateChanges" :key="entry.id" class="bg-[#F2F6FA] rounded-lg shadow-sm p-6 mb-6">
                    <div class="flex justify-between items-center">
                        <div class="flex-1">
                            <div class="flex gap-2">
                                <h3 class="font-semibold text-lg">{{ entry.title }}</h3>
                                <span class=" text-xs px-2 py-1 my-auto rounded-full"
                                    :class="entry.type === 'single' ? 'bg-blue-100 text-blue-800' : 'bg-[#f3e8ff] text-[#5b21b6]'">
                                    {{ entry.type === 'single' ? 'Single Location' : 'Bulk Update' }}
                                </span>
                            </div>
                            <p class="text-gray-600 ">{{ entry.address }}</p>
                            <p class="text-sm text-gray-500">
                                Requested by: {{ entry.requested_by || "Unknown" }} •
                                {{ formatFirestoreDate(entry.requested_at) }}
                            </p>
                            <div class="flex gap-3 mt-1 text-sm">
                                <span class="text-green-600 flex items-center gap-1">
                                    <v-icon small color="green">mdi-check-circle</v-icon>
                                    {{ getSuccessCount(entry.updateResults) }} successful
                                </span>
                                <span class="text-red-600 flex items-center gap-1">
                                    <v-icon small color="red">mdi-alert-circle</v-icon>
                                    {{ getFailedCount(entry.updateResults) }} failed
                                </span>
                                <span class="text-blue-600 flex items-center gap-1 cursor-pointer" @click="showUpdateResultsDialog(entry.updateResults)">
                                    <v-icon small color="blue">mdi-eye</v-icon>
                                    View Details
                                </span>
                            </div>
                        </div>
                        <button class="px-4 py-2 rounded-md top-0 text-sm font-semibold gap-1" @click="
                            entry.showChanges = !entry.showChanges;
                        showAllSubChanges = false
                            ">
                            <span>{{ entry.showChanges ? "Hide" : "Show" }} Details</span>
                            <v-icon>{{ entry.showChanges ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                        </button>
                        <!-- <button class="px-4 border border-red-600 py-2 rounded-md top-0 text-sm font-semibold gap-1"
                            @click="reApplyChanges(entry)">
                            <span>Re-apply</span>
                            <v-icon>{{ entry.showChanges ? "mdi-refresh" : "mdi-refresh" }}</v-icon>
                        </button> -->
                    </div>

                    <transition name="fade">
                        <div v-if="entry.showChanges" class="mt-4 space-y-4">
                            <div v-for="(change, index) in entry.changes" :key="index"
                                class="border p-4 rounded-lg bg-gray-50">
                                <h4 class="font-medium text-gray-700 mb-2">{{ change.field }}</h4>
                                <template>
                                    <!-- For multiple-type items, sub-changes may appear in `change.new` if it's an array.
                         For single-type items, sub-changes appear in `change.changes`. -->
                                    <template v-if="entry.type === 'multiple' && Array.isArray(change.new)">
                                        <!-- We'll treat `change.new` as the subChanges array -->
                                        <div v-for="
(subChange, subIndex) in showAllSubChanges
    ? change.new
    : change.new.slice(0, 5)
                        " :key="subIndex" class="ml-4 mt-2 p-2 border-l-4 border-blue-200">
                                            <p class="text-sm font-medium">{{ subChange.displayName }}</p>
                                            <!-- If `oldValue` is present here, show it. Otherwise just show newValue. -->
                                            <p class="text-sm text-gray-600">
                                                <span class="font-semibold">Old:</span>
                                                {{ subChange.oldValue || "None" }}
                                            </p>
                                            <p class="text-sm text-green-600">
                                                <span class="font-semibold">New:</span>
                                                {{ subChange.newValue || "None" }}
                                            </p>
                                        </div>
                                        <div v-if="!showAllSubChanges && change.new.length > 5" class="ml-4 mt-2">
                                            <p class="text-sm text-gray-500">
                                                {{ change.new.length - 5 }} items more
                                            </p>
                                            <button @click="showAllSubChanges = true" class="text-blue-500">
                                                Show all
                                            </button>
                                        </div>
                                    </template>

                                    <template v-else-if="Array.isArray(change.changes)">
                                        <!-- This handles single-type subChanges (or wherever `change.changes` is used) -->
                                        <div v-for="
(subChange, subIndex) in showAllSubChanges
    ? change.changes
    : change.changes.slice(0, 5)
                        " :key="subIndex" class="ml-4 mt-2 p-2 border-l-4 border-blue-200">
                                            <p class="text-sm font-medium">{{ subChange.displayName }}</p>
                                            <p class="text-sm text-gray-600">
                                                <span class="font-semibold">Old:</span>
                                                {{ subChange.oldValue || "None" }}
                                            </p>
                                            <p class="text-sm text-green-600">
                                                <span class="font-semibold">New:</span>
                                                {{ subChange.newValue || "None" }}
                                            </p>
                                        </div>
                                        <div v-if="!showAllSubChanges && change.changes.length > 5" class="ml-4 mt-2">
                                            <p class="text-sm text-gray-500">
                                                {{ change.changes.length - 5 }} items more
                                            </p>
                                            <button @click="showAllSubChanges = true" class="text-blue-500">
                                                Show all
                                            </button>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <!-- SINGLE TYPE: old/new display -->
                                        <div v-if="entry.type === 'single'" class="grid grid-cols-2 gap-4">
                                            <div>
                                                <p class="text-xs text-gray-500 font-medium">Old Value:</p>
                                                <p class="text-sm text-gray-600 break-words">
                                                    {{ formatChangeValue(change.field, change.old) }}
                                                </p>
                                            </div>
                                            <div>
                                                <p class="text-xs text-gray-500 font-medium">New Value:</p>
                                                <p class="text-sm text-green-600 break-words">
                                                    {{ formatChangeValue(change.field, change.new) }}
                                                </p>
                                            </div>
                                        </div>

                                        <!-- MULTIPLE TYPE: new value only -->
                                        <div v-else-if="entry.type === 'multiple'">
                                            <p class="text-xs text-gray-500 font-medium">Change:</p>
                                            <p class="text-sm text-green-600 break-words">
                                                {{ formatChangeValue(change.field, change.new) }}
                                            </p>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </transition>
                </div>
                <InfiniteLoading ref="loader" @infinite="fetchChangeHistory">
                    <div slot="spinner">
                        <v-skeleton-loader class="mx-auto max-w-[1285px]" type="article"></v-skeleton-loader>
                    </div>
                    <div slot="no-results"></div>
                </InfiniteLoading>
            </div>
        </section>

        <!-- Update Results Dialog -->
        <v-dialog v-model="showUpdateResults" max-width="600px" scrollable>
            <v-card>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span>Update Results</span>
                    <v-btn icon @click="showUpdateResults = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                
                <v-card-text class="pt-4">
                    <!-- Summary Stats -->
                    <div class="mb-4 flex gap-4">
                        <v-chip outlined color="success">
                            Success: {{ getSuccessCount(currentUpdateResults) }}
                        </v-chip>
                        <v-chip outlined color="error">
                            Failed: {{ getFailedCount(currentUpdateResults) }}
                        </v-chip>
                    </div>

                    <!-- Results Table -->
                    <v-data-table
                        :headers="[
                            { text: 'Location', value: 'title' },
                            { text: 'Status', value: 'status' }
                        ]"
                        :items="currentUpdateResults || []"
                        :items-per-page="10"
                        :footer-props="{
                            'items-per-page-options': [10, 25, 50, 100],
                        }"
                        dense
                    >
                        <template v-slot:item.status="{ item }">
                            <v-chip
                                small
                                :color="item.status === 'success' ? 'success' : 'error'"
                                text-color="white"
                            >
                                {{ item.status }}
                            </v-chip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { db } from "@/utils/firebase.utils";
import { collection, getDocs, limit, orderBy, query, startAfter } from "firebase/firestore";
import InfiniteLoading from "vue-infinite-loading";

export default {
    components: {
        InfiniteLoading
    },
    data() {
        return {
            batchSize: 10,
            lastDoc: null,
            updateChanges: [],
            showAllSubChanges: false,
            showUpdateResults: false,
            currentUpdateResults: null,
        };
    },
    methods: {
        handleGoBack() {
            this.$emit("goBack");
        },
        reApplyChanges(change) {
            console.log(change, "this is the change");
        },
        formatFirestoreDate(timestamp) {
            if (!timestamp) return "N/A";
            const date = new Date(
                timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
            );
            const optionsDate = { day: "2-digit", month: "long", year: "numeric" };
            const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: true };
            const formattedDate = date.toLocaleDateString(undefined, optionsDate);
            const formattedTime = date.toLocaleTimeString(undefined, optionsTime);
            return `${formattedDate}, ${formattedTime}`;
        },

        formatChangeValue(field, value) {
            if (!value) return "None";

            // Handle special formatting for complex fields
            switch (field) {
                case "Business Hours":
                    return this.formatBusinessHours(value);
                case "Additional Categories":
                case "Primary Category":
                    return this.formatCategories(value);
                default:
                    return typeof value === "object" ? JSON.stringify(value, null, 2) : value;
            }
        },

        formatBusinessHours(hours) {
            if (!hours?.periods) return "No hours set";
            return hours.periods
                .map(
                    (p) =>
                        `${p.openDay}: ${this.formatTime(p.openTime)} - ${this.formatTime(
                            p.closeTime
                        )}`
                )
                .join("\n");
        },

        formatTime(time) {
            return new Date(0, 0, 0, time.hours, time.minutes).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit"
            });
        },

        formatCategories(cats) {
            if (Array.isArray(cats)) return cats.join(", ");
            if (typeof cats === "string") return cats;
            return "No categories";
        },

        async fetchChangeHistory($state) {
            try {
                // Implement your Firestore query here
                // Example or mock implementation:
                const queries = [orderBy("requested_at", "desc"), limit(this.batchSize)];
                // Adjust these references to your actual Firestore paths
                const collectionRef = collection(
                    db,
                    "users",
                    this.currentUID,
                    "fly_workspaces",
                    this.WID,
                    "gmb-update-history"
                );

                if (this.lastDoc) {
                    queries.push(startAfter(this.lastDoc));
                }

                const gmbHistoryQuery = query(collectionRef, ...queries);
                const gmbHistoryDocs = await getDocs(gmbHistoryQuery);

                const changes = gmbHistoryDocs.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    showChanges: false // Initialize the toggle state
                }));

                this.updateChanges.push(...changes);
                this.lastDoc =
                    gmbHistoryDocs.docs[gmbHistoryDocs.docs.length - 1] || null;

                // This is just to demonstrate finishing or continuing infinite loading
                if (changes.length < this.batchSize) {
                    $state?.complete();
                } else {
                    $state?.loaded();
                }
            } catch (error) {
                console.error("Error fetching change history:", error);
                $state?.error();
            }
        },

        getSuccessCount(results) {
            if (!results) return 0;
            return results.filter(result => result.status === 'success').length;
        },
        
        getFailedCount(results) {
            if (!results) return 0;
            return results.filter(result => result.status === 'failed').length;
        },

        showUpdateResultsDialog(results) {
            this.currentUpdateResults = results;
            this.showUpdateResults = true;
        },
    }
};
</script>

<style scoped>
.whitespace-pre-line {
    white-space: pre-line;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s, transform 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}
</style>