<template>
  <v-container class="text-center">
    <v-card class="mx-auto" max-width="400" v-if="!tokenExpired">
      <v-card-title class="headline">Accept Invitation</v-card-title>
      <v-card-text>
        <p>You have been to connect your social handles.</p>
        <!-- <div v-for="item in workspaces" class="flex flex-start"> -->
        <p class="font-semibold">
          Admin: {{ user.email || user.display_name || user.phone_number }}
        </p>
        <!-- </div> -->
        <v-btn color="primary mt-3" @click="acceptInvitation" :loading="loading"
          >Accept Invitation</v-btn
        >
      </v-card-text>
    </v-card>
    <div
      v-else
      class="flex mt-6 pt-6 md:mt-10 md:pt-10 mx-auto px-6 text-lg items-center justify-center"
    >
      <v-card>
        <v-card-title>Token expired or it does not exist</v-card-title>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { functions, db, auth, colUsers } from "@/utils/firebase.utils";

export default {
  data() {
    return {
      loading: false,
      workspaces: [],
      user: {},
      unsubscribe:null,
      tokenExpired: false,
    };
  },
  async mounted() {
    let sender_id = this.$route.query.uid;
    let wid = this.$route.query.wid;
    const teamMemberDocRef = doc(db, "users", sender_id, "fly_workspaces", wid);
    const userDocRef = doc(db, "users", sender_id);
    const teamMemberDoc = await getDoc(teamMemberDocRef);
    const userDoc = await getDoc(userDocRef);
    this.user = userDoc.data();
    if (!teamMemberDoc.exists()) {
      this.$alert.show("Invalid invitation");
      return;
    }

    this.$store.commit("SET_CURRENT_STORE_UID", this.$route.query.uid);
    this.$store.commit("SET_CURRENT_STORE_WID", this.$route.query.wid);
    localStorage.setItem("CURRENT_WORKSPACE_ID", `${this.$route.query.wid}__${this.$route.query.uid}`);
  
    this.token = this.$route.query.token;
    setTimeout(async () => {
      this.validateToken();
    }, 1000);
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
  },
  methods: {
    isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    async validateToken() {
      try {
        let uid = this.currentUID;
        let wid = this.WID;
        if(this.$route.name=='store-invitation-accepted'){
          wid = this.$route.query.wid;
          uid = this.$route.query.uid;
        }
        // console.log(
        //   "currentStoreUID, uid, this.currentUID:",
        //   this.currentStoreUID,
        //   uid,
        //   this.currentUID
        // );
        // console.log("storeUserWID:", this.storeUserWID);
        const workspaceDocRef = doc(
          db,
          "users",
          uid,
          "fly_workspaces",
          wid
        );
        this.unsubscribe = onSnapshot(
          workspaceDocRef,
          (doc) => {
            if (doc.exists()) {
              const data = doc.data();
              const expiresAt = data.token_expires_at.toDate();
              const now = new Date();
              if (expiresAt > now) {
                this.tokenExpired = false;
                console.log("token is valid");
              } else {
                this.tokenExpired = true;
                console.log("token is expired");
              }
            } else {
              console.error("No such document!");
              this.tokenExpired = true;
            }
          },
          (error) => {
            console.error("Error getting document:", error);
          }
        );
      } catch (error) {
        console.error("Error validating token:", error);
      }
    },
    async acceptInvitation() {
      this.loading = true;
      // console.log("his.$route.query:", this.$route.query);
      let sender_id = this.$route.query.uid;
      let wid = this.$route.query.wid;
      let token = this.$route.query.token;
      let workspace_name = this.$route.query.workspace_name;
      let admin = this.$route.query.sender;
      try {
        const workspaceRef = doc(db, "users", sender_id, "fly_workspaces", wid);
        const workspace = await getDoc(workspaceRef);
        if (workspace.exists() && workspace.data().status === "invited") {
          await updateDoc(workspaceRef, {
            status: "accepted",
          });
        }

        this.$router.push(
          "/store-invitation-accepted?uid=" +
            sender_id +
            "&wid=" +
            wid +
            "&token=" +
            token +
            "&workspace_name=" +
            workspace_name +
            "&sender=" +
            admin
        );
      } catch (error) {
        console.error("Error accepting invitation:", error);
        this.$alert.show("Failed to accept the invitation. Please try again.");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
