<template>
  <div
    v-if="!tokenExpired"
    class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]"
  >
    <section
      class="flex flex-col items-center w-full justify-center xl:w-[1285px]"
    >
      <div
        class="flex items-center justify-between md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]"
      >
        <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
          <img
            src="@/assets/img/social.svg"
            class="cursor-pointer w-6 md:w-[28px]"
            alt="post"
          />
          <div class="flex items-center gap-1">
            <p class="text-base md:text-[22px] font-bold">
              Your Social Channels
            </p>
          </div>
        </div>

        <div class="px-4 mt-5" v-if="$route.query.self">
          <v-btn
            class="back-button flex items-center gap-2"
            text
            @click="goBack"
            color="#8050DE"
          >
            <v-icon small>mdi-arrow-left</v-icon>
            <span>Back to Locations</span>
          </v-btn>
        </div>
      </div>
      <div
        class="flex items-center justify-start mb-5 ml-[48px] md:mb-0 px-2 md:px-0 w-full xl:w-[1285px]"
      >
        <div class="items-center gap-2 px-4 d-flex">
          <div class="flex items-center gap-1">
            <p class="text-xs md:text-[18px] text-gray-600">
              Connect social handles for
              <span v-html="workspaceName"></span>
            </p>
          </div>
        </div>
      </div>
      <div
        class="w-full pb-[33px] mt-5 bg-white md:px-4 xl:px-10 md:rounded-3xl"
      >
        <div class="flex flex-col">
          <div class="flex flex-col mt-6">
            <div class="bg-white rounded-3xl">
              <please-wait v-if="loading" class="my-5"></please-wait>
              <template v-else>
                <div class="px-4 py-6 mt-4 bg-slate-100 rounded-xl">
                  <div class="font-medium text-white">
                    <div class="flex justify-between">
                      <!-- <div class="text-lg text-black">Connected</div> -->
                    </div>
                    <div class="">
                      <connected-app-list-item
                        v-for="(item, i) in socialAppLists"
                        :key="item.alias"
                        :item="item"
                        :profile="profile"
                        :isLast="socialAppLists.length - 1 === i"
                        :allConnectedProfiles="allConnectedProfiles"
                        @onDisconnect="fetchConnectedApps"
                      />
                    </div>
                  </div>
                </div>
              </template>

              <add-social-profile-dialog
                ref="dialog"
                @onNewProfile="fetchSocialProfiles"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div
    v-else
    class="flex mt-6 pt-6 md:mt-10 md:pt-10 mx-auto px-6 text-lg items-center justify-center"
  >
    <v-card>
      <v-card-title>Token expired or it does not exist</v-card-title>
    </v-card>
  </div>
</template>

<script>
import connectedAppsMixins from "@/mixins/connected.apps.mixins";
import { auth, colUsers, platforms } from "@/utils/firebase.utils.js";
import { db } from "@/utils/firebase.utils";
import { collection, doc, onSnapshot, query, getDocs, getDoc } from "firebase/firestore";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import ConnectedAppListItem from "@/components/connectedApps/ConnectedAppListItem.vue";
import AddSocialProfileDialog from "@/components/connectedApps/AddSocialProfileDialog.vue";

export default {
  name: 'InvitationAccepted',
  
  components: {
    PleaseWait,
    ConnectedAppListItem,
    AddSocialProfileDialog,
  },

  mixins: [connectedAppsMixins],

  data() {
    return {
      tempWorkspace: null,
      tempWID: null,
      tempUID: null,
      tempUser: null,
      loading: true,
      unsubscribeConnection: null,
      connections: {},
      profiles: [],
      allConnectedProfiles: [],
      profile: "personal",
      socialAppLists: [],
      tokenExpired: false,
    };
  },

  computed: {
    workspaceName() {
      const name = this.$route.query?.workspace_name || "";
      return name.includes('_') ? name.replace(/_/g, " ") : name;
    },
  },

  async mounted() {
    await this.fetchConnectedApps();
  },
  beforeDestroy(){
    this.unsubscribeConnection();
  },
  methods: {
    goBack() {
      this.$router.push('/locations');
    },
    async fetchConnectedApps() {
      let uid = this.currentUID;
      let wid = this.WID;
      if(this.$route.name=='store-invitation-accepted'){
        wid = this.$route.query.wid;
        uid = this.$route.query.uid;
      }
      if (!uid || !wid) return;

      try {
        this.loading = true;
        const allPlatforms = await this.getAllPlatforms();
        let userCollections = {};

        const q = query(
          collection(doc(colUsers, uid, "fly_workspaces", wid), "connected-apps")
        );
        this.unsubscribeConnection = onSnapshot(q, (snap) => {
          this.allConnectedProfiles = [];
          userCollections = {};
          snap.docs.forEach(this.processConnectedApp(userCollections));
          this.processSocialAppsList(allPlatforms, userCollections);
        });
      } catch (error) {
        console.error("Error fetching connected apps:", error);
        this.$alert.show(error.message);
      } finally {
        this.loading = false;
      }
    },

    processConnectedApp(userCollections) {
      return (item) => {
        const data = item.data();
        this.connections[item.id] = { ...data, id: item.id };
        userCollections[item.id] = { ...data, id: item.id };
        
        let childrenArray = this.getChildrenArray(data, item.id);
        this.allConnectedProfiles.push({
          name: item.id,
          children: childrenArray,
        });
      };
    },

    getChildrenArray(data, id) {
      let children = data.pages || data.profiles || data.boards || 
                    data["sub-reddits"] || data.blogs || data.users || 
                    (Array.isArray(data?.accounts) && data.accounts[0]?.locations) || [];

      if (id === "linkedin") {
        children = children.map(d => ({ ...d, user_id: data.user_id }));
      }
      return children;
    },

    processSocialAppsList(allPlatforms, userCollections) {
      this.socialAppLists = allPlatforms
        .map(item => {
          const connectionId = item.type === "google_business_profile" ? item.type : item.alias;
          return { ...item, connection: userCollections[connectionId] };
        })
        .sort((a, b) => (b.connection ? 1 : 0) - (a.connection ? 1 : 0));
    },

    async fetchSocialProfiles() {
      let uid = this.currentUID;
      if(this.$route.name=='store-invitation-accepted'){
        uid = this.$route.query.uid;
      }
      let profileQuery = query(
        collection(doc(colUsers, uid), "social-profiles")
      );
      this.profiles = (await getDocs(profileQuery)).docs.map((i) => {
        return { ...i.data(), id: i.id };
      });
      let personalProfile = this.profiles.find((i) => i.id == "personal");
      if (personalProfile == null) {
        this.profiles.unshift({ name: "Personal", id: "personal" });
      }
      return this.profiles;
    },

    async getAllPlatforms() {
      const platformsRef = await getDocs(platforms);
      let allPlatforms = [];
      platformsRef.forEach((doc) => {
        allPlatforms.push(doc.data());
      });
      allPlatforms.sort((a, b) => a.display_priority - b.display_priority);
      return allPlatforms;
    },
  },
};
</script>



<style></style>
