var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.$route.name !== 'lite' &&
    _vm.$route.name !== 'accept-invite' &&
    _vm.$route.name !== 'store-invitation-accepted'
  )?_c('div',{class:_vm.$route === '/connected-apps'
      ? 'relative z-[99999999]'
      : 'relative z-[99999999]'},[(_vm.isAuth)?_c('v-navigation-drawer',{staticClass:"z-50 white",attrs:{"app":"","width":"270"},on:{"input":function($event){return _vm.$emit('input', _vm.drawer)}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"justify-start pt-2 text-center pa-1 d-flex",staticStyle:{"height":"63px"}},[_c('img',{staticStyle:{"object-fit":"contain"},attrs:{"src":require("../assets/img/Ic_fly.png"),"width":"100","height":"50"}}),_c('div',{staticClass:"-ml-5"},[(_vm.currentWorkspaceLogo)?_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-close")]):_vm._e(),(_vm.currentWorkspaceLogo)?_c('v-list-item-avatar',{staticClass:"mr-2"},[_c('img',{staticClass:"h-6 w-6 rounded-full",attrs:{"src":_vm.currentWorkspaceLogo,"alt":"Workspace Logo"}})]):_vm._e()],1)]),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Time to renew!"),_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-bell-ring-outline")]),(_vm.showDialog)?_c('v-btn',{staticClass:"close-dialog-btn",attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-card-text',{staticClass:"font-semibold"},[_vm._v(" You have run out of credits. Please renew your subscription to keep posting! ")]),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.closeDialog}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.renewSubscription}},[_vm._v("Renew"),_c('v-icon',[_vm._v("mdi-creation")])],1)],1)],1)],1),_c('v-divider'),_c('workspace-menu'),_c('v-list',{staticClass:"pa-3 ml-3 my-2",attrs:{"dense":"","nav":""}},[_vm._l((_vm.menus),function(item,i){return [_c('v-list-item',{key:i,class:_vm.active(item),on:{"click":function($event){item.name === 'analytics' || item.name === 'gmb' || item.name === 'social'
              ? _vm.toggleDropdown(item.name)
              : _vm.open(item)}}},[_c('v-list-item-avatar',{attrs:{"size":"22","tile":""}},[(_vm.active(item))?_c('img',{attrs:{"src":item.icon,"alt":"icons"}}):_c('img',{attrs:{"src":item.icon2,"alt":"icons"}})]),_c('v-list-item-content',[_c('v-list-item-title',{class:{
                'text-[#7a58d5] text-md text-[15px]': _vm.active(item),
                'text-whites text-md font-semibold': !_vm.active(item),
              }},[_vm._v(" "+_vm._s(item.title)+" ")])],1),(item.name === 'analytics' || item.name === 'gmb' || item.name === 'social')?_c('v-icon',{staticClass:"ml-auto transition-transform duration-200",class:{
              'rotate-180': (item.name === 'analytics' && _vm.showAnalyticsDropdown) || 
                            (item.name === 'gmb' && _vm.showGMBDropdown) || 
                            (item.name === 'social' && _vm.showSocialDropdown)
            },attrs:{"color":"black"}},[_vm._v(" mdi-chevron-down ")]):_vm._e()],1),((item.name === 'analytics' && _vm.showAnalyticsDropdown) || (item.name === 'gmb' && _vm.showGMBDropdown) || (item.name==='social' && _vm.showSocialDropdown))?_vm._l((item.subMenus),function(subItem,j){return _c('v-list-item',{key:subItem.name,staticClass:"ml-6",class:_vm.active(subItem),on:{"click":function($event){return _vm.open(subItem)}}},[_c('v-list-item-avatar',{attrs:{"size":"22","tile":""}},[(item.name === 'analytics')?[(_vm.active(subItem))?_c('img',{attrs:{"src":subItem.icon,"alt":"icons"}}):_c('img',{attrs:{"src":subItem.icon2,"alt":"icons"}})]:_vm._e(),(item.name === 'gmb')?[_c('img',{attrs:{"src":_vm.active(subItem) 
                    ? require('@/assets/img/sidebar/icons8-google-48.png') 
                    : require('@/assets/img/sidebar/icons8-google-48-unselect.png'),"alt":"icons"}})]:_vm._e(),(item.name === 'social')?[(_vm.active(subItem))?_c('img',{attrs:{"src":subItem.icon,"alt":"icons"}}):_c('img',{attrs:{"src":subItem.icon2,"alt":"icons"}})]:_vm._e()],2),_c('v-list-item-content',[_c('v-list-item-title',{class:{
                  'text-[#7a58d5] text-md': _vm.active(subItem),
                  'text-whites text-md': !_vm.active(subItem),
                }},[_vm._v(" "+_vm._s(subItem.title)+" ")])],1)],1)}):_vm._e()]})],2)],1):_vm._e(),(!_vm.drawer && _vm.$route.path !== '/resources')?_c('v-navigation-drawer',{staticClass:"z-50 hidden white md:block",attrs:{"app":"","width":"60"},on:{"input":function($event){return _vm.$emit('input', _vm.drawer)}}},[_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[(_vm.showDialog)?_c('v-btn',{staticClass:"close-dialog-btn",attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-list',{staticClass:"flex flex-col items-center",attrs:{"nav":""}},[_vm._l((_vm.menus),function(item,i){return [_c('v-list-item',{key:i,staticClass:"pl-4",class:_vm.active(item),on:{"click":function($event){return _vm.open(item)}}},[_c('v-list-item-avatar',{attrs:{"size":"22","tile":""}},[(_vm.active(item))?_c('img',{attrs:{"src":item.icon,"alt":"icons"}}):_c('img',{attrs:{"src":item.icon2,"alt":"icons"}})])],1)]})],2)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }