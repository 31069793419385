<template>
  <div
    class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]"
  >
    <section class="flex flex-col items-center w-full justify-center xl:w-[1285px]">
      <div class="flex items-center justify-start md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]">
        <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
          <img src="@/assets/img/sidebar/icons8-badge-32.png"
            class="cursor-pointer w-6 md:w-[28px]"
            alt="Offer"
          />
          <div class="flex items-center gap-1">
            <p class="text-base md:text-[22px] font-bold">Offers</p>
          </div>
        </div>
        <div class="flex items-center justify-end gap-1 mt-4">
          <v-btn color="primary" @click="openGlobalOfferDialog">Create Global Offer</v-btn>
        </div>
      </div>
      <div class="w-full mx-auto mt-3">
        <div class="bg-white rounded-lg shadow-sm p-4 md:p-6 mb-6 min-h-[80vh]">
          <div v-if="locations.length === 0" class="text-center py-6">
            <p class="text-lg font-medium text-gray-700">
              Please first connect your Google business locations with Fly to access this feature.
            </p>
            <div class="flex flex-col items-center mt-4">
              <v-icon size="48" color="gray">mdi-alert-circle-outline</v-icon>
              <p class="mt-2 text-gray-600">No Location Available</p>
            </div>
          </div>
          <div v-else>
            <!-- Note -->
            <div class="mb-6 flex justify-center mx-auto w-[94%]">
              <p
                class="flex items-center  text-gray-800 bg-gradient-to-r from-blue-50 to-indigo-50 p-4 rounded-md border-l-4 border-blue-500 shadow-sm">
                <v-icon color="blue" class="mr-3">mdi-information</v-icon>
                <span class="font-medium">
                  Set an offer for any of your business locations, and we'll highlight it in AI-generated GMB review replies while it's valid.
                </span>
              </p>
            </div>

            <!-- Search Box and Global Offer Button -->
            <div class="w-full mx-auto mt-4 flex justify-start px-4">
              <input 
                type="text" 
                v-model="searchQuery" 
                placeholder="Search Location" 
                class="search-input"
              />
              <!-- Offer Status Filters -->
              <div class="status-filters flex ml-4 gap-4">
                <button class="status-filter-btn" :class="{ active: selectedFilter === 'all' }" @click="selectedFilter = 'all'">
                  All ({{ locations.length }})
                </button>
                <button class="status-filter-btn status-withoutOffer" :class="{ active: selectedFilter === 'withoutOffer' }" @click="selectedFilter = 'withoutOffer'">
                  Without Offer ({{ withoutOfferCount }})
                </button>
                <button class="status-filter-btn status-withOffer" :class="{ active: selectedFilter === 'withOffer' }" @click="selectedFilter = 'withOffer'">
                  Valid Offer ({{ withOfferCount }})
                </button>
                <button class="status-filter-btn status-expired" :class="{ active: selectedFilter === 'expired' }" @click="selectedFilter = 'expired'">
                  Expired Offer ({{ expiredCount }})
                </button>
              </div>
            </div>
            <!-- Table -->
            <div class="w-full pb-[33px] mt-5 bg-white md:px-4 xl:px-10 md:rounded-3xl shadow-sm">
              <div v-if="paginatedLocations.length === 0" class="text-center text-gray-500 py-6">
                No locations found matching your search criteria.
              </div>
              <div v-else class="overflow-x-auto">
                <table class="table-auto w-full border-collapse border border-gray-300">
                  <thead>
                    <tr class="bg-purple-600 text-white">
                      <th class="p-3 text-left w-[20%]">Location
                      </th>
                      <th class="p-3 text-left w-[36%]">Offer Description
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="white" v-bind="attrs" v-on="on">mdi-information</v-icon>
                          </template>
                          <div class="max-w-[400px]">
                            <span >
                              Provide a clear and concise description of the promotional offer, 
                              including its validity period.
                              <br /><br />
                              <strong>Examples:</strong><br />
                              1. <em>Get 30% off on all shakes this Deepawali week.</em><br />
                              <!-- 2. <em>Enjoy free fries with every burger order above ₹300 on Independence Day.</em><br /> -->
                              2. <em>Buy 1 Get 1 Free on all pizzas this weekend only.</em><br />
                            </span>
                          </div>
                        </v-tooltip>
                      </th>
                      <th class="p-3 text-left w-[16%]">Publish From
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="white" v-bind="attrs" v-on="on">mdi-information</v-icon>
                          </template>
                          <div class="max-w-[400px]">
                            <span>
                              Select the start date for your promotional offer. From this date, the specified offer will be included in automated review replies.
                            </span>
                          </div>
                        </v-tooltip>
                      </th>
                      <th class="p-3 text-left w-[16%]">Publish To
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="white" v-bind="attrs" v-on="on">mdi-information</v-icon>
                          </template>
                          <div class="max-w-[400px]">
                            <span>
                              Select the end date for your promotional offer. After this date, the offer will no longer be included in review replies.
                            </span>
                          </div>
                        </v-tooltip>
                      </th>
                      <th class="p-3 text-center w-[12%]">Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Global Offer Row -->
                    <tr v-if="globalOffer" :key="'global'" class="global-offer-row">
  <td class="p-3 w-[20%]">Global Offer ({{ globalMathcedLocationId.length }} Locations)</td>
  <td class="p-3 w-[40%]">
    <input
      v-if="editingGlobalOffer"
      v-model="editedGlobalDescription"
      class="border p-2 w-full rounded"
      placeholder="Enter offer description"
    />
    <span v-else>{{ globalOffer.description || "-" }}</span>
  </td>
  <td class="p-3 w-[14%]">
    <input
      v-if="editingGlobalOffer"
      type="datetime-local"
      v-model="editedGlobalStartsFrom"
      class="border p-2 rounded"
    />
    <div v-else class="flex gap-2 items-center justify-center">
      {{ globalOffer.starts || "-" }}
    </div>
  </td>
  <td class="p-3 w-[14%]">
    <input
      v-if="editingGlobalOffer"
      type="datetime-local"
      v-model="editedGlobalValidUntil"
      class="border p-2 rounded"
    />
    <div v-else class="flex gap-2 items-center justify-center">
      {{ globalOffer.valid || "-" }}
    </div>
  </td>
  <td class="p-3 w-[12%]">
    <div class="flex gap-2 items-center justify-center">
      <template v-if="editingGlobalOffer">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              size="20"
              class="cursor-pointer text-green-500 hover:text-green-700"
              @click="saveGlobalOfferEdits"
            >
              mdi-check
            </v-icon>
          </template>
          <span>Save changes</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              size="20"
              class="cursor-pointer text-red-500 hover:text-red-700"
              @click="editingGlobalOffer = false"
            >
              mdi-close
            </v-icon>
          </template>
          <span>Cancel</span>
        </v-tooltip>
      </template>
      <template v-else>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              size="20"
              class="cursor-pointer text-gray-500 hover:text-gray-700"
              @click="editGlobalOffer"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit Global Offer</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              size="20"
              class="cursor-pointer text-red-500 hover:text-red-700"
              @click="deleteGlobalOffer"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Delete Global Offer</span>
        </v-tooltip>
      </template>
    </div>
  </td>
</tr>
                    <!-- Existing Location Rows -->
                    <tr v-for="location in paginatedLocations" :key="location.id">
                      <td class="p-3 w-[20%]">{{ location.storeLocation }}</td>
                      <td class="p-3 w-[40%]">
                        <input
                          v-if="editingLocation === location.id"
                          v-model="editedDescription"
                          class="border p-2 w-full rounded"
                          placeholder="Enter offer description"
                        />
                        <span v-else>{{ location.description || "-" }}</span>
                      </td>
                      <td class="p-3 w-[14%]">
                        <input
                          v-if="editingLocation === location.id"
                          type="datetime-local"
                          v-model="editedStartsFrom"
                          class="border p-2 rounded"
                        />
                        <div v-else class="flex gap-2 items-center justify-center">
                          {{ location.starts || "-" }}
                        </div>
                      </td>
                      <td class="p-3 w-[14%]">
                        <input
                          v-if="editingLocation === location.id"
                          type="datetime-local"
                          v-model="editedValidUntil"
                          class="border p-2 rounded"
                        />
                        <div v-else class="flex gap-2 items-center justify-center">
                          {{ location.valid || "-" }}
                        </div>
                      </td>
                      <td class="p-3 w-[12%]">
                        <div class="flex gap-2 items-center justify-center">
                          <template v-if="editingLocation === location.id">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  size="20"
                                  class="cursor-pointer text-green-500 hover:text-green-700"
                                  @click="saveLocation(location)"
                                >
                                  mdi-check
                                </v-icon>
                              </template>
                              <span>Save changes</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  size="20"
                                  class="cursor-pointer text-red-500 hover:text-red-700"
                                  @click="cancelEdit"
                                >
                                  mdi-close
                                </v-icon>
                              </template>
                              <span>Cancel</span>
                            </v-tooltip>
                          </template>

                          <template v-else>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  size="20"
                                  class="cursor-pointer text-gray-500 hover:text-gray-700"
                                  @click="editLocation(location)"
                                >
                                  mdi-pencil
                                </v-icon>
                              </template>
                              <span>Edit or Create a new offer for this location</span>
                            </v-tooltip>
                            <template v-if="location.description !== '-' && location.valid !== '-'">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    size="20"
                                    class="cursor-pointer text-red-500 hover:text-red-700"
                                    @click="openDeleteDialog(location)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </template>
                                <span>End this offer</span>
                              </v-tooltip>
                            </template>
                          </template>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Pagination Controls -->
              <div class="flex justify-center mt-4">
                <button
                  class="pagination-button px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed rounded-md transition-all"
                  @click="previousPage"
                  :disabled="currentPage === 1"
                >
                  Previous
                </button>
                <span class="px-4 py-2">Page {{ currentPage }} of {{ totalPages }}</span>
                <button
                  class="pagination-button px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed rounded-md transition-all"
                  @click="nextPage"
                  :disabled="currentPage >= totalPages"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Global Offer Dialog -->
    <v-dialog v-model="showGlobalOfferDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Global Offer</v-card-title>
        <v-card-text>
          <v-text-field
            label="Description"
            v-model="globalOfferDescription"
            required
          ></v-text-field>
          <v-text-field
            label="Starts From"
            type="datetime-local"
            v-model="globalOfferStartsFrom"
            required
          ></v-text-field>
          <v-text-field
            label="Valid Until"
            type="datetime-local"
            v-model="globalOfferValidUntil"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="showGlobalOfferDialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="showOverrideDialogFunction()">Apply on all locations</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Override Locations Dialog -->
    <v-dialog v-model="showOverrideDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Override Locations with Valid Offer</v-card-title>
        <v-card-text>
          <v-text-field v-model="searchLocationQuery" placeholder="Search Locations"></v-text-field>
          <v-list>
            <v-list-item-group v-model="selectedOverrideLocations" multiple>
              <v-list-item v-for="location in filteredOverrideLocations" :key="location.id">
                <template v-slot:default>
                  <v-checkbox v-model="selectedOverrideLocations" :value="location.id"></v-checkbox>
                  {{ location.storeLocation }}
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="resetOverrideDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="applyGlobalOffer">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="showDeleteDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Confirm Offer Deletion</v-card-title>
        <v-card-text>
          Are you sure you want to end this offer? This action cannot be undone.
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="grey darken-1" text @click="showDeleteDialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="confirmDeleteOffer">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>   

<script>
import {
  collection,
  query,
  orderBy,
  getDocs,
  startAfter,
  limit,
  doc,
  updateDoc,
  deleteField,
  getDoc 
} from "firebase/firestore";
import { db } from "@/utils/firebase.utils"; // Ensure you have Firebase initialized
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";

export default {
  data() {
    return {
      locations: [],
      lastVisible: null, // Track last document for pagination
      hasMore: true, // Indicates if more documents exist
      batchSize: 20, // Number of documents per batch
      editingLocation: null, // ID of the location being edited
      editedDescription: "", // Description being edited
      editedValidUntil: "", // Valid until being edited
      editedStartsFrom: "", // Starts from being edited
      currentPage: 1, // Track current page
      perPage: 5, // Show only 2 locations per page
      searchQuery: "",
      selectedFilter: "all",
      showDeleteDialog: false,
      selectedLocationForDelete: null,
      showGlobalOfferDialog: false,
      globalOfferDescription: "",
      globalOfferStartsFrom: "",
      globalOfferValidUntil: "",
      globalOffer: null,
      showOverrideDialog: false,
      searchLocationQuery: "",
      selectedOverrideLocations: [],
      globalMathcedLocationId: [],
      editingGlobalOffer : false,
      editedGlobalDescription: "",
      editedGlobalStartsFrom: "",
      editedGlobalValidUntil: "",
    };
  },
  computed: {
    filteredOverrideLocations() {
      let result = this.locations.filter(location => location.description && location.valid && this.isTimeValid(location.valid));
      if(this.searchLocationQuery){
        return result.filter(loc =>
          loc.storeLocation.toLowerCase().includes(this.searchLocationQuery.toLowerCase())
        );
      }
      return result;
    },
    filteredLocations() {
      let result = this.locations;
      // Apply search filter
      if (this.searchQuery) {
        result = result.filter(location =>
          location.storeLocation.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      // Apply offer filters
      if (this.selectedFilter === "withoutOffer") {
        result = result.filter(location => location.description==='-' && location.valid==='-');
      } else if (this.selectedFilter === "withOffer") {
        result = result.filter(location => location.description && location.valid && this.isTimeValid(location.valid));
      } else if (this.selectedFilter === "expired") {
        result = result.filter(location => location.description && location.valid && this.isExpired(location.valid));
      }

      return result;
    },
    totalPages() {
      return Math.ceil(this.filteredLocations.length / this.perPage);
    },
    paginatedLocations() {
      const start = (this.currentPage - 1) * this.perPage;
      return this.filteredLocations.slice(start, start + this.perPage);
    },
    withoutOfferCount() {
      return this.locations.filter(location => location.description ==='-' && location.valid==='-').length;
    },
    withOfferCount() {
      return this.locations.filter(location => location.description && location.valid && this.isTimeValid(location.valid)).length;
    },
    expiredCount() {
      return this.locations.filter(location => location.description && location.valid && this.isExpired(location.valid)).length;
    },
  },
  watch: {
    searchQuery() {
      this.currentPage = 1; // Reset page when search query changes
    },
    selectedFilter() {
      this.currentPage = 1; // Reset page when filter changes
    }
  },
  methods: {
    resetOverrideDialog() {
      this.showOverrideDialog = false;
      this.searchLocationQuery = ""; // Reset search query
    },
    openGlobalOfferDialog() {
      this.showGlobalOfferDialog = true;
    },
    showOverrideDialogFunction() {
      // if (this.globalOfferDescription.length < 1) {
      //   alert("Description must not be empty.");
      //   return;
      // }

      // if (!this.globalOfferStartsFrom) {
      //   alert("Please add a start date.");
      //   return;
      // }

      // if (!this.globalOfferValidUntil) {
      //   alert("Please add a valid date range.");
      //   return;
      // }

      if (!this.globalOfferDescription || !this.globalOfferStartsFrom || !this.globalOfferValidUntil) {
        alert("All fields are required.");
        return;
      }

      console.log("THis. time ", this.globalOfferStartsFrom, this.globalOfferValidUntil);
      const selectedStartTime = new Date(this.globalOfferStartsFrom);
      const selectedValidTime = new Date(this.globalOfferValidUntil);
      const currentTime = new Date();
      const minTime = new Date(currentTime.getTime() + 5 * 60 * 1000); // Minimum 5 minutes from now

      if (selectedStartTime < minTime) {
        alert("Starts From must be at least 5 minutes from now.");
        return;
      }

      if (selectedValidTime <= selectedStartTime) {
        alert("Valid Until must be greater than Starts From.");
        return;
      }

      this.selectedOverrideLocations = this.locations.filter(loc => loc.description !== "-" && loc.valid !== "-").map(loc => loc.id);
      if (this.selectedOverrideLocations.length === 0) {
        // Directly apply the global offer if no override is needed
        this.applyGlobalOffer();
      } else {
        // Otherwise, open the override dialog
        this.showOverrideDialog = true;
      }
      this.showGlobalOfferDialog = false;
    },
    async applyGlobalOffer() {

      const selectedValidTime = new Date(this.globalOfferValidUntil);
      const selectedStartTime =  new Date(this.globalOfferStartsFrom);

      const firestoreValidTimestamp = {
        seconds: Math.floor(selectedValidTime.getTime() / 1000),
        nanoseconds: (selectedValidTime.getTime() % 1000) * 1000000,
      };
      const firestoreStartTimestamp = {
        seconds: Math.floor(selectedStartTime.getTime() / 1000),
        nanoseconds: (selectedStartTime.getTime() % 1000) * 1000000,
      };

      let newGlobalOfferLocations = [];

      const batchUpdates = this.locations.map(async (location) => {
        if (location.description === "-" || location.valid === "-" || this.selectedOverrideLocations.includes(location.id) || this.isExpired(location.valid)) {
          const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", location.id);

          await updateDoc(workspaceRef, {
            gmb_custom_reply: {
              description: this.globalOfferDescription,
              valid_until: selectedValidTime,
              starts_from:selectedStartTime,
              status: "global"
            },
          });

          // **Update local UI immediately**
          location.description = this.globalOfferDescription;
          location.valid = firestoreValidTimestamp ? this.formatDate(firestoreValidTimestamp) : "-";
          location.starts =  firestoreStartTimestamp ? this.formatDate(firestoreStartTimestamp) : "-";

          newGlobalOfferLocations.push(location.id);
        }
      });

      await Promise.all(batchUpdates);

      const adminWorkspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
      await updateDoc(adminWorkspaceRef, {
            global_offer: {
              description: this.globalOfferDescription,
              valid_until: selectedValidTime,
              starts_from:selectedStartTime,
            },
      });

      this.globalOffer = {
        storeLocation: "Global Offer",
        description: this.globalOfferDescription,
        starts: firestoreStartTimestamp ? this.formatDate(firestoreStartTimestamp) : "-",
        valid: firestoreValidTimestamp ? this.formatDate(firestoreValidTimestamp) : "-",
      };
      this.searchLocationQuery = "";
      this.showOverrideDialog = false;
      this.showGlobalOfferDialog = false;

      // Reset the input fields
      this.globalOfferDescription = "";
      this.globalOfferStartsFrom = "";
      this.globalOfferValidUntil = "";

      this.globalMathcedLocationId = newGlobalOfferLocations;
      // fetchWorkspaces();

    },
    async saveGlobalOffer() {
      if (!this.globalOfferDescription || !this.globalOfferStartsFrom || !this.globalOfferValidUntil) {
        alert("All fields are required.");
        return;
      }

      const selectedStartTime = new Date(this.globalOfferStartsFrom);
      const selectedValidTime = new Date(this.globalOfferValidUntil);
      const currentTime = new Date();
      const minTime = new Date(currentTime.getTime() + 5 * 60 * 1000); // 5 minutes from now

      if (selectedStartTime < minTime) {
        alert("Starts From must be at least 5 minutes from now.");
        return;
      }

      if (selectedValidTime <= selectedStartTime) {
        alert("Valid Until must be greater than Starts From.");
        return;
      }

      this.globalOffer = {
        description: this.globalOfferDescription,
        starts: this.formatDate(selectedStartTime),
        valid: this.formatDate(selectedValidTime),
      };

      this.showGlobalOfferDialog = false;
      this.globalOfferDescription = "";
      this.globalOfferStartsFrom = "";
      this.globalOfferValidUntil = "";

      // Handle overriding existing offers
      this.overrideExistingOffers();
    },
    overrideExistingOffers() {
      // Logic to show existing offers and allow user to override
      // This can be implemented using another dialog with checkboxes
    },
    editGlobalOffer() {
      this.editingGlobalOffer = true;
      this.globalOfferDescription = this.globalOffer.description;
      this.globalOfferStartsFrom = this.convertToDateTimeLocal(this.globalOffer.starts);
      this.globalOfferValidUntil = this.convertToDateTimeLocal(this.globalOffer.valid);
    },

    async saveGlobalOfferEdits() {
  if (!this.editedGlobalDescription || !this.editedGlobalStartsFrom || !this.editedGlobalValidUntil) {
    alert("All fields are required.");
    return;
  }

  const selectedStartTime = new Date(this.editedGlobalStartsFrom);
  const selectedValidTime = new Date(this.editedGlobalValidUntil);
  const currentTime = new Date();
  const minTime = new Date(currentTime.getTime() + 5 * 60 * 1000); // Minimum 5 minutes from now

  if (selectedStartTime < minTime) {
    alert("Starts From must be at least 5 minutes from now.");
    return;
  }

  if (selectedValidTime <= selectedStartTime) {
    alert("Valid Until must be greater than Starts From.");
    return;
  }

  // Convert to Firestore timestamp
  const firestoreValidTimestamp = {
    seconds: Math.floor(selectedValidTime.getTime() / 1000),
    nanoseconds: (selectedValidTime.getTime() % 1000) * 1000000,
  };
  const firestoreStartTimestamp = {
    seconds: Math.floor(selectedStartTime.getTime() / 1000),
    nanoseconds: (selectedStartTime.getTime() % 1000) * 1000000,
  };

  try {
    // Update all locations in globalMathcedLocationId
    const batchUpdates = this.globalMathcedLocationId.map(async (locationId) => {
      const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", locationId);
      await updateDoc(workspaceRef, {
        gmb_custom_reply: {
          description: this.editedGlobalDescription,
          valid_until: selectedValidTime,
          starts_from: selectedStartTime,
          status: "global",
        },
      });

      // Update UI locally
      const location = this.locations.find(loc => loc.id === locationId);
      if (location) {
        location.description = this.editedGlobalDescription;
        location.valid = this.formatDate(firestoreValidTimestamp);
        location.starts = this.formatDate(firestoreStartTimestamp);
      }
    });

    await Promise.all(batchUpdates);

    // Update global offer in Firestore
    const adminWorkspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
    await updateDoc(adminWorkspaceRef, {
      global_offer: {
        description: this.editedGlobalDescription,
        valid_until: selectedValidTime,
        starts_from: selectedStartTime,
      },
    });

    // Update UI global offer
    this.globalOffer = {
      description: this.editedGlobalDescription,
      starts: this.formatDate(firestoreStartTimestamp),
      valid: this.formatDate(firestoreValidTimestamp),
    };

    // Exit edit mode
    this.editingGlobalOffer = false;
  } catch (error) {
    console.error("Error updating global offer:", error);
  }
},
async deleteGlobalOffer() {
  if (!confirm(`Are you sure you want to delete the global offer from ${this.globalMathcedLocationId.length} locations?`)) return;

  try {
    // Delete the offer from all locations in globalMathcedLocationId
    const batchDeletes = this.globalMathcedLocationId.map(async (locationId) => {
      const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", locationId);
      await updateDoc(workspaceRef, {
        gmb_custom_reply: deleteField(),
      });

      // Update UI locally
      const location = this.locations.find(loc => loc.id === locationId);
      if (location) {
        location.description = "-";
        location.valid = "-";
        location.starts = "-";
      }
    });

    await Promise.all(batchDeletes);

    // Remove global offer from Firestore
    const adminWorkspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
    await updateDoc(adminWorkspaceRef, {
      global_offer: deleteField(),
    });

    // Clear global offer in UI
    this.globalOffer = null;
    this.globalMathcedLocationId = [];

    alert("Global offer has been deleted successfully.");
  } catch (error) {
    console.error("Error deleting global offer:", error);
  }
},
    openDeleteDialog(location) {
      this.selectedLocationForDelete = location;
      this.showDeleteDialog = true;
    },
    async confirmDeleteOffer() {
      if (!this.selectedLocationForDelete) return;
      
      try {
        const workspaceRef = doc(
          db,
          "users",
          this.currentUID,
          "fly_workspaces",
          this.selectedLocationForDelete.id
        );

        // Remove gmb_custom_reply field from Firestore
        await updateDoc(workspaceRef, {
          gmb_custom_reply: deleteField()
        });

        // Update UI: Set description and valid_until to "-"
        this.selectedLocationForDelete.description = "-";
        this.selectedLocationForDelete.valid = "-";
        this.selectedLocationForDelete.starts = "-";

        console.log("Offer deleted successfully for location:", this.selectedLocationForDelete.storeLocation);
        
      } catch (error) {
        console.error("Error deleting offer:", error);
      } finally {
        this.showDeleteDialog = false; // Close dialog after deletion
        this.selectedLocationForDelete = null; // Reset selection
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    parseDateTime(dateTimeStr) {
      try {
        const [datePart, timePart] = dateTimeStr.split(" ");
        const [day, month, year] = datePart.split("/").map(Number); // Extract day, month, year
        const [time, period] = timePart.split(" "); // Extract time and AM/PM
        let [hours, minutes] = time.split(":").map(Number);

        // Convert 12-hour format to 24-hour format
        if (period === "PM" && hours !== 12) {
          hours += 12;
        } else if (period === "AM" && hours === 12) {
          hours = 0;
        }

        return new Date(year, month - 1, day, hours, minutes); // Construct Date object
      } catch (error) {
        console.error("Error parsing date:", dateTimeStr, error);
        return NaN; // Return NaN if parsing fails
      }
    },
    isExpired(validUntil) {
      if (!validUntil || validUntil === "-") return false; // Handle empty cases
        const parsedDate = this.parseDateTime(validUntil);
        return parsedDate instanceof Date && !isNaN(parsedDate) && parsedDate < new Date(); // Ensure valid date
      },

    isTimeValid(validUntil) {
      if (!validUntil || validUntil === "-") return false; // Handle empty cases
      const parsedDate = this.parseDateTime(validUntil);
      return parsedDate instanceof Date && !isNaN(parsedDate) && parsedDate > new Date();
    },

    async fetchWorkspaces() {
      try {
        const workspaceRef = collection(
          db,
          "users",
          this.currentUID,
          "fly_workspaces"
        );

        // Query Firestore
        let q = query(
          workspaceRef,
          orderBy("created_at"),
          limit(this.batchSize)
        );

        // Apply pagination
        if (this.lastVisible) {
          q = query(q, startAfter(this.lastVisible));
        }

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          this.hasMore = false; // No more documents
          return;
        }

        const newLocations = [];
        const matchedLocations = []; // Store matched location IDs

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.storeWorkspace) {
            const locationData = {
              id: doc.id,
              storeLocation: data.storeLocation || "Unnamed Location",
              description: data.gmb_custom_reply?.description || "-",
              valid: this.formatDate(data.gmb_custom_reply?.valid_until) || "-",
              starts: this.formatDate(data.gmb_custom_reply?.starts_from) || "-",
            };
            newLocations.push(locationData);
            if (
              this.globalOffer &&
              locationData.description === this.globalOffer.description &&
              locationData.valid === this.globalOffer.valid &&
              locationData.starts === this.globalOffer.starts
            ) {
              matchedLocations.push(doc.id);
            }
          }
        });
        this.locations = newLocations;
        this.globalMathcedLocationId = matchedLocations;

        this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]; // Set last document for pagination
      } catch (error) {
        console.error("Error fetching workspaces:", error);
      }
    },
    convertToDateTimeLocal(dateString) {
        if (!dateString) return "";

        // If it's a Firestore Timestamp (object), convert properly
        if (typeof dateString === "object" && dateString.seconds) {
            const date = new Date(dateString.seconds * 1000);
            return date.toISOString().slice(0, 16); // Format to YYYY-MM-DDTHH:MM
        }

        // If it's already a formatted date string (like "2/24/2025"), parse it
        const parsedDate = new Date(dateString);
        if (!isNaN(parsedDate.getTime())) {
            return parsedDate.toISOString().slice(0, 16); // Format correctly
        }

        return "";
    },
    editLocation(location) {
      this.editingLocation = location.id;
      this.editedDescription =
        location.description !== "-" ? location.description : "";
      this.editedStartsFrom = location.starts !== "-" ? this.convertToDateTimeLocal(location.starts) : "";
      this.editedValidUntil = location.valid !== "-" ? this.convertToDateTimeLocal(location.valid) : "";
    },
    cancelEdit() {
      this.editingLocation = null; // Reset editing state
      this.editedDescription = "";
      this.editedValidUntil = "";
      this.editedStartsFrom = "";
    },
    async saveLocation(location) {
      if (this.editedDescription.length < 1) {
        alert("Description must not be empty.");
        return;
      }

      if (!this.editedStartsFrom) {
        alert("Please add a start date.");
        return;
      }

      if (!this.editedValidUntil) {
        alert("Please add a valid date range.");
        return;
      }

      const selectedStartTime = new Date(this.editedStartsFrom);
      const selectedValidTime = new Date(this.editedValidUntil);
      const currentTime = new Date();
      const minTime = new Date(currentTime.getTime() + 5 * 60 * 1000); // 5 minutes from now

      if (selectedStartTime < minTime) {
        alert("Starts From must be at least 5 minutes from now.");
        return;
      }

      if (selectedValidTime <= selectedStartTime) {
        alert("Valid Until must be greater than Starts From.");
        return;
      }

      try {
        const workspaceRef = doc(
          db,
          "users",
          this.currentUID,
          "fly_workspaces",
          location.id
        );
        await updateDoc(workspaceRef, {
          gmb_custom_reply: {
            description: this.editedDescription,
            valid_until: selectedValidTime,
            starts_from: selectedStartTime,
            status: "local"
          },
        });
        const firestoreValidTimestamp = {
          seconds: Math.floor(selectedValidTime.getTime() / 1000),
          nanoseconds: (selectedValidTime.getTime() % 1000) * 1000000,
        };
        const firestoreStartTimestamp = {
          seconds: Math.floor(selectedStartTime.getTime() / 1000),
          nanoseconds: (selectedStartTime.getTime() % 1000) * 1000000,
        };
        // Update the local state
        location.description = this.editedDescription;
        location.valid = firestoreValidTimestamp ? this.formatDate(firestoreValidTimestamp) : "-";
        location.starts = firestoreStartTimestamp ? this.formatDate(firestoreStartTimestamp) : "-";

        this.globalMathcedLocationId = this.globalMathcedLocationId.filter(id => id !== location.id);
        this.editingLocation = null; // Exit editing mode
      } catch (error) {
        console.error("Error updating location:", error);
      }
    },
    formatDate(timestamp) {
      if (!timestamp || !timestamp.seconds) return "";
      // Convert Firestore Timestamp manually using seconds
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleDateString("en-GB"); // "en-GB" ensures dd/mm/yyyy format
      // Format time as hh:mm AM/PM
      const formattedTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true, // Ensures AM/PM format
      });

      return `${formattedDate} ${formattedTime}`;
    },
    async fetchGlobalOffer() {
      const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
      const docSnap = await getDoc(workspaceRef);
      if (docSnap.exists() && docSnap.data().global_offer) {
        let global_offer_data = docSnap.data().global_offer;
        this.globalOffer = {
          storeLocation: "Global Offer",
          description: global_offer_data?.description || "-",
          valid: this.formatDate(global_offer_data?.valid_until) || "-",
          starts: this.formatDate(global_offer_data?.starts_from) || "-",      
        }
      }
    },
  },
  mounted() {
    this.fetchGlobalOffer();
    this.fetchWorkspaces();
  },
};
</script>

<style scoped>
/* Table Styling */
table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}
th {
  background-color: #8056de;
  color: white;
}
tr:hover {
  background-color: #f1f5f9;
}
.pagination-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    background-color: #8050DE; /* Primary color */
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    margin: 0 0.5rem; /* Space between buttons */
}
.pagination-button:hover {
    background-color: #6A3FBD; /* Darker shade on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}
.pagination-button.disabled {
    background-color: #ccc; /* Gray color for disabled state */
    cursor: not-allowed; /* Change cursor for disabled state */
}

.search-input {
  width: 30%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 7px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  margin-left: 24px;
}

.search-input:focus {
  border-color: #8056dd;
  outline: none;
}

.status-filter-btn {
  padding: 6px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.status-filter-btn.status-withoutOffer.active {
  background-color: #fff3e0;
  border-color: #ff9800;
  color: black;
  font-weight: 500;
}

.status-filter-btn.status-withoutOffer {
  background-color:#fff3e0;
  border-color: #ff9800;
  color: #ff9800;
}

.status-filter-btn.status-withOffer.active {
  background-color: #e8f5e9;
  border-color: #4caf50;
  color: black;
  font-weight: 500;
}

.status-filter-btn.status-withOffer {
  background-color: #e8f5e9;
  border-color: #4caf50;
  color: #4caf50;
}

.status-filter-btn.status-expired.active {
  background-color: #ffebee;
  border-color: #f30000;
  color: black;
  font-weight: 500;
}

.status-filter-btn.status-expired {
  background-color: #ffebee;
  border-color: #f30000;
  color: #f30000;
}

.status-filter-btn.status-global {
  background-color:#eee0ff;
  border-color: #8c00ff;
  color: #9837f3;
}

.status-filter-btn:hover {
  background-color: #f7fafc;
}

.status-filter-btn.active {
  background-color: #edf2f7;
  border-color: #cbd5e0;
}
.global-offer-row {
  background-color: #eaf0fd; /* Light gray background */
  font-weight: bold;
}
.global-offer-row:hover {
  background-color: #e5e7eb; /* Slightly darker on hover */
}

</style>
