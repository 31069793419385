<template>
  <v-avatar v-bind="$attrs" :color="logo ? '' : 'primary'" :size="size" class="mb-1">
    <img class="cursor-pointer" v-if="logo" :src="logo"/>
    <span v-else class="white--text font-weight-bold">{{ logoName }}</span>
  </v-avatar>
</template>

<script>
import { get } from "lodash";
export default {
  inheritAttrs: false,
  props: {
    size: {
      type: Number,
      default: 30,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    logo() {
      return this.user.photo_url || 'https://img.icons8.com/3d-fluency/94/guest-male--v1.png';
    },
    logoName() {
      let name = get(this.user, "display_name", "A");
      if (!name) name = "A";
      return name.charAt(0).toUpperCase();
    },
  },
};
</script>

<style></style>
