<template>
  <div class="api-key-page" @click="handleClick">
    <h1 class="text-2xl font-semibold text-gray-800 mb-6">
      API Key Integrations
    </h1>

    <!-- Form to generate API keys -->
    <v-card class="mb-2 mt-4">
      <v-card-title>{{
        !existingApiKey && apiKeys.length === 0
          ? "Generate a New API Key"
          : "Your API key"
      }}</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="generateApiKey">
          <v-text-field
            v-if="!existingApiKey && apiKeys.length === 0"
            v-model="newKeyDescription"
            label="Key Description"
            placeholder="e.g., Key for Social Media App"
            outlined
            required
          ></v-text-field>
          <p v-if="errors.newKeyDescription" class="error-message">
            {{ errors.newKeyDescription }}
          </p>

          <div v-if="existingApiKey || apiKeys.length > 0">
            <v-text-field
              :value="
                showApiKey
                  ? existingApiKey || apiKeys[0]?.key
                  : maskedKey(existingApiKey || apiKeys[0]?.key)
              "
              label="Your API Key"
              readonly
              outlined
              :append-icon="showApiKey ? 'mdi-eye' : 'mdi-eye-off'"
              prepend-inner-icon="mdi-content-copy"
              @click:prepend-inner="copyApiKey"
              @click:append="toggleShowApiKey"
            ></v-text-field>
            <p
              v-if="copySuccessMessage"
              :class="{
                'success-message': copySuccessMessage.includes('copied'),
                'error-message': copySuccessMessage.includes('Failed'),
              }"
            >
              {{ copySuccessMessage }}
            </p>
          </div>

          <v-btn
            v-if="!existingApiKey && apiKeys.length === 0"
            type="submit"
            color="primary"
            class="submit-button"
            :loading="isLoading"
            :disabled="!!existingApiKey || apiKeys.length > 0"
          >
            Generate API Key
          </v-btn>

          <v-btn
            v-if="existingApiKey || apiKeys.length > 0"
            color="primary"
            class="submit-button"
            :loading="isLoading"
            @click="reGenerateApiKey"
          >
            Regenerate Key
          </v-btn>
          <p v-if="errors.submit" class="error-message">{{ errors.submit }}</p>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Social Configurations -->
    <h2
      v-if="existingApiKey || apiKeys.length > 0"
      class="text-2xl font-semibold text-gray-800 mb-2 mt-6"
    >
      Publish Configs
    </h2>
    <div
      v-if="existingApiKey || apiKeys.length > 0"
      style="display: flex; align-items: center; gap: 5px"
    >
      <span style="font-size: 16px; font-weight: 600; color: #333"
        >Create new config</span
      >
      <v-btn
        icon
        style="min-width: auto; padding: 0"
        @click.stop="openInputDiaglog()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <p
      v-if="showWarningMessage && (existingApiKey || apiKeys.length > 0)"
      style="color: red; font-size: 14px; margin-top: 5px"
    >
      Please connect a social handle first.
    </p>
    <div v-if="showInputForWorkspace && workspaceNames.length > 0" class="mt-2">
      <v-text-field
        v-model="newConfigKeyName"
        label="Configuration Name"
        placeholder="Enter configuration name"
        outlined
        dense
        required
      ></v-text-field>

      <!-- 🔹 Select Workspace Dropdown -->
      <v-select
        v-model="selectedWorkspaceId"
        label="Select Workspace"
        :items="workspaceNames.map((ws) => ({ text: ws.name, value: ws.id }))"
        item-text="text"
        item-value="value"
        outlined
        dense
        required
      ></v-select>

      <!-- 🔴 Warning Message -->
      <p
        v-if="errorMessage"
        style="color: red; font-size: 14px; margin-top: 5px"
      >
        {{ errorMessage }}
      </p>
      <v-btn
        color="primary"
        class="mt-1 mb-2"
        :disabled="newConfigKeyName.length === 0 || !selectedWorkspaceId"
        @click="createConfig"
      >
        Create
      </v-btn>
    </div>
    <v-container>
      <v-card
        v-for="config in configurationsList"
        :key="config.id"
        class="mb-4"
      >
        <v-card-title @click="toggleConfig(config.id)">
          {{ config.name }}
          <v-spacer></v-spacer>
          <v-icon>{{
            expandedConfig === config.id ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-card-title>
        <v-expand-transition>
          <v-card-text v-if="expandedConfig === config.id">
            <p
              class="text-gray-500 font-medium text-base sm:text-lg md:text-xl leading-relaxed"
            >
              Select social networks and pages
            </p>
            <v-list>
              <v-list-item
                v-for="platform in getConnectedPlatformsForConfig(config.id)"
                :key="platform.id"
                class="block"
              >
                <v-checkbox
                  v-model="platform.isSelected"
                  :label="platform.name"
                  @change="
                    togglePlatformSelectionForConfig(config.id, platform)
                  "
                ></v-checkbox>
                <v-expand-transition>
                  <div v-if="platform.isSelected && platform.pages.length">
                    <!-- Selected Profiles -->
                    <div
                      v-if="platform.selectedProfiles.length"
                      class="selected-items"
                    >
                      <v-chip
                        v-for="page in platform.selectedProfiles"
                        :key="getPageKey(page)"
                        close
                        @click:close="
                          removePageFromConfigSelection(
                            config.id,
                            page,
                            platform
                          )
                        "
                      >
                        {{ getPageName(page) }}
                      </v-chip>
                    </div>

                    <!-- Available Pages for Selection -->
                    <div
                      v-if="
                        platform.pages.length !==
                        platform.selectedProfiles.length
                      "
                      class="flex justify-end text-blue-500 cursor-pointer"
                      @click="toggleShowList(platform)"
                    >
                      {{ platform.showList ? "Hide List" : "Show List" }}
                    </div>

                    <v-list
                      v-if="
                        platform.pages.length !==
                          platform.selectedProfiles.length && platform.showList
                      "
                    >
                      <v-list-item
                        v-for="page in platform.pages"
                        :key="getPageKey(page)"
                        @click="
                          togglePageSelectionForConfig(
                            config.id,
                            page,
                            platform
                          )
                        "
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{
                            getPageName(page)
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-expand-transition>
              </v-list-item>
            </v-list>

            <!-- Configuration Key -->
            <div v-if="generatedConfigKeys[config.id]" class="mt-4">
              <v-text-field
                :value="
                  showConfigKey[config.id]
                    ? generatedConfigKeys[config.id]
                    : maskedKey(generatedConfigKeys[config.id])
                "
                label="Configuration Key"
                readonly
                outlined
                :append-icon="
                  showConfigKey[config.id] ? 'mdi-eye' : 'mdi-eye-off'
                "
                prepend-inner-icon="mdi-content-copy"
                @click:prepend-inner="copyConfigKey(config.id)"
                @click:append="toggleShowConfigKey(config.id)"
              ></v-text-field>
            </div>

            <!-- Apply Button -->
            <v-btn
              color="primary"
              class="apply-button mt-4"
              @click="applyConfigSettings(config.id)"
              :loading="isApplying"
              :disabled="isApplying"
            >
              {{ isApplying ? "Applying..." : "Apply" }}
            </v-btn>
          </v-card-text>
        </v-expand-transition>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { httpsCallable } from "firebase/functions";
import { functions, db } from "@/utils/firebase.utils";
import { doc, getDoc, collection, getDocs, addDoc } from "firebase/firestore";

export default {
  data() {
    return {
      newKeyDescription: "",
      apiKeys: [],
      existingApiKey: null,
      showApiKey: false,
      isApplying: false,
      isLoading: false,
      workspaceNames: [],
      showList: true,
      errors: {
        newKeyDescription: "",
        apiKey: "",
        submit: "",
      },
      workspaceData: {},
      workspaceConfigKeys: {}, // Store received config keys per workspace
      showConfigKey: {}, // Store toggle state for each workspace
      expandedWorkspace: null,
      newConfigKeyName: "", // For storing new config key name input
      showNewConfigDialog: false,
      showInputForWorkspace: false,
      selectedWorkspaceId: null, // Stores workspace ID for which the new config is being added
      platforms: [
        {
          name: "Facebook",
          picture: "",
          user_name: "",
          isConnected: false,
          id: "facebook",
          user_id: null,
          pages: [],
          selectedProfiles: [],
          isSelected: false,
          showList: true,
        },
        {
          name: "Threads",
          picture: "",
          user_name: "",
          isConnected: false,
          id: "threads",
          user_id: null,
          pages: [],
          selectedProfiles: [],
          isSelected: false,
          showList: true,
        },
        {
          name: "Instagram",
          picture: "",
          user_name: "",
          isConnected: false,
          id: "instagram",
          user_id: null,
          pages: [],
          selectedProfiles: [],
          isSelected: false,
          showList: true,
        },
        {
          name: "YouTube",
          picture: "",
          user_name: "",
          isConnected: false,
          id: "youtube",
          user_id: null,
          pages: [],
          selectedProfiles: [],
          isSelected: false,
          showList: true,
        },
        {
          name: "Google My Business",
          picture: "",
          user_name: "",
          isConnected: false,
          user_id: null,
          id: "google_business_profile",
          pages: [],
          selectedProfiles: [],
          isSelected: false,
          showList: true,
        },
        {
          name: "Pinterest",
          picture: "",
          user_name: "",
          isConnected: false,
          id: "pinterest",
          user_id: null,
          pages: [],
          selectedProfiles: [],
          isSelected: false,
          showList: true,
        },
        {
          name: "Twitter",
          picture: "",
          user_name: "",
          isConnected: false,
          id: "twitter",
          user_id: null,
          pages: [],
          selectedProfiles: [],
          isSelected: false,
          showList: true,
        },
        {
          name: "LinkedIn",
          picture: "",
          user_name: "",
          isConnected: false,
          id: "linkedin",
          user_id: null,
          pages: [],
          selectedProfiles: [],
          isSelected: false,
          showList: true,
        },
        {
          name: "Tumblr",
          picture: "",
          user_name: "",
          isConnected: false,
          id: "tumblr",
          user_id: null,
          pages: [],
          selectedProfiles: [],
          isSelected: false,
          showList: true,
        },
        {
          name: "Tiktok",
          picture: "",
          user_name: "",
          isConnected: false,
          id: "tiktok",
          user_id: null,
          pages: [],
          selectedProfiles: [],
          isSelected: false,
          showList: true,
        },
        {
          name: "BlueSky",
          picture: "",
          user_name: "",
          isConnected: false,
          id: "bluesky",
          user_id: null,
          pages: [],
          selectedProfiles: [],
          isSelected: false,
          showList: true,
        },
        {
          name: "Mastodon",
          picture: "",
          user_name: "",
          isConnected: false,
          id: "mastodon",
          user_id: null,
          pages: [],
          selectedProfiles: [],
          isSelected: false,
          showList: true,
        },
      ],
      configurationsList: [],
      generatedConfigKeys: {},
      showWarningMessage: false,
      expandedConfig: null,
      configData: {},
      errorMessage: "",
      copySuccessMessage: "",
    };
  },
  methods: {
    getConnectedPlatformsForConfig(configId) {
      return (
        this.configData[configId]?.filter((platform) => platform.isConnected) ||
        []
      );
    },
    openInputDiaglog() {
      if (this.workspaceNames.length === 0) {
        this.showWarningMessage = !this.showWarningMessage;
        return;
      }
      this.showInputForWorkspace = !this.showInputForWorkspace;
    },
    async createConfig() {
      try {
        if (!this.newConfigKeyName.trim() || !this.selectedWorkspaceId) {
          return;
        }
        const nameExists = this.configurationsList.some(
          (config) => config.name === this.newConfigKeyName.trim()
        );

        if (nameExists) {
          this.errorMessage =
            "This name already exists. Please try an unique name.";
          return;
        }
        this.errorMessage = "";
        // 🔹 Add a new document to Firestore
        const configurationsRef = collection(
          db,
          "users_api_key",
          this.currentUID,
          "configurations"
        );

        const newConfig = {
          name: this.newConfigKeyName.trim(), // Store the name
          created_at: new Date(), // Optional: Store timestamp
          wid: this.selectedWorkspaceId,
        };

        const docRef = await addDoc(configurationsRef, newConfig);
        // console.log(`New configuration "${this.newConfigKeyName}" created successfully!`);
        // 🔹 Refresh configurations list after adding
        await this.getConfigkeyDetails();
        this.newConfigKeyName = "";
        this.showInputForWorkspace = false;
        this.expandedConfig = docRef.id;
        // console.log("THis is id ", this.expandedConfig);
      } catch (error) {
        console.log("Error creating up the config", error);
      } finally {
        this.selectedWorkspaceId = null;
      }
    },
    async applyConfigSettings(configId) {
      this.isApplying = true;

      try {
        const selectedPlatforms = this.configData[configId].filter(
          (platform) => platform.isSelected
        );

        // Validation: At least one platform must be selected
        if (selectedPlatforms.length === 0) {
          alert("Please select at least one platform.");
          this.isApplying = false;
          return;
        }
        // Validation: If platform has pages, selectedProfiles must not be empty
        const isValid = selectedPlatforms.every(
          (platform) =>
            platform.pages.length === 0 || platform.selectedProfiles.length > 0
        );

        if (!isValid) {
          alert("Please select at least one profile for selected platforms.");
          this.isApplying = false;
          return;
        }

        // Prepare payload for API call
        const payload = {
          uid: this.currentUID,
          wid: this.configData[configId][0].wid,
          config_id: configId,
          configuration: this.configData[configId],
          api_key: this.apiKeys[0] || this.existingApiKey,
        };

        console.log("Sending payload:", payload);

        // Call backend API (Replace with actual function name)
        const storeSettings = httpsCallable(
          functions,
          "storeWorkspaceSettings"
        );
        const response = await storeSettings(payload);

        console.log("API Response:", response.data);

        if (response.data.configKey) {
          // alert(`Config Key: ${response.data.configKey}`);
          this.$set(
            this.generatedConfigKeys,
            configId,
            response.data.configKey
          );
        } else {
          alert("Failed to retrieve API key.");
        }
      } catch (error) {
        console.log("Error while applying the settings", error);
      } finally {
        this.isApplying = false; // Stop loader
      }
    },
    toggleShowList(platform) {
      platform.showList = !platform.showList;
    },
    getPageKey(page) {
      return page.id || page.page_id || page.name; // Use the first available unique key
    },
    isPageSelected(pageId, selectedProfiles) {
      return selectedProfiles && selectedProfiles.includes(pageId);
    },
    togglePlatformSelectionForConfig(configId, platform) {
      if (!platform.isSelected) {
        // If unselected, clear selected profiles
        platform.selectedProfiles = [];
      }
      // Update the configData object
      if (this.configData[configId]) {
        const index = this.configData[configId].findIndex(
          (p) => p.id === platform.id
        );
        if (index !== -1) {
          this.$set(this.configData[configId], index, { ...platform });
        }
      }
    },
    removePageFromConfigSelection(configId, page, platform) {
      const pageKey = this.getPageKey(page);
      platform.selectedProfiles = platform.selectedProfiles.filter(
        (p) => this.getPageKey(p) !== pageKey
      );

      // Update the configData object
      if (this.configData[configId]) {
        const index = this.configData[configId].findIndex(
          (p) => p.id === platform.id
        );
        if (index !== -1) {
          this.$set(this.configData[configId], index, { ...platform });
        }
      }
    },
    togglePageSelectionForConfig(configId, page, platform) {
      const pageKey = this.getPageKey(page);
      const index = platform.selectedProfiles.findIndex(
        (p) => this.getPageKey(p) === pageKey
      );

      if (index === -1) {
        platform.selectedProfiles.push(page);
      } else {
        platform.selectedProfiles.splice(index, 1);
      }

      // Update the configData object
      if (this.configData[configId]) {
        const platformIndex = this.configData[configId].findIndex(
          (p) => p.id === platform.id
        );
        if (platformIndex !== -1) {
          this.$set(this.configData[configId], platformIndex, { ...platform });
        }
      }
    },
    getPageName(page) {
      return page.name || page.title || "Unnamed Page";
    },
    handleClick() {
      if (this.errors.apiKey) {
        this.clearErrors();
      }
    },
    toggleConfig(configId) {
      this.expandedConfig = this.expandedConfig === configId ? null : configId;
    },
    async getConfigkeyDetails() {
      try {
        const configurationsRef = collection(
          db,
          "users_api_key",
          this.currentUID,
          "configurations"
        );
        const querySnapshot = await getDocs(configurationsRef);
        const configurations = [];
        querySnapshot.forEach((doc) => {
          configurations.push({
            id: doc.id, // Store document ID
            ...doc.data(), // Store other fields
          });
        });

        this.configurationsList = configurations;
        await this.getConfigConnectionDetails();
      } catch (error) {
        console.log("Error retrieving the data ", error);
      }
    },
    async getConfigConnectionDetails() {
      try {
        if (this.configurationsList?.length > 0) {
          for (let i = 0; i < this.configurationsList.length; i++) {
            let config = this.configurationsList[i];
            let configId = config.id;
            let configWid = config.wid;
            let platforms = config?.platforms || [];
            let config_key = config?.encryptedKey?.encryptedData || "";

            if (platforms.length === 0) {
              this.$set(
                this.configData,
                configId,
                this.workspaceData[configWid]
              );
            } else {
              const updatedPlatforms = this.platforms.map((platform) => {
                const matchedPlatform = platforms.find(
                  (p) => p.name === platform.id
                );
                const connectedPages =
                  this.workspaceData[configWid]?.find(
                    (p) => p.id === platform.id
                  )?.pages || [];
                const matchedPages = matchedPlatform?.pages || [];

                const selectedProfiles = connectedPages.filter((connection) =>
                  matchedPages.some(
                    (matchConnection) =>
                      connection.id === matchConnection.id ||
                      connection.page_id === matchConnection.id ||
                      connection.name === matchConnection.id
                  )
                );

                return {
                  ...platform,
                  isConnected:
                    !!this.workspaceData[configWid]?.find(
                      (p) => p.id === platform.id
                    )?.isConnected || false,
                  pages: connectedPages,
                  user_id:
                    this.workspaceData[configWid]?.find(
                      (p) => p.id === platform.id
                    )?.user_id || "",
                  picture:
                    this.workspaceData[configWid]?.find(
                      (p) => p.id === platform.id
                    )?.picture || "",
                  user_name:
                    this.workspaceData[configWid]?.find(
                      (p) => p.id === platform.id
                    )?.user_name || "Admin",
                  isSelected: !!matchedPlatform,
                  selectedProfiles: selectedProfiles || [],
                  wid: configWid || "",
                };
              });
              this.$set(this.configData, configId, updatedPlatforms);
              this.$set(this.generatedConfigKeys, configId, config_key);
            }
          }
        }
        console.log("details ", this.configData);
      } catch (error) {
        console.log("Error while retreiving connections ", error);
      }
    },
    async storeWorkspaceConnection() {
      try {
        if (this.workspaceNames?.length > 0) {
          for (let i = 0; i < this.workspaceNames.length; i++) {
            const connectedAppsCollection = collection(
              db,
              "users",
              this.currentUID,
              "fly_workspaces",
              this.workspaceNames[i].id,
              "connected-apps"
            );
            const connectedAppDocs = await getDocs(connectedAppsCollection);

            const connectedAppsMap = {};
            connectedAppDocs.forEach((appDoc) => {
              let childrenArray =
                appDoc.data()?.pages ||
                appDoc.data()?.profiles ||
                appDoc.data()?.boards ||
                appDoc.data()?.blogs ||
                (Array.isArray(appDoc.data()?.accounts) &&
                  appDoc.data().accounts[0]?.locations) ||
                appDoc.data()?.users ||
                [];

              // Special handling for LinkedIn to include user_id
              if (appDoc.id === "linkedin") {
                childrenArray = childrenArray.map((d) => ({
                  ...d,
                  user_id: appDoc.data().user_id,
                }));
              }
              if (appDoc.id === "youtube" && appDoc.data().channel) {
                childrenArray = this.ytCategories; // Adjust as needed
              }

              // Add connected app to connectedApps array
              connectedAppsMap[appDoc.id] = {
                id: appDoc.id,
                ...appDoc.data(),
                pages: childrenArray,
              };
            });
            // Update platforms with connected app data
            this.$set(
              this.workspaceData,
              this.workspaceNames[i].id,
              this.platforms.map((platform) => ({
                ...platform,
                isConnected: !!connectedAppsMap[platform.id],
                pages: connectedAppsMap[platform.id]?.pages || [],
                user_id:
                  connectedAppsMap[platform.id]?.user_id ||
                  connectedAppsMap[platform.id]?.name,
                picture: connectedAppsMap[platform.id]?.picture || "",
                user_name: connectedAppsMap[platform.id]?.name || "Admin",
                selectedProfiles: connectedAppsMap[platform.id]
                  ? connectedAppsMap[platform.id].selectedProfiles || []
                  : platform.selectedProfiles,
                wid: this.workspaceNames[i].id,
              }))
            );
            console.log("These are the workspace data", this.workspaceData);
          }
        }
      } catch (error) {
        console.log("Error Retrieving workspace config", error);
      }
    },
    async fetchExistingApiKey() {
      try {
        const userDocRef = doc(db, "users", this.currentUID);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists() && userDocSnap.data().api_key) {
          this.existingApiKey = userDocSnap.data().api_key;
          await this.fetchWorkspaces();
        }
      } catch (error) {
        console.error("Error fetching API key:", error);
      }
    },
    async generateApiKey() {
      this.clearErrors();

      if (this.existingApiKey || this.apiKeys.length > 0) {
        this.errors.submit = "You can only create one API key.";
        return;
      }

      if (!this.newKeyDescription) {
        this.errors.newKeyDescription = "Please provide a key description.";
        return;
      }
      this.isLoading = true;
      try {
        const generateKey = httpsCallable(functions, "generateAndStoreApiKey");
        const payload = {
          uid: this.currentUID, // Replace with the user's UID
          description: this.newKeyDescription,
        };
        const response = await generateKey(payload);

        console.log("this the response ", response);
        const { api } = response.data;

        if (api) {
          const newKey = {
            id: (this.apiKeys.length + 1).toString(),
            key: api, // Store encrypted key in the frontend
            description: this.newKeyDescription,
            createdAt: new Date().toISOString().split("T")[0],
            status: "active",
          };

          this.apiKeys.push(newKey);
          this.newKeyDescription = "";

          // New logic to iterate through flyworkspace documents
          await this.fetchWorkspaces();
          this.$snackbar.show("Api Key generated successfully.");
        }
      } catch (error) {
        console.error("Error generating API Key:", error);
        this.errors.submit = "Failed to generate API Key. Please try again.";
      } finally {
        this.isLoading = false; // Stop loading after completion
      }
    },
    async fetchWorkspaces() {
      const workspaces = [];
      const workspaceCollection = collection(
        db,
        "users",
        this.currentUID,
        "fly_workspaces"
      );
      const workspaceDocs = await getDocs(workspaceCollection);

      workspaceDocs.forEach((doc) => {
        if (doc.data().storeWorkspace) {
          console.log("Skipping this workspace", doc.id);
        } else {
          const connectedApps = doc.data().connected_apps || [];
          if (connectedApps.length > 0) {
            workspaces.push({ id: doc.id, ...doc.data() });
          }
        }
      });
      this.workspaceNames = workspaces;
      await this.storeWorkspaceConnection();
      await this.getConfigkeyDetails();
    },
    toggleShowApiKey() {
      this.showApiKey = !this.showApiKey;
    },
    toggleShowConfigKey(configId) {
      this.$set(this.showConfigKey, configId, !this.showConfigKey[configId]);
    },

    // Copy config key to clipboard
    copyConfigKey(configId) {
      const key = this.generatedConfigKeys[configId];
      if (key) {
        navigator.clipboard
          .writeText(key)
          .then(() => {
            alert("Configuration Key copied to clipboard!");
          })
          .catch(() => {
            alert("Failed to copy Configuration Key.");
          });
      }
    },
    maskedKey(key) {
      return key ? key.slice(0, 4) + "********" + key.slice(-4) : "";
    },
    copyApiKey() {
      const apiKey = this.existingApiKey || this.apiKeys[0]?.key;
      if (apiKey) {
        navigator.clipboard
          .writeText(apiKey)
          .then(() => {
            this.copySuccessMessage = "API Key copied to clipboard!";
            setTimeout(() => {
              this.copySuccessMessage = ""; // Hide message after 3 seconds
            }, 3000);
          })
          .catch(() => {
            this.copySuccessMessage = "Failed to copy API Key.";
          });
      }
    },
    clearErrors() {
      this.errors.newKeyDescription = "";
      this.errors.apiKey = "";
      this.errors.submit = "";
    },
    async reGenerateApiKey() {
      this.clearErrors();
      this.isLoading = true;

      try {
        const regenerateKey = httpsCallable(functions, "reGenerateAndStoreApiKey");
        const payload = {
          uid: this.currentUID,
        };

        const response = await regenerateKey(payload);

        if (response.data.api) {
          this.existingApiKey = response.data.api;
          this.apiKeys = [{ key: response.data.api }];
          this.$snackbar.show("API Key regenerated successfully.");
        } else {
          throw new Error("Failed to regenerate API Key.");
        }
      } catch (error) {
        console.error("Error regenerating API Key:", error);
        this.errors.submit = "Failed to regenerate API Key. Please try again.";
      } finally {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    await this.fetchExistingApiKey();
  },
};
</script>

<style scoped>
.api-key-page {
  max-width: 800px;
  margin: 5px auto;
  padding: 20px;
}
.submit-button {
  margin-top: 1px;
  cursor: pointer;
}
.submit-button:disabled {
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed !important;
}
.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  display: none; /* Hide by default */
}
.success-message {
  color: rgb(84, 84, 241);
  font-size: 14px;
  margin-left: 4px;
}

.error-message.visible {
  display: block; /* Show when visible */
}
.workspace-title {
  cursor: pointer;
  font-weight: bold;
}

.platform-list {
  margin-left: 20px;
}

.platform-item {
  padding-left: 15px;
}

.page-list {
  margin-left: 40px;
}

.v-container {
  padding: 0;
}

.v-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.selected-items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.chip {
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 5px 10px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.chip v-icon {
  cursor: pointer;
  margin-left: 5px;
}
</style>
