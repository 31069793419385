<template>
  <div class="flex items-center justify-center min-h-screen">
    <div v-if="isLoading" class="text-center">
      <div
        class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"
      ></div>
      <p class="mt-4 text-gray-600">Processing login...</p>
    </div>
  </div>
</template>

<script>
import { auth } from "@/utils/firebase.utils";
import { signInWithCustomToken } from "firebase/auth";

export default {
  data() {
    return {
      isLoading: true,
      isProcessing: false,
      hasAttemptedLogin: false,
      error: null,
      redirectPath: null,
    };
  },
  async mounted() {
    if (this.isProcessing || this.hasAttemptedLogin) return;
    this.isProcessing = true;
    this.hasAttemptedLogin = true;

    try {
      this.$loader.show();

      // Validate token exists and format
      const token = this.$route.query.token;
      if (!token) {
        throw new Error("No token provided");
      }

      // Validate token format (basic JWT format check)
      if (
        !/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/.test(token)
      ) {
        throw new Error("Invalid token format");
      }

      // Get redirect path before authentication
      this.redirectPath = sessionStorage.getItem("redirectPath") || "/";

      // Clear the redirect path from sessionStorage immediately
      sessionStorage.removeItem("redirectPath");

      // Wait for the sign-in operation to complete
      const userCredential = await signInWithCustomToken(auth, token);

      if (!userCredential?.user) {
        throw new Error("Failed to sign in with custom token");
      }

      // Wait a brief moment to ensure auth state is updated
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Navigate to the redirect path or root
      if (this.redirectPath && this.redirectPath !== "/") {
        try {
          await this.$router.replace(this.redirectPath);
        } catch (err) {
          if (err.name !== "NavigationDuplicated") {
            console.error("Navigation error:", err);
            await this.$router.replace("/");
          }
        }
      } else {
        await this.$router.replace("/");
      }
    } catch (error) {
      console.error("Custom token login error:", error);
      this.error = error;
      this.handleError(error);

      // Only redirect to login if we're not already there
      if (this.$route.path !== "/login") {
        try {
          await this.$router.push("/login");
        } catch (err) {
          if (err.name !== "NavigationDuplicated") {
            console.error("Login redirect error:", err);
            await this.$router.replace("/");
          }
        }
      }
    } finally {
      this.isLoading = false;
      this.isProcessing = false;
      this.$loader.hide();
    }
  },
  methods: {
    handleError(error) {
      // Handle specific error cases
      if (error.code === "auth/invalid-custom-token") {
        this.$toast.error("Invalid login token. Please try again.");
      } else if (error.code === "auth/custom-token-mismatch") {
        this.$toast.error("Login token mismatch. Please try again.");
      } else if (error.code === "auth/user-disabled") {
        this.$toast.error(
          "This account has been disabled. Please contact support."
        );
      } else if (error.code === "auth/user-not-found") {
        this.$toast.error("User not found. Please try again.");
      } else if (error.code === "auth/network-request-failed") {
        this.$toast.error(
          "Network error. Please check your connection and try again."
        );
      } else {
        this.$toast.error(error.message || "Login failed. Please try again.");
      }
    },
  },
};
</script>

<style></style>
