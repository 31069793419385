<template>
  <div class="flex-1">
    <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
      {{ metricName }}
      <tool-tip :message="tooltipMessage" class="tooltip-container">
        <v-icon small>mdi-information</v-icon>
      </tool-tip>
    </p>
    <div class="text-lg font-bold whitespace-nowrap">
      <div
        class="flex gap-2 text-2xl font-bold whitespace-nowrap items-center"
        v-if="!isLoading && metricsData?.length > 0"
      >
        <span v-if="typeof metricData === 'number' && !isNaN(metricData)">
          {{ metricData }}
        </span>
        <span v-else-if="isNaN(metricData)"> -- </span>
        <v-progress-circular
          v-if="
            previousMonthAggregatedDataLoading && selectedDate?.months !== 12
          "
          indeterminate
          color="primary"
          :size="16"
          :width="3"
        ></v-progress-circular>
        <span
          v-if="
            !previousMonthAggregatedDataLoading &&
            metricChange &&
            selectedDate?.months !== 12 &&
            typeof metricChange === 'number' &&
            !isNaN(metricChange)
          "
          class="text-sm font-semibold"
          :class="{
            'text-green-500': metricChange >= 0,
            'text-red-500': metricChange < 0,
          }"
        >
          <v-icon small :color="metricChange >= 0 ? 'green' : 'red'">{{
            metricChange == 0
              ? "mdi-minus"
              : metricChange > 0
              ? "mdi-arrow-up"
              : "mdi-arrow-down"
          }}</v-icon>
          {{ metricChange }}%
        </span>
      </div>
      <span v-if="isLoading" class="skeleton-loader"></span>
      <span
        v-else-if="
          !isLoading &&
          !aggregatedData &&
          !(metricsData?.length > 0 || chartData?.length > 0)
        "
        >--</span
      >
    </div>
    <div v-if="months != 12 && !isLoading"><p class="text-xs text-gray-500 font-medium">{{months == 1 ? 'vs Last Month' : `vs Last ${months} Months`}}</p></div>
  </div>
</template>

<script>
import ToolTip from "@/components/ui/ToolTip.vue";

export default {
  components: { ToolTip },
  props: {
    metricName: String,
    tooltipMessage: String,
    aggregatedData: Number,
    metricKey: String,
    isLoading: Boolean,
    previousMonthAggregatedDataLoading: Boolean,
    previousMonthDataFetched: Boolean,
    selectedDate: Object,
    metricsData: Array,
    chartData: Array,
    metricData: Number,
    metricChange: Number,
    months: Number,
  },
  methods: {
    getTotalMetric(key) {
      // Implement this method to return the total metric for the given key
    },
    getMetricChange(key) {
      // Implement this method to return the metric change for the given key
    },
  },
};
</script>

<style scoped>
.skeleton-loader {
  display: inline-block;
  width: 50px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite;
}
/* Add any specific styles for the component here */
</style>
