<template>
    <v-menu offset-y v-model="menu" rounded="3xl">
        <template v-slot:activator="{ on }">
            <v-btn depressed outlined color="#8056DE" v-on="on">

                Special Posts
                <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
        </template>
        <v-list class="py-4 flex flex-col p-1 md:p-4 gap-2" width="330px">
            <div class="text-neutral-900 text-base font-medium font-['Poppins']">Car listing Post</div>

            <v-list-item class="w-[293px] h-12 bg-slate-100 rounded-3xl cursor-pointer"
                @click="$router.push('/create-car-listings')"> <img src="@/assets/img/car-list.svg" width="20px"
                    class="mr-2" />Car
                Listing</v-list-item>
            <v-list-item class="w-[293px] h-12 bg-slate-100 rounded-3xl cursor-pointer"
                @click="$router.push('/create-car-sell')"> <img
                    src="https://img.icons8.com/ios-filled/50/7950F2/car-sale.png" width="20px" class="mr-2" /> Car Sell
                Listing</v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    data() {
        return {
            menu: false,
        };
    },
};
</script>

<style></style>