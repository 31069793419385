<template>
  <div class="bg-[#F2F6FA] h-full">
    <div class="flex flex-col items-center justify-center w-full pb-20 ">

      <section class="flex flex-col items-center w-full justify-center xl:w-[1285px]">
        <div class="flex items-center justify-between md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]">
          <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
            <img src="@/assets/img/sidebar/pr_g.png" class="cursor-pointer w-6 md:w-[28px]" alt="post" />
            <div class="flex items-center gap-1">
              <p class="text-base md:text-[22px] font-bold">Profiles Manager</p>
            </div>
          </div>
        </div>
        <div class=" min-h-screen w-full overflow-x-hidden rounded-2xl mt-5">
          <v-tabs v-model="activeTab">
            <v-tab value="profiles">All Profiles</v-tab>
            <v-tab value="bulk">Bulk Updates</v-tab>
            <v-tab value="history">Update History</v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab" class="rounded-b-2xl">
            <v-tab-item :value="0" class="mt-5">
              <GMBAllProfiles :categories="categories" :locations="locations" :categoriesId="categoriesId" :fetch-workspace-data="fetchWorkspaceData"/>

            </v-tab-item>
            <!-- Bulk Updates Tab -->
            <v-tab-item :value="1">
              <GMBBulkUpdate :locations="locations" :categories="categories" :categoriesId="categoriesId" :fetch-workspace-data="fetchWorkspaceData"/>
            </v-tab-item>

            <!-- Update History Tab -->
            <v-tab-item :value="2">
              <GMBProfileHistory />
            </v-tab-item>
          </v-tabs-items>
          <div class="container mx-auto w-full px-4 md:px-8 lg:px-12 xl:px-16 max-w-[1285px]">
            <!-- Header -->

            <!-- Add this button section below the header -->
            <!-- Note -->

            <!-- Add this note section after the existing note -->
            <!-- <div class="mb-6 px-4">
              <div v-if="!checkUpdateEligibility(gmb_bulk_last_updated).canUpdate"
                class="flex items-center text-gray-800 bg-yellow-50 p-4 rounded-md border-l-4 border-yellow-500 shadow-sm">
                <v-icon color="warning" class="mr-3">mdi-clock-alert</v-icon>
                <span class="font-medium">
                  Bulk updates will be available in {{ checkUpdateEligibility(gmb_bulk_last_updated).remainingTime }}
                  hours
                </span>
              </div>
            </div> -->

          </div>
        </div>
      </section>
    </div>
  </div>

</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { auth, functions, storage } from '@/utils/firebase.utils';
import GMBProfileHistory from './gmb_profile_components/GMBProfileHistory.vue';
import GMBBulkUpdate from './gmb_profile_components/GMBBulkUpdate.vue';
import GMBAllProfiles from './gmb_profile_components/GMBAllProfiles.vue';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '@/utils/firebase.utils'; // Ensure you have a Firestore instance

export default {
  components: {
    GMBProfileHistory,
    GMBBulkUpdate,
    GMBAllProfiles
  },
  data() {
    return {
      loading: false,
      locations: [],
      categories: [],
      categoriesId: [],
      activeTab: 0,
      weekDays: [
        { label: "Sunday", value: "SUNDAY" },
        { label: "Monday", value: "MONDAY" },
        { label: "Tuesday", value: "TUESDAY" },
        { label: "Wednesday", value: "WEDNESDAY" },
        { label: "Thursday", value: "THURSDAY" },
        { label: "Friday", value: "FRIDAY" },
        { label: "Saturday", value: "SATURDAY" }
      ],
      gmb_bulk_last_updated: null,
      selectedWIDs: [],
    };
  },

  async created() {
    await this.fetchLastUpdatedAndRefreshed();
    await this.fetchWorkspaceData();
    await this.fetchGMBCategories();
  },

  methods: {
    formatTime(time) {
      if (!time) return '';
      if (Object.keys(time).length == 0) {
        time = { hours: 0, minutes: 0 };
      }
      return `${String(time.hours).padStart(2, '0')}:${String(time.minutes || 0).padStart(2, '0')}`;
    },
    
    async fetchWorkspaceData(workspaceID) {
      try {
        if (workspaceID) {
          console.log(workspaceID, "updating for a specific workspace");
          const workspaceDoc = doc(db, "users", this.currentUID, "fly_workspaces", workspaceID);

          try {
            const snapshot = await getDoc(workspaceDoc);

            if (snapshot.exists()) {
              const updatedLocation = snapshot.data()?.gmbProfile;

              // Skip if gmbProfile is not present
              if (!updatedLocation) {
                console.log(`No gmbProfile found for workspace ID ${workspaceID}, skipping.`);
                return;
              }

              // Update the specific location in this.locations
              const index = this.locations.findIndex(location => location.workspaceId === workspaceID);
              if (index !== -1) {

                this.$set(this.locations, index, {
                  ...this.locations[index],
                  ...updatedLocation,
                  nameEditable: false,
                  categoryEditable: false,
                  primaryCategorySearchQuery: '',
                  gmbLastFetchedAt: snapshot.data()?.gmbLastFetchedAt,
                  selectedPrimaryCategory: null,
                  formData: {
                    businessName: updatedLocation?.title || '',
                    primaryPhone: this.formatFetchedPhoneNumber(updatedLocation?.phoneNumbers?.primaryPhone || ''),
                    primaryCategory: updatedLocation?.categories?.primaryCategory || {},
                    websiteUri: updatedLocation?.websiteUri || '',
                    description: updatedLocation?.profile?.description || '',
                    additionalCategories: (updatedLocation?.categories?.additionalCategories || []).map((cat) => ({
                      ...cat,
                      isOriginal: true, // Mark original categories
                    })),
                    regularHours: this.weekDays.reduce((acc, day) => {
                      acc[day.value] = {
                        isOpen: false,
                        open: '09:00',
                        close: '17:00',
                        openMenu: false,
                        closeMenu: false
                      };
                      if (updatedLocation?.regularHours?.periods) {
                        const matchingPeriod = updatedLocation.regularHours.periods.find(
                          (period) => period.openDay === day.value && period.closeDay === day.value
                        );

                        if (matchingPeriod) {
                          acc[day.value].isOpen = true;
                          acc[day.value].open = this.formatTime(matchingPeriod.openTime);
                          acc[day.value].close = this.formatTime(matchingPeriod.closeTime);
                        }
                      }
                      return acc;
                    }, {}),
                    originalRegularHours: this.weekDays.reduce((acc, day) => {
                      acc[day.value] = {
                        isOpen: false,
                        open: '09:00',
                        close: '17:00',
                        openMenu: false,
                        closeMenu: false
                      };
                      if (updatedLocation?.regularHours?.periods) {
                        const matchingPeriod = updatedLocation.regularHours.periods.find(
                          (period) => period.openDay === day.value && period.closeDay === day.value
                        );

                        if (matchingPeriod) {
                          acc[day.value].isOpen = true;
                          acc[day.value].open = this.formatTime(matchingPeriod.openTime);
                          acc[day.value].close = this.formatTime(matchingPeriod.closeTime);
                        }
                      }
                      return acc;
                    }, {}),
                  },
                });
              }
            }
          } catch (error) {
            console.error(`Error fetching workspace document for ID ${workspaceID}:`, error);
          }
        } else {
          this.loading = true;
          this.selectedWIDs = [];
          try {
            let collectionRef = query(
              collection(db, "users", this.currentUID, "fly_workspaces"),
              where("storeWorkspace", "==", true),
              where("admin", "==", this.WID)
            );

            let snapshot = await getDocs(collectionRef);

            // this.totalPages = Math.ceil(snapshot.docs.length / this.itemsPerPage);
            collectionRef = query(
              collection(db, "users", this.currentUID, "fly_workspaces"),
              where("storeWorkspace", "==", true),
              where("admin", "==", this.WID)
            );

            snapshot = await getDocs(collectionRef);

            // Fetch all members
            const locations = await Promise.all(
              snapshot.docs.map(async (docSnapshot) => {
                try {
                  let location = docSnapshot.data()?.gmbProfile;

                  // Skip if gmbProfile is not present
                  if (!location) {
                    console.log(`No gmbProfile found for document ID ${docSnapshot?.id}, skipping.`);
                    return null;
                  }

                  const profileData = {
                    ...location,
                    sub_locality: docSnapshot.data()?.sub_locality || null,
                    city: docSnapshot.data()?.city || null,
                    state: docSnapshot.data()?.state || null,
                    gmbLastFetchedAt: docSnapshot.data()?.gmbLastFetchedAt,
                    workspaceId: docSnapshot?.id,
                    wid: docSnapshot?.id,
                    storeLocation: docSnapshot.data()?.storeLocation || "",
                    formData: {
                      businessName: location?.title || '',
                      primaryPhone: this.formatFetchedPhoneNumber(location?.phoneNumbers?.primaryPhone || ''),
                      primaryCategory: location?.categories?.primaryCategory || {},
                      websiteUri: location?.websiteUri || '',
                      description: location?.profile?.description,
                      additionalCategories: (location?.categories?.additionalCategories || []).map((cat) => ({
                        ...cat,
                        isOriginal: true, // Mark original categories
                      })),
                      regularHours: this.weekDays.reduce((acc, day) => {
                        acc[day.value] = {
                          isOpen: false,
                          open: '09:00',
                          close: '17:00',
                          openMenu: false,
                          closeMenu: false
                        };
                        if (location?.regularHours?.periods) {
                          const matchingPeriod = location.regularHours.periods.find(
                            (period) => period.openDay === day.value && period.closeDay === day.value
                          );

                          if (matchingPeriod) {
                            acc[day.value].isOpen = true;
                            acc[day.value].open = this.formatTime(matchingPeriod.openTime);
                            acc[day.value].close = this.formatTime(matchingPeriod.closeTime);
                          }
                        }
                        return acc;
                      }, {}),
                      originalRegularHours: this.weekDays.reduce((acc, day) => {
                        acc[day.value] = {
                          isOpen: false,
                          open: '09:00',
                          close: '17:00',
                          openMenu: false,
                          closeMenu: false
                        };
                        if (location?.regularHours?.periods) {
                          const matchingPeriod = location.regularHours.periods.find(
                            (period) => period.openDay === day.value && period.closeDay === day.value
                          );

                          if (matchingPeriod) {
                            acc[day.value].isOpen = true;
                            acc[day.value].open = this.formatTime(matchingPeriod.openTime);
                            acc[day.value].close = this.formatTime(matchingPeriod.closeTime);
                          }
                        }
                        return acc;
                      }, {}),
                    },
                    gmb_last_updated: docSnapshot.data()?.gmb_last_updated || null,
                    keywords: docSnapshot.data()?.gmbKeywords || [],
                    currentKeyword: '',
                    showHours: false,
                    checked: true,
                    shallowConnect:docSnapshot.data()?.shallowConnect||false,
                    updating: false,
                    nameEditable: false,
                    websiteUriEditable: false,
                    descriptionEditable: false,
                    categoryEditable: false,
                    generatingDescription: false,
                    applyingDescription: false,
                    needs_gmb_reauth:docSnapshot.data()?.needs_gmb_reauth||false,
                    newDescription: null,
                    selectedAdditionalCategory: null,
                    selectedPrimaryCategory: null,
                    additionalCategorySearchQuery: '',
                    primaryCategorySearchQuery: '',
                    suggestingCategories: false,
                  };
                  this.selectedWIDs.push(docSnapshot?.id);
                  return profileData; // Return the member data
                } catch (error) {
                  console.error(`Error processing document ${docSnapshot?.id}:`, error);
                  return null; // Skip on error
                }
              })
            );

            // Filter out null values from locations
            this.locations = locations.filter(location => location !== null);
          } catch (error) {
            console.error("Error fetching collection:", error);
          } finally {
            this.loading = false;
          }
        }
      } catch (error) {
        console.error("Error in fetchWorkspaceData function:", error);
      }
    },

    async fetchGMBCategories() {
      try {
        const gmbCategories = await fetch("https://firebasestorage.googleapis.com/v0/b/around-dev-1.appspot.com/o/gmb-categories%2Fgmb-categories.json?alt=media&token=cccd1123-19ac-4d68-a46a-56da6a0e481d")
        this.categories = await gmbCategories.json();
        this.categoriesId = this.categories.map((item) => item.name);
      } catch (error) {
        console.error('Error fetching GMB data:', error);
        this.$alert.show('Error fetching GMB data');
      }
    },
    
    async fetchLastUpdatedAndRefreshed() {
      const workspaceDoc = doc(db, `users/${this.currentUID}/fly_workspaces/${this.WID}`);
      const workspaceSnapshot = await getDoc(workspaceDoc);
      if (workspaceSnapshot.exists()) {
        this.gmb_bulk_last_updated = workspaceSnapshot.data().gmb_bulk_last_updated;
      }
    },
    formatFetchedPhoneNumber(phone) {
      // Remove any existing +91, leading zeros, or spaces
      const cleanNumber = phone?.replace(/^\+91|^91|^0+|\s+/g, '').trim();
      // Add +91 prefix if not empty
      return cleanNumber ? `+91${cleanNumber}` : '';
    },

  }
};
</script>