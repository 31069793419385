<template>
  <div
    class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]"
  >
    <section
      class="flex flex-col items-center w-full justify-center xl:w-[1285px]"
    >
      <!-- <div class="flex items-center justify-start md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]">
        <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
          <img src="@/assets/img/global-icons/dashboard.svg" class="cursor-pointer w-6 md:w-[28px]" alt="post" />
          <div class="flex items-center gap-1">
            <p class="text-base md:text-[22px] font-bold">Reviews Dashboard</p>
            </div>
        </div>
      </div> -->
      <!-- ... your top sections omitted for brevity ... -->

      <!-- <div class="w-full mx-auto"> -->
      <!-- <div class="bg-white rounded-lg shadow-sm p-4 md:p-6 mb-3 min-h-[80vh]"> -->
      <!-- <div
    class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]"
  > -->
      <!-- <section
      class="flex flex-col items-center w-full justify-center xl:w-[1285px]"
    > -->
      <div class="w-full bg-white rounded-lg shadow-md">
        <div class="w-full xl:w-[100%] flex justify-center items-center gap-4">
          <div class="w-full bg-white">
            <div class="flex flex-col justify-between w-full px-5 py-2 mx-auto">
              <div class="flex w-full items-center justify-between">
                <div class="justify-start flex flex-row items-center gap-2">
                  <div class="location-dropdown relative">
                    <div class="flex gap-4 items-center">
                      <v-btn
                        @click="toggleStoreDropdown($event)"
                        outlined
                        color="primary"
                      >
                        {{ selectedWIDs?.length || 0 }} Selected Locations
                      </v-btn>
                      <v-progress-circular
                        v-if="isLoading"
                        indeterminate
                        color="primary"
                        size="20"
                      ></v-progress-circular>
                    </div>
                    <LocationDropdown
                      class="custom-dropdown"
                      ref="locationDropdown"
                      :stores="filteredStores"
                      :showStoreDropdown="showStoreDropdown"
                      v-model="selectedWIDs"
                      @change="handleLocationChange"
                    />
                  </div>
                </div>

                <!-- TIME RANGE FILTER -->
                <div class="justify-end flex flex-row items-center gap-2 mt-5">
                  <div class="star-rating-selection bg-white rounded-lg">
                    <v-select
                      v-model="selectedTimeRange"
                      :items="timeRangeItems"
                      @change="handleTimeRangeChange"
                      label="Filter by Time Range"
                      dense
                      outlined
                      class="w-[150px]"
                    >
                      <template v-slot:selection="{ item }">
                        <span class="flex items-center">
                          {{ item.text }}
                        </span>
                      </template>
                      <template v-slot:item="{ item }">
                        <span class="flex items-center">
                          {{ item.text }}
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </section> -->

        <!-- If no locations selected -->
        <div
          v-if="selectedWIDs.length === 0"
          class="flex flex-col items-center justify-center h-80"
        >
          <v-icon size="64" color="grey lighten-1"
            >mdi-alert-circle-outline</v-icon
          >
          <p class="text-gray-500 mt-2 text-lg">No data available</p>
        </div>

        <!-- If locations are selected, show dash data -->
        <div
          v-else
          class="grid grid-cols-1 md:grid-cols-1 gap-6 w-full px-6 mt-4"
        >
          <!-- First Box (Ratings) -->
          <div class="bg-white rounded-lg shadow-md p-6 w-full">
            <div class="flex justify-between items-center">
              <div class="flex items-center gap-2">
                <h2 class="text-lg font-semibold">Rating</h2>
                <!-- Tooltip Wrapper -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon size="18" color="grey" v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>Breakdown of Reviews</span>
                </v-tooltip>
                <p class="text-md font-bold" style="color: rgb(107, 106, 106);">
                  Total Reviews: {{ totalReviews }}
                </p>
              </div>
            </div>
            <!-- Grey Break Line -->
            <hr class="border-gray-300 my-3" />
            <p class="text-3xl font-bold mt-2">
              {{ averageRating.toFixed(2) }} ⭐
            </p>

            <!-- Star breakdown bars -->
            <div class="mt-4">
              <div
                v-for="star in [5, 4, 3, 2, 1]"
                :key="star"
                class="flex items-center mb-1"
              >
                <span class="w-10">{{ star }} ⭐</span>
                <div class="w-full bg-gray-200 rounded h-2">
                  <div
                    class="bg-yellow-500 h-2 rounded"
                    :style="{ width: getStarPercentage(star) + '%' }"
                  ></div>
                </div>
                <span class="ml-2 text-gray-600">
                  {{ getStarPercentage(star) }}%
                </span>
              </div>
            </div>

            <!-- Positive & Negative Counts -->
            <div class="flex justify-center items-center mt-6 space-x-8">
                <span class="w-3 h-3 bg-green-500 rounded-full"></span>
                <p class="text-green-600 font-semibold">
                  Positive: {{ positiveCount }}
                </p>
                <span class="w-3 h-3 bg-red-500 rounded-full"></span>
                <p class="text-red-600 font-semibold">
                  Negative: {{ negativeCount }}
                </p>
            </div>
          </div>
        </div>

        <!-- New Boxes Section -->
        <div
          v-if="selectedWIDs.length"
          class="grid grid-cols-1 md:grid-cols-2 gap-6 w-full px-6 mt-8"
        >
          <!-- Box 1: Text vs No Text -->
          <div class="bg-white rounded-lg shadow-md p-6 w-full">
            <div class="flex justify-between items-center">
              <div class="flex items-center gap-2">
                <h2 class="text-lg font-semibold">Text vs No Text</h2>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon size="18" color="grey" v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>Comparison of reviews with and without text.</span>
                </v-tooltip>
              </div>
            </div>
            <hr class="border-gray-300 my-3" />
            <!-- Circular Chart (Optional, or any style you want) -->
            <div class="flex justify-center">
              <svg width="80" height="80" viewBox="0 0 36 36">
                <circle
                  cx="18"
                  cy="18"
                  r="15.9155"
                  fill="transparent"
                  stroke="#E5E7EB"
                  stroke-width="3"
                ></circle>
                <!-- 'textPercentage' drawn here -->
                <circle
                  cx="18"
                  cy="18"
                  r="15.9155"
                  fill="transparent"
                  stroke="#3B82F6"
                  stroke-width="3"
                  :stroke-dasharray="`${textPercentage} ${
                    100 - textPercentage
                  }`"
                  stroke-linecap="round"
                  transform="rotate(-90 18 18)"
                ></circle>
              </svg>
            </div>

            <!-- Labels -->
            <div class="mt-4 flex flex-col gap-2">
              <div class="flex items-center justify-between w-full">
                <div class="flex items-center gap-2 w-1/3">
                  <span class="w-3 h-3 bg-blue-500 rounded-full"></span>
                  <p class="text-gray-600">Text</p>
                </div>
                <div class="flex items-center justify-center w-1/3">
                  <p class="text-blue-600 font-semibold">
                    {{ textPercentage }}%
                  </p>
                </div>
                <p class="text-gray-500 text-right w-1/3">
                  {{ textReviews }} Reviews
                </p>
              </div>
              <div class="flex items-center justify-between w-full">
                <div class="flex items-center gap-2 w-1/3">
                  <span class="w-3 h-3 bg-gray-200 rounded-full"></span>
                  <p class="text-gray-600">No Text</p>
                </div>
                <div class="flex items-center justify-center w-1/3">
                  <p class="text-grey-400 font-semibold">
                    {{ noTextPercentage }}%
                  </p>
                </div>
                <p class="text-gray-500 text-right w-1/3">
                  {{ noTextReviews }} Reviews
                </p>
              </div>
            </div>
          </div>

          <!-- Box 2: Replied vs Not Replied -->
          <div class="bg-white rounded-lg shadow-md p-6 w-full">
            <div class="flex justify-between items-center">
              <div class="flex items-center gap-2">
                <h2 class="text-lg font-semibold">Replied vs Not Replied</h2>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon size="18" color="grey" v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>Comparison of replied vs unreplied reviews.</span>
                </v-tooltip>
              </div>
            </div>
            <hr class="border-gray-300 my-3" />
            <div class="flex justify-center">
              <svg width="80" height="80" viewBox="0 0 36 36">
                <circle
                  cx="18"
                  cy="18"
                  r="15.9155"
                  fill="transparent"
                  stroke="#E5E7EB"
                  stroke-width="3"
                ></circle>
                <!-- 'repliedPercentage' drawn here -->
                <circle
                  cx="18"
                  cy="18"
                  r="15.9155"
                  fill="transparent"
                  stroke="#33FF33"
                  stroke-width="3"
                  :stroke-dasharray="`${repliedPercentage} ${
                    100 - repliedPercentage
                  }`"
                  stroke-linecap="round"
                  transform="rotate(-90 18 18)"
                ></circle>
              </svg>
            </div>
            <div class="mt-4 flex flex-col gap-2">
              <div class="flex items-center justify-between w-full">
                <div class="flex items-center gap-2 w-1/3">
                  <span class="w-3 h-3 bg-green-500 rounded-full"></span>
                  <p class="text-gray-600">Replied</p>
                </div>
                <div class="flex items-center justify-center w-1/3">
                  <p class="text-green-600 font-semibold">
                    {{ repliedPercentage }}%
                  </p>
                </div>
                <p class="text-gray-500 text-right w-1/3">
                  {{ repliedReviews }} Reviews
                </p>
              </div>
              <div class="flex items-center justify-between w-full">
                <div class="flex items-center gap-2 w-1/3">
                  <span class="w-3 h-3 bg-gray-200 rounded-full"></span>
                  <p class="text-gray-600">Not Replied</p>
                </div>
                <div class="flex items-center justify-center w-1/3">
                  <p class="text-grey-400 font-semibold">
                    {{ notRepliedPercentage }}%
                  </p>
                </div>
                <p class="text-gray-500 text-right w-1/3">
                  {{ notRepliedReviews }} Reviews
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Monthly Bar Chart Section -->
        <div
          v-if="selectedWIDs.length"
          class="bg-white rounded-lg shadow-md p-6 w-full mt-8"
        >
          <div class="flex justify-between items-center">
            <div class="flex items-center gap-2">
              <h2 class="text-lg font-semibold">
                Monthly Reviews & Rating Analysis
              </h2>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="18" color="grey" v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>Shows the monthly review count and average rating</span>
              </v-tooltip>
            </div>
          </div>
          <hr class="border-gray-300 my-3" />
          <!-- Add a fixed-height scrollable container for the chart -->
          <div style="height: 400px; position: relative">
            <canvas ref="barChartCanvas"></canvas>
          </div>

          <!-- Add manual horizontal scroller -->
          <div class="chart-scroller mt-3">
            <div class="flex items-center justify-between">
              <button class="scroll-btn mr-2" @click="scrollChart('left')">
                <v-icon>mdi-chevron-left</v-icon>
              </button>
              <div
                class="relative w-full h-2 bg-gray-200 rounded overflow-hidden"
              >
                <div
                  class="scroll-track w-full h-full"
                  ref="scrollTrack"
                  @mousedown="startDrag"
                  @touchstart="startDrag"
                >
                  <div
                    class="scroll-thumb bg-primary h-full rounded"
                    :style="{
                      width: thumbWidth + '%',
                      left: thumbPosition + '%',
                    }"
                    ref="scrollThumb"
                  ></div>
                </div>
              </div>
              <button class="scroll-btn ml-2" @click="scrollChart('right')">
                <v-icon>mdi-chevron-right</v-icon>
              </button>
            </div>
          </div>

          <!-- Legend Section (optional) -->
          <div class="flex justify-center items-center gap-4 mt-4">
              <span class="w-4 h-4 bg-green-600 rounded-full"></span>
              <p class="text-gray-600 text-sm">5 Star</p>
              <span class="w-4 h-4 bg-green-400 rounded-full"></span>
              <p class="text-gray-600 text-sm">4 Star</p>
              <span class="w-4 h-4 bg-yellow-500 rounded-full"></span>
              <p class="text-gray-600 text-sm">3 Star</p>
              <span class="w-4 h-4 bg-orange-500 rounded-full"></span>
              <p class="text-gray-600 text-sm">2 Star</p>
              <span class="w-4 h-4 bg-[#fc164c] rounded-full"></span>
              <p class="text-gray-600 text-sm">1 Star</p>
              <span class="w-4 h-4 bg-[#f34409] rounded-full"></span>
              <p class="text-gray-600 text-sm">0 Star</p>

          </div>
        </div>
      </div>

      <!-- The rest of your template logic ... -->
      <!-- </div> -->
      <!-- </div> -->
      <!-- </div> -->
    </section>
  </div>
</template>

<script>
import {
  collection,
  onSnapshot,
  query,
  where,
  getDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "@/utils/firebase.utils";
import LocationDropdown from "@/components/LocationDropdown.vue";
import { Chart, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
Chart.register(...registerables, zoomPlugin);

export default {
  components: {
    LocationDropdown,
  },
  data() {
    return {
      stores: [],
      showStoreDropdown: false,
      selectedWIDs: [],

      // Dashboard data arrays:
      monthlyBuckets: [], // from API
      presentMonthData: {}, // from API, if you want to use it

      // Aggregated UI fields:
      totalReviews: 0,
      averageRating: 0,
      ratingBreakdown: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },

      positiveCount: 0,
      negativeCount: 0,

      // For text vs no text:
      textReviews: 0,
      noTextReviews: 0,
      textPercentage: 0,
      noTextPercentage: 0,

      // For replied vs not replied:
      repliedReviews: 0,
      notRepliedReviews: 0,
      repliedPercentage: 0,
      notRepliedPercentage: 0,

      // Time range filter v-select
      selectedTimeRange: null, // e.g. 'ONE', 'TWO', 'THREE', or null for "All Time"
      timeRangeItems: [
        { text: "All Time", value: null },
        { text: "Last 1 Month", value: "ONE" },
        { text: "Last 6 Months", value: "TWO" },
        { text: "Last 12 Months", value: "THREE" },
      ],

      // For chart scrolling
      thumbWidth: 30, // percentage width of the scroll thumb
      thumbPosition: 0, // percentage position of the scroll thumb
      isDragging: false,
      startX: 0,
      scrollStartPosition: 0,
      visibleMonths: 12, // Number of months to show at once

      // Chart stuff
      chartInstance: null,
      isLoading: false,
    };
  },
  computed: {
    filteredStores() {
      // Return all stores or filter as you prefer
      return this.stores;
    },
  },
  mounted() {
    // Load initial store data
    this.fetchStores();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("mousemove", this.doDrag);
    document.removeEventListener("mouseup", this.stopDrag);
    document.removeEventListener("touchmove", this.doDrag);
    document.removeEventListener("touchend", this.stopDrag);
  },
  methods: {
    // 1) Whenever you check/uncheck a location in the dropdown:
    async handleLocationChange(profile) {
      this.isLoading = true;
      // You might store the selected WIDs in your store objects
      // once you confirm them, call fetchReviewsAnalyticsForAllLocations
      try {
        this.selectedWIDs = this.stores
          .filter((item) => item.checked) // Get checked items
          .map((item) => item.id); // Extract their IDs

        // If selectedWIDs is empty, push all item IDs
        if (this.selectedWIDs.length === 0) {
          this.selectedWIDs = this.stores.map((item) => item.id);
        }

        this.selectedWID = profile.wid;
        if (this.selectedWIDs.length > 0) {
          await this.fetchReviewsAnalyticsForAllLocations();
        } else {
          // reset everything if no locations selected
          this.resetDashboardStats();
        }
      } catch (err) {
        console.log("Error updating the settings ", err);
      } finally {
        this.isLoading = false; // Stop loading
      }
    },

    // 2) Time Range changed
    async handleTimeRangeChange() {
      // Re-compute your stats from monthlyBuckets
      this.updateDashboardStats();
      this.renderBarChart();
    },

    // 3) Actually fetch from your Cloud Function
    async fetchReviewsAnalyticsForAllLocations() {
      try {
        this.isLoading = true;
        // Get workspace details to identify shallow workspaces
        const workspacesDetails = await Promise.all(
          this.selectedWIDs.map(async (wid) => {
            const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", wid);
            const workspaceDoc = await getDoc(workspaceRef);
            return {
              wid,
              placeId: workspaceDoc.data()?.placeId,
              isShallow: workspaceDoc.exists() && workspaceDoc.data()?.shallowConnect === true
            };
          })
        );
        // Process all workspaces
        const accounts = [];
    
        // Process each workspace based on whether it's shallow or not
        for (let i = 0; i < workspacesDetails.length; i++) {
          const { wid, isShallow, placeId } = workspacesDetails[i];
          
          if (isShallow) {
            // For shallow workspaces, just add the wid without checking connections
            accounts.push({
              uid: this.currentUID,
              wid,
              location: placeId,
              isShallow: true
            });
          } else {
            // For regular workspaces, check for GMB connection
            const connectedAppsRef = collection(
              db,
              "users",
              this.currentUID,
              "fly_workspaces",
              wid,
              "connected-apps"
            );
            
            const gbpSnapshot = await getDocs(
              query(
                connectedAppsRef,
                where("__name__", "==", "google_business_profile")
              )
            );
            
            if (!gbpSnapshot.empty) {
              const gbpData = gbpSnapshot.docs[0].data();
              const accountId = gbpData.accounts?.[0]?.id || "";
              
              (gbpData.accounts || []).forEach((account) => {
                const locationId = account.locations?.[0]?.name || "";
                accounts.push({
                  uid: this.currentUID,
                  wid,
                  location: locationId,
                  account: accountId,
                  isShallow: false
                });
              });
            }
          }
        }

        if (accounts.length === 0) {
          return;
        }

        const fetchGmbReviewsAnalytics = httpsCallable(
          functions,
          "getReviewDashboardAnalytics"
        );
        const payload = {
          uid: this.currentUID || this.UID,
          accounts,
          action: "get_gmb_reviews_analytics",
          platform: "google_business_profile",
          sort: this.sortOrder,
        };

        const res = await fetchGmbReviewsAnalytics(payload);
        console.log("res", res.data.data);
        if (res.data?.success) {
          const { monthlyBuckets, presentMonthData } = res.data.data || {};

          // Save to local state
          this.monthlyBuckets = monthlyBuckets || [];
          this.presentMonthData = presentMonthData || {};

          // Now recalc stats for whichever time range user currently has:
          this.updateDashboardStats();
          this.renderBarChart();
        }
      } catch (error) {
        console.error("Error fetching analytics:", error);
      } finally {
        this.isLoading = false;
      }
    },

    // 4) Summation & UI calculations
    updateDashboardStats() {
      // Filter monthlyBuckets by the selectedTimeRange
      const filtered = this.getTimeFilteredBuckets();

      // Sum up star ratings, total reviews, text count, reply count, etc.
      let totalReviews = 0;
      let totalStars = 0; // for computing average
      const ratingBreakdown = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
      let totalText = 0;
      let totalReplied = 0;

      filtered.forEach((bucket) => {
        // 4.1 total reviews
        totalReviews += bucket.totalReviews || 0;

        // 4.2 ratingCounts => { ONE: 3, TWO: 2, etc. }
        if (bucket.ratingCounts) {
          Object.entries(bucket.ratingCounts).forEach(([key, val]) => {
            const numericStar = this.convertStarStringToNum(key); // 'ONE' => 1, etc.
            ratingBreakdown[numericStar] += val;
          });
        }

        // 4.3 text reviews = sum of commentCount
        totalText += bucket.commentCount || 0;

        // 4.4 replied reviews = sum of reviewReplyCount
        totalReplied += bucket.reviewReplyCount || 0;
      });

      // Summation of star rating
      Object.entries(ratingBreakdown).forEach(([star, count]) => {
        totalStars += Number(star) * count;
      });

      const avgRating = totalReviews > 0 ? totalStars / totalReviews : 0;

      // Set to data
      this.totalReviews = totalReviews;
      this.ratingBreakdown = ratingBreakdown;
      this.averageRating = avgRating;

      // Positive vs Negative can be your own definition:
      // e.g. positive => 4 star or 5 star, negative => 1,2,3
      const positiveCount = ratingBreakdown[3] + ratingBreakdown[4] + ratingBreakdown[5];
      const negativeCount =
        ratingBreakdown[1] + ratingBreakdown[2];

      this.positiveCount = positiveCount;
      this.negativeCount = negativeCount;

      // Text vs No Text
      this.textReviews = totalText;
      const noText = totalReviews - totalText;
      this.noTextReviews = noText;
      this.textPercentage =
        totalReviews > 0 ? Math.round((totalText / totalReviews) * 100) : 0;
      this.noTextPercentage = 100 - this.textPercentage;

      // Replied vs Not Replied
      this.repliedReviews = totalReplied;
      const notReplied = totalReviews - totalReplied;
      this.notRepliedReviews = notReplied;
      this.repliedPercentage =
        totalReviews > 0 ? Math.round((totalReplied / totalReviews) * 100) : 0;
      this.notRepliedPercentage = 100 - this.repliedPercentage;
    },

    // 5) Helper to return a slice of monthlyBuckets for the chosen range
    getTimeFilteredBuckets() {
      if (!this.monthlyBuckets.length) return [];

      if (!this.selectedTimeRange) {
        // "All Time" - sort by date ascending (oldest to newest)
        return [...this.monthlyBuckets].sort((a, b) => {
          return new Date(a.month) - new Date(b.month);
        });
      }

      // Otherwise parse the user's choice
      let monthsCount = 0;
      if (this.selectedTimeRange === "ONE") monthsCount = 1;
      if (this.selectedTimeRange === "TWO") monthsCount = 6;
      if (this.selectedTimeRange === "THREE") monthsCount = 12;

      // Sort buckets by date ascending, then take last X months
      const sorted = [...this.monthlyBuckets].sort((a, b) => {
        return new Date(a.month) - new Date(b.month);
      });

      // Take the last X months (most recent months)
      return sorted.slice(-monthsCount);
    },

    // 6) Render the chart with the filtered monthly data
    renderBarChart() {
      const ctx = this.$refs.barChartCanvas?.getContext("2d");
      if (!ctx) return;

      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      const filteredBuckets = this.getTimeFilteredBuckets();
      const totalMonths = filteredBuckets.length;

      // Calculate thumb width as percentage of visible window
      this.thumbWidth = Math.min(100, (this.visibleMonths / totalMonths) * 100);

      // Prepare chart data
      const labels = filteredBuckets.map((b) => {
        const d = new Date(b.month);
        return d.toLocaleString("default", {
          month: "short",
          year: "numeric",
        });
      });

      const reviewData = filteredBuckets.map((b) => b.totalReviews || 0);
      const avgRatingData = filteredBuckets.map((b) => {
        const ratingCounts = b.ratingCounts || {};
        let sumStars = 0;
        let sumReviews = 0;
        Object.entries(ratingCounts).forEach(([key, val]) => {
          const starNum = this.convertStarStringToNum(key);
          sumStars += starNum * val;
          sumReviews += val;
        });
        return sumReviews ? sumStars / sumReviews : 0;
      });

      const ratingCountsData = filteredBuckets.map((b) => {
        const counts = {
          FIVE: 0,
          FOUR: 0,
          THREE: 0,
          TWO: 0,
          ONE: 0,
          ...b.ratingCounts,
        };
        return counts;
      });

      // Function to determine dot color based on rounded rating
      const getDotColor = (rating) => {
        const roundedRating = Math.round(rating);
        switch (roundedRating) {
          case 5:
            return "#16A34A"; // bg-green-600
          case 4:
            return "#4ADE80"; // bg-green-400
          case 3:
            return "#EAB308"; // bg-yellow-500
          case 2:
            return "#F97316"; // bg-orange-500
          case 1:
            return "#fc164c"; // bg-red-500  
          case 0:
            return "#f34409"; // bg-red-500
          default:
            return "#4CAF50"; // fallback color
        }
      };

      // Create arrays for point colors, border colors, and hover colors
      const pointBackgroundColors = avgRatingData.map((rating) => getDotColor(rating));
      const pointBorderColors = avgRatingData.map((rating) => getDotColor(rating));
      const pointHoverBackgroundColors = avgRatingData.map((rating) => getDotColor(rating));

      const maxReviews = Math.max(...reviewData, 5);
      const maxRating = Math.max(...avgRatingData, 5);
      const minRating = Math.min(...avgRatingData, 0);

      // Calculate the range of the dots graph (difference between max and min ratings)
      const ratingRange = maxRating - minRating || 1; // Avoid division by zero

      this.chartInstance = new Chart(ctx, {
        type: "bar",
        data: {
          labels,
          datasets: [
            {
              label: "Number of Reviews",
              data: reviewData,
              backgroundColor: "#3955fa",
              borderRadius: 5,
              barThickness: 30,
              maxBarThickness: 35,
              yAxisID: "y",
              ratingCounts: ratingCountsData, // Store rating counts for tooltip
            },
            {
              label: "Average Rating",
              data: avgRatingData,
              type: "line",
              borderColor: "#3955fa",
              backgroundColor: pointBackgroundColors,
              pointBackgroundColor: pointBackgroundColors, // Dynamic dot color
              pointBorderColor: pointBorderColors, // Dynamic border color
              pointHoverBackgroundColor: pointHoverBackgroundColors, // Dynamic hover color
              pointHoverBorderColor: pointHoverBackgroundColors,
              borderWidth: 2,
              pointRadius: 15,
              pointHoverRadius: 17,
              fill: false,
              yAxisID: "y1",
              datalabels: {
                formatter: (value) => value?.toFixed(1),
                color: "white",
                font: {
                  weight: "bold",
                  size: 11,
                },
              },
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
            mode: "index",
          },
          layout: {
            padding: {
              top: 50, // Add padding at the top to ensure dots are fully visible
            },
          },
          scales: {
            x: {
              min: Math.max(0, labels.length - this.visibleMonths),
              max: labels.length - 1,
              ticks: {
                autoSkip: false,
              },
              grid: {
                display: true,
              },
            },
            y: {
              beginAtZero: true,
              max: maxReviews * 2,
              title: {
                display: true,
                text: "No. of Reviews",
              },
            },
            y1: {
              position: "right",
              min: 0,
              max: 6, // This will be adjusted dynamically in the plugin
              display: false,
              title: {
                display: true,
                text: "Average Rating",
              },
              grid: {
                drawOnChartArea: false,
              },
            },
          },
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                label: function (context) {
                  const datasetLabel = context.dataset.label;
                  const value = context.parsed.y;

                  if (datasetLabel === "Average Rating") {
                    return `Avg Rating: ${value?.toFixed(1)}`;
                  }

                  if (datasetLabel === "Number of Reviews") {
                    const ratingCounts =
                      context.dataset.ratingCounts[context.dataIndex];
                    return [
                      `Reviews:    ${value}`,
                      `Five Star:  ${ratingCounts.FIVE || 0}`,
                      `Four Star:  ${ratingCounts.FOUR || 0}`,
                      `Three Star: ${ratingCounts.THREE || 0}`,
                      `Two Star:   ${ratingCounts.TWO || 0}`,
                      `One Star:   ${ratingCounts.ONE || 0}`,
                    ];
                  }

                  return datasetLabel + ": " + value;
                },
              },
            },
            legend: {
              display: true,
              position: "top",
            },
            zoom: {
              pan: {
                enabled: false,
              },
            },
          },
        },
        plugins: [
          {
            id: "customPointLabels",
            afterDatasetsDraw(chart) {
              const ctx = chart.ctx;
              chart.data.datasets.forEach((dataset, i) => {
                if (dataset.type === "line") {
                  const meta = chart.getDatasetMeta(i);
                  meta.data.forEach((point, index) => {
                    const value = dataset.data[index]?.toFixed(1);
                    ctx.save();
                    ctx.textAlign = "center";
                    ctx.textBaseline = "middle";
                    ctx.fillStyle = "white";
                    ctx.font = "bold 11px Arial";
                    ctx.fillText(value, point.x, point.y);
                    ctx.restore();
                  });
                }
              });
            },
          },
          {
            id: "shiftDotsGraphAboveBars",
            beforeDatasetsDraw(chart) {
              const barMeta = chart.getDatasetMeta(0); // Bar dataset (Number of Reviews)
              const dotsMeta = chart.getDatasetMeta(1); // Dots dataset (Average Rating)

              if (barMeta && dotsMeta && barMeta.data && dotsMeta.data) {
                // Find the tallest bar height in terms of the y-axis value
                const tallestBarValue = Math.max(...chart.data.datasets[0].data);
                const tallestBarTopPixel = chart.scales.y.getPixelForValue(tallestBarValue);

                // Get the y1 scale (Average Rating)
                const y1Scale = chart.scales.y1;

                // Get the min and max rating values
                const minRating = Math.min(...chart.data.datasets[1].data);
                const maxRating = Math.max(...chart.data.datasets[1].data);
                const ratingRange = maxRating - minRating || 1; // Avoid division by zero

                // Calculate the pixel range for the dots graph (based on the rating range)
                const chartHeight = chart.chartArea.bottom - chart.chartArea.top;
                const dotsGraphHeight = chartHeight * 0.3; // Allocate 30% of the chart height for the dots graph
                const pixelPerRatingUnit = dotsGraphHeight / ratingRange;

                // Calculate the base position (lowest point of the dots graph) to be above the tallest bar
                const paddingAboveBar = 30; // Padding between the tallest bar and the lowest dot
                const dotsGraphBasePixel = tallestBarTopPixel - paddingAboveBar - dotsGraphHeight;

                // Adjust the y1 axis to reflect the new positioning (for reference)
                const y1Min = minRating;
                const y1Max = maxRating;
                y1Scale.options.min = y1Min;
                y1Scale.options.max = y1Max;

                // Position each dot within the dots graph area
                dotsMeta.data.forEach((point, index) => {
                  const ratingValue = chart.data.datasets[1].data[index];
                  // Calculate the relative position within the dots graph
                  const relativeRating = ratingValue - minRating;
                  const pixelOffset = relativeRating * pixelPerRatingUnit;
                  // Position the dot within the dots graph area
                  point.y = dotsGraphBasePixel + (dotsGraphHeight - pixelOffset);

                  // Ensure the dot doesn't go above the chart area (keep it fully visible)
                  const minY = chart.chartArea.top + 15; // 15 pixels from the top to account for dot radius
                  if (point.y < minY) {
                    point.y = minY;
                  }
                });
              }
            },
          },
        ],
      });

      // Initialize scroll position to show most recent months
      this.updateScrollPosition(100 - this.thumbWidth);
    },

    // New methods for scrolling functionality
    scrollChart(direction) {
      const scrollAmount = 10; // Percentage to scroll
      let newPosition;

      if (direction === "left") {
        newPosition = Math.max(0, this.thumbPosition - scrollAmount);
      } else {
        newPosition = Math.min(
          100 - this.thumbWidth,
          this.thumbPosition + scrollAmount
        );
      }

      this.updateScrollPosition(newPosition);
    },

    updateScrollPosition(position) {
      this.thumbPosition = position;

      if (this.chartInstance) {
        const totalMonths = this.chartInstance.data.labels.length;
        const startIndex = Math.floor(
          (position / (100 - this.thumbWidth)) *
            (totalMonths - this.visibleMonths)
        );

        this.chartInstance.options.scales.x.min = Math.max(0, startIndex);
        this.chartInstance.options.scales.x.max = Math.min(
          totalMonths - 1,
          startIndex + this.visibleMonths - 1
        );
        this.chartInstance.update();
      }
    },

    startDrag(event) {
      this.isDragging = true;
      const startX =
        event.type === "mousedown" ? event.clientX : event.touches[0].clientX;
      this.startX = startX;
      this.scrollStartPosition = this.thumbPosition;

      // Add event listeners for drag movement and release
      if (event.type === "mousedown") {
        document.addEventListener("mousemove", this.doDrag);
        document.addEventListener("mouseup", this.stopDrag);
      } else {
        document.addEventListener("touchmove", this.doDrag);
        document.addEventListener("touchend", this.stopDrag);
      }

      // Prevent default to avoid text selection
      event.preventDefault();
    },

    doDrag(event) {
      if (!this.isDragging) return;

      const currentX =
        event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
      const diffX = currentX - this.startX;
      const trackWidth = this.$refs.scrollTrack.offsetWidth;

      // Calculate percentage moved
      const percentageMoved = (diffX / trackWidth) * 100;

      // Calculate new position
      let newPosition = this.scrollStartPosition + percentageMoved;

      // Constrain between 0 and (100 - thumbWidth)
      newPosition = Math.max(0, Math.min(100 - this.thumbWidth, newPosition));

      this.updateScrollPosition(newPosition);
    },

    stopDrag() {
      this.isDragging = false;

      // Remove event listeners
      document.removeEventListener("mousemove", this.doDrag);
      document.removeEventListener("mouseup", this.stopDrag);
      document.removeEventListener("touchmove", this.doDrag);
      document.removeEventListener("touchend", this.stopDrag);
    },

    // Utils
    convertStarStringToNum(str) {
      switch (str) {
        case "ONE":
          return 1;
        case "TWO":
          return 2;
        case "THREE":
          return 3;
        case "FOUR":
          return 4;
        case "FIVE":
          return 5;
        default:
          return 0;
      }
    },
    getStarPercentage(star) {
      // star is 1..5
      const count = this.ratingBreakdown[star];
      if (!this.totalReviews) return 0;
      return Math.round((count / this.totalReviews) * 100);
    },
    resetDashboardStats() {
      // optional helper if user deselects all WIDs
      this.monthlyBuckets = [];
      this.presentMonthData = {};
      this.totalReviews = 0;
      this.averageRating = 0;
      this.ratingBreakdown = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
      this.positiveCount = 0;
      this.negativeCount = 0;
      this.textReviews = 0;
      this.noTextReviews = 0;
      this.textPercentage = 0;
      this.noTextPercentage = 0;
      this.repliedReviews = 0;
      this.notRepliedReviews = 0;
      this.repliedPercentage = 0;
      this.notRepliedPercentage = 0;
      if (this.chartInstance) {
        this.chartInstance.destroy();
        this.chartInstance = null;
      }
    },
    async fetchStores() {
      const uid = this.currentUID;
      const storeRef = collection(db, "users", uid, "fly_workspaces");
      const storeQuery = query(
        storeRef,
        where("admin", "==", this.WID),
        where("storeWorkspace", "==", true)
      );
      const querySnapshot = await getDocs(storeQuery);
      this.stores = [];
      this.selectedWIDs = [];

      querySnapshot.forEach((doc) => {
        let storeData = doc.data();
        if (
          (storeData.storeWorkspace &&
          storeData.admin === this.WID &&
          storeData.connected_apps?.includes("google_business_profile")) || storeData.shallowConnect
        ) {
          storeData.wid = doc.id;
          storeData.type = "store";
          storeData.checked = true;
          storeData.isReview = true;
          this.stores.push(storeData);
          this.selectedWIDs.push(doc.id);
        }
      });

      if (this.selectedWIDs.length > 0) {
        await this.fetchReviewsAnalyticsForAllLocations();
      }
    },
    toggleStoreDropdown(event) {
      event.stopPropagation();
      this.showStoreDropdown = !this.showStoreDropdown;
    },
    handleClickOutside(event) {
      const dropdown = this.$refs.locationDropdown?.$refs.showStoreDropdown;
      const input = event.target.closest(".location-dropdown");
      if (!input && dropdown && !dropdown.contains(event.target)) {
        this.showStoreDropdown = false;
      }
    },
  },
};
</script>

<style scoped>
/* Any styling you want here */
.chart-scroller {
  width: 100%;
}

.scroll-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: #f3f4f6;
  cursor: pointer;
  transition: background-color 0.2s;
}

.scroll-btn:hover {
  background-color: #e5e7eb;
}

.scroll-track {
  position: relative;
  cursor: pointer;
}

.scroll-thumb {
  position: absolute;
  top: 0;
  background-color: #3955fa; /* Primary color - matches the line color */
  cursor: grab;
  border-radius: 8px;
}

.scroll-thumb:active {
  cursor: grabbing;
}
</style>
