<template>
    <div ref="showStoreDropdown" v-if="showStoreDropdown"
        class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-[500px] max-w-[800px] overflow-y-auto px-5 w-[400px] max-md:w-[300px] pb-2">
        <!-- Search Input -->
        <div class="my-2">
            <input type="text" v-model="searchTerm" placeholder="Search locations..."
                class="w-full px-2 py-1 border border-gray-300 rounded" />
        </div>
        <div class="flex items-center gap-2">
            <input type="checkbox" :checked="allSelected" @change="toggleSelectAll" />
            <span class="font-medium">Select All</span>
            <span v-if="reviewReport" class="text-xs text-gray-500">
                Total Average Rating: {{ totalAverageRating }} <v-icon small color="amber">mdi-star</v-icon>
            </span>
        </div>
        <div v-for="(stores, state) in groupedStores" :key="state" class="state-group mt-2">
            <div class="flex items-center gap-2 cursor-pointer font-medium" >
                <v-icon small @click="toggleState(state)">
                    {{ expandedStates[state] ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
                </v-icon>
                <input type="checkbox" :checked="isStateFullySelected(state)" @change="toggleSelectState(state)" />
                <span>{{ state }}</span>
            </div>
            <div v-if="expandedStates[state]" class="pl-6">
                <div v-for="profile in stores" :key="profile.wid" class="platform p-2 group">
                    <div class="flex items-center gap-2">
                        <input type="checkbox" v-model="profile.checked" :disabled="checkDisabled(profile)" @change="updateSelectedWIDs" />
                        <div class="flex flex-col">
                            <span class="text-md font-medium">{{ profile?.gmbProfile?.title||profile?.title }}</span>
                            <span class="font-medium text-sm text-gray-500">{{ profile.sub_locality }}</span>
                            <span class="flex gap-3">
                                <span v-if="profile.city" class="text-xs">{{ profile.city }}</span>
                                <span v-if="profile.tooltipMessage" class="text-xs text-orange-500">
                                    {{ profile.tooltipMessage }}</span>
                                <span v-if="profile.needs_gmb_reauth" class="text-xs font-semibold text-red-500">
                                    reconnect </span>
                            </span>
                            <span v-if="reviewReport" class="text-xs text-gray-500">
                                Average Rating: {{ profile.averageRating }} <v-icon small color="amber">mdi-star</v-icon>
                            </span>
                        </div>
                        <!-- Only Link -->
                        <a href="#" v-if="!checkDisabled(profile)" class="text-blue-500 text-xs opacity-0 group-hover:opacity-100 transition-opacity ml-auto" @click.prevent="setOnlyThisStore(profile)">
                            Only
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="Object.keys(groupedStores).length === 0" class="p-4 text-center text-gray-500">
            No locations found
        </div>
    </div>
</template>

<script>
export default {
    props: {
        stores: {
            type: Array,
            required: true,
        },
        value: {
            type: Array,
            default: () => [],
        },
        showStoreDropdown: {
            type: Boolean,
            default: false
        },
        reviewReport: {
            type: Boolean,
            default: false,
        },
        totalAverageRating: {
            type: String,
        }
    },
    data() {
        return {
            searchTerm: "",
            expandedStates: {},
        };
    },
    watch: {
        groupedStores: {
            immediate: true,
            handler(newVal) {
                if (Object.keys(newVal).length > 0) {
                    this.expandFirstState();
                }
            }
        }
    },
    computed: {
        groupedStores() {
            const groups = {};
            this.filteredStores.forEach(store => {
                const state = store.state || "Unknown";
                if (!groups[state]) {
                    groups[state] = [];
                }
                groups[state].push(store);
            });
            return groups;
        },
        filteredStores() {
            if (!this.searchTerm) {
                return this.stores;
            }
            const searchTermLower = this.searchTerm.toLowerCase();
            return this.stores.filter(store => 
                store.sub_locality.toLowerCase().includes(searchTermLower) ||
                (store.city && store.city.toLowerCase().includes(searchTermLower)) ||
                (store.state && store.state.toLowerCase().includes(searchTermLower))
            );
        },
        allSelected() {
            return this.stores.length > 0 && this.stores
                .filter(store => !this.checkDisabled(store))
                .every(store => store.checked);
        }
    },
    methods: {
        checkDisabled(profile){
            return (!profile.isReview&&(profile.tooltipMessage||profile.shallowConnect||profile.needs_gmb_reauth))
        },
        toggleState(state) {
            this.$set(this.expandedStates, state, !this.expandedStates[state]);
        },
        toggleSelectAll() {
            const newValue = !this.allSelected;
            this.stores.forEach(store => {
                if(!this.checkDisabled(store)) store.checked = newValue;
            });
            this.updateSelectedWIDs();
        },
        toggleSelectState(state) {
            const newValue = !this.isStateFullySelected(state);
            this.groupedStores[state].forEach(store => {
                if(!this.checkDisabled(store)) store.checked = newValue;
            });
            this.updateSelectedWIDs();
        },
        isStateFullySelected(state) {
            return this.groupedStores[state]
                .filter(store => !this.checkDisabled(store))
                .every(store => store.checked);
        },
        updateSelectedWIDs() {
            const selectedWIDs = this.stores.filter(store => store.checked).map(store => store.wid);
            this.$emit('change', selectedWIDs);
        },
        setOnlyThisStore(selectedProfile) {
            this.stores.forEach(store => {
                store.checked = (store.wid === selectedProfile.wid);
            });
            this.updateSelectedWIDs();
        },
        expandFirstState() {
            const states = Object.keys(this.groupedStores);
            if (states.length > 0) {
                this.$set(this.expandedStates, states[0], true);
            }
        },
    },
    mounted() {
        // Update items based on value prop and disabled status
        this.stores.forEach(item => {
            if (this.checkDisabled(item)) {
                item.checked = false;
            } else {
                // Set checked based on whether the item's wid is in the value array
                item.checked = this.value.includes(item.wid);
            }
        });

        // Expand all states by default
        Object.keys(this.groupedStores).forEach(state => {
            this.$set(this.expandedStates, state, true);
        });
    }
};
</script>

<style scoped>
.platform {
    position: relative;
}

.platform a {
    opacity: 0;
    transition: opacity 0.3s;
    position: absolute;
    right: 0;
}

.platform:hover a {
    opacity: 1;
}
</style>
