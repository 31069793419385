<template>
  <div class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]">
    <section class="flex flex-col items-center w-full justify-center xl:w-[1285px]">
      <!-- <div class="flex items-center justify-between md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]">
        <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
          <img src="@/assets/img/sidebar/reviews_g.png" class="cursor-pointer w-6 md:w-[28px]" alt="post" />
          <div class="flex items-center gap-1">
            <p class="text-base md:text-[22px] font-bold">Google Reviews</p>
          </div>
        </div>
      </div> -->
      <!-- <v-menu offset-y v-model="showActionsMenu">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="h-[34px] pl-3 pr-4 py-[7px] bg-white rounded-md border border-[#e6e8ec] gap-2 inline-flex cursor-pointer"
                v-bind="attrs" v-on="on">
                <v-icon small>mdi-menu</v-icon>
                <div class="text-black text-sm font-medium font-['Inter'] leading-tight hidden md:inline-flex">
                  Menu
                </div>
                <v-icon small :class="{ 'rotate-180': showActionsMenu }" class="transition-transform duration-300">
                  mdi-chevron-down
                </v-icon>
              </div>
            </template>
            <v-list>
              <v-list-item @click="showLocationLatestReview(); showActionsMenu = false">
                <v-list-item-title class="flex items-center gap-2">
                  View G Locations Without Reviews
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="showReport(); showActionsMenu = false">
                <v-list-item-title class="flex items-center gap-2">
                  <span>View G Review Insights</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="showWelcomePopup = true; showActionsMenu = false">
                <v-list-item-title class="flex items-center gap-2">
                  <span>Auto Responder Settings</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
      <div class="flex flex-col items-center justify-center w-full h-full pb-20">
        <section class="flex flex-col items-center w-full justify-center xl:w-[100%]">
          <div class="w-full xl:w-[100%] flex justify-center items-center gap-4">
            <div class="w-full bg-white rounded-b-xl">
              <div class="flex flex-col justify-between w-full px-5 py-2 mx-auto">
                <div class="flex w-full items-center justify-between mt-5">
                  <div class="justify-end flex flex-row items-center gap-2">
                    <div class="location-dropdown relative ">
                      <div class="flex gap-5">
                        <v-btn @click="toggleStoreDropdown($event)" outlined color="primary">
                          {{ selectedWIDs?.length || 0 }} Selected Locations
                        </v-btn>
                        <v-select v-model="needsAction" :items="[
                          { text: 'All', value: false },
                          { text: 'Needs Action', value: true }
                        ]" @change="toggleNeedsAction" dense label="Show reviews by" outlined class="w-[150px]" />
                        <v-select v-model="sortOrder" :items="[
                          { text: 'Newest', value: 'desc' },
                          { text: 'Oldest', value: 'asc' }
                        ]" label="Sort by" dense outlined class="w-[130px]" @change="handleSortChange" />
                      </div>
                      <LocationDropdown class="custom-dropdown" ref="locationDropdown" :stores="filteredStores"
                        :showStoreDropdown="showStoreDropdown" v-model="selectedWIDs" @change="handleLocationChange" />
                    </div>
                    <div class="flex items-center gap-1">
                    </div>
                    <!-- Location dropdown remains same -->
                    <v-dialog v-model="showWelcomePopup" max-width="500px">
                      <v-card>
                        <v-card-title class="d-flex justify-space-between align-center">
                          <span class="text-h6">Auto Respond Setting</span>
                          <v-btn icon @click="closePopup">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-checkbox v-model="autoRespond" @change="updateAutoRespond"
                            label="Enable Auto Respond Review"></v-checkbox>

                          <v-checkbox v-if="autoRespond" v-model="autoRespondNegative.value"
                            @change="updateNegativeRespond" label="Enable Auto Respond Negative Review"></v-checkbox>

                          <div v-if="autoRespondNegative.value" class="ml-4 border-l-2 border-gray-300 pl-2">
                            <span class="font-weight-medium">Negative Reply Type:</span>
                            <v-radio-group v-model="autoRespondNegative.type" @change="updateNegativeRespond" row>
                              <v-radio label="AI Generated Reply" value="ai"></v-radio>
                              <v-radio label="Manual Reply" value="manual"></v-radio>
                            </v-radio-group>
                          </div>

                          <div v-if="autoRespondNegative.value && autoRespondNegative.type === 'manual'" class="ml-4 border-l-2 border-gray-300 pl-2">
                            <v-textarea v-model="autoRespondNegative.reply" label="Type your reply..." outlined rows="2"
                              dense></v-textarea>
                            <v-btn :disabled="!autoRespondNegative.reply" color="primary" @click="submitManualReply">
                              Submit
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </div>
                  <div class="relative flex justify-end md:flex-row items-start md:items-center mx-4">
                    <!-- Star rating selection dropdown -->
                    <div class="star-rating-selection bg-white rounded-lg">
                      <v-select v-model="selectedRating" :items="[
                        { text: 'All', value: null },
                        { text: '1 Star', value: 'ONE' },
                        { text: '2 Stars', value: 'TWO' },
                        { text: '3 Stars', value: 'THREE' },
                        { text: '4 Stars', value: 'FOUR' },
                        { text: '5 Stars', value: 'FIVE' }
                      ]" @change="filterByRating(selectedRating)" label="Filter by Rating" dense outlined class="w-[150px]">
                        <template v-slot:selection="{ item }">
                          <span class="flex items-center">
                            <span v-if="item.value" class="text-yellow-400 ">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 15l-5.878 3.09 1.121-6.535L0 6.545l6.545-.955L10 0l2.455 5.59L20 6.545l-5.243 4.005 1.121 6.535z" />
                              </svg>
                            </span>
                            {{ item.text }}
                          </span>
                        </template>
                        <template v-slot:item="{ item }">
                          <span class="flex items-center">
                            <span v-if="item.value" class="text-yellow-400">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 15l-5.878 3.09 1.121-6.535L0 6.545l6.545-.955L10 0l2.455 5.59L20 6.545l-5.243 4.005 1.121 6.535z" />
                              </svg>
                            </span>
                            {{ item.text }}
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="grid grid-cols-1 gap-6 mt-6 px-4">
                    <div v-if="
                      !isLoading && (!allReviews || allReviews?.length == 0)
                    " class="text-center py-8 text-gray-500">
                      No reviews found
                    </div>
                  </div>
                  <!-- Reviews -->
                  <div v-if="isLoading" class="grid grid-cols-1 md:grid-cols-2 gap-4  mx-auto w-full">
                    <v-skeleton-loader v-for="n in 2" :key="n" class="bg-white rounded-lg shadow-md mx-4"
                      type="paragraph,list-item-avatar,article" :boilerplate="true">
                    </v-skeleton-loader>
                  </div>
                  <div v-if="!isLoading" class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 px-4">
                    <div v-for="review in allReviews" :key="review.reviewId"
                      class="bg-white rounded-lg shadow-md p-6 border border-gray-200">
                      <div class="flex items-center gap-2 mb-2 pb-3 border-b border-gray-100">
                        <!-- <img
                    :src="gmb_logo"
                    alt="Google Business Profile"
                    class="w-6 h-6 object-contain"
                  /> -->
                        <span class="text-xs md:text-sm font-medium text-gray-700">{{
                          review.sub_locality
                        }}</span>
                      </div>
                      <!-- Reviewer Info -->
                      <div class="flex items-center gap-3 mb-4">
                        <img v-if="review.profilePhotoUrl" :src="review.profilePhotoUrl" alt="profile"
                          crossorigin="anonymous" referrerpolicy="no-referrer"
                          class="w-10 h-10 rounded-full object-cover" />
                        <img v-else class="w-10 h-10 rounded-full object-cover" src="@/assets/img/mgacc.svg" />
                        <div>
                          <h3 class="font-medium text-gray-900 text-sm md:text-base">
                            {{ review.displayName }}
                          </h3>
                          <div class="flex items-start sm:items-center gap-0 md:gap-2 flex-col md:flex-row">
                            <!-- Star Rating -->
                            <div class="flex">
                              <span v-for="index in 5" :key="index">
                                <svg :class="index <= review.starRating
                                  ? 'text-yellow-400'
                                  : 'text-gray-300'
                                  " class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                  fill="currentColor">
                                  <path
                                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                              </span>
                            </div>
                            <!-- Date -->
                            <!-- <span class="text-xs text-gray-500">
                        {{ formatDate(review.createTime) }}
                      </span> -->
                            <div class="text-xs text-gray-500">
                              <span>{{ formatTime(review.updateTime) }}</span>
                              <span class="mx-1">•</span>
                              <span>{{ formatDate(review.updateTime) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Review Comment -->
                      <div class="flex flex-col gap-2">
                        <div class="flex flex-col gap-1 items-start">
                          <div class="text-gray-700 text-sm md:text-base">
                            <p v-if="
                              review.comment?.length <= 60 ||
                              review.showFullComment
                            ">
                              {{ review.comment }}
                            </p>
                            <p v-else>
                              {{ review.comment.slice(0, 60) }}...
                              <button @click="toggleComment(review)"
                                class="text-blue-600 hover:text-blue-700 text-sm ml-1">
                                See more
                              </button>
                            </p>
                            <p v-if="
                              review.comment?.length > 60 &&
                              review.showFullComment
                            ">
                              <button @click="toggleComment(review)"
                                class="text-blue-600 hover:text-blue-700 text-sm mt-1">
                                See less
                              </button>
                            </p>
                          </div>
                          <div class="flex items-center gap-1">
                            <div v-if="!review.reviewReply.comment" class="flex items-center gap-2">
                              <!-- Manual Reply Button -->
                              <v-btn @click="startNewReply(review)" class="m-0 p-0 -mt-1 text-gray-600" icon
                                title="Write manual reply">
                                <v-icon>mdi-reply</v-icon>
                              </v-btn>
                              <button @click="startNewReply(review)" class="text-blue-600 hover:text-blue-700 text-xs">
                                Reply
                              </button>

                              <!-- Divider -->
                              <div class="h-4 w-px bg-gray-300 mx-1"></div>
                              <button @click="openSuggestionsDrawer(review)"
                                class="text-blue-600 hover:text-blue-700 text-xs">
                                Suggest Reply
                              </button>
                            </div>
                          </div>
                        </div>
                        <div v-if="review.reviewReply?.isNewReply" class="mt-2">
                          <textarea v-model="review.reviewReply.newComment" rows="3"
                            class="w-full px-3 py-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm md:text-base focus:border-blue-500"
                            placeholder="Type your reply..."></textarea>
                          <div class="flex justify-end gap-2 mt-2">
                            <button @click="cancelNewReply(review)"
                              class="px-3 py-1 text-sm text-gray-600 hover:text-gray-800 rounded-md hover:bg-gray-100">
                              Cancel
                            </button>
                            <button @click="submitNewReply(review)"
                              class="px-3 py-1 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50 relative"
                              :disabled="review.isSubmittingNew">
                              <span v-if="!review.isSubmittingNew">
                                Update Reply
                              </span>
                              <v-progress-circular v-else indeterminate size="16" width="2" color="white"
                                class="mx-4"></v-progress-circular>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div v-if="
                        review.reviewReply?.comment
                      " class="mt-4 pl-4 border-l-2 border-gray-200">
                        <div class="flex items-start gap-2">
                          <div
                            class="w-1 h-1 flex-none rounded-full bg-purple-500 flex items-center justify-center text-white text-lg">
                            V
                          </div>
                          <div class="flex-grow overflow-wrap">
                            <!-- Business Name and Owner Badge -->
                            <div class="flex items-center justify-between">
                              <div class="flex items-center gap-2">
                                <span class="font-medium text-sm md:text-base">{{
                                  review.locationTitle || "Business Owner"
                                }}</span>
                                <span class="text-sm text-wrap text-gray-500">Owner</span>
                              </div>
                              <!-- Action Buttons -->
                              <div class="flex items-center justify-end gap-1">
                                <button @click="startEditingReply(review)"
                                  class="p-1.5 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
                                  title="Edit reply">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                  </svg>
                                </button>
                                <button @click="deleteReply(review)"
                                  class="p-1.5 text-gray-500 hover:text-red-600 rounded-full hover:bg-gray-100 relative"
                                  title="Delete reply" :disabled="deletingReplyId === review.reviewId">
                                  <v-progress-circular v-if="deletingReplyId === review.reviewId" indeterminate
                                    size="16" width="2" color="red"
                                    class="absolute inset-0 m-auto"></v-progress-circular>
                                  <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                  </svg>
                                </button>
                              </div>
                            </div>

                            <!-- Reply Time -->
                            <div class="text-xs text-gray-500 mt-1">
                              <span>{{
                                formatTime(review.reviewReply.updateTime)
                              }}</span>
                              <span class="mx-1">•</span>
                              <span>{{
                                formatDate(review.reviewReply.updateTime)
                              }}</span>
                            </div>
                            <!-- Normal Reply View -->
                            <div v-if="!review.reviewReply.isEditing" class="mt-1 text-gray-700">
                              <p class="text-sm md:text-base" v-if="
                                review.reviewReply.comment?.length <= 100 ||
                                review.reviewReply.showFullComment
                              ">
                                {{ review.reviewReply.comment }}
                              </p>
                              <p v-else>
                                {{ review.reviewReply.comment?.slice(0, 100) }}...
                                <button @click="toggleReplyComment(review)"
                                  class="text-blue-600 hover:text-blue-700 text-sm ml-1">
                                  See more
                                </button>
                              </p>
                              <p v-if="
                                review.reviewReply.comment?.length > 100 &&
                                review.reviewReply.showFullComment
                              ">
                                <button @click="toggleReplyComment(review)"
                                  class="text-blue-600 hover:text-blue-700 text-sm mt-1">
                                  See less
                                </button>
                              </p>
                            </div>
                            <!-- Edit Form -->
                            <div v-else class="mt-2">
                              <textarea v-model="review.reviewReply.editedComment" rows="3"
                                class="w-full px-3 py-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Edit your reply..."></textarea>
                              <div class="flex justify-end gap-2 mt-2">
                                <button @click="cancelEditingReply(review)"
                                  class="px-3 py-1 text-sm text-gray-600 hover:text-gray-800 rounded-md hover:bg-gray-100">
                                  Cancel
                                </button>
                                <button @click="submitReplyEdit(review)"
                                  class="px-3 py-1 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50 relative"
                                  :disabled="updatingReplyId === review.reviewId">
                                  <span v-if="updatingReplyId !== review.reviewId">
                                    Update Reply
                                  </span>
                                  <v-progress-circular v-else indeterminate size="16" width="2" color="white"
                                    class="mx-4"></v-progress-circular>
                                </button>
                              </div>
                            </div>

                            <!-- Reply Comment -->
                            <!-- <p class="mt-1 text-sm md:text-lg text-gray-700">
                        {{ review.reviewReply.comment }}
                      </p> -->
                          </div>
                        </div>
                      </div>
                      <div v-if="review.needsAction" class="flex items-center gap-2 mt-2">
                        <label class="flex items-center cursor-pointer">
                          <input type="checkbox" :checked="review.actionTaken" :disabled="review.isActionLoading"
                            @change="handleActionTaken(review)"
                            class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out" />
                          <span :class="[
                            'ml-2 text-sm',
                            review.actionTaken ? 'text-green-600 font-medium' : 'text-gray-600'
                          ]">
                            {{ review.actionTaken ? 'Action Taken' : 'Mark as Actioned' }}
                          </span>
                        </label>
                        <v-progress-circular v-if="review.isActionLoading" indeterminate size="16" width="2"
                          color="primary" class="ml-2"></v-progress-circular>
                      </div>
                      <div v-if="review.showSuggestions" class="mt-4 border-t pt-4">
                        <div class="flex items-center justify-between mb-3">
                          <h4 class="text-base font-medium">Suggested Replies</h4>
                          <button @click="review.showSuggestions = false" class="text-gray-500 hover:text-gray-700">
                            <v-icon small>mdi-close</v-icon>
                          </button>
                        </div>

                        <div v-if="review.isLoadingSuggestions" class="flex justify-center py-4">
                          <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </div>

                        <div v-else-if="review.suggestedReplies?.length > 0" class="space-y-3">
                          <div v-for="(reply, index) in review.suggestedReplies" :key="index"
                            class="p-3 border rounded bg-gray-50 flex justify-between items-start gap-2">
                            <p class="text-sm text-gray-700">{{ reply }}</p>
                            <v-btn small color="primary" @click="useSuggestedReply(review, reply)"
                              :loading="review.sendingReply" class="mt-0 flex-shrink-0">
                              Use
                            </v-btn>
                          </div>
                        </div>

                        <div v-else class="text-center py-3 text-gray-500">
                          No suggestions available
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex justify-center my-6">
                <div class="flex max-w-md items-center gap-2">
                  <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1"
                    class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 cursor-pointer">
                    Previous
                  </button>

                  <div class="flex items-center gap-1">
                    <span class="text-sm text-gray-700">Page</span>
                    <span class="font-medium">{{ currentPage }}</span>
                    <span class="text-sm text-gray-700">of</span>
                    <span class="font-medium">{{ totalPages }}</span>
                  </div>

                  <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"
                    class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 cursor-pointer">
                    Next
                  </button>

                  <div class="mt-6">
                    <v-select v-model="pageSize" :items="[12, 20, 50]" label="Items per page" dense outlined
                      class="w-full" @change="handlePageSizeChange">
                      <template v-slot:selection="{ item }">
                        {{ item }} per page
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </section>
  </div>


</template>

<script>
import {
  collection,
  onSnapshot,
  query,
  where,
  getDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import { db } from "@/utils/firebase.utils";
import { ai } from "@cloudinary/url-gen/qualifiers/format";
import LocationDropdown from '@/components/LocationDropdown.vue';

export default {
  components: {
    LocationDropdown
  },
  data() {
    return {
      stores: [],
      showStoreDropdown: false,
      showActionsMenu: false,
      selectedWID: null,
      selectedWIDs: [],
      metricsData: {},
      isLoading: false,
      dateRanges: ["Last 7 days", "Last 28 days", "Last 60 days"],
      selectedDateRange: "Last 7 days",
      sortBy: "location",
      sortDesc: false,
      locationTitles: {},
      gmb_logo: "",
      allReviews: [],
      isSubmitting: false,
      selectedRating: null,
      currentPage: 1,
      pageSize: 12,
      totalItems: 0,
      totalPages: 1,
      locationSearch: "",
      autoRespond: false,
      autoRespondNegative: {
        value: false,
        type: "",
        reply: "",
      },
      isAutoRespondLoading: false,
      needsAction: this.$route.query.action === 'true',
      isNeedsActionLoading: false,
      ratingMap: {
        1: "ONE",
        2: "TWO",
        3: "THREE",
        4: "FOUR",
        5: "FIVE",
      },
      showSuggestionsDrawer: false,
      isLoadingSuggestions: false,
      suggestedReplies: [],
      currentReview: null,
      sendingReply: false,
      deletingReplyId: null,
      updatingReplyId: null,
      sortOrder: 'desc',
      isGeneratingReport: false,
      isOpeningLatestReview: false,
      showWelcomePopup: false,
    };
  },
  computed: {
    filteredStores() {
      if (!this.locationSearch) {
        return this.stores;
      }

      const searchTerm = this.locationSearch.toLowerCase();
      return this.stores.filter(
        (store) =>
          store.name?.toLowerCase().includes(searchTerm) ||
          store.sub_locality?.toLowerCase().includes(searchTerm) ||
          store.city?.toLowerCase().includes(searchTerm)
      );
    },
    filteredReviews() {
      if (!this.selectedRating) {
        return this.allReviews;
      }
      return this.allReviews.filter(
        (review) => review.starRating === this.selectedRating
      );
    },

    displayedReviews() {
      if (this.selectedRating === null) {
        return this.allReviews;
      }

      return this.allReviews.filter((review) => {
        // Convert string ratings to numbers if necessary
        const reviewRating =
          typeof review.starRating === "string"
            ? parseInt(review.starRating)
            : review.starRating;

        // console.log("reviewRating:", reviewRating);
        return reviewRating === this.selectedRating;
      });
    },

    ratingCounts() {
      return this.allReviews.reduce((acc, review) => {
        const rating =
          typeof review.starRating === "string"
            ? parseInt(review.starRating)
            : review.starRating;

        console.log("rating", rating);
        acc[rating] = (acc[rating] || 0) + 1;
        return acc;
      }, {});
    },
  },
  watch: {
    // Reset pagination when filters change
    selectedWIDs: {
      handler() {
        this.currentPage = 1;
        this.allReviews = [];
        if (this.selectedWIDs?.length > 0) {
          this.fetchReviewsForAllLocations();
        }
      },
    },
    needsAction: {
      handler() {
        this.currentPage = 1;
        this.allReviews = [];
        if (this.selectedWIDs?.length > 0) {
          this.fetchReviewsForAllLocations();
        }
      }
    },
    '$route.query.action': {
      immediate: true,
      handler(newValue) {
        this.needsAction = newValue === 'true';
        if (this.needsAction) {
          this.allReviews = [];
          if (this.selectedWIDs?.length > 0) {
            this.fetchReviewsForAllLocations();
          }
        }
      }
    }
  },
  methods: {
    async handleSortChange() {
      console.log("entered into handlesortchange");
      this.currentPage = 1; // Reset to first page when sorting changes
      if (this.selectedWIDs?.length > 0) {
        await this.fetchReviewsForAllLocations();
      }
    },
    toggleStoreDropdown(event) {
      event.stopPropagation();
      this.showStoreDropdown = !this.showStoreDropdown;
    },
    handleClickOutside(event) {
      const dropdown = this.$refs.locationDropdown?.$refs.showStoreDropdown;
      const menuDropdown = this.$refs.menuDropdown;
      const input = event.target.closest(".location-dropdown");
      const actionButton = event.target.closest(".menu-action-dropdown");

      if (!input && dropdown && !dropdown.contains(event.target)) {
        this.showStoreDropdown = false;
      }

      if (menuDropdown && !menuDropdown.contains(event.target) && !actionButton) {
        this.showActionsMenu = false;
      }
    },
    async changePage(newPage) {
      if (newPage >= 1 && newPage <= this.totalPages) {
        this.currentPage = newPage;
        await this.fetchReviewsForAllLocations();
        // Scroll to top of reviews section
        this.$nextTick(() => {
          window.scrollTo({
            top: this.$refs.reviewsSection?.offsetTop,
            behavior: "smooth",
          });
        });
      }
    },
    async handlePageSizeChange() {
      this.currentPage = 1; // Reset to first page when changing page size
      await this.fetchReviewsForAllLocations();
    },
    getLocationIdForWid(wid) {
      const store = this.stores.find((store) => store.wid === wid);
      return store?.locationId || "";
    },
    getStoreForWid(wid) {
      const store = this.stores.find((store) => store.wid === wid);
      return store || "";
    },

    async filterByRating(rating) {
      this.currentPage = 1;
      // this.selectedRating = rating;
      this.selectedRating = rating || null;
      if (this.selectedWIDs?.length > 0) {
        await this.fetchReviewsForAllLocations();
      }
    },

    getReviewCountByRating(rating) {
      return this.ratingCounts[rating] || 0;
    },
    clearRatingFilter() {
      this.selectedRating = null;
    },
    async handleLocationChange(profile) {
      this.selectedWIDs = this.stores
        .filter(item => item.checked) // Get checked items
        .map(item => item.id); // Extract their IDs

      // If selectedWIDs is empty, push all item IDs
      if (this.selectedWIDs.length === 0) {
        this.selectedWIDs = this.stores.map(item => item.id);
      }

      this.selectedWID = profile.wid;
      this.currentPage = 1; // Reset to first page
      if (this.selectedWIDs?.length > 0) {
        await this.fetchReviewsForAllLocations();
      }
    },
    startEditingReply(review) {
      if (review.reviewReply) {
        this.$set(review.reviewReply, "isEditing", true);
        this.$set(
          review.reviewReply,
          "editedComment",
          review.reviewReply.comment
        );
        // console.log("Editing started:", review.reviewReply.isEditing);
      }
    },
    startNewReply(review) {
      if (!review.reviewReply.comment) {
        this.$set(review.reviewReply, "isNewReply", true);
        this.$set(review.reviewReply, "newComment", "");
        // console.log("Reply started:", review.reviewReply.isNewReply);
      }
    },

    async deleteReply(review) {
      try {
        if (review.reviewReply?.comment) {
          this.$confirm.show({
            message: "Are you sure you want to delete this reply?",
            onConfirm: async () => {
              this.deletingReplyId = review.reviewId;
              const updateReply = httpsCallable(functions, "GmbReviewsManagement");
              let payload = {
                uid: this.currentUID || this.uid,
                wid: review.wid,
                account: `accounts/${review.accountId}`,
                location: `locations/${review.locationId}`,
                reviewId: review.reviewId,
                action: "delete_gmb_review",
              };
              let result = await updateReply(payload);
              if (result.data?.success) {
                this.$snackbar.show("Reply deleted successfully");
                this.$set(review, 'reviewReply', {
                  isNewReply: "",
                  newComment: "",
                  comment: "",
                  updateTime: null,
                  showFullComment: false,
                });
              } else if (result.data.error) {
                this.$snackbar.show({
                  message: result.data.error,
                  color: "error",
                });
              }
              this.deletingReplyId = null;
            },
          });
        }
      } catch (error) {
        console.error("Error deleting reply:", error);
        this.deletingReplyId = null;
      }
    },

    cancelNewReply(review) {
      if (!review.reviewReply.comment) {
        this.$set(review.reviewReply, "isNewReply", false);
        this.$set(review.reviewReply, "newComment", "");
      }
    },
    cancelEditingReply(review) {
      if (review.reviewReply) {
        this.$set(review.reviewReply, "isEditing", false);
        this.$set(
          review.reviewReply,
          "editedComment",
          review.reviewReply.comment
        );
      }
    },
    async submitReplyEdit(review) {
      if (!review.reviewReply?.editedComment?.trim()) {
        return;
      }

      this.updatingReplyId = review.reviewId;
      try {
        const updateReply = httpsCallable(functions, "GmbReviewsManagement");
        let payload = {
          uid: this.currentUID || this.uid,
          wid: review.wid,
          account: `accounts/${review.accountId}`,
          location: `locations/${review.locationId}`,
          comment: review.reviewReply.editedComment,
          reviewId: review.reviewId,
          action: "update_gmb_review",
        };

        let result = await updateReply(payload);

        if (result.data?.success) {
          this.$snackbar.show("Reply updated successfully");
          // review.reviewReply.comment = review.reviewReply.editedComment;
          // review.reviewReply.updateTime = result.data.data.updateTime;
          // review.reviewReply.isEditing = false;
          const index = this.allReviews.findIndex(r => r.reviewId === review.reviewId);
          if (index !== -1) {
            this.$set(this.allReviews, index, {
              ...review,
              reviewReply: {
                ...review.reviewReply,
                comment: review.reviewReply.editedComment,
                updateTime: new Date().toISOString(),
                isEditing: false,
              }
            });
          }

          review.reviewReply.updateStatus = {
            success: true,
            message: "Reply updated successfully",
          };

          setTimeout(() => {
            review.reviewReply.updateStatus = null;
          }, 3000);
        } else {
          throw new Error(result.data.error || "Failed to update reply");
        }
      } catch (error) {
        console.error("Error updating reply:", error);
        this.$toast.error("Failed to update reply. Please try again.");
      } finally {
        this.updatingReplyId = null;
      }
    },
    async submitNewReply(review) {
      if (!review.reviewReply?.newComment?.trim()) {
        return;
      }

      this.$set(review, 'isSubmittingNew', true);
      try {
        const updateReply = httpsCallable(functions, "GmbReviewsManagement");
        let payload = {
          uid: this.currentUID || this.uid,
          wid: review.wid,
          account: `accounts/${review.accountId}`,
          location: `locations/${review.locationId}`,
          comment: review.reviewReply.newComment,
          reviewId: review.reviewId,
          action: "update_gmb_review",
        };

        let result = await updateReply(payload);

        if (result.data?.success) {
          this.$snackbar.show("Replied successfully");
          // review.reviewReply.comment = review.reviewReply.newComment;
          // review.reviewReply.updateTime = result.data.data.updateTime;
          // review.reviewReply.isNewReply = false;
          const index = this.allReviews.findIndex(r => r.reviewId === review.reviewId);
          if (index !== -1) {
            this.$set(this.allReviews, index, {
              ...review,
              reviewReply: {
                ...review.reviewReply,
                comment: review.reviewReply.newComment,
                updateTime: new Date().toISOString(),
                isNewReply: false,
              },
              showSuggestions: false
            });
          }

          review.reviewReply.updateStatus = {
            success: true,
            message: "Reply updated successfully",
          };

          setTimeout(() => {
            review.reviewReply.updateStatus = null;
          }, 3000);
        } else {
          throw new Error(result.data.error || "Failed to update reply");
        }
      } catch (error) {
        console.error("Error updating reply:", error);
        this.$toast.error("Failed to update reply. Please try again.");
      } finally {
        this.$set(review, 'isSubmittingNew', false);
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },

    formatTime(dateString) {
      return new Date(dateString).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
    },
    async fetchGMBLogo() {
      if (this.gmb_logo) {
        return;
      }
      try {
        const platformDoc = await getDoc(
          doc(db, "fly_platforms", "google_business_profile")
        );
        if (platformDoc.exists()) {
          const logoUrl = platformDoc.data().logo_url;
          this.gmb_logo = logoUrl;
        }
      } catch (error) {
        console.error("Error fetching platform logo:", error);
      }
    },
    formatTimeAgo(dateString) {
      const date = new Date(dateString);
      const now = new Date();
      const diffMinutes = Math.floor((now - date) / (1000 * 60));

      if (diffMinutes < 60) return `${diffMinutes} mins ago`;
      if (diffMinutes < 1440)
        return `${Math.floor(diffMinutes / 60)} hours ago`;
      return `${Math.floor(diffMinutes / 1440)} days ago`;
    },

    isNewReview(dateString) {
      const date = new Date(dateString);
      const now = new Date();
      const diffHours = (now - date) / (1000 * 60 * 60);
      return diffHours < 24; // Consider reviews less than 24 hours old as new
    },

    toggleComment(review) {
      review.showFullComment = !review.showFullComment;
    },

    toggleReplyComment(review) {
      if (review.reviewReply) {
        review.reviewReply.showFullComment =
          !review.reviewReply.showFullComment;
      }
    },
    closeProfileDropdown() {
      if (this.showStoreDropdown) {
        this.showStoreDropdown = false;
      }
    },

    async fetchStores() {
      const uid = this.currentUID;
      const storeRef = collection(db, "users", uid, "fly_workspaces");
      const storeQuery = query(storeRef, where("admin", "==", this.WID), where("storeWorkspace", "==", true));
      const querySnapshot = await getDocs(storeQuery);
      this.stores = [];
      this.selectedWIDs = [];

      querySnapshot.forEach((doc) => {
        let storeData = doc.data();
        if ((storeData.storeWorkspace &&
          storeData.admin === this.WID &&
          storeData.connected_apps?.includes("google_business_profile")) || storeData.shallowConnect) {
          console.log("doc.id", doc.id);
          storeData.wid = doc.id;
          storeData.type = "store";
          storeData.checked = true;
          storeData.isReview = true;
          this.stores.push(storeData);
          this.selectedWIDs.push(doc.id);
        }
      });

      if (this.selectedWIDs.length > 0) {
        await this.fetchReviewsForAllLocations(); 
      }
    },

    handleImageError(e) {
      // Fallback to default avatar if image fails to load
      e.target.src = "path/to/default-avatar.png"; // Add your default avatar image
    },
    async fetchReviewsForAllLocations() {
      try {
        this.isLoading = true;

      // Get workspace details to identify shallow workspaces
      const workspacesDetails = await Promise.all(
        this.selectedWIDs.map(async (wid) => {
          const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", wid);
          const workspaceDoc = await getDoc(workspaceRef);
          return {
            wid,
            placeId: workspaceDoc.data()?.placeId,
            isShallow: workspaceDoc.exists() && workspaceDoc.data()?.shallowConnect
          };
        })
      );

      // Process all workspaces
      const accounts = [];
      
      // Process each workspace based on whether it's shallow or not
      for (let i = 0; i < workspacesDetails.length; i++) {
        const { wid, isShallow, placeId } = workspacesDetails[i];
        
        if (isShallow) {
          // For shallow workspaces, just add the wid without checking connections
          accounts.push({
            uid: this.currentUID,
            wid,
            location: placeId,
            isShallow: true
          });
        } else {
          // For regular workspaces, check for GMB connection
          const connectedAppsRef = collection(
            db,
            "users",
            this.currentUID,
            "fly_workspaces",
            wid,
            "connected-apps"
          );
          
          const gbpSnapshot = await getDocs(
            query(
              connectedAppsRef,
              where("__name__", "==", "google_business_profile")
            )
          );
          
          if (!gbpSnapshot.empty) {
            const gbpData = gbpSnapshot.docs[0].data();
            const accountId = gbpData.accounts?.[0]?.id || "";
            
            (gbpData.accounts || []).forEach((account) => {
              const locationId = account.locations?.[0]?.name || "";
              accounts.push({
                uid: this.currentUID,
                wid,
                location: locationId,
                account: accountId,
                isShallow: false
              });
            });
          }
        }
      }

        if (accounts.length === 0) {
          this.allReviews = [];
          this.totalPages = 0;
          this.currentPage = 0;
          return;
        }

        const fetchGmbReviews = httpsCallable(
          functions,
          "GmbReviewsManagement"
        );
        const payload = {
          uid: this.currentUID || this.UID,
          accounts,
          action: "get_gmb_reviews",
          platform: "google_business_profile",
          page: this.currentPage,
          pageSize: this.pageSize,
          starRating: this.selectedRating,
          needsAction: this.needsAction,
          sort: this.sortOrder,
        };
    console.log("payload", payload);

        const res = await fetchGmbReviews(payload);

        if (!res.data?.success || !res.data?.data) {
          console.log('Error fetching reviews:', res.data?.error);
        }
        // Process pagination and reviews
        const { pagination, reviews } = res.data.data;
        Object.assign(this, {
          // currentPage: pagination.currentPage,
          pageSize: pagination.pageSize,
          totalItems: pagination.totalItems,
          totalPages: pagination.totalPages || 0,
          currentPage: pagination.totalPages === 0 ? 0 : pagination.currentPage,
        });
        // Map reviews with optimized object creation
        const ratingMap = { ONE: 1, TWO: 2, THREE: 3, FOUR: 4, FIVE: 5 };
        this.allReviews = reviews.map((review) => ({
          reviewId: review.reviewId,
          displayName: review.reviewer?.displayName || "Anonymous",
          profilePhotoUrl: review.reviewer?.profilePhotoUrl || "",
          starRating: ratingMap[review.starRating] || 0,
          comment: review.comment || "",
          sub_locality: this.getStoreForWid(review.wid)?.sub_locality || "",
          createTime: review.createTime,
          updateTime: review.updateTime,
          locationId: review.location_id,
          accountId: review.name.split("/")[1] || '',
          locationTitle: review.gmb_location_name,
          showFullComment: false,
          wid: review.wid,
          reviewReply: review.reviewReply
            ? {
              comment: review.reviewReply.comment,
              updateTime: review.reviewReply.updateTime,
              showFullComment: false,
            }
            : {
              isNewReply: "",
              newComment: "",
            },
          needsAction: review.action_needed || false,
          actionTaken: review.action_taken || false,
          isActionLoading: false,
        }));
      } catch (error) {
        console.error("Error fetching metrics:", error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async toggleAutoRespond() {
      try {
        this.isAutoRespondLoading = true;

        // Update for each selected workspace

        const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
        await updateDoc(workspaceRef, {
          gmb_auto_respond: this.autoRespond
        });


        this.$snackbar.show(`Auto respond ${this.autoRespond ? 'enabled' : 'disabled'} successfully`);
      } catch (error) {
        console.error('Error updating auto respond setting:', error);
        this.$alert.show('Failed to update auto respond setting');
        this.autoRespond = !this.autoRespond; // Revert the toggle if failed
      } finally {
        this.isAutoRespondLoading = false;
      }
    },
    async toggleNeedsAction() {
      try {
        this.isNeedsActionLoading = true;

        // Update URL without reloading the page
        const query = { ...this.$route.query };
        if (this.needsAction) {
          query.action = 'true';
        } else {
          delete query.action;
        }

        await this.$router.replace({ query });
        this.currentPage = 1; // Reset to first page when toggling
        if (this.selectedWIDs?.length > 0) {
          await this.fetchReviewsForAllLocations();
        }
      } catch (error) {
        console.error('Error toggling needs action filter:', error);
        this.$alert.show('Failed to update needs action filter');
        this.needsAction = !this.needsAction; // Revert the toggle if failed
      } finally {
        this.isNeedsActionLoading = false;
      }
    },
    async handleActionTaken(review) {
      try {
        // Set loading state for this specific review
        this.$set(review, 'isActionLoading', true);

        const payload = {
          uid: this.currentUID,
          reviewId: review.reviewId,
          locationId: review.locationId,
          accountId: review.accountId,
          wid: review.wid,
          action: "update_action_status"
        };

        // Call your Cloud Function
        const updateActionStatus = httpsCallable(functions, "GmbReviewsManagement");
        const response = await updateActionStatus(payload);

        if (response.data?.success) {
          // Update the review status locally
          this.$set(review, 'actionTaken', true);
          this.$snackbar.show('Review marked as actioned successfully');
        } else {
          throw new Error('Failed to update action status');
        }
      } catch (error) {
        console.error('Error updating action status:', error);
        this.$alert.show('Failed to update action status');
        // Revert the checkbox state
        this.$set(review, 'actionTaken', false);
      } finally {
        this.$set(review, 'isActionLoading', false);
      }
    },
    async getWorkspaceName() {
      const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
      const docSnap = await getDoc(workspaceRef);

      if (docSnap.exists()) {
        const name = docSnap.data().name;
        if (name != 'default' && name !== undefined) {
          return name;
        }
      }

      return "";
    },
    async getBusinessDescription(review) {
      try {
        const connectedAppsRef = doc(
          db,
          "users",
          this.currentUID,
          "fly_workspaces",
          review.wid,
          "connected-apps",
          "google_business_profile"
        );

        const docSnap = await getDoc(connectedAppsRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          // Navigate through the nested structure
          const description = data?.accounts?.[0]?.locations?.[0]?.description;
          return description || "";
        }

        return "";
      } catch (error) {
        console.error("Error fetching business description:", error);
        return "";
      }
    },
    async getCustomGmbReply(wid, ai_input){
        try{
          let userDoc = doc(db,"users", this.currentUID, "fly_workspaces", wid);
          const userDocSnap = await getDoc(userDoc);
          let customReply = {};
          if (userDocSnap.exists()) {
            customReply = userDocSnap.data().gmb_custom_reply || {};
          }
          
            console.log("these are the data ", customReply, ai_input);
            if(customReply?.description && customReply?.valid_until && customReply?.starts_from){
              const current_date = new Date();
              const validUntilDate = customReply.valid_until.toDate();
              const startsFrom = customReply.starts_from.toDate();
              if(validUntilDate>=current_date && current_date>=startsFrom){
                return {status: true, customReply};
              }
            }
            return {status: false, customReply};
          }catch(error){
            console.log("Error while retriving custom reponse status",error);
            return {status: false, customReply:{}};
          }
    },
    async openSuggestionsDrawer(review) {
      // Set initial state
      this.$set(review, 'showSuggestions', true);
      this.$set(review, 'isLoadingSuggestions', true);
      this.$set(review, 'suggestedReplies', []);
      this.$set(review, 'sendingReply', false);

      try {
        console.log("Review ", review);
        let customer = review?.displayName || "";
        let companyName = await this.getWorkspaceName() || "";
        let businessDescription = await this.getBusinessDescription(review) || "";
        console.log("This is the company name ", businessDescription);
        let payload = {
          operation: 'generateReviewReplies',
          rating: review.starRating,
          textContent: review.comment,
          customer,
          companyName,
          businessDescription
        };
        let {status, customReply} = await this.getCustomGmbReply(review.wid, payload);
        if(status){
          payload.offerDescription = customReply.description;
          const validUntilDate = customReply.valid_until.toDate();
          payload.validUntil = validUntilDate
          payload.operation = "generateCustomOfferReply";
        }

        const aiAction = httpsCallable(functions, 'aiActions');
        const result = await aiAction(payload);

        if (result.data?.result?.output?.reply) {
          this.$set(review, 'suggestedReplies', result.data.result.output.reply);
        } else {
          throw new Error('No suggestions received');
        }
      } catch (error) {
        console.error('Error getting suggestions:', error);
        this.$alert.show('Failed to get reply suggestions');
      } finally {
        this.$set(review, 'isLoadingSuggestions', false);
      }
    },
    async fetchAutoRespondStatus() {
      try {
        const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
        const workspaceDoc = await getDoc(workspaceRef);

        if (workspaceDoc.exists()) {
          const workspaceData = workspaceDoc.data();
          this.autoRespond = workspaceData.gmb_auto_respond || false; // Default to false if field is not present
          this.autoRespondNegative = workspaceData.gmb_negative_respond || {
            value: false,
            type: "",
            reply: "",
          };
        }
      } catch (error) {
        console.error("Error fetching auto-respond status:", error);
        this.autoRespond = false; // Ensure a default value on error
      }
    },

    async useSuggestedReply(review, reply) {
      if (review.sendingReply) return;

      this.$set(review, 'sendingReply', true);
      try {
        // Set up the reply
        this.$set(review.reviewReply, 'newComment', reply);

        // Use existing submit logic
        await this.submitNewReply(review);

        // Close suggestions on success
        this.$set(review, 'showSuggestions', false);
      } catch (error) {
        console.error('Error sending suggested reply:', error);
        this.$alert.show('Failed to send reply');
      } finally {
        this.$set(review, 'sendingReply', false);
      }
    },
    async showReport() {
      this.$router.push('/gmb-reviews-report')
    },
    async showLocationLatestReview() {
      this.$router.push('/gmb-no-locations')
    },
    async closePopup() {
      if (this.autoRespondNegative) {
        if (!this.autoRespondNegative?.type) {
          this.autoRespondNegative = {
            value: false,
            reply: "",
            type: "",
          };
        } else if (!this.autoRespondNegative?.reply && this.autoRespondNegative?.type === "manual") {
          await this.fetchAutoRespondStatus();
        }
      }
      this.showWelcomePopup = false;
    },
    async submitManualReply() {
      try {
        const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
        await updateDoc(workspaceRef, {
          gmb_negative_respond: {
            value: true,
            reply: this.autoRespondNegative.reply,
            type: "manual",
          },
        });
        await this.closePopup();
        this.$snackbar.show("Manual reply submitted successfully");
      } catch (error) {
        console.log("error ", error);
        this.$alert.show("Failed to submit manual reply");
      }
    },
    async updateAutoRespond() {
      try {
        const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
        if (this.autoRespond) {
          await updateDoc(workspaceRef, {
            gmb_auto_respond: this.autoRespond,
          });
        }
        else {
          await updateDoc(workspaceRef, {
            gmb_auto_respond: this.autoRespond,
            gmb_negative_respond: {
              value: false,
              reply: "",
              type: "",
            },
          });
          this.autoRespondNegative = {
            value: false,
            reply: "",
            type: "",
          };
        }
        this.$snackbar.show(`Auto respond ${this.autoRespond ? 'enabled' : 'disabled'} successfully`);
      } catch (error) {
        console.error('Error updating auto respond setting:', error);
        this.$alert.show('Failed to update auto respond setting');
      }
    },
    async updateNegativeRespond() {
      try {
        const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
        if (this.autoRespondNegative.value && this.autoRespondNegative.type) {
          this.autoRespondNegative.reply = "";
          await updateDoc(workspaceRef, {
            gmb_negative_respond: this.autoRespondNegative,
          });
          this.$snackbar.show(`AI Auto respond enabled`);
        } else {
          await updateDoc(workspaceRef, {
            gmb_negative_respond: {
              value: false,
              reply: "",
              type: "",
            },
          });
        }
      } catch (error) {
        console.error('Error updating negative respond setting:', error);
        this.$alert.show('Failed to update negative respond setting');
      }
    },
  },
  mounted() {
    this.fetchGMBLogo();
    this.fetchStores();
    this.fetchAutoRespondStatus();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
<style scoped>
textarea {
  min-height: 80px;
  resize: vertical;
  border-color: #e5e7eb;
  /* Tailwind gray-300 */
  background-color: #ffffff;
  transition: all 0.15s ease-in-out;
}

textarea:focus {
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid rgb(145, 143, 143);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  /* To position the close button */
  width: 30%;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: red
}

.border {
  border-width: 1px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.form-checkbox {
  @apply rounded border-gray-300;
}

.form-checkbox:checked {
  @apply bg-blue-600 border-blue-600;
}

.form-checkbox:disabled {
  @apply opacity-50 cursor-not-allowed;
}

.overflow-wrap {
  overflow-wrap: anywhere;
}

.custom-dropdown {
  top: 2.5rem; /* Adjust this value as needed */
}
</style>