<template>
    <div class="gmb-menu-container">
        <!-- Static Header -->
        <div class="static-header">
            <div class="header-content">
                <v-tabs v-model="menu.tab" class="menu-tabs">
                    <v-tab>Full menu</v-tab>
                </v-tabs>
            </div>
        </div>

        <!-- Scrollable Content -->
        <div class="">
            <v-container v-if="isFetchingMenu" class="loading-container">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                <p class="text-center mt-2">Loading menu...</p>
            </v-container>

            <!-- Add Section or Item Form -->
            <v-container v-else-if="isAddingSection || isAddingItem" class="form-container">
                <div class="form-header">
                    <h2 class="form-title">
                        {{ isAddingItem ? menu.sections[selectedSection]?.labels?.[0]?.displayName : 'Add a new section'
                        }}
                    </h2>
                    <v-btn icon @click="clearMenu">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-row class="form-body">
                    <v-col cols="12" v-if="isAddingSection">
                        <v-text-field v-model="newSectionName" label="Section name*" required maxlength="140" counter
                            outlined dense></v-text-field>
                        <div class="text-right text-sm text-gray-500">{{ newSectionName.length }}/140</div>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="menuItem.name" label="Item name*" maxlength="140" required counter
                            outlined dense></v-text-field>
                        <div class="text-right text-sm text-gray-500">{{ menuItem.name.length }}/140</div>
                        <v-text-field v-model="menuItem.price" label="Item price (INR)" type="number" required outlined
                            dense></v-text-field>
                        <v-textarea v-model="menuItem.description" label="Item description" maxlength="1000" counter
                            outlined dense></v-textarea>
                        <div class="text-right text-sm text-gray-500">{{ menuItem.description.length }}/1000</div>
                    </v-col>
                    <v-col cols="6" class="image-upload">
                        <div v-if="!menuItem.imagePreview" class="upload-box" @click="triggerFileInput"
                            @dragover.prevent @drop="handleDrop">
                            <p v-if="menuItem.existingImage" class="existing-image-text">This item already has an image
                            </p>
                            <p class="text-gray-500">Drag image here</p>
                            <p class="text-gray-500">or</p>
                            <v-btn text color="primary">
                                <v-icon left>mdi-plus</v-icon> Select image
                            </v-btn>
                            <input ref="fileInput" type="file" accept="image/*" class="hidden"
                                @change="handleImageUpload" />
                        </div>
                        <div v-else class="image-preview">
                            <v-progress-linear v-if="menuItem.uploadProgress" :value="menuItem.uploadProgress"
                                height="4" class="mb-2"></v-progress-linear>
                            <img :src="menuItem.imagePreview" class="preview-img" alt="Uploaded Image" />
                            <v-btn text color="red" @click="removeUploadedImage">
                                <v-icon left>mdi-delete</v-icon> Remove
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <span class="font-semibold">Dietary restrictions</span>
                        <v-checkbox v-for="(diet, index) in dietaryOptions" :key="index"
                            v-model="menuItem.dietaryRestriction" :label="diet" :value="diet" dense></v-checkbox>
                    </v-col>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <div class="form-actions">
                    <v-btn text @click="clearMenu">Back</v-btn>
                    <v-btn color="primary" @click="saveNewMenu">Save</v-btn>
                </div>
            </v-container>

            <!-- Menu Display -->
            <v-container v-else class="menu-container">
                <v-window v-model="menu.tab">
                    <v-window-item>
                        <draggable v-model="menu.sections" handle=".drag-handle" >
                            <div v-for="(section, secIndex) in menu.sections" :key="secIndex" class="menu-section">
                                <div class="section-header">
                                    <v-icon class="drag-handle">mdi-drag-vertical</v-icon>
                                    <h2 v-if="editingSectionIndex !== secIndex" class="section-title">
                                        {{ section.labels[0]?.displayName }}
                                        <v-icon small class="ml-2 cursor-pointer"
                                            @click="editSectionName(secIndex)">mdi-pencil</v-icon>
                                    </h2>
                                    <input v-else v-model="editableSectionName" class="section-edit-input"
                                        @blur="saveSectionName(secIndex)" @keyup.enter="saveSectionName(secIndex)"
                                        :ref="(el) => ($refs.inputRef[secIndex] = el)" />
                                    <v-btn icon @click="confirmDeleteSection(secIndex)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                                <draggable v-model="section.items" handle=".drag-handle" >
                                    <div v-for="(item, itemIndex) in section.items" :key="itemIndex" class="menu-item">
                                        <v-icon class="drag-handle">mdi-drag-vertical</v-icon>
                                        <div class="item-content">
                                            <h3>{{ item.labels[0]?.displayName }}</h3>
                                            <p v-if="item.labels[0]?.description">{{ item.labels[0].description }}</p>
                                            <p v-if="item.attributes?.dietaryRestriction?.length">
                                                {{ item.attributes.dietaryRestriction[0]?.toLowerCase() }}
                                            </p>
                                            <p class="price">₹{{ item.attributes?.price?.units }}</p>
                                        </div>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon v-on="on">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item @click="editMenuItem(secIndex, itemIndex)">
                                                    <v-list-item-title>Edit Item</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="confirmDeleteItem(secIndex, itemIndex)">
                                                    <v-list-item-title>Delete Item</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </draggable>
                                <v-btn text color="primary" @click="openAddItem(secIndex)" class="add-item-btn">
                                    <v-icon left>mdi-plus</v-icon> Add menu item
                                </v-btn>
                            </div>
                        </draggable>
                        <v-btn text color="primary" @click="isAddingSection = true" class="add-section-btn">
                            <v-icon left>mdi-plus</v-icon> Add menu section
                        </v-btn>
                    </v-window-item>
                </v-window>
            </v-container>
        </div>

        <!-- Static Footer -->
        <div class="static-footer" v-if="!(isAddingSection || isAddingItem)">
            <div class="flex items-center justify-end gap-4">
                <!-- Add Update Results Chip -->
                <v-chip
                    v-if="updateResults.length"
                    class="mr-2"
                    :color="hasErrors ? 'error' : 'success'"
                    outlined
                    @click="showUpdateResults = true"
                >
                    <v-icon left size="16">
                        {{ hasErrors ? 'mdi-alert-circle' : 'mdi-check-circle' }}
                    </v-icon>
                    Update Results
                </v-chip>

                <v-btn @click="updateMenu" :loading="isUpdating" :disabled="isUpdating || checkChanges" class="update-btn">
                    <span class="flex items-center space-x-2">
                        <img src="@/assets/img/icons8-google-48.png" alt="Google Icon" class="w-5 h-5" />
                        <span v-if="isBulk" class="font-bold">Update for {{ locations.length }} locations</span>
                        <span v-else class="font-bold">Update on Google</span>
                    </span>
                </v-btn>
            </div>
        </div>

        <!-- Delete Confirmation Dialog -->
        <v-dialog v-model="confirmDialog" max-width="400">
            <v-card>
                <v-card-title class="dialog-title">Confirm Deletion</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this {{ deleteType }}?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="confirmDialog = false">Cancel</v-btn>
                    <v-btn color="error" @click="deleteConfirmed">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Add Update Results Dialog -->
        <v-dialog v-model="showUpdateResults" max-width="600px" scrollable>
            <v-card>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span>Update Results</span>
                    <v-btn icon @click="showUpdateResults = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                
                <v-card-text class="pt-4">
                    <!-- Summary Stats -->
                    <div class="mb-4 flex gap-4">
                        <v-chip outlined color="success">
                            Success: {{ successCount }}
                        </v-chip>
                        <v-chip outlined color="error">
                            Failed: {{ failedCount }}
                        </v-chip>
                    </div>

                    <!-- Results Table -->
                    <v-data-table
                        :headers="[
                            { text: 'Location', value: 'title' },
                            { text: 'Status', value: 'status' }
                        ]"
                        :items="updateResults"
                        :items-per-page="10"
                        :footer-props="{
                            'items-per-page-options': [10, 25, 50, 100]
                        }"
                        dense
                    >
                        <template v-slot:item.status="{ item }">
                            <v-chip
                                small
                                :color="item.status === 'success' ? 'success' : 'error'"
                                text-color="white"
                            >
                                {{ item.status }}
                            </v-chip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import draggable from 'vuedraggable';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, functions, storage } from '@/utils/firebase.utils';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';

export default {
    props: {
        locations: {
            type: Array,
            required: true,
        }
    },
    components: {
        draggable,
    },
    data() {
        return {
            isFetchingMenu: false,
            isAddingItem: false,
            isAddingSection: false,
            newSectionName: '',
            menuItem: {
                isEditing: false,
                name: '',
                price: '',
                description: '',
                file: null,
                imagePreview: null,
                uploadProgress: 0,
                downloadURL: '',
                dietaryRestriction: '',
                existingImage: null,
            },
            dietaryOptions: ['VEGETARIAN', 'VEGAN'],
            menu: { sections: [], tab: 0 },
            originalMenu: [],
            isUpdating: false,
            selectedSection: null,
            editableSectionName: '',
            editingSectionIndex: null,
            selectedItem: null,
            confirmDialog: false,
            deleteType: '',
            deleteIndex: null,
            deleteSectionIndex: null,
            deletedMedia: [],
            showUpdateResults: false,
            updateResults: [],
        };
    },
    created() {
        this.$refs.inputRef = {};
        this.fetchMenu();
    },
    computed: {
        isBulk() {
            return this.locations.length > 1 ? true : false;
        },
        checkChanges() {
            const menu = JSON.stringify(this.menu.sections);
            const originalMenu = JSON.stringify(this.originalMenu);
            return menu === originalMenu;
        },
        successCount() {
            return this.updateResults.filter(result => result.status === 'success').length;
        },
        failedCount() {
            return this.updateResults.filter(result => result.status === 'failed').length;
        },
        hasErrors() {
            return this.failedCount > 0;
        }
    },
    methods: {
        async fetchMenu() {
            if (this.locations[0].name === this.menu?.locationId) {
                this.dialogMenu = true;
                return;
            }
            this.isFetchingMenu = true;
            const wid = this.locations[0].workspaceId;
            const uid = this.currentUID;
            try {
                if (!this.isBulk) {
                    const fetchGMBMenu = httpsCallable(functions, 'fetchGmbMenuFunction');
                    const res = await fetchGMBMenu({ wid, uid, locationId: this.locations[0].name });
                    this.menu = { locationId: this.locations[0].name, sections: res.data?.menus[0]?.sections || [], tab: 0 };
                    this.originalMenu = JSON.parse(JSON.stringify(res.data?.menus[0]?.sections || []));
                }
                this.dialogMenu = true;
            } catch (error) {
                console.error('Error fetching Menu Details:', error);
                this.$snackbar.show('Failed to fetch Menu');
            } finally {
                this.isFetchingMenu = false;
            }
        },
        async uploadGmbHistoryJson(currentUID, WID, historyId, changes) {
            try {
                const filePath = `gmb_history/${currentUID}/${WID}/${historyId}.json`;
                const storageRef = ref(storage, filePath);

                // Convert the changes object to JSON
                const historyData = JSON.stringify(changes);

                // Create a Blob for the JSON file
                const blob = new Blob([historyData], { type: "application/json" });

                // Upload JSON file to Firebase Storage
                await uploadBytes(storageRef, blob);

                console.log(`History JSON uploaded successfully: ${filePath}`);
                return filePath;
            } catch (error) {
                console.error("Error uploading history JSON:", error);
                throw error;
            }
        },
        getLocationName(location){
            return `${location.title} ${location?.storefrontAddress?.addressLines?.[0]?.split(',')[0]},
                                                ${location?.storefrontAddress?.locality} ,
                                                ${location?.storefrontAddress?.administrativeArea}`
        },
        async updateMenu() {
            try {
                this.isUpdating = true;
                const changes = [];
                let flag = false;
                const updates = this.locations.map(location => {
                    const updateFields = {};
                    updateFields.menuSection = this.menu.sections;
                    if (!flag) {
                        changes.push({
                            id: 'menuSection',
                            field: 'Menu',
                            value: JSON.stringify(this.menu.sections),
                            new: 'Updated menu with revised items and prices'
                        })
                    }

                    // Only return if there are fields to update
                    flag = true;
                    return Object.keys(updateFields).length > 0 ? {
                        locationId: location.name,
                        updateFields,
                        displayName: location.storeLocation,
                        wid: location.workspaceId,
                        accountId: location.accountId
                    } : null;
                }).filter(Boolean);
                if (updates.length === 0) {
                    this.$snackbar.show('No changes to update');
                    return;
                }
                const payload = {
                    uid: this.currentUID,
                    workspace_id: this.WID,
                    updates
                };
                if (this.deletedMedia.length) {
                    payload.mediaKeys = this.deletedMedia;
                    payload.location_wid = this.locations[0].workspaceId;
                }

                const generateGmbKeys = httpsCallable(functions, "generateGmbMediaKeysFunction");
                const gmbKeyResponse = await generateGmbKeys(payload);
                payload.updates = gmbKeyResponse.data?.updatedResults;
                this.failedMenuUploads = gmbKeyResponse.data?.failedUpdates || {};

                if (Object.keys(this.failedMenuUploads).length > 0) {
                    this.failedMenuDialogVisible = true;
                    return;
                }
                const updateGMBMenu = httpsCallable(functions, 'updateGmbMenuFunction');
                const res = await updateGMBMenu(payload);
                
                if (res?.data?.results) {
                    // Create a location map for faster lookups
                    const locationMap = {};
                    this.locations.forEach(location => {
                        locationMap[location.workspaceId] = this.getLocationName(location);
                    });

                    // Map results with titles using the lookup map
                    this.updateResults = res.data.results.map(item => ({
                        ...item,
                        title: locationMap[item.wid] || 'Unknown Location'
                    }));

                    // Count successful and failed updates
                    const successCount = res.data.results.filter(result => result.status === "success").length;
                    const failedCount = res.data.results.filter(result => result.status === "failed").length;

                    if (this.isBulk) {
                        await addDoc(collection(db, "users", this.currentUID, "fly_workspaces", this.WID, "gmb-update-history"),
                            {
                                requested_by: this.currentUser.display_name || "unknow",
                                title: "Request for bulk Update",
                                type: 'multiple',
                                address: ``,
                                changes,
                                updateResults: this.updateResults,
                                requested_at: new Date(),
                            }
                        );
                    }
                    else {
                        await addDoc(collection(db, 'users', this.currentUID, 'fly_workspaces', this.WID, 'gmb-update-history'), {
                            requested_by: this.currentUser.display_name || 'unknown',
                            locationId: this.locations[0].name,
                            type: 'single',
                            title: this.locations[0].title,
                            address: `${this.locations[0]?.storefrontAddress?.addressLines?.[0]?.split(',')[0]}, ${this.locations[0]?.storefrontAddress?.locality}, ${this.locations[0]?.storefrontAddress?.administrativeArea}`,
                            changes,
                            requested_at: new Date(),
                            updateResults: this.updateResults,
                            wid: this.locations[0].workspaceId,
                            accountId: this.locations[0].accountId || '',
                        });
                    }
                    const currDate = new Date();
                    if (this.locations.length > 1) {
                        const workspaceRef = doc(db, `users/${this.currentUID}/fly_workspaces/${this.WID}`);
                        await updateDoc(workspaceRef, { gmb_bulk_last_updated: currDate });
                    }
                    else {
                        const workspaceRef = doc(db, `users/${this.currentUID}/fly_workspaces/${this.locations[0].workspaceId}`);
                        await updateDoc(workspaceRef, { gmb_last_updated: currDate });
                    }

                    // Show the results dialog if there are any failures
                    if (failedCount > 0) {
                        this.showUpdateResults = true;
                    } else {
                        this.$snackbar.show(`Menu successfully updated for all ${successCount} locations`);
                    }
                }
            } catch (error) {
                console.error('Error updating Menu:', error);
                this.$alert.show('Error updating Menu');
            } finally {
                this.isUpdating = false;
            }
        },
        openAddItem(sectionIndex) {
            this.selectedSection = sectionIndex;
            this.isAddingItem = true;
        },
        saveNewMenu() {
            let mediaURL = this.menuItem.downloadURL;
            if (this.isAddingItem) {
                if (!this.menuItem.name) {
                    alert('Section name, Item name are required!');
                    return;
                }
                if (this.menuItem.isEditing) {
                    let existingItem = this.menu.sections[this.selectedSection].items[this.selectedItem];
                    this.menu.sections[this.selectedSection].items[this.selectedItem] = {
                        ...existingItem,
                        labels: [{ ...existingItem?.labels?.[0], displayName: this.menuItem.name, description: this.menuItem.description }],
                        attributes: {
                            ...existingItem.attributes,
                            price: { currencyCode: 'INR', units: this.menuItem.price },
                            dietaryRestriction: this.menuItem.dietaryRestriction ? [this.menuItem.dietaryRestriction] : [],
                            ...(mediaURL ? { newMedia: [mediaURL] } : {}),
                        },
                    };
                } else {
                    this.menu.sections[this.selectedSection].items.push({
                        labels: [{ displayName: this.menuItem.name, description: this.menuItem.description }],
                        attributes: {
                            price: { currencyCode: 'INR', units: this.menuItem.price },
                            dietaryRestriction: this.menuItem.dietaryRestriction ? [this.menuItem.dietaryRestriction] : [],
                            ...(mediaURL ? { newMedia: [mediaURL] } : {}),
                        },
                    });
                }
            } else {
                if (!this.menuItem.name || !this.newSectionName) {
                    alert('Section name and Item name are required!');
                    return;
                }
                this.menu.sections.push({
                    labels: [{ displayName: this.newSectionName }],
                    items: [
                        {
                            labels: [{ displayName: this.menuItem.name, description: this.menuItem.description }],
                            attributes: {
                                price: { currencyCode: 'INR', units: this.menuItem.price },
                                dietaryRestriction: this.menuItem.dietaryRestriction ? [this.menuItem.dietaryRestriction] : [],
                                ...(mediaURL ? { newMedia: [mediaURL] } : {}),
                            },
                        },
                    ],
                });
            }
            this.menu.sections = [...this.menu.sections];
            this.clearMenu();
        },
        clearMenu() {
            this.isAddingItem = false;
            this.isAddingSection = false;
            this.newSectionName = '';
            this.selectedSection = null;
            this.selectedItem = null;
            this.menuItem = {
                isEditing: false,
                name: '',
                price: '',
                description: '',
                file: null,
                imagePreview: null,
                uploadProgress: 0,
                downloadURL: '',
                existingImage: null,
                dietaryRestriction: '',
            };
        },
        triggerFileInput() {
            if (this.$refs.fileInput) this.$refs.fileInput.click();
        },
        handleDrop(event) {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            if (file) {
                this.menuItem.file = file;
                this.menuItem.imagePreview = URL.createObjectURL(file);
                this.uploadImageToFirebase(file);
            }
        },
        handleImageUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.menuItem.file = file;
                this.menuItem.imagePreview = URL.createObjectURL(file);
                this.uploadImageToFirebase(file);
            }
        },
        async uploadImageToFirebase(file) {
            try {
                const resizedFile = await this.resizeImage(file, 250, 250);
                const storageRef = ref(storage, `gmb-menu/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, resizedFile);
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        this.menuItem.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    },
                    (error) => console.error('Upload failed', error),
                    async () => {
                        this.menuItem.downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    }
                );
            } catch (error) {
                console.error('Error processing image:', error);
            }
        },
        async resizeImage(file, minWidth = 250, minHeight = 250) {
            const dataUrl = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
            const img = await new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = dataUrl;
            });
            let { width, height } = img;
            const targetAspectRatio = 4 / 3;
            let targetWidth = width;
            let targetHeight = width / targetAspectRatio;
            if (targetHeight > height) {
                targetHeight = height;
                targetWidth = height * targetAspectRatio;
            }
            const cropX = (width - targetWidth) / 2;
            const cropY = (height - targetHeight) / 2;
            const scaleFactor = Math.max(minWidth / targetWidth, minHeight / targetHeight);
            const newWidth = Math.ceil(targetWidth * scaleFactor);
            const newHeight = Math.ceil(targetHeight * scaleFactor);
            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = '#FFFFFF';
            ctx.fillRect(0, 0, newWidth, newHeight);
            ctx.drawImage(img, cropX, cropY, targetWidth, targetHeight, 0, 0, newWidth, newHeight);
            const blob = await new Promise((resolve) => canvas.toBlob((blob) => resolve(blob), 'image/jpeg'));
            return new File([blob], file.name.replace(/\.\w+$/, '.jpg'), { type: 'image/jpeg' });
        },
        removeUploadedImage() {
            this.menuItem.imagePreview = '';
            this.menuItem.downloadURL = '';
            if (this.menuItem.isEditing && this.selectedSection !== null && this.selectedItem !== null) {
                delete this.menu.sections[this.selectedSection].items[this.selectedItem].attributes?.newMedia;
            }
            this.menuItem.uploadProgress = 0;
        },
        editMenuItem(sectionIndex, itemIndex) {
            const item = this.menu.sections[sectionIndex].items[itemIndex];
            this.isAddingItem = true;
            this.menuItem = {
                isEditing: true,
                name: item?.labels?.[0]?.displayName || '',
                price: item?.attributes?.price?.units || '',
                description: item?.labels?.[0]?.description || '',
                file: null,
                imagePreview: item.attributes?.newMedia?.[0] || null,
                existingImage: item.attributes?.mediaKeys?.[0] || null,
                uploadProgress: 0,
                downloadURL: '',
                dietaryRestriction: item?.attributes?.dietaryRestriction?.[0] || '',
            };
            this.selectedSection = sectionIndex;
            this.selectedItem = itemIndex;
        },
        deleteMenuItem(sectionIndex, itemIndex) {
            if (confirm('Are you sure you want to delete this item?')) {
                if (this.menu.sections[sectionIndex].items[itemIndex]?.attributes?.mediaKeys?.length) {
                    this.deletedMedia.push(this.menu.sections[sectionIndex].items[itemIndex]?.attributes?.mediaKeys[0]);
                }
                this.menu.sections[sectionIndex].items.splice(itemIndex, 1);
                this.$snackbar.show('Item deleted successfully');
            }
        },
        moveItemUp(sectionIndex, itemIndex) {
            if (itemIndex > 0) {
                const items = this.menu.sections[sectionIndex].items;
                const itemToMove = items[itemIndex];
                items.splice(itemIndex, 1);
                items.splice(itemIndex - 1, 0, itemToMove);
            }
        },
        moveItemDown(sectionIndex, itemIndex) {
            const items = this.menu.sections[sectionIndex].items;
            if (itemIndex < items.length - 1) {
                const itemToMove = items[itemIndex];
                items.splice(itemIndex, 1);
                items.splice(itemIndex + 1, 0, itemToMove);
            }
        },
        editSectionName(index) {
            this.editingSectionIndex = index;
            this.editableSectionName = this.menu.sections[index].labels[0].displayName;
            this.$nextTick(() => {
                if (this.$refs.inputRef && this.$refs.inputRef[index]) this.$refs.inputRef[index].focus();
            });
        },
        saveSectionName(index) {
            if (this.editableSectionName.trim()) {
                this.menu.sections[index].labels[0].displayName = this.editableSectionName;
            }
            this.editingSectionIndex = null;
        },
        addDeletedSectionMedia(sectionIndex) {
            this.menu.sections[sectionIndex].items.forEach((item) => {
                if (item?.attributes?.mediaKeys?.[0].length) {
                    this.deletedMedia.push(item?.attributes?.mediaKeys[0]);
                }
            })
        },
        confirmDeleteSection(sectionIndex) {
            this.deleteType = 'section';
            this.deleteIndex = sectionIndex;
            this.confirmDialog = true;
        },
        confirmDeleteItem(sectionIndex, itemIndex) {
            this.deleteType = 'item';
            this.deleteSectionIndex = sectionIndex;
            this.deleteIndex = itemIndex;
            this.confirmDialog = true;
        },
        deleteConfirmed() {
            if (this.deleteType === 'section') {
                this.addDeletedSectionMedia(this.deleteIndex);
                this.menu.sections.splice(this.deleteIndex, 1);
            } else if (this.deleteType === 'item') {
                if (this.menu.sections[this.deleteSectionIndex].items[this.deleteIndex]?.attributes?.mediaKeys?.length) {
                    this.deletedMedia.push(this.menu.sections[this.deleteSectionIndex].items[this.deleteIndex].attributes.mediaKeys[0]);
                }
                this.menu.sections[this.deleteSectionIndex].items.splice(this.deleteIndex, 1);
            }
            this.$snackbar.show(`${this.deleteType} deleted successfully`);
            this.confirmDialog = false;
        },
    },
};
</script>
<style scoped>
.gmb-menu-container {
    display: flex;
    flex-direction: column;
    /* Full viewport height */
}

.static-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
}

.header-content {
    max-width: 1200px;
    margin: 0 auto;
}

.header-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 8px;
}

.menu-tabs {
    border-bottom: none;
}

.scrollable-content {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.form-container {
    max-width: 800px;
    margin: 0 auto;
}

.form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.form-title {
    font-size: 1.25rem;
    font-weight: 500;
}

.form-body {
    margin-top: 16px;
}

.image-upload {
    display: flex;
    justify-content: center;
}

.upload-box {
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.2s;
}

.upload-box:hover {
    background-color: #f0f0f0;
}

.existing-image-text {
    font-weight: 500;
    color: #333;
}

.image-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview-img {
    max-height: 128px;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.menu-container {
    max-width: 1200px;
    margin: 0 auto;
}

.menu-section {
    margin-bottom: 24px;
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.section-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
}

.drag-handle {
    cursor: move;
    color: #666;
}

.section-title {
    flex: 1;
    font-size: 1.25rem;
    font-weight: 500;
}

.section-edit-input {
    flex: 1;
    padding: 4px 8px;
    font-size: 1.25rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 8px;
    background: #fafafa;
    border-radius: 6px;
    transition: background-color 0.2s;
}

.menu-item:hover {
    background: #f0f0f0;
}

.item-content {
    flex: 1;
    margin-left: 12px;
}

.item-content h3 {
    font-size: 1rem;
    font-weight: 500;
}

.item-content p {
    margin: 4px 0;
    color: #666;
}

.price {
    font-weight: bold;
    color: #333;
}

.add-item-btn,
.add-section-btn {
    margin-top: 12px;
}

.static-footer {
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 10;
    padding: 16px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: flex-end;
}

.update-btn {
    background: white;
    color: #4285f4;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 6px;
    transition: all 0.2s;
}

.update-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.dialog-title {
    font-size: 1.25rem;
    font-weight: 500;
}
</style>