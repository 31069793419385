<template>
  <div v-if="
    $route.name !== 'lite'
  ">
    <div v-if="
      $route.path !== '/resources' &&
      $route.path !== '/login-phone' &&
      $route.name !== 'login-email' &&
      $route.name !== 'lite'
    ">
      <div v-if="!isAuth" class="justify-center pt-20 pb-10 d-flex">
        <router-link to="/">
          <img width="100px" src="../assets/img/fly-new-logo.png" alt="logo" /></router-link>
      </div>
      <v-app-bar app height="64" class="white app-header" flat v-else>
        <v-app-bar-nav-icon @click="drawer = !drawer" class="d-flex d-lg-none"></v-app-bar-nav-icon>
        <div class="justify-center d-flex flex-column">
          <div class="text-base sm:text-lg flex items-center" style="line-height: 1em !important">
            <!-- Display Workspace Logo -->
            <v-list-item-avatar v-if="currentWorkspaceLogo" class="mr-2">
              <img :src="currentWorkspaceLogo" alt="Workspace Logo" class="h-6 w-6 rounded-full">
            </v-list-item-avatar>
            <!-- Display Workspace Name -->
            <span v-if="currentWorkspaceName || selectedTab" class="text-gray-900 text-base font-bold">
              {{ currentWorkspaceName }}
            </span>
            <!-- Modify the selectedTab span to hide on mobile -->
            <span v-if="selectedTab" class="text-gray-900 text-base font-bold d-none d-md-flex">
              <span class="mx-1">|</span>{{ selectedTab }}
            </span>


          </div>
        </div>
        <v-spacer></v-spacer>

        <div class="items-center justify-between gap-5 d-flex mr-2">
          <router-link to="/create-post">
            <div
              v-if="$route.path !== '/create-post' && $route.path !== '/gallery' && $route.path !== '/create' && checkWorkspaceRole"
              class="h-[34px] pl-3 pr-4 py-[7px] bg-white rounded-md border border-[#e6e8ec] justify-center items-center gap-2 inline-flex"
              @click="trackMixpanelEvent; setInitialTab(); updateSelectedTab('Create Post')">
              <div class="w-4 h-4 relative">
                <img src="@/assets/img/plus_c.svg" alt="Create Post" />
              </div>
              <!-- Show the text only on larger screens -->
              <div class="text-black text-sm font-medium font-['Inter'] leading-tight d-none d-md-inline-flex">
                New Post
              </div>
            </div>
          </router-link>
          <!-- <workspace-menu></workspace-menu> -->

          <notification />
        </div>
        <user-profile />
      </v-app-bar>
    </div>

    <app-drawer v-model="drawer" v-if="isAuth"></app-drawer>
  </div>
</template>

<script>
import { onSnapshot, doc } from "firebase/firestore";
import headerConstants from "../assets/constants/header.constants.js";
import AppDrawer from "./AppDrawer.vue";
import WorkspaceMenu from "./WorkspaceMenu.vue";
import Notification from "./notification/Notification.vue"
import UserProfile from "./UserProfile.vue";
import { colUsers } from "@/utils/firebase.utils.js";
export default {
  components: {
    AppDrawer,
    UserProfile,
    WorkspaceMenu,
    Notification
  },
  data() {
    return {
      selectedTab: null, // Holds the currently selected tab name
      drawer: false,
      currentWorkspaceLogo: null,
      currentWorkspaceName: null,
      unsubscribe : null,
    };
  },
  computed: {
    checkWorkspaceRole() {
      if (this.currentWorkspace && this.currentWorkspace?.role === "Client") return false;
      return true;
    },
    name() {
      if (this.user?.firstName) {
        return `${this.user.firstName} ${this.user.lastName}`;
      }
      return `${this.authUser.username}`;
    },
    title() {
      return headerConstants[this.$route.name] || "";
    },
  },
  methods: {
    updateSelectedTab(tabName) {
      this.selectedTab = tabName;
    },
    setInitialTab() {
      // Map your route names to the corresponding tab titles
      const routeToTabMap = {
        'calendar': 'Planner',
        'fly': 'Posts',
        'analytics': 'Analytics',
        'contentai': 'Content AI',
        'contentfeed': 'Social Tracker',
        'connected-apps': 'Social Channels',
        'gmb-reviews-report': 'GMB Reviews Report',
        'create-post':'Create Post'
        // Add other route-to-tab mappings here
      };

      // Get the current route name
      const currentRouteName = this.$route.name;
      // Set selectedTab based on the current route
      if (routeToTabMap[currentRouteName]) {
        this.selectedTab = routeToTabMap[currentRouteName];
      }
    },
    loadCurrentWorkspace() {
      const workspaceDocRef = doc(colUsers, this.currentUID, 'fly_workspaces', this.WID);
      this.unsubscribe = onSnapshot(workspaceDocRef, (doc) => {
        if (doc.exists()) {
          const workspaceData = doc.data();
          this.currentWorkspaceLogo = workspaceData.logo_original || "";
          this.currentWorkspaceName = workspaceData.name || "";
        } else {
          console.error("No such document!");
        }
      }, (error) => {
        console.error("Error getting document:", error);
      });

    },
    trackMixpanelEvent() {
      this.$mixpanel.track("Create Post Clicked");
    },
  },
  mounted() {
    this.$root.$on('tab-selected', this.updateSelectedTab); // Listen for the 'tab-selected' event
    console.log("Listening for tab-selected event"); // Log for debugging

    // Set the selectedTab based on the current route name on initial render
    this.setInitialTab();

    this.loadCurrentWorkspace(); // Keep your existing logic here
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
    this.$root.$off('tab-selected', this.updateSelectedTab); // Clean up the event listener
  }
};
</script>
<style scoped>
.border-primary {
  border-color: #007BFF;
  /* Example blue color, adjust as per your theme */
}

.text-primary {
  color: #007BFF;
  /* Same as border to keep consistency */
}

.create-button {
  transition: box-shadow 0.3s ease;
}

.create-button:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}
</style>
