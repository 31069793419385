<template>
  <v-card flat tile max-width="500px" class="mx-auto mb-2">
    <v-list-item class="px-0">
      <v-list-item-avatar class="mr-2" size="40" @click="openProfile()">
        <v-img v-if="userData.picture" :src="userData.picture" width="30px"></v-img>
        <user-logo v-else :size="40" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          v-if="userData.name"
          class="text-sm md:text-lg d-flex align-center"
        >
          {{ userData.name }}
        </v-list-item-title>
        <v-list-item-title v-else> username </v-list-item-title>
        <v-list-item-subtitle class="text-xs md:text-sm">
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-img
          src="https://firebasestorage.googleapis.com/v0/b/around-a8790.appspot.com/o/fly_platforms%2Ficons%2Frdd.png?alt=media&token=615ec977-76c0-4fbe-b331-96e245f18909"
          width="30px"
        ></v-img>
      </v-list-item-action>
    </v-list-item>
    <v-card-text class="pa-0" v-if="hasPreview">
      <div v-html="reddit_text ? reddit_text : content" class="mb-3 text-lg" />
    </v-card-text>
    <v-card :href="url" target="_blank" v-if="hasPreview">
      <v-img :src="detail?.image" />
      <v-card-title>{{ detail?.title }}</v-card-title>
      <v-card-text>{{ detail?.description }}</v-card-text>
    </v-card>
    <v-card-text v-else class="pa-0">
      <div v-html="reddit_text ? reddit_text : content" class="mb-3 text-lg" />
      <div v-html="hashtags" />
      <v-carousel
        hide-delimiters
        v-model="carousel"
        v-if="carousels.length"
        :show-arrows="carousels.length > 1"
        :height="isMobile ? '400px' : '500px'"
      >
        <v-carousel-item v-for="(item, i) in carousels" :key="i">
          <div class="h-full d-flex align-center black">
            <v-img :src="item.src" v-if="item.type == 'image'" />
            <video-auto-play
              v-if="item.type == 'video'"
              :autoplay="false"
              :videoSrc="item.src"
            />
          </div>
        </v-carousel-item>
      </v-carousel>
      <div class="my-3 d-flex align-center">
        <div
          class="justify-center p-2 d-flex align-center bg-slate-100 rounded-xl"
        >
          <v-img
            max-width="20px"
            class="mx-2 mr-2"
            src="https://img.icons8.com/badges/48/up.png"
          ></v-img>
          0
          <v-img
            max-width="20px"
            class="mx-2 mt-1"
            src="https://img.icons8.com/badges/48/down.png"
          ></v-img>
        </div>
        <div
          class="justify-center px-4 py-2 ml-2 d-flex align-center bg-slate-100 rounded-xl"
        >
          <v-img
            max-width="20px"
            class="mr-2 font-bold"
            src="https://img.icons8.com/windows/32/topic--v1.png"
            alt="chat-message"
          ></v-img
          >0
        </div>
        <div
          class="justify-center px-4 py-2 ml-4 d-flex align-center bg-slate-100 rounded-xl"
        >
          <v-img
            max-width="20px"
            class="mx-2 mr-2"
            src="https://img.icons8.com/ios-glyphs/30/share-rounded.png"
          ></v-img>
          share
        </div>
      </div>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import UserLogo from "@/components/UserLogo.vue";
import VideoAutoPlay from "@/components/public/VideoAutoPlay.vue";
import { auth, colUsers } from "@/utils/firebase.utils";
import { doc, getDoc } from "firebase/firestore";

export default {
  components: { UserLogo, VideoAutoPlay },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    userData: {
      type: Array,
      default: () => [],
    },
    detail: Object,
    hasPreview: Boolean,
    url: String,
    configuration: Object,
    reddit_text: String,
  },
  data() {
    return {
      carousel: 0,
      picture: "",
      name: "",
    };
  },
  watch: {
    "configuration.subreddit": {
      handler(val) {
        this.fetchRedditData();
      },
    },
  },
  computed: {
    logoName() {
      let name = this.currentUser.display_name || "";
      return name.charAt(0).toUpperCase();
    },
    hashtags() {
      let hashtags = this.post.hashtags || [];
      return hashtags
        .map((i) => {
          if (!i.startsWith("#")) return `#${i}`;
          return i;
        })
        .toString()
        .split(",")
        .join(" ");
    },
    content() {
      let content = this.post.text || "";
      // content = content.toString().replace(/(?:\r\n|\r|\n)/g, " <br>");
      content = content.toString().replace(/(?:\r\n|\r|\n)+/g, "<br>");
      content = content.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" class="primary--text text-decoration-underline">$1</a>'
      );
      return content;
    },
    carousels() {
      let list = [];
      if (this.post.videos) {
        // console.log(this.post.videos);
        this.post.videos.forEach((item) => {
          list.push({
            src: item.url || item,
            type: "video",
          });
        });
      }
      if (this.post.images) {
        if (this.post.images.length > 10) {
          let images = this.post.images.slice(0, 10);
          images.forEach((item) => {
            list.push({
              src: item.thumbnail || item.url || item,
              type: "image",
            });
          });
        } else {
          this.post.images.forEach((item) => {
            list.push({
              src: item.thumbnail || item.url || item,
              type: "image",
            });
          });
        }
      }
      return list;
    },
  },
  methods: {
    async fetchRedditData() {
      const connectedAppsRef = doc(
        colUsers,
        this.currentUID,
        "fly_workspaces",
        this.WID,
        "connected-apps",
        "reddit"
      );
      const connectedAppsSnap = await getDoc(connectedAppsRef);
      const connectedAppsData = connectedAppsSnap.data();
      const rd_subreddit = this.configuration.subreddit;
      connectedAppsData?.subreddits.map((subreddit) => {
        if (subreddit.name === rd_subreddit) {
          if (subreddit.picture) {
            this.picture = subreddit.picture;
          }
          if (subreddit.name) {
            this.name = subreddit.name;
          }
          return true;
        }
      });
      return false;
    },
  },
};
</script>

<style></style>
