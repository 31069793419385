<template>
    <div class="min-h-[70vh]" ref="locationsContainer">
        <!-- Top Section: Search and Action Buttons -->
        <div v-show="!showOverlay">
            <div class="mb-6 flex flex-col gap-4 px-4">
                <div class="flex flex-col md:flex-row justify-between items-center">
                    <div class="flex flex-col md:flex-row gap-4 items-center w-full">
                        <input v-model="searchQuery" type="text" placeholder="Search locations..."
                            class="form-input w-full md:w-80 shadow-2xl mb-2 md:mb-0" />
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" @click="fetchGMBData()" :loading="isFetching"
                                    :disabled="!canFetchLatestData || isFetching" class="w-full sm:w-auto -mt-2"
                                    v-on="on">
                                    <v-icon left>mdi-refresh</v-icon>
                                    <span v-if="canFetchLatestData">Refresh from Google</span>
                                    <span v-else>Refresh available in {{ refreshAvailableIn }} hours</span>
                                </v-btn>
                            </template>
                            <span>This will refresh the profiles data from Google for locations. This operation is
                                allowed to be performed only once in 24 hours.</span>
                        </v-tooltip>
                    </div>
                </div>

                <!-- Strength Filters -->
                <div class="status-filters">
                    <button v-for="filter in strengthFilters" :key="filter.value" @click="strengthFilter = filter.value"
                        class="status-filter-btn" :class="[
                            {
                                'status-excellent': filter.value === 'excellent',
                                'status-good': filter.value === 'good',
                                'status-average': filter.value === 'average',
                                'status-poor': filter.value === 'poor',
                                'status-critical': filter.value === 'critical',
                            },
                            { active: strengthFilter === filter.value },
                        ]">
                        {{ filter.label }} ({{ getFilterCount(filter.value) }})
                    </button>
                </div>

                <!-- Category Filters -->
                <div class="category-filter flex flex-wrap gap-2">
                    <v-chip class="category-chip" :color="selectedCategory === null ? 'blue' : 'gray'"
                        :text-color="selectedCategory === null ? 'white' : 'black'" @click="selectedCategory = null">
                        All categories
                    </v-chip>
                    <div v-for="category in uniqueCategories" :key="category.name" class="category-chip">
                        <v-chip class="category-chip" :color="selectedCategory === category.name ? 'blue' : 'gray'"
                            :text-color="selectedCategory === category.name ? 'white' : 'black'"
                            @click="selectedCategory = category.name"
                            :class="{ active: selectedCategory === category.name }">
                            {{ category.name }} ({{ category.count }})
                        </v-chip>
                    </div>
                </div>
            </div>

            <!-- Information Message -->
            <div class="mb-6 px-4">
                <p
                    class="flex items-center text-gray-800 bg-gradient-to-r from-blue-50 to-indigo-50 p-4 rounded-md border-l-4 border-blue-500 shadow-sm">
                    <v-icon color="blue" class="mr-3">mdi-information</v-icon>
                    <span class="font-medium">
                        To keep your business listing at the top, please ensure all the information you provide is
                        accurate.
                    </span>
                </p>
            </div>

            <!-- Main Content -->
            <div class="bg-white rounded-lg shadow-sm p-6 mr-[0.05rem]" >
                <div v-if="loading" class="flex flex-col justify-center items-center h-64">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    <p class="mt-2 text-gray-600">Fetching data from GMB could take a few minutes</p>
                </div>
                <div v-else>
                    <div v-if="!locations.length" class="flex flex-col justify-center items-center h-64">
                        <p class="text-gray-600">No locations available. Please add locations to manage your GMB
                            profiles.
                        </p>
                    </div>
                    <div class="space-y-6" v-else>
                        <div v-for="location in paginatedLocations" :key="location?.id" class="border rounded-lg p-4 ">
                            <!-- Location Info -->
                            <div class="flex justify-between items-start mb-4">
                                <div class="space-y-3 w-full">
                                    <div class="flex justify-between items-center">
                                        <div>
                                            <h3 class="font-semibold text-lg">{{ location?.title }}</h3>
                                            <p class="text-gray-600">
                                                {{ location?.storefrontAddress?.addressLines?.[0]?.split(',')[0] }},
                                                {{ location?.storefrontAddress?.locality }},
                                                {{ location?.storefrontAddress?.administrativeArea }}
                                            </p>
                                            <!-- Add reauth notification alert -->
                                        </div>
                                        <div class="flex justify-end items-center">
                                            <span v-if="!isLocationDisabled(location)">Last Fetched: {{ formattedLastFetchedAt(location) }}</span>
                                            <v-tooltip bottom v-if="!isFetching && !isLocationDisabled(location)">
                                                <template v-slot:activator="{ on }">
                                                    <button class="ml-4" v-on="on"
                                                        @click="fetchGMBData(location?.workspaceId)">
                                                        <v-icon medium>mdi-refresh</v-icon>
                                                    </button>
                                                </template>
                                                <span>Refresh latest gmb data</span>
                                            </v-tooltip>
                                            <v-progress-circular v-else-if="isFetching" indeterminate color="grey" size="22"
                                                class="ml-4" />
                                            <v-chip small
                                                :color="getVerificationStateColor(location?.verificationState, location?.shallowConnect)"
                                                text-color="white" class="ml-2">
                                                {{ formatVerificationState(location?.verificationState, location?.shallowConnect) }}
                                            </v-chip>
                                        </div>
                                    </div>
                                    <div v-if="location.needs_gmb_reauth" class="mt-2 max-w-[40%] bg-red-50 text-red-600 p-2 rounded-md border border-red-200 flex items-center">
                                        <v-icon color="error" size="18" class="mr-2">mdi-alert-circle</v-icon>
                                        <span class="text-sm font-medium">Google account reauthorization needed</span>
                                    </div>
                                    <!-- Categories -->
                                    <div>
                                        <span class="text-sm font-medium text-gray-600">Categories:</span>
                                        <div class="flex flex-wrap gap-2 mt-1">
                                            <v-chip small color="primary" text-color="white" class="text-sm">
                                                {{ location?.categories?.primaryCategory?.displayName ||
                                                    'No primary category' }}
                                            </v-chip>
                                            <template v-if="location?.categories?.additionalCategories?.length">
                                                <v-chip
                                                    v-for="(category, index) in location?.categories.additionalCategories"
                                                    :key="index" small color="secondary" text-color="white"
                                                    class="text-sm">
                                                    {{ category.displayName }}
                                                </v-chip>
                                            </template>
                                        </div>
                                    </div>

                                    <!-- Description -->
                                    <div v-if="!location.shallowConnect">
                                        <div>
                                            <span class="text-sm font-medium text-gray-600">Description:</span>
                                            <div v-if="location?.generatingDescription" class="mt-4 flex justify-center">
                                                <v-progress-circular indeterminate color="primary"
                                                    size="24"></v-progress-circular>
                                                <span class="ml-2 text-sm text-gray-600">Generating optimized
                                                    description...</span>
                                            </div>
                                            <div v-else-if="!location.newDescription" class="space-y-2">
                                                <div class="flex flex-wrap gap-2 items-center">
                                                    <div v-for="(keyword, index) in location.keywords" :key="index"
                                                        class="keyword-chip"
                                                        :style="{ width: `${keyword.length * 10 + 40}px` }">
                                                        <span class="text-sm truncate">{{ keyword }}</span>
                                                        <button @click="removeKeyword(location, index)"
                                                            class="flex-shrink-0">
                                                            <v-icon small>mdi-close</v-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="flex gap-2">
                                                    <input v-model="location.currentKeyword"
                                                        @keyup.enter="addKeyword(location)" type="text"
                                                        placeholder="Add keywords and press Enter"
                                                        class="form-input flex-1" />
                                                </div>
                                                <div class="flex gap-2 mt-2">
                                                    <v-btn small color="secondary" @click="addKeyword(location)"
                                                        :disabled="location.needs_gmb_reauth">
                                                        <v-icon small left>mdi-plus</v-icon>
                                                        Add Keyword
                                                    </v-btn>
                                                    <v-btn small color="primary" @click="generateDescription(location)"
                                                        :disabled="location.needs_gmb_reauth || location?.generatingDescription">
                                                        <v-icon small left>mdi-refresh</v-icon>
                                                        <span class="text-xs sm:text-sm">Generate Description</span>
                                                    </v-btn>
                                                </div>
                                            </div>
                                            <div v-else class="mt-2 space-y-4">
                                                <div class="grid md:grid-cols-2 gap-4">
                                                    <div class="bg-red-50 p-4 rounded-lg border border-red-100">
                                                        <div class="text-sm font-medium text-red-600 mb-2">Current
                                                            Description:
                                                        </div>
                                                        <p class="text-sm text-gray-700 whitespace-pre-wrap">
                                                            {{ location?.profile?.description || 'No description available'
                                                            }}
                                                        </p>
                                                    </div>
                                                    <div class="bg-green-50 p-4 rounded-lg border border-green-100">
                                                        <div class="text-sm font-medium text-green-600 mb-2">Optimized
                                                            Description:
                                                        </div>
                                                        <p class="text-sm text-gray-700 whitespace-pre-wrap">
                                                            {{ location?.newDescription }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="flex justify-end gap-2">
                                                    <v-btn small @click="updateProfile(location)"
                                                        :loading="location.updating"
                                                        :disabled="location.updating || Dis || isDescriptionTooLong(location)">
                                                        <span class="flex items-center space-x-2 text-blue-500">
                                                            <img src="@/assets/img/icons8-google-48.png" alt="Google Icon"
                                                                class="w-5 h-5" />
                                                            <span class="font-bold">Update on Google</span>
                                                        </span>
                                                    </v-btn>
                                                    <v-btn small outlined @click="location.newDescription = null">
                                                        <v-icon small left>mdi-close</v-icon>
                                                        Cancel
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if='!isLocationDisabled(location)'>
                                            <div class="textarea-wrapper relative">
                                                <textarea class="form-input mt-5 w-full" rows="7" maxlength="750"
                                                    :ref="`descriptionTextarea_${location.wid}`"
                                                    v-model="location.formData.description"
                                                    :class="[
                                                        {'border-red-500': isDescriptionTooLong(location)},
                                                        !location.descriptionEditable ? 'bg-gray-50 cursor-not-allowed' : ''
                                                    ]"
                                                    placeholder="Enter description"
                                                    :disabled="location.needs_gmb_reauth || !location.descriptionEditable"></textarea>
                                                
                                                <div v-if="!location.needs_gmb_reauth" 
                                                     class="edit-icon-container absolute top-7 right-1 z-10 cursor-pointer"
                                                     @click="toggleDescriptionEdit(location)">
                                                    <v-icon>{{ location.descriptionEditable ? 'mdi-check' : 'mdi-pencil' }}</v-icon>
                                                </div>
                                            </div>
                                            <div class="flex justify-between mt-1">
                                                <p v-if="isDescriptionTooLong(location)" class="text-sm text-red-500">
                                                    Description must be 750 characters or less
                                                </p>
                                                <span class="text-sm text-gray-600 ml-auto" :class="{'text-red-500': isDescriptionTooLong(location)}">
                                                    {{ location.formData.description?.length || 0 }}/750
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Profile Strength -->
                                    <div class="mt-2 space-y-2">
                                        <div class="flex items-center gap-2 mb-4">
                                            <div class="w-24 h-2 bg-gray-200 rounded-full overflow-hidden">
                                                <div class="h-full rounded-full" :style="{
                                                    width: `${getProfileStrength(location).total}%`,
                                                    backgroundColor: getProfileStrengthColor(location),
                                                }"></div>
                                            </div>
                                            <span class="text-sm text-gray-600">
                                                Profile Strength: {{ getProfileStrength(location).total }}%
                                            </span>
                                            <a v-if="getProfileStrength(location).total < 60"
                                                :href="getGMBUrl(location)" target="_blank" rel="noopener noreferrer"
                                                class="inline-flex items-center gap-1 text-sm text-[#8050DE] hover:text-[#6040AE]"
                                                title="Complete your profile on Google">
                                                <v-icon small>mdi-link</v-icon>
                                                <span>View Your Profile</span>
                                            </a>
                                        </div>
                                        <div class="space-y-3">
                                            <div v-for="(value, field) in getFieldStatus(location).basicInfo"
                                                :key="field" class="flex items-center justify-between">
                                                <div class="flex items-center gap-2">
                                                    <span class="w-2 h-2 rounded-full"
                                                        :class="value ? 'bg-green-500' : 'bg-red-500'"></span>
                                                    <span class="text-sm">{{ formatFieldName(field) }}</span>
                                                </div>
                                                <div class="w-24 h-1.5 bg-gray-200 rounded-full overflow-hidden">
                                                    <div class="h-full rounded-full"
                                                        :class="value ? 'bg-green-500' : 'bg-red-200'"
                                                        :style="{ width: value ? '100%' : '0%' }"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- GMB Profile Form -->
                            <form @submit.prevent="updateProfile(location)" class="space-y-6">
                                <div class="grid md:grid-cols-2 gap-6">
                                    <div class="form-group">
                                        <label class="form-label">Business Name</label>
                                        <div class="flex items-center relative">
                                            <input v-model="location.formData.businessName" type="text"
                                                class="form-input flex-1 pr-10"
                                                :class="[
                                                    (isLocationDisabled(location) || !location.nameEditable) ? 'bg-gray-50 cursor-not-allowed' : 'bg-white',
                                                    isBusinessNameTooLong(location) ? 'border-red-500' : ''
                                                ]"
                                                :disabled="isLocationDisabled(location) || !location.nameEditable"
                                                maxlength="100" />
                                            <v-icon v-if="!isLocationDisabled(location)" class="cursor-pointer absolute right-8"
                                                @click="toggleNameEdit(location)">
                                                {{ location.nameEditable ? 'mdi-check' : 'mdi-pencil' }}
                                            </v-icon>
                                        </div>
                                        <div class="flex justify-end mt-1">
                                            <span class="text-sm text-gray-600" :class="{'text-red-500': isBusinessNameTooLong(location)}">
                                                {{ location.formData.businessName?.length || 0 }}/100
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Primary Phone</label>
                                        <div class="flex items-center relative">
                                            <vue-tel-input-vuetify 
                                            v-model="location.formData.primaryPhone" 
                                            type="tel"
                                            class="w-full relative rounded box-border h-[42px] pb-[15px] border-[1px] border-solid border-stroke gap-3 "
                                            @input="(event) => formatPhoneNumber(event, location)" 
                                            :placeholder="''"
                                            :inputOptions="{ placeholder: '' }" 
                                            :dropdownOptions="{ placeholder: '' }"
                                            :label="''" 
                                            hide-details 
                                            :defaultCountry="'IN'"
                                            :disabled="isLocationDisabled(location)"
                                            :enabledCountryCode="true">
                                            </vue-tel-input-vuetify>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid md:grid-cols-2 gap-6">
                                    <div class="form-group">
                                        <label class="form-label">Website URL</label>
                                        <div class="flex items-center relative">
                                            <input v-model="location.formData.websiteUri" type="url" class="form-input flex-1 pr-10"
                                                placeholder="https://example.com" 
                                                :disabled="isLocationDisabled(location) || !location.websiteUriEditable"
                                                :class="[
                                                    (isLocationDisabled(location) || !location.websiteUriEditable) ? 'bg-gray-50 cursor-not-allowed' : '',
                                                    isWebsiteUriTooLong(location) ? 'border-red-500' : ''
                                                ]"
                                                maxlength="256" />
                                            <v-icon v-if="!isLocationDisabled(location)" class="cursor-pointer absolute right-8"
                                                @click="toggleWebsiteUriEdit(location)">
                                                {{ location.websiteUriEditable ? 'mdi-check' : 'mdi-pencil' }}
                                            </v-icon>
                                        </div>
                                        <div class="flex justify-between mt-1">
                                            <p v-if="isWebsiteUriTooLong(location)" class="text-sm text-red-500">
                                                Website URL must be 256 characters or less
                                            </p>
                                            <span class="text-sm text-gray-600 ml-auto" :class="{'text-red-500': isWebsiteUriTooLong(location)}">
                                                {{ location.formData.websiteUri?.length || 0 }}/256
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group relative">
                                        <label class="form-label">Primary Category</label>
                                        <div class="flex relative">
                                            <input v-if="!location.categoryEditable || isLocationDisabled(location)"
                                                v-model="location.formData.primaryCategory.displayName" type="text"
                                                class="form-input w-full pr-10"
                                                :class="[
                                                    isLocationDisabled(location) ||!location.categoryEditable? 'bg-gray-50 cursor-not-allowed' : 'bg-white',
                                                    isBusinessNameTooLong(location) ? 'border-red-500' : ''
                                                ]"
                                                :disabled="isLocationDisabled(location)||!location.categoryEditable" />
                                            <v-autocomplete v-else v-model="location.selectedPrimaryCategory"
                                                :items="filteredPrimaryCategory(location)" item-text="displayName"
                                                item-value="name" :loading="location.loadingCategories"
                                                placeholder="Search and add primary category"
                                                :search-input.sync="location.primaryCategorySearchQuery" outlined dense
                                                hide-details @change="addPrimaryCategory(location)"
                                                class="category-dropdown" :no-data-text="location.primaryCategorySearchQuery
                                                    ? 'No matching categories found'
                                                    : 'Start typing to search categories'
                                                    "></v-autocomplete>
                                            <div v-if="!isLocationDisabled(location)" 
                                                 class="edit-icon-container-category" 
                                                 @click="toggleCategoryEdit(location)">
                                                <v-icon>{{ location.categoryEditable ? 'mdi-check' : 'mdi-pencil' }}</v-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label class="form-label flex items-center justify-between">
                                        <span>Additional Categories</span>
                                        <span class="text-sm text-gray-600">({{
                                            location.formData.additionalCategories?.length || 0
                                            }}/9)</span>
                                    </label>
                                    <div class="flex flex-wrap gap-2 mb-2">
                                        <v-chip v-for="(category, index) in location.formData.additionalCategories"
                                            :key="index" :close="!isLocationDisabled(location)"
                                            @click:close="removeAdditionalCategory(location, index)" class="mr-2 mb-2"
                                            small :class="!category.isOriginal ? 'white category-chip-new' : null">
                                            {{ category.displayName }}
                                        </v-chip>
                                    </div>
                                    <div v-if="!isLocationDisabled(location)" class="flex gap-2 mb-2">
                                        <v-btn small color="success" @click="suggestCategories(location)"
                                            :loading="location.suggestingCategories" :disabled="location.suggestingCategories
                                                || location.formData.additionalCategories?.length == 9">
                                            <v-icon small left>mdi-lightbulb</v-icon>
                                            Suggest Additional Categories
                                        </v-btn>
                                    </div>
                                    <v-autocomplete v-if="!isLocationDisabled(location)" v-model="location.selectedAdditionalCategory"
                                        :items="filteredAdditionalCategories(location)" item-text="displayName"
                                        item-value="name" :loading="location.loadingCategories"
                                        :disabled="location.formData.additionalCategories?.length >= 9"
                                        placeholder="Search and add categories..."
                                        :search-input.sync="location.additionalCategorySearchQuery" outlined dense
                                        hide-details @change="addAdditionalCategory(location)" class="category-dropdown"
                                        :no-data-text="location.additionalCategorySearchQuery
                                            ? 'No matching categories found'
                                            : 'Start typing to search categories'
                                            "></v-autocomplete>
                                    <div class="form-group">
                                        <label class="form-label">Business Hours</label>
                                        <v-btn v-if="!isLocationDisabled(location)" text color="primary" @click="location.showHours = !location.showHours"
                                            class="px-0">
                                            <v-icon left>{{ location.showHours ? 'mdi-chevron-up' : 'mdi-chevron-down'
                                                }}</v-icon>
                                            {{ location.showHours ? 'Hide Hours' : 'Set Hours' }}
                                        </v-btn>
                                    </div>
                                </div>
                                <div v-if="location.showHours || isLocationDisabled(location)" class="border rounded-lg p-4 space-y-6">
                                    <div class="space-y-4">
                                        <div class="flex flex-row flex-wrap justify-between items-center gap-4">
                                            <h3 class="font-semibold text-base">Business Hours</h3>
                                            <div class="flex flex-wrap items-center gap-3">
                                                <div class="flex items-center">
                                                    <span class="mr-2 text-sm text-gray-700 whitespace-nowrap">Default Hours:</span>
                                                    <div class="flex gap-2 items-center">
                                                        <v-autocomplete 
                                                            v-model="defaultOpenTime" 
                                                            :items="timeSlots"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            class="time-autocomplete"
                                                            :disabled="isLocationDisabled(location)">
                                                        </v-autocomplete>
                                                        <span class="mx-1 self-center">-</span>
                                                        <v-autocomplete 
                                                            v-model="defaultCloseTime" 
                                                            :items="timeSlots"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            class="time-autocomplete"
                                                            :disabled="isLocationDisabled(location)">
                                                        </v-autocomplete>
                                                    </div>
                                                    <v-btn 
                                                        small 
                                                        color="primary" 
                                                        class="ml-2" 
                                                        @click="applyDefault(location)" 
                                                        :disabled="isLocationDisabled(location) || !isValidTimeRange(defaultOpenTime, defaultCloseTime)">
                                                        Apply
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="!isValidTimeRange(defaultOpenTime, defaultCloseTime)" class="text-red-500 text-sm mt-1">
                                            Closing time must be later than opening time
                                        </div>
                                        <div class="flex items-center justify-between mb-2 px-2 py-1 bg-gray-50 rounded">
                                            <div class="flex items-center gap-2">
                                                <span class="text-sm font-medium">Weekend Hours</span>
                                                <v-switch v-model="weekendHours" dense hide-details inset :disabled="isLocationDisabled(location)" @change="toggleWeekendHours(location)"></v-switch>
                                            </div>
                                        </div>

                                        <div v-for="day in weekDays" :key="day.value" class="border rounded-md p-2 space-y-2 bg-gray-50">
                                            <div class="flex items-center justify-between ">
                                                <h4 class="text-sm font-medium w-28">{{ day.label }}</h4>
                                                <div class="flex gap-6 items-center">
                                                    <div class="flex items-center gap-2">
                                                        <span class="text-sm text-gray-600">Open</span>
                                                        <v-switch 
                                                            v-model="location.formData.regularHours[day.value].isOpen" 
                                                            dense 
                                                            hide-details 
                                                            inset 
                                                            :disabled="isLocationDisabled(location)">
                                                        </v-switch>
                                                    </div>
                                                    <div 
                                                        v-if="location.formData.regularHours[day.value].isOpen" 
                                                        class="flex items-center gap-2">
                                                        <span class="text-sm text-gray-600">24 Hours</span>
                                                        <v-switch 
                                                            v-model="location.formData.regularHours[day.value].is24" 
                                                            dense 
                                                            hide-details 
                                                            inset 
                                                            :disabled="isLocationDisabled(location)">
                                                        </v-switch>
                                                    </div>
                                                </div>
                                            </div>

                                            <div 
                                                v-if="location.formData.regularHours[day.value].isOpen && !location.formData.regularHours[day.value].is24" 
                                                class="space-y-1">
                                                <div 
                                                    v-for="(period, idx) in location.formData.regularHours[day.value].periods || []" 
                                                    :key="idx" 
                                                    class="flex flex-col gap-1 ">
                                                    <div class="flex items-center gap-2">
                                                        <v-autocomplete
                                                            v-model="period.open"
                                                            :items="getTimeOptions(period.open)"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            class="time-autocomplete"
                                                            :error="!isValidTimeRange(period.open, period.close)"
                                                            :disabled="isLocationDisabled(location)"
                                                            @change="validateTimePeriod(period)">
                                                        </v-autocomplete>
                                                        <span>to</span>
                                                        <v-autocomplete
                                                            v-model="period.close"
                                                            :items="getTimeOptions(period.close)"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            class="time-autocomplete"
                                                            :error="!isValidTimeRange(period.open, period.close)"
                                                            :disabled="isLocationDisabled(location)"
                                                            @change="validateTimePeriod(period)">
                                                        </v-autocomplete>
                                                        <v-icon 
                                                            v-if="!isLocationDisabled(location)" 
                                                            medium 
                                                            class="cursor-pointer text-red-500" 
                                                            @click="removePeriod(location, day.value, idx)">
                                                            mdi-delete
                                                        </v-icon>
                                                    </div>
                                                    <div v-if="!isValidTimeRange(period.open, period.close)" class="text-red-500 text-xs ml-2">
                                                        Closing time must be later than opening time
                                                    </div>
                                                </div>
                                                <v-btn 
                                                    v-if="!isLocationDisabled(location)" 
                                                    small 
                                                    class="mt-4" 
                                                    @click="addPeriod(location, day.value)">
                                                    <v-icon small left>mdi-plus</v-icon>
                                                    Add Time
                                                </v-btn>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="flex gap-4">
                                        <span v-if="!isLocationDisabled(location)"
                                            class="cursor-pointer inline-flex items-center font-medium text-sm md:text-base lg:text-lg hover:text-blue-700 transition duration-200"
                                            @click="fetchOverlay(location,'attribute')">
                                            <span class="inline-flex items-center">
                                                <v-icon 
                                                    class="hover:scale-110 transition-transform duration-200"
                                                    style="color: #3b82f6">
                                                    mdi-plus
                                                </v-icon>
                                            </span>
                                            <span
                                                class="text-blue-500 underline hidden sm:inline ml-1">Attributes</span>
                                        </span>
                                        <span v-if="!isLocationDisabled(location)"
                                            class="cursor-pointer inline-flex items-center font-medium text-sm md:text-base lg:text-lg hover:text-blue-700 transition duration-200"
                                            @click="fetchOverlay(location,'menu')">
                                            <span class="inline-flex items-center">
                                                <v-icon
                                                    class="hover:scale-110 transition-transform duration-200"
                                                    style="color: #3b82f6">
                                                    mdi-food
                                                </v-icon>
                                            </span>
                                            <span class="text-blue-500 underline hidden sm:inline ml-1">Menu</span>
                                        </span>
                                    </div>
                                    <div class="actions p-4 flex justify-between items-center">
                                      <!-- Update Results Section -->
                                      <div class="flex items-center">
                                        <v-chip
                                          v-if="updateResults.length"
                                          class="mr-2"
                                          :color="hasErrors ? 'error' : 'success'"
                                          outlined
                                          @click="showUpdateResults = true"
                                        >
                                          <v-icon left size="16">
                                            {{ hasErrors ? 'mdi-alert-circle' : 'mdi-check-circle' }}
                                          </v-icon>
                                          Update Results
                                        </v-chip>
                                      </div>
                                      
                                      <!-- Existing Update Button -->
                                      <v-btn type="submit" :loading="location.updating" :disabled="location.updating||isLocationDisabled(location)"
                                        class="border border-blue-500 bg-white rounded-md px-4 py-2">
                                        <span class="flex items-center space-x-2 text-blue-500">
                                          <img src="@/assets/img/icons8-google-48.png" alt="Google Icon"
                                            class="w-5 h-5" />
                                          <span class="font-bold">Update on Google</span>
                                        </span>
                                      </v-btn>
                                    </div>
                                </div>
                            </form>
                            <div v-if="location.shallowConnect" class="mt-4 p-2 bg-gray-50 rounded-lg border border-gray-200 text-center">
                                <p class="text-sm text-amber-600 font-medium">
                                    This profile is partially connected and in read-only mode.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Pagination Controls -->
            <div v-if="filteredLocations?.length > itemsPerPage"
                class="flex items-center justify-center space-x-4 my-4">
                <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">Previous</button>
                <span class="text-gray-700 font-medium"> Page {{ currentPage }} of {{ totalPages }} </span>
                <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">Next</button>
            </div>
        </div>

        <div v-if="showOverlay=='attribute'" class="field-editor flex flex-col bg-white">
            <div class="heading flex justify-between items-center p-4">
                <h3 class="text-xl font-bold">{{ selectedOverlayLocation.title }} | Attributes</h3>
                <v-btn icon @click="closeOverlayEditor">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <v-divider class="m-auto w-[98%] mb-10" />
            <div class="flex-grow overflow-auto p-4">
                <h1 class="ml-5 text-ellipsis font-semibold">Complete Your Business Information & Sync</h1>
                <div class="content p-4">
                    <GMBAttributes :locations="[selectedOverlayLocation]" @update-done="closeOverlayEditor" />
                </div>
                <v-divider class="m-auto w-[98%] my-10" />
            </div>
        </div>
        <div v-if="showOverlay=='menu'" class="field-editor flex flex-col bg-white">
            <div class="heading flex justify-between items-center p-4">
                <div>
                    <h3 class="text-xl font-bold">{{ selectedOverlayLocation.title }} | Menu</h3>
                    <p>{{ selectedOverlayLocation?.storefrontAddress?.addressLines?.[0]?.split(',')[0] }},
                        {{ selectedOverlayLocation?.storefrontAddress?.locality }},
                        {{ selectedOverlayLocation?.storefrontAddress?.administrativeArea }}</p>
                </div>
                <v-btn icon @click="closeOverlayEditor">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <v-divider class="m-auto w-[98%] mb-10" />
            <div class="flex-grow p-4">
                <h1 class="ml-5 text-ellipsis font-semibold">Add your business menu details</h1>
                <div class="content p-4">
                    <GMBMenu :locations="[selectedOverlayLocation]" @close-menu="closeOverlayEditor" />
                </div>
            </div>
        </div>

        <!-- Add Update Results Dialog -->
        <v-dialog 
            v-model="showUpdateResults" 
            max-width="600px" 
            scrollable
            persistent
            :retain-focus="false"
        >
            <v-card>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span>Update Results</span>
                    <v-btn icon @click="closeUpdateResults">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                
                <v-card-text class="pt-4">
                    <!-- Summary Stats -->
                    <div class="mb-4 flex gap-4">
                        <v-chip outlined color="success">
                            Success: {{ successCount }}
                        </v-chip>
                        <v-chip outlined color="error">
                            Failed: {{ failedCount }}
                        </v-chip>
                    </div>

                    <!-- Results Table -->
                    <v-data-table
                        :headers="[
                            { text: 'Location', value: 'title' },
                            { text: 'Status', value: 'status' }
                        ]"
                        :items="updateResults"
                        :items-per-page="10"
                        :footer-props="{
                            'items-per-page-options': [10, 25, 50, 100],
                        }"
                        dense
                    >
                        <template v-slot:item.status="{ item }">
                            <v-chip
                                small
                                :color="item.status === 'success' ? 'success' : 'error'"
                                text-color="white"
                            >
                                {{ item.status }}
                            </v-chip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc, collection, getDocs, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { auth, functions, storage, db } from '@/utils/firebase.utils';
import GMBAttributes from './GMBAttributes.vue';
import GMBMenu from './GMBMenu.vue';

export default {
    props: {
        locations: { type: Array, required: true },
        categories: { type: Array, required: true },
        categoriesId: { type: Array, required: true },
        fetchWorkspaceData: { type: Function },
    },
    components: {
        GMBAttributes,
        GMBMenu,
    },
    data() {
        return {
            updateTimeLimit: 24,
            loading: false,
            searchQuery: '',
            isFetching: false,
            weekDays: [
                { label: 'Sunday', value: 'SUNDAY' },
                { label: 'Monday', value: 'MONDAY' },
                { label: 'Tuesday', value: 'TUESDAY' },
                { label: 'Wednesday', value: 'WEDNESDAY' },
                { label: 'Thursday', value: 'THURSDAY' },
                { label: 'Friday', value: 'FRIDAY' },
                { label: 'Saturday', value: 'SATURDAY' },
            ],
            gmb_bulk_last_updated: null,
            gmb_bulk_last_refreshed: null,
            strengthFilter: 'all',
            strengthFilters: [
                { label: 'All', value: 'all', condition: () => true },
                { label: 'Excellent', value: 'excellent', condition: (strength) => strength >= 85 },
                { label: 'Good', value: 'good', condition: (strength) => strength >= 70 && strength < 85 },
                { label: 'Average', value: 'average', condition: (strength) => strength >= 50 && strength < 70 },
                { label: 'Poor', value: 'poor', condition: (strength) => strength >= 30 && strength < 50 },
                { label: 'Critical', value: 'critical', condition: (strength) => strength < 30 },
            ],
            currentPage: 1,
            itemsPerPage: 5,
            showOverlay:null,
            selectedOverlayLocation:null,
            editingSectionIndex: null,
            selectedCategory: null,
            showUpdateResults: false,
            updateResults: [],
            timeSlots: this.generateTimeSlots(),
            defaultOpenTime: '10:00',
            defaultCloseTime: '18:00',
            weekendHours: true, // Default to true - weekend hours enabled
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.filteredLocations.length ? this.filteredLocations.length / this.itemsPerPage : 1);
        },
        filteredLocations() {
            let filtered = this.locations;
            if (this.searchQuery.trim()) {
                const query = this.searchQuery.toLowerCase();
                filtered = filtered.filter(
                    (location) =>
                        location?.storeLocation?.toLowerCase().includes(query) || location?.title?.toLowerCase().includes(query)
                );
            }
            if (this.strengthFilter !== 'all') {
                const filter = this.strengthFilters.find((f) => f.value === this.strengthFilter);
                filtered = filtered.filter((location) => {
                    const strength = this.getProfileStrength(location).total;
                    return filter.condition(strength);
                });
            }
            if (this.selectedCategory) {
                filtered = filtered.filter(
                    (location) => location.categories.primaryCategory?.displayName === this.selectedCategory
                );
            }
            return filtered;
        },
        paginatedLocations() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            return this.filteredLocations.slice(start, start + this.itemsPerPage);
        },
        canFetchLatestData() {
            const now = new Date();
            const lastRefreshed =
                this.gmb_bulk_last_refreshed instanceof Date
                    ? this.gmb_bulk_last_refreshed
                    : this.gmb_bulk_last_refreshed
                        ? this.gmb_bulk_last_refreshed.toDate()
                        : null;
            if (!lastRefreshed) return true;
            const diffHours = (now - lastRefreshed) / (1000 * 60 * 60);
            return diffHours >= 24;
        },
        refreshAvailableIn() {
            const now = new Date();
            const lastRefreshed =
                this.gmb_bulk_last_refreshed instanceof Date
                    ? this.gmb_bulk_last_refreshed
                    : this.gmb_bulk_last_refreshed
                        ? this.gmb_bulk_last_refreshed.toDate()
                        : null;
            if (!lastRefreshed) return 0;
            const diffHours = (now - lastRefreshed) / (1000 * 60 * 60);
            return Math.floor(Math.max(0, 24 - diffHours));
        },
        uniqueCategories() {
            const categoryCount = {};
            this.locations.forEach((location) => {
                const primaryCategory = location.categories.primaryCategory?.displayName;
                if (primaryCategory) {
                    categoryCount[primaryCategory] = (categoryCount[primaryCategory] || 0) + 1;
                }
            });
            return Object.entries(categoryCount).map(([name, count]) => ({ name, count }));
        },
        isLocationDisabled() {
            return function(location) {
                return location.needs_gmb_reauth || location.shallowConnect;
            }
        },
        successCount() {
            return this.updateResults.filter(result => result.status === 'success').length;
        },
        
        failedCount() {
            return this.updateResults.filter(result => result.status === 'failed').length;
        },
        
        hasErrors() {
            return this.failedCount > 0;
        },
    },
    async created() {
        this.$refs.inputRef = {};
        await this.fetchLastUpdatedAndRefreshed();
        // Initialize business hours for all locations
        if (this.locations && this.locations.length) {
            this.locations.forEach(location => {
                this.initializeBusinessHours(location);
            });
        }
    },
    watch: {
        locations: {
            handler(newLocations) {
                if (newLocations && newLocations.length) {
                    newLocations.forEach(location => {
                        if (!location.hoursInitialized) {
                            this.initializeBusinessHours(location);
                            location.hoursInitialized = true;
                        }
                    });
                }
            },
            deep: true
        }
    },
    methods: {
        closeOverlayEditor(){
            this.showOverlay = null;
        },
        formattedLastFetchedAt(location) {
            if (location.gmbLastFetchedAt) {
                const date = new Date(location.gmbLastFetchedAt.seconds * 1000);
                const now = new Date();
                const diffInSeconds = Math.floor((now - date) / 1000);
                const minutes = Math.floor(diffInSeconds / 60);
                const hours = Math.floor(diffInSeconds / 3600);
                const days = Math.floor(diffInSeconds / 86400);
                const weeks = Math.floor(diffInSeconds / 604800);
                if (minutes < 60) return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
                else if (hours < 24) return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
                else if (days < 7) return `${days} day${days !== 1 ? 's' : ''} ago`;
                else return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
            }
            return 'Not fetched yet';
        },
        formatTime(time) {
            if (!time || Object.keys(time).length === 0) return '00:00';
            // Handle case where hours might be undefined or 0 but minutes exist
            const hours = time.hours !== undefined ? time.hours : 0;
            const minutes = time.minutes !== undefined ? time.minutes : 0;
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        },
        fetchOverlay(location,overlay){
            this.selectedOverlayLocation = location;
            this.showOverlay = overlay
        },
        toggleNameEdit(location) {
            location.nameEditable = !location.nameEditable;
            if (location.nameEditable) {
                this.$nextTick(() => {
                    // Find the input field for business name
                    const inputs = this.$el.querySelectorAll('input[type="text"]');
                    for (const input of inputs) {
                        if (input.value === location.formData.businessName) {
                            input.focus();
                            break;
                        }
                    }
                });
            }
        },
        toggleCategoryEdit(location) {
            location.categoryEditable = !location.categoryEditable;
        },
        toggleWebsiteUriEdit(location) {
            location.websiteUriEditable = !location.websiteUriEditable;
            if (location.websiteUriEditable) {
                this.$nextTick(() => {
                    // Find the input field for website URL
                    const inputs = this.$el.querySelectorAll('input[type="url"]');
                    for (const input of inputs) {
                        if (input.value === location.formData.websiteUri) {
                            input.focus();
                            break;
                        }
                    }
                });
            }
        },
        toggleDescriptionEdit(location) {
            location.descriptionEditable = !location.descriptionEditable;
            if (location.descriptionEditable) {
                this.$nextTick(() => {
                    const textarea = this.$refs[`descriptionTextarea_${location.wid}`];
                    if (textarea) {
                        // Check if it's an array (which happens with v-for)
                        if (Array.isArray(textarea)) {
                            textarea[0]?.focus();
                        } 
                        // Check if it's a Vue component with $el
                        else if (textarea.$el) {
                            textarea.$el.focus();
                        }
                        // Direct DOM element
                        else if (textarea.focus) {
                            textarea.focus();
                        }
                    }
                });
            }
        },
        async fetchLastUpdatedAndRefreshed() {
            const workspaceDoc = doc(db, `users/${this.currentUID}/fly_workspaces/${this.WID}`);
            const workspaceSnapshot = await getDoc(workspaceDoc);
            if (workspaceSnapshot.exists()) {
                this.gmb_bulk_last_updated = workspaceSnapshot.data().gmb_bulk_last_updated;
                this.gmb_bulk_last_refreshed = workspaceSnapshot.data().gmb_bulk_last_refreshed;
            }
        },
        async fetchGMBData(workspaceID) {
            if (!workspaceID) this.loading = true;
            this.isFetching = true;
            if (!workspaceID) {
                const workspaceRef = doc(db, `users/${this.currentUID}/fly_workspaces/${this.WID}`);
                this.gmb_bulk_last_refreshed = new Date();
                await updateDoc(workspaceRef, { gmb_bulk_last_refreshed: this.gmb_bulk_last_refreshed });
            }
            try {
                const fetchData = httpsCallable(functions, 'fetchGMBDataFunction');
                const res = await fetchData({
                    uid: this.currentUID,
                    wid: this.WID,
                    workspaceID,
                });
                if (res.data.success) {
                    await this.fetchWorkspaceData(workspaceID);
                    this.$snackbar.show('GMB data fetched successfully');
                } else {
                    this.$snackbar.show("Couldn't fetch latest GMB data");
                }
            } catch (error) {
                console.error('Error fetching GMB data:', error);
                this.$alert.show('Error fetching GMB data');
            } finally {
                this.isFetching = false;
                this.loading = false;
            }
        },
        formatFetchedPhoneNumber(phone) {
            const cleanNumber = phone?.replace(/^\+91|^91|^0+|\s+/g, '').trim();
            return cleanNumber ? `+91${cleanNumber}` : '';
        },
        formatPhoneNumber(event, location) {
            if (!location || !location.formData) return;
            let value = typeof event === 'object' ? event.formattedNumber || '' : event;
            value = value?.replace(/[^\d+]/g, '');
            location.formData.primaryPhone = value;
        },
        getLocationName(location){
            return `${location.title} ${location?.storefrontAddress?.addressLines?.[0]?.split(',')[0]},
                                                ${location?.storefrontAddress?.locality} ,
                                                ${location?.storefrontAddress?.administrativeArea}`
        },
        async updateProfile(location) {
            if (location.needs_gmb_reauth) {
                this.$alert.show('Please reauthorize your Google account before making updates');
                return;
            }
            
            // Validate field lengths
            if (this.isDescriptionTooLong(location)) {
                this.$alert.show('Description must be 750 characters or less');
                return;
            }
            
            if (this.isWebsiteUriTooLong(location)) {
                this.$alert.show('Website URL must be 256 characters or less');
                return;
            }
            
            if (this.isBusinessNameTooLong(location)) {
                this.$alert.show('Business name must be 100 characters or less');
                return;
            }
            
            // Validate business hours
            let hasInvalidHours = false;
            this.weekDays.forEach(({ value }) => {
                if (location.formData.regularHours[value] && 
                    location.formData.regularHours[value].isOpen && 
                    !location.formData.regularHours[value].is24) {
                    
                    (location.formData.regularHours[value].periods || []).forEach(period => {
                        if (!this.isValidTimeRange(period.open, period.close)) {
                            hasInvalidHours = true;
                        }
                    });
                }
            });
            
            if (hasInvalidHours) {
                this.$alert.show('Please fix invalid business hours. Closing time must be later than opening time.');
                return;
            }
            
            location.updating = true;
            try {
                const formattedPhone = location.formData.primaryPhone;
                const updateFields = {};
                const changes = [];
                const formattedRegularHours = this.formatBusinessHours(location.formData.regularHours);
                const formattedOriginalHours = this.formatBusinessHours(location.formData.originalRegularHours);
                if (JSON.stringify(formattedRegularHours) !== JSON.stringify(formattedOriginalHours)) {
                    updateFields.regularHours = formattedRegularHours;
                    changes.push({
                        id: 'regularHours',
                        field: 'Business Hours',
                        old: formattedOriginalHours || null,
                        new: formattedRegularHours,
                    });
                }
                if (formattedPhone !== this.formatFetchedPhoneNumber(location.phoneNumbers?.primaryPhone)) {
                    updateFields.phoneNumbers = { primaryPhone: formattedPhone };
                    changes.push({
                        id: 'phoneNumbers',
                        field: 'Phone Number',
                        old: this.formatFetchedPhoneNumber(location.phoneNumbers?.primaryPhone) || null,
                        new: formattedPhone,
                    });
                }
                if ((location.formData.websiteUri || location.websiteUri) && location.formData.websiteUri !== location.websiteUri) {
                    updateFields.websiteUri = location.formData.websiteUri;
                    changes.push({
                        id: 'websiteUri',
                        field: 'Website URL',
                        old: location.websiteUri || '',
                        new: location.formData.websiteUri,
                    });
                }
                if (location.newDescription || location.formData.description !== location.profile?.description) {
                    updateFields.profile = { description: location.newDescription || location.formData.description };
                    changes.push({
                        id: 'description',
                        field: 'Business Description',
                        old: location.profile?.description || '',
                        new: location.newDescription || location.formData.description,
                    });
                }
                if (location.formData.businessName !== location.title) {
                    updateFields.title = location.formData.businessName;
                    changes.push({
                        id: 'title',
                        field: 'Business Name',
                        old: location.title || '',
                        new: location.formData.businessName,
                    });
                }
                const originalAdditionalCategories = location.categories?.additionalCategories?.map((cat) => cat.name) || [];
                const newAdditionalCategories = location.formData.additionalCategories?.map((cat) => cat.name) || [];
                const originalAdditionalCategoriesDisplay =
                    location.categories?.additionalCategories?.map((cat) => cat.displayName) || [];
                const newAdditionalCategoriesDisplay = location.formData.additionalCategories?.map((cat) => cat.displayName) || [];
                if (!this.arraysEqual(originalAdditionalCategories, newAdditionalCategories)) {
                    if (!updateFields.categories) updateFields.categories = {};
                    updateFields.categories.additionalCategories = newAdditionalCategories;
                    changes.push({
                        id: 'additionalCategories',
                        field: 'Additional Categories',
                        value: newAdditionalCategories,
                        old: originalAdditionalCategoriesDisplay.join(', ') || '',
                        new: newAdditionalCategoriesDisplay.join(', ') || '',
                    });
                }
                if (location?.categories?.primaryCategory.name !== location.formData.primaryCategory.name) {
                    if (!updateFields.categories) updateFields.categories = {};
                    updateFields.categories.primaryCategory = { name: location.formData.primaryCategory.name };
                    changes.push({
                        id: 'primaryCategory',
                        field: 'Primary Category',
                        value: location.formData.primaryCategory.name,
                        old: location?.categories?.primaryCategory.displayName || '',
                        new: location.formData.primaryCategory.displayName,
                    });
                }
                if (updateFields.categories) {
                    if (!updateFields.categories.primaryCategory) {
                        updateFields.categories.primaryCategory = { name: location.categories.primaryCategory.name };
                    }
                    if (!updateFields.categories.additionalCategories)
                        updateFields.categories.additionalCategories = originalAdditionalCategories;
                }
                if (Object.keys(updateFields).length === 0) {
                    this.$snackbar.show('No changes to update');
                    return;
                }
                const payload = {
                    uid: this.currentUID,
                    workspace_id: this.WID,
                    updates: [
                        {
                            locationId: location.name,
                            displayName: location?.title || '',
                            updateFields,
                            wid: location.workspaceId,
                            accountId: location.accountId,
                        },
                    ],
                };
                const updateGMBProfile = httpsCallable(functions, 'updateGMBDataFunction');
                const res = await updateGMBProfile(payload);
                
                if (res?.data?.results) {
                    location.updating = false;
                    
                    // Update results tracking
                    this.updateResults = res.data.results.map(result => ({
                        ...result,
                        title: this.getLocationName(location)
                    }));
                    
                    // Check for success or failures in the results
                    const successResults = res.data.results.filter(result => result.status === "success");
                    const failedResults = res.data.results.filter(result => result.status === "failed");
                    
                    // Add to history
                    await addDoc(collection(db, 'users', this.currentUID, 'fly_workspaces', this.WID, 'gmb-update-history'), {
                        requested_by: this.currentUser.display_name || 'unknown',
                        locationId: location.name,
                        type: 'single',
                        title: location.title,
                        address: `${location?.storefrontAddress?.addressLines?.[0]?.split(',')[0]}, ${location?.storefrontAddress?.locality}, ${location?.storefrontAddress?.administrativeArea}`,
                        changes,
                        updateResults: this.updateResults,
                        requested_at: new Date(),
                        wid: location.workspaceId,
                        accountId: location.accountId || '',
                    });

                    if (successResults.length > 0) {
                        const currDate = new Date();
                        const workspaceRef = doc(db, `users/${this.currentUID}/fly_workspaces/${location.workspaceId}`);
                        await updateDoc(workspaceRef, { gmb_last_updated: currDate });
                        location.gmb_last_updated = currDate;
                        
                        if (failedResults.length > 0) {
                            // Some fields failed to update
                            this.showUpdateResults = true;  // Show dialog instead of alert
                            this.$snackbar.show('Some updates failed. Check results for details.');
                        } else {
                            // All successful
                            this.$snackbar.show('Profile updated successfully');
                        }
                        
                        // Refresh data
                        await this.fetchWorkspaceData(location.workspaceId);
                    } else if (failedResults.length > 0) {
                        // All updates failed
                        this.showUpdateResults = true;  // Show dialog instead of alert
                        this.$snackbar.show('Update failed. Check results for details.');
                    }
                }
            } catch (error) {
                console.error('Error updating profile:', error);
                this.$snackbar.show('Error updating profile');  // Use snackbar instead of alert
            } finally {
                location.updating = false;
            }
        },
        formatVerificationState(state, shallowConnect) {
            if (shallowConnect) return 'Partially connected';
            if (!state) return 'Not Verified';
            if (state === 'COMPLETED') return 'Verified';
            return state
                .split('_')
                .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
                .join(' ');
        },
        getVerificationStateColor(state, shallowConnect) {
            if (shallowConnect) return 'orange';
            const colors = { VERIFIED: 'success', COMPLETED: 'success', PENDING: 'warning', FAILED: 'error', STATE_UNSPECIFIED: 'grey' };
            return colors[state] || 'grey';
        },
        getProfileStrength(location) {
            const fields = {
                businessName: !!location?.title,
                description: !!location?.profile?.description,
                websiteUri: !!location?.websiteUri,
                primaryCategory: !!location?.categories?.primaryCategory?.displayName,
                additionalCategories: !!location?.categories?.additionalCategories?.length,
                regularHours: !!location?.regularHours?.periods?.length,
                phoneNumber: !!location?.phoneNumbers?.primaryPhone,
            };
            const totalFields = Object.keys(fields).length;
            const filledFields = Object.values(fields).filter(Boolean).length;
            const total = Math.round((filledFields / totalFields) * 100);
            return { total, fields };
        },
        formatFieldName(field) {
            return field?.replace(/([A-Z])/g, ' $1').toLowerCase()?.replace(/^./, (str) => str.toUpperCase());
        },
        getProfileStrengthColor(location) {
            const strength = this.getProfileStrength(location).total;
            if (strength >= 85) return '#4CAF50';
            if (strength >= 70) return '#8BC34A';
            if (strength >= 50) return '#FFA726';
            if (strength >= 30) return '#FF7043';
            return '#EF5350';
        },
        checkUpdateEligibility(lastUpdated) {
            if (!lastUpdated) return { canUpdate: true, remainingTime: 0 };
            const now = new Date();
            const lastUpdate = lastUpdated instanceof Date ? lastUpdated : lastUpdated.toDate();
            const diffHours = (now - lastUpdate) / (1000 * 60 * 60);
            const remainingHours = Math.max(0, Math.ceil(this.updateTimeLimit - diffHours));
            return { canUpdate: diffHours >= this.updateTimeLimit, remainingTime: remainingHours };
        },
        async generateDescription(location) {
            if (location.needs_gmb_reauth) return;
            location.generatingDescription = true;
            try {
                const completeAddress = [
                    location?.storefrontAddress?.addressLines?.[0],
                    location?.storefrontAddress?.locality,
                    location?.storefrontAddress?.administrativeArea,
                    location?.storefrontAddress?.postalCode,
                    location?.storefrontAddress?.regionCode,
                ]
                    .filter(Boolean)
                    .join(', ');
                const primaryCategory = location.categories?.primaryCategory?.displayName || '';
                const additionalCategories = location.categories?.additionalCategories?.map((cat) => cat.displayName) || [];
                const allCategories = [primaryCategory, ...additionalCategories].filter(Boolean).join(', ');
                const payload = {
                    uid: this.currentUID,
                    category: allCategories,
                    wid: this.WID,
                    operation: 'generategmbKeywords',
                    textContent: completeAddress || '',
                    businessDescription: location?.profile?.description || '',
                    brandName: location.title,
                    keywords: location.keywords,
                };
                const generateKeywords = httpsCallable(functions, 'aiActions');
                const res = await generateKeywords(payload);
                let generatedDescription = res?.data?.result?.output[0] || '';
                
                // Truncate if longer than 750 characters
                if (generatedDescription.length > 750) {
                    generatedDescription = generatedDescription.substring(0, 747) + '...';
                    this.$snackbar.show('Description was truncated to fit 750 character limit');
                }
                
                location.newDescription = generatedDescription;
            } catch (error) {
                console.error('Error generating description:', error);
                this.$alert.show('Error generating description');
            } finally {
                location.generatingDescription = false;
            }
        },
        getFieldStatus(location) {
            return {
                basicInfo: {
                    businessName: !!location?.title,
                    description: !!location?.profile?.description,
                    websiteUri: !!location?.websiteUri,
                    primaryCategory: !!location?.categories?.primaryCategory?.displayName,
                    additionalCategories: !!location?.categories?.additionalCategories?.length,
                    regularHours: !!location?.regularHours?.periods?.length,
                    phoneNumber: !!location?.phoneNumbers?.primaryPhone,
                },
            };
        },
        getFilterCount(filterValue) {
            return this.locations.filter((location) => {
                const strength = this.getProfileStrength(location).total;
                const filter = this.strengthFilters.find((f) => f.value === filterValue);
                return filter.condition(strength);
            }).length;
        },
        getGMBUrl(location) {
            const locationId = location.name.split('/').pop();
            return `https://business.google.com/u/0/dashboard/l/${locationId}`;
        },
        formatBusinessHours(hours) {
            const periods = [];
            
            this.weekDays.forEach(({ value, label }, index) => {
                if (hours[value] && hours[value].isOpen) {
                    if (hours[value].is24) {
                        // Handle 24-hour case - open on current day at 00:00, close on current day at 24:00
                        periods.push({
                            openDay: value,
                            openTime: { hours: 0, minutes: 0 },
                            closeDay: value,
                            closeTime: { hours: 24, minutes: 0 }
                        });
                    } else if (hours[value].periods && hours[value].periods.length > 0) {
                        // Only include valid time periods
                        hours[value].periods.forEach(period => {
                            if (this.isValidTimeRange(period.open, period.close)) {
                                // Split time and handle missing components
                                let openParts = period.open.split(':');
                                let closeParts = period.close.split(':');
                                
                                // Default to 0 for any missing parts
                                const openHour = parseInt(openParts[0] || '0', 10);
                                const openMinute = parseInt(openParts[1] || '0', 10);
                                const closeHour = parseInt(closeParts[0] || '0', 10);
                                const closeMinute = parseInt(closeParts[1] || '0', 10);
                                
                                periods.push({
                                    openDay: value,
                                    openTime: { hours: openHour, minutes: openMinute },
                                    closeDay: value,
                                    closeTime: { hours: closeHour, minutes: closeMinute }
                                });
                            }
                        });
                    }
                }
            });
            
            return { periods };
        },
        async addKeyword(location) {
            if (location.needs_gmb_reauth) return;
            const keyword = location.currentKeyword.trim();
            if (keyword && !location.keywords.includes(keyword)) {
                location.keywords.push(keyword);
                location.currentKeyword = '';
                const workspaceDoc = doc(db, `users/${this.currentUID}/fly_workspaces/${location.workspaceId}`);
                await updateDoc(workspaceDoc, { gmbKeywords: arrayUnion(keyword) });
            }
        },
        async removeKeyword(location, index) {
            const keyword = location.keywords[index];
            location.keywords.splice(index, 1);
            const workspaceDoc = doc(db, `users/${this.currentUID}/fly_workspaces/${location.workspaceId}`);
            await updateDoc(workspaceDoc, { gmbKeywords: arrayRemove(keyword) });
        },
        getLocationUpdateEligibility(location) {
            const locationEligibility = this.checkUpdateEligibility(location.gmb_last_updated);
            const bulkEligibility = this.checkUpdateEligibility(this.gmb_bulk_last_updated);
            return {
                canUpdate: locationEligibility.canUpdate && bulkEligibility.canUpdate,
                remainingTime: Math.max(locationEligibility.remainingTime, bulkEligibility.remainingTime),
            };
        },
        filteredAdditionalCategories(location) {
            if (!this.categories || !location.additionalCategorySearchQuery) return [];
            const searchQuery = location.additionalCategorySearchQuery.toLowerCase();
            return this.categories.filter((category) => {
                const isPrimary = category.name === location.categories?.primaryCategory?.name;
                const isAlreadySelected = location.formData.additionalCategories?.some(
                    (selected) => selected.name === category.name
                );
                const matchesSearch = category.displayName.toLowerCase().includes(searchQuery);
                return !isPrimary && !isAlreadySelected && matchesSearch;
            });
        },
        filteredPrimaryCategory(location) {
            if (!this.categories || !location.primaryCategorySearchQuery) return [];
            const searchQuery = location.primaryCategorySearchQuery.toLowerCase();
            return this.categories.filter((category) => category.displayName.toLowerCase().includes(searchQuery));
        },
        addAdditionalCategory(location) {
            if (!location.selectedAdditionalCategory) return;
            if (!location.formData.additionalCategories) location.formData.additionalCategories = [];
            if (location.formData.additionalCategories.length >= 9) {
                this.$alert.show('Maximum 9 additional categories allowed');
                return;
            }
            const categoryToAdd = this.categories.find((cat) => cat.name === location.selectedAdditionalCategory);
            if (categoryToAdd) {
                location.formData.additionalCategories.push({ ...categoryToAdd, isOriginal: false });
            }
            location.selectedAdditionalCategory = null;
        },
        addPrimaryCategory(location) {
            if (!location.formData.primaryCategory) location.formData.primaryCategory = {};
            const categoryToAdd = this.categories.find((cat) => cat.name === location.selectedPrimaryCategory);
            if (categoryToAdd) {
                delete categoryToAdd?.moreHoursTypes;
                location.formData.primaryCategory = categoryToAdd;
            }
            location.selectedPrimaryCategory = null;
        },
        removeAdditionalCategory(location, index) {
            location.formData.additionalCategories.splice(index, 1);
        },
        arraysEqual(a, b) {
            if (a.length !== b.length) return false;
            for (let i = 0; i < a.length; i++) if (a[i] !== b[i]) return false;
            return true;
        },
        async suggestCategories(location) {
            location.suggestingCategories = true;
            const primaryCategory = location?.categories?.primaryCategory?.name || null;
            let additionalCategories = location.formData.additionalCategories.map((cat) => cat.name) || [];
            try {
                const payload = {
                    uid: this.currentUID,
                    wid: this.WID,
                    additionalCategories,
                    primaryCategory,
                    operation: 'suggestGMBCategories',
                    allCategories: this.categoriesId,
                    businessDescription: location?.profile?.description || '',
                    brandName: location?.title,
                };
                const suggestCategories = httpsCallable(functions, 'aiActions');
                const res = await suggestCategories(payload);
                if (res?.data?.result?.output) {
                    const suggestions = res.data.result.output.suggested_categories;
                    for (let suggestion of suggestions) {
                        suggestion = `categories/gcid:${suggestion}`;
                        const matchingCategory = this.categories.find((cat) => cat.name.toLowerCase() === suggestion.toLowerCase());
                        if (
                            matchingCategory &&
                            !location.formData.additionalCategories.some((cat) => cat.name === matchingCategory.name) &&
                            location.formData.additionalCategories.length < 9
                        ) {
                            location.formData.additionalCategories.push({ ...matchingCategory, isOriginal: false });
                        }
                    }
                    this.$snackbar.show('Categories suggested successfully');
                }
            } catch (error) {
                console.error('Error suggesting categories:', error);
                this.$alert.show('Error suggesting categories');
            } finally {
                location.suggestingCategories = false;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.scrollToTop();
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.scrollToTop();
            }
        },
        scrollToTop() {
            if (this.$refs.locationsContainer) {
                this.$refs.locationsContainer.scrollIntoView({ behavior: 'smooth' });
            } else {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        },
        isDescriptionTooLong(location) {
            return location.formData.description?.length > 750;
        },
        isWebsiteUriTooLong(location) {
            return location.formData.websiteUri?.length > 256;
        },
        isBusinessNameTooLong(location) {
            return location.formData.businessName?.length > 100;
        },
        closeUpdateResults() {
            this.showUpdateResults = false;
            this.updateResults = [];  // Clear results when closing
        },
        generateTimeSlots() {
            const slots = [];
            for (let h = 0; h < 24; h++) {
                slots.push(`${String(h).padStart(2, '0')}:00`);
                slots.push(`${String(h).padStart(2, '0')}:30`);
            }
            return slots;
        },
        addPeriod(location, day) {
            // Make sure we have an initialized array
            if (!location.formData.regularHours[day].periods) {
                this.$set(location.formData.regularHours[day], 'periods', []);
            }
            
            // Create the new period with default values
            const newPeriod = this.isValidTimeRange(this.defaultOpenTime, this.defaultCloseTime)
                ? { open: this.defaultOpenTime, close: this.defaultCloseTime }
                : { open: '10:00', close: '18:00' };
            
            // Force deep reactivity by replacing the entire regularHours object
            const updatedHours = JSON.parse(JSON.stringify(location.formData.regularHours));
            updatedHours[day].periods.push(newPeriod);
            this.$set(location.formData, 'regularHours', updatedHours);
            
            // Force a component update
            this.$forceUpdate();
        },
        removePeriod(location, day, index) {
            if (location.formData.regularHours[day].periods && index >= 0) {
                // Force deep reactivity by replacing the entire regularHours object
                const updatedHours = JSON.parse(JSON.stringify(location.formData.regularHours));
                updatedHours[day].periods.splice(index, 1);
                this.$set(location.formData, 'regularHours', updatedHours);
                
                // Force a component update
                this.$forceUpdate();
            }
        },
        applyDefault(location) {
            // Skip if time range is invalid
            if (!this.isValidTimeRange(this.defaultOpenTime, this.defaultCloseTime)) {
                return;
            }
            
            this.weekDays.forEach((day) => {
                if (location.formData.regularHours[day.value].isOpen && 
                    !location.formData.regularHours[day.value].is24) {
                    
                    if (!location.formData.regularHours[day.value].periods || 
                        location.formData.regularHours[day.value].periods.length === 0) {
                        this.$set(location.formData.regularHours[day.value], 'periods', []);
                    }
                    
                    location.formData.regularHours[day.value].periods = [{
                        open: this.defaultOpenTime,
                        close: this.defaultCloseTime
                    }];
                }
            });
        },
        toggleWeekendHours(location) {
            // Skip validation for default hours if toggling weekend hours
            ['SATURDAY', 'SUNDAY'].forEach((day) => {
                this.$set(location.formData.regularHours, day, {
                    isOpen: this.weekendHours,
                    is24: false,
                    periods: this.weekendHours ? [{ 
                        open: this.isValidTimeRange(this.defaultOpenTime, this.defaultCloseTime) ? 
                              this.defaultOpenTime : '10:00', 
                        close: this.isValidTimeRange(this.defaultOpenTime, this.defaultCloseTime) ? 
                               this.defaultCloseTime : '18:00' 
                    }] : []
                });
            });
            
            // Ensure weekdays are always enabled
            ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'].forEach((day) => {
                if (!location.formData.regularHours[day].isOpen) {
                    this.$set(location.formData.regularHours, day, {
                        isOpen: true,
                        is24: false,
                        periods: [{ 
                            open: this.isValidTimeRange(this.defaultOpenTime, this.defaultCloseTime) ? 
                                  this.defaultOpenTime : '10:00', 
                            close: this.isValidTimeRange(this.defaultOpenTime, this.defaultCloseTime) ? 
                                   this.defaultCloseTime : '18:00'  
                        }]
                    });
                }
            });
        },
        initializeBusinessHours(location) {
            // Ensure formData exists
            if (!location.formData) location.formData = {};
            
            // Initialize regularHours structure for all days
            const regularHours = {};
            const sameDayPeriods = {};
            this.weekDays.forEach(day => {
                regularHours[day.value] = {
                    isOpen: false,
                    is24: false,
                    periods: []
                };
                sameDayPeriods[day.value] = [];
            });
            
            // Process existing business hours if available
            if (location.regularHours && location.regularHours.periods) {
                location.regularHours.periods.forEach(period => {
                    const openDay = period.openDay;
                    const closeDay = period.closeDay;
                    
                    // Check if this is a 24-hour period
                    if (period.closeTime && period.closeTime.hours === 24) {
                        // Google's 24-hour format
                        regularHours[openDay].is24 = true;
                        regularHours[openDay].isOpen = true;
                    } else {
                        // Ensure we properly handle times where hours might be 0 or undefined
                        const openHours = period.openTime?.hours !== undefined ? period.openTime?.hours : 0;
                        const openMinutes = period.openTime?.minutes !== undefined ? period.openTime?.minutes : 0;
                        const closeHours = period.closeTime?.hours !== undefined ? period.closeTime?.hours : 0;
                        const closeMinutes = period.closeTime?.minutes !== undefined ? period.closeTime?.minutes : 0;
                        
                        const openTime = `${String(openHours).padStart(2, '0')}:${String(openMinutes).padStart(2, '0')}`;
                        const closeTime = `${String(closeHours).padStart(2, '0')}:${String(closeMinutes).padStart(2, '0')}`;
                        
                        if (openDay === closeDay) {
                            // Standard same-day period
                            sameDayPeriods[openDay].push({ open: openTime, close: closeTime });
                        } else if (openTime === '00:00' && closeTime === '00:00') {
                            // Check if closeDay is the next day (alternate 24-hour format)
                            const dayIndex = this.weekDays.findIndex(d => d.value === openDay);
                            const nextDayIndex = (dayIndex + 1) % 7;
                            const nextDay = this.weekDays[nextDayIndex].value;
                            
                            if (nextDay === closeDay) {
                                regularHours[openDay].is24 = true;
                                regularHours[openDay].isOpen = true;
                            }
                        }
                    }
                });
            }
            
            // Finalize each day's settings
            this.weekDays.forEach(day => {
                const dayValue = day.value;
                if (regularHours[dayValue].is24) {
                    // For 24-hour days, ensure periods is empty
                    regularHours[dayValue].periods = [];
                } else if (sameDayPeriods[dayValue].length > 0) {
                    // For non-24-hour open days, use collected periods
                    regularHours[dayValue].isOpen = true;
                    regularHours[dayValue].periods = sameDayPeriods[dayValue];
                }
            });
            
            // Assign to formData and create a deep copy for originalRegularHours
            location.formData.regularHours = regularHours;
            location.formData.originalRegularHours = JSON.parse(JSON.stringify(regularHours));
            
            // Check weekend hours status
            const saturdayOpen = regularHours['SATURDAY']?.isOpen || false;
            const sundayOpen = regularHours['SUNDAY']?.isOpen || false;
            this.weekendHours = saturdayOpen && sundayOpen;
        },
        getTimeOptions(currentTime) {
            // If current time isn't in the standard list, add it to the options
            let timeOptions = [...this.timeSlots];
            
            if (currentTime && !timeOptions.includes(currentTime)) {
                timeOptions.push(currentTime);
                
                // Sort all times
                timeOptions.sort((a, b) => {
                    const [aHours, aMinutes] = a.split(':').map(n => parseInt(n, 10));
                    const [bHours, bMinutes] = b.split(':').map(n => parseInt(n, 10));
                    
                    if (aHours !== bHours) {
                        return aHours - bHours;
                    }
                    return aMinutes - bMinutes;
                });
            }
            
            return timeOptions;
        },
        isValidTimeRange(openTime, closeTime) {
            if (!openTime || !closeTime) return false; // If either time is missing, the range is invalid
            
            // Parse time strings to numbers for comparison
            const [openHour, openMinute] = openTime.split(':').map(n => parseInt(n, 10) || 0);
            const [closeHour, closeMinute] = closeTime.split(':').map(n => parseInt(n, 10) || 0);
            
            // Convert to minutes since midnight for easier comparison
            const openTotalMinutes = openHour * 60 + openMinute;
            const closeTotalMinutes = closeHour * 60 + closeMinute;
            
            // Closing time should be later than opening time
            return closeTotalMinutes > openTotalMinutes;
        },
        
        validateTimePeriod(period) {
            // If the time range is invalid, adjust the closing time
            if (!this.isValidTimeRange(period.open, period.close)) {
                // Parse opening time
                const [openHour, openMinute] = period.open.split(':').map(n => parseInt(n, 10) || 0);
                
                // Set closing time to at least 30 minutes after opening time
                let newCloseHour = openHour;
                let newCloseMinute = openMinute + 30;
                
                // Adjust for minute overflow
                if (newCloseMinute >= 60) {
                    newCloseHour += 1;
                    newCloseMinute -= 60;
                }
                
                // Handle day wrapping (e.g., if opening time is 23:30)
                if (newCloseHour >= 24) {
                    newCloseHour = 23;
                    newCloseMinute = 59;
                }
                
                // Format and set the new closing time
                period.close = `${String(newCloseHour).padStart(2, '0')}:${String(newCloseMinute).padStart(2, '0')}`;
            }
        },
    },
};
</script>

<style scoped>
.mdi-plus {
    color: rgb(59 130 246);
}

.group-container {
    margin-bottom: 16px;
}

.group-header {
    padding: 8px 0;
}

.group-title {
    margin: 0;
}

.separator {
    height: 1px;
    background-color: #e0e0e0;
}

.v-btn-toggle {
    display: flex;
    gap: 8px;
}

.form-group {
    @apply space-y-2;
}

.form-label {
    @apply block text-sm font-medium text-gray-700;
}

.form-input {
    @apply w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm;
}

form .form-input {
    @apply shadow-md;
}

:deep(.v-text-field__details),
:deep(.v-messages) {
    display: none;
}

button {
    @apply transform hover:scale-105;
}

.status-filters {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.status-filter-btn {
    padding: 0.175rem 0.55rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    transition: all 0.2s;
    background-color: white;
    color: #4a5568;
}

.status-filter-btn:hover {
    opacity: 0.9;
}

.status-filter-btn.status-excellent {
    border-color: #4caf50;
}

.status-filter-btn.status-excellent.active {
    background-color: #4caf50;
    color: white;
}

.status-filter-btn.status-excellent:not(.active) {
    color: #4caf50;
    background-color: #e8f5e9;
}

.status-filter-btn.status-good {
    border-color: #8bc34a;
}

.status-filter-btn.status-good.active {
    background-color: #8bc34a;
    color: white;
}

.status-filter-btn.status-good:not(.active) {
    color: #8bc34a;
    background-color: #f1f8e9;
}

.status-filter-btn.status-average {
    border-color: #ffa726;
}

.status-filter-btn.status-average.active {
    background-color: #ffa726;
    color: white;
}

.status-filter-btn.status-average:not(.active) {
    color: #ffa726;
    background-color: #fff3e0;
}

.status-filter-btn.status-poor {
    border-color: #ff7043;
}

.status-filter-btn.status-poor.active {
    background-color: #ff7043;
    color: white;
}

.status-filter-btn.status-poor:not(.active) {
    color: #ff7043;
    background-color: #fbe9e7;
}

.status-filter-btn.status-critical {
    border-color: #ef5350;
}

.status-filter-btn.status-critical.active {
    background-color: #ef5350;
    color: white;
}

.status-filter-btn.status-critical:not(.active) {
    color: #ef5350;
    background-color: #ffebee;
}

.status-filter-btn:first-child {
    border-color: #cbd5e0;
}

.status-filter-btn:first-child.active {
    background-color: #63b3ed;
    color: white;
}

.status-filter-btn:first-child:not(.active) {
    color: #63b3ed;
    background-color: #edf2f7;
}

.keyword-chip {
    @apply bg-gray-100 px-2 py-1 rounded-full flex items-center justify-between gap-1;
    min-width: 60px;
    transition: width 0.2s ease;
}

.keyword-chip span {
    @apply flex-1 text-center text-sm truncate;
}

.keyword-chip button {
    @apply text-gray-500 hover:text-red-500;
}

.category-chip-new {
    border: 1px solid #8050de !important;
    color: #8050de !important;
}

.pagination-button {
    @apply bg-blue-500 text-white font-semibold py-2 px-4 rounded-md border border-blue-600 transition duration-200;
}

.pagination-button:hover {
    @apply bg-blue-600;
}

.pagination-button:disabled {
    @apply bg-gray-300 text-gray-500 border-gray-400 cursor-not-allowed;
}

.v-list-item-subtitle {
    margin-bottom: 2px;
}

.field-editor {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: white;
    overflow: auto;
}

.textarea-wrapper {
    position: relative;
}

.textarea-wrapper textarea {
    padding-right: 2.5rem;
}

:deep(.v-input__control) {
  /* padding-top: -1rem !important; */
}

.form-select {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 5px;
  font-size: 14px;
  height: 32px; /* Control height */
  box-sizing: border-box;
}

.time-autocomplete {
    width: 120px;
}

/* Override Vuetify default styling for time inputs */


:deep(.v-select__selections) {
  line-height: 1 !important;
  padding-top: 0 !important;
}

/* Override Vuetify's default margin/padding for selection controls */
:deep(.v-input--selection-controls) {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

/* More specific override if needed for switches */
:deep(.v-switch.v-input--selection-controls) {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.edit-icon-container-category {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
}

.category-dropdown {
    width: 100%;
    padding-right: 40px; /* Make room for the icon */
}

</style>

