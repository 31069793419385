var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]"},[_c('section',{staticClass:"flex flex-col items-center w-full justify-center xl:w-[1285px]"},[_c('div',{staticClass:"flex items-center justify-start md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]"},[_c('div',{staticClass:"items-center gap-2 px-4 mt-5 -ml-2 d-flex"},[_c('img',{staticClass:"cursor-pointer w-6 md:w-[28px]",attrs:{"src":require("../../assets/img/sidebar/post.svg"),"alt":"post"}}),_c('div',{staticClass:"flex items-center gap-1"},[_vm._m(0),_c('p',{staticClass:"text-[#8056DE] text-sm font-semibold md:hidden"},[_vm._v("  "+_vm._s(_vm.selectedTabName)+" ")])])])]),_c('div',{staticClass:"w-full pb-[33px] mt-5 bg-white md:px-4 xl:px-10 md:rounded-3xl"},[_c('div',{staticClass:"md:py-[28px] xl:w-[1285px] flex gap-4 font-semibold w-full"},[_c('div',{staticClass:"bg-[#F2F6FA] py-2 px-2 md:px-0 flex gap-1 md:gap-4 md:p-1 md:rounded-full w-full"},[_c('button',{staticClass:"rounded-full py-2 md:py-[14px] flex items-center justify-center md:w-full text-xs md:text-base",class:_vm.tab == 0
                ? 'bg-[#8056DE] text-white '
                : 'bg-white text-[#636B8C]',on:{"click":() => {
                _vm.tab = 0;

                _vm.resetLoader();
              }}},[_c('img',{staticClass:"w-[20px] h-[20px] md:mr-2",class:_vm.tab == 0 ? 'hidden ' : '',attrs:{"src":require("../../assets/img/post-icons/gallerygray.svg"),"alt":"delete"}}),_c('img',{staticClass:"w-[20px] h-[20px] md:mr-2",class:_vm.tab == 0 ? '' : 'hidden',attrs:{"src":require("../../assets/img/post-icons/gallerywhite.svg"),"alt":"delete"}}),_c('div',{staticClass:"hidden md:block"},[_vm._v("Published")])]),_c('button',{staticClass:"rounded-full py-2 md:py-[14px] flex items-center justify-center md:w-full text-xs md:text-base",class:_vm.tab == 1
                ? 'bg-[#8056DE] text-white '
                : 'bg-white text-[#636B8C]',on:{"click":() => {
                _vm.tab = 1;
                _vm.resetLoader();
                // closeSearch(), resetFilter();
              }}},[_c('img',{staticClass:"w-[20px] h-[20px] md:mr-2",class:_vm.tab == 1 ? 'hidden' : '',attrs:{"src":require("../../assets/img/post-icons/calendergray.svg"),"alt":"delete"}}),_c('img',{staticClass:"w-[20px] h-[20px] md:mr-2",class:_vm.tab == 1 ? '' : 'hidden',attrs:{"src":require("../../assets/img/post-icons/calenderwhite.svg"),"alt":"delete"}}),_c('div',{staticClass:"hidden md:block"},[_vm._v("Scheduled")])]),_c('button',{staticClass:"rounded-full py-2 md:py-[14px] flex items-center justify-center md:w-full text-xs md:text-base",class:_vm.tab == 3
                ? 'bg-[#8056DE] text-white '
                : 'bg-white text-[#636B8C]',on:{"click":() => {
                _vm.tab = 3;
                _vm.resetLoader();
              }}},[_c('img',{staticClass:"w-[20px] h-[20px] md:mr-2",class:_vm.tab == 3 ? 'hidden' : '',attrs:{"src":require("../../assets/img/post-icons/draftgray.svg"),"alt":"delete"},on:{"click":() => {
                  _vm.tab = 3;
                  _vm.resetLoader();
                }}}),_c('img',{staticClass:"w-[20px] h-[20px] md:mr-2",class:_vm.tab == 3 ? '' : 'hidden',attrs:{"src":require("../../assets/img/post-icons/draftwhite.svg"),"alt":"delete"},on:{"click":() => {
                  _vm.tab = 3;
                  _vm.resetLoader();
                }}}),_c('div',{staticClass:"hidden md:block"},[_vm._v("Drafts")])]),_c('button',{staticClass:"rounded-full py-2 md:py-[14px] flex items-center justify-center md:w-full text-xs md:text-base",class:_vm.tab == 4
                ? 'bg-[#8056DE] text-white '
                : 'bg-white text-[#636B8C]',on:{"click":() => {
              _vm.tab = 4;
              _vm.resetLoader();
              // closeSearch(), resetFilter();
            }}},[_c('img',{staticClass:"w-[20px] h-[20px] md:mr-2",class:_vm.tab == 4 ? 'hidden' : '',attrs:{"src":require("../../assets/img/gallery-icons/delete.svg"),"alt":"delete"}}),_c('img',{staticClass:"w-[20px] h-[20px] md:mr-2",class:_vm.tab == 4 ? '' : 'hidden',attrs:{"src":require("../../assets/img/post-icons/deletewhite.svg"),"alt":"delete"}}),_c('div',{staticClass:"hidden md:block"},[_vm._v("Deleted")])])])]),_c('div',{staticClass:"bg-[#F2F6FA] pb-2 pt-2 md:pt-[33px] px-2 lg:px-5 md:rounded-3xl"},[_c('div',{staticClass:"grid grid-cols-1 gap-4 md:grid-cols-1 posts"},_vm._l((_vm.posts),function(post,i){return _c('post-card',{key:i,attrs:{"tab":_vm.tab,"post":post},on:{"onPostDelete":function($event){return _vm.onPostDelete(i)},"onSocialPostDelete":function($event){return _vm.onSocialPostDelete()}}})}),1),_c('infinite-loading',{ref:"loader",on:{"infinite":_vm.fetchPosts}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"image,article"}})],1),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})]),(!_vm.isLoading && _vm.posts.length == 0 && _vm.tab == 0)?[_vm._m(1)]:_vm._e()],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-base md:text-[22px] font-bold"},[_vm._v(" Posts "),_c('span',{staticClass:"md:hidden"},[_vm._v(">")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text-center"},[_c('h4',{staticClass:"text-xl font-bold"},[_vm._v("No posts to display")])])
}]

export { render, staticRenderFns }