<template>
    <div class="bg-white shadow-md p-4 rounded-lg">
        <!-- Row Header -->
        <div class="flex justify-between items-start">
            <div class="flex gap-2">
                <a :href="`https://business.google.com/locations`" target="_blank">
                    <h2 class="text-lg font-semibold cursor-pointer text-black hover:underline">
                        {{ member.name }}
                    </h2>
                </a>

                <div class="flex items-start space-x-1 mr-3 mt-0.5">
                    <v-icon v-if="true" color="blue" size="large" class="self-start mt-0.5">mdi-check-decagram</v-icon>
                    <span v-else class="ml-2 text-red-500 text-sm">Unverified</span>
                    <span v-for="star in 5" :key="star" class="relative inline-block text-gray-300">
                        ★
                        <span v-if="star <= fullStars" class="absolute top-0 left-0 text-yellow-400">
                            ★
                        </span>
                        <span v-else-if="star === fullStars + 1 && decimalPart > 0"
                            class="absolute top-0 left-0 text-yellow-400 overflow-hidden"
                            :style="{ width: decimalPart * 100 + '%' }">
                            ★
                        </span>
                    </span>
                    <span class="text-sm text-purple-600 font-semibold ml-2">
                        {{ member.averageRating?.toFixed(1) }}
                    </span>
                    <v-icon size="8" class="self-start mt-1.5">mdi-circle</v-icon>
                    <span
                        class="text-sm text-purple-600 font-semibold cursor-pointer hover:underline whitespace-nowrap">
                        {{ member.reviewCount }} Reviews
                    </span>
                </div>
            </div>

            <div class="flex gap-3 justify-end items-start mt-0.5">
                <v-icon small class="cursor-pointer text-green-500 hover:text-green-700"
                    @click="$emit('resend-invitation', member)" title="Resend Invitation">
                    mdi-email-outline
                </v-icon>
                <v-icon small class="cursor-pointer text-red-500 hover:text-red-700" @click="$emit('delete', member)"
                    title="Delete">
                    mdi-delete-outline
                </v-icon>
            </div>
        </div>

        <!-- Address -->
        <p class="text-gray-600 text-sm pb-3 border-b border-gray-300">
            {{ member.sub_locality }}, {{ member.city }}, {{ member.state }}, {{ member.zipcode }}
        </p>

        <!-- Data Row -->
        <div class="grid grid-cols-5 divide-x divide-gray-300 mt-4">
            <div class="flex flex-col text-center px-3">
                <span class="text-gray-500 text-sm">Phone Number</span>
                <p v-if="member.phone" class="text-blue-600 font-semibold">{{ member.phone }}</p>
                <span v-else class="text-black font-semibold">-</span>
            </div>
            <div class="flex flex-col text-center px-3">
                <span class="text-gray-500 text-sm">Email</span>
                <a v-if="member.email" :href="`mailto:${member.email}`"
                    class="text-blue-600 font-semibold hover:underline">
                    {{ member.email }}
                </a>
                <span v-else class="text-black font-semibold">-</span>
            </div>
            <div class="flex flex-col text-center px-3">
                <span class="text-gray-500 text-sm">Website Link</span>
                <a :href="member.website" class="text-blue-500 underline truncate" target="_blank">
                    {{ formatWebsite(member.website) }}
                </a>
            </div>
            <div class="flex flex-col text-center px-3">
                <span class="text-gray-500 text-sm">Profile Health</span>
                <div class="" v-if="member.shallowConnect && member.isProfileDataLoading">
                    <v-progress-circular indeterminate color="primary" size="24" />
                </div>
                <div class="flex flex-col items-center" v-else>
                    <span class="font-semibold" :style="{ color: getProfileStrengthColor(member.profile_strength) }">
                        {{ getProfileHealthText(member.profile_strength) }}
                    </span>
                    <v-progress-linear :value="member.profile_strength"
                        :color="getProfileStrengthColor(member.profile_strength)" height="6" />
                    <span>{{ member.profile_strength }}%</span>
                </div>
            </div>
            <div class="flex flex-col text-center px-3">
                <span class="text-gray-500 text-sm">Connected Apps</span>
                <div class="flex justify-center gap-2">
                    <v-icon v-if="member.verification_state === 'COMPLETED'" small color="success" title="GMB Verified">
                        mdi-check-circle
                    </v-icon>
                    <v-icon v-else-if="member.verification_state === 'PENDING'" small color="warning"
                        title="GMB Verification Pending" @click="openVerificationGuide">
                        mdi-information
                    </v-icon>
                    <v-icon v-else-if="member.verification_state === 'STATE_UNSPECIFIED'" small color="warning"
                        title="GMB Not Verified">
                        mdi-information
                    </v-icon>
                    <v-icon v-else-if="member.verification_state === 'FAILED'" small color="warning"
                        title="GMB Verification Failed" @click="openVerificationGuide">
                        mdi-close-circle
                    </v-icon>
                    <div>
                        <span v-if="member.connectType && member.connectType === 'shallow'"
                            class="text-gray-500 text-sm">
                            Partial Access
                        </span>
                        <tool-tip v-if="member.connectType && member.connectType === 'shallow'"
                            message="GMB not connected">
                            <v-icon small class="pr-2">mdi-information</v-icon>
                        </tool-tip>
                        <v-icon v-if="member.status !== 'connected'" small
                            class="cursor-pointer hover:text-[#8050DE] transition-colors"
                            @click="$emit('open-invitation-link', member)" title="Open invitation link">
                            mdi-plus-circle-outline
                        </v-icon>
                    </div>
                    <div v-if="member.status === 'connected'"
                        class="flex gap-2 -mt-3 flex-wrap items-center justify-center">
                        <img v-for="platform in member.connected_apps" :key="platform"
                            :src="platformLogoCache[platform] || ''" :alt="platform" class="w-5 h-5 object-contain"
                            :title="platform" />
                        <v-icon small class="cursor-pointer hover:text-[#8050DE] transition-colors"
                            @click="$emit('open-invitation-link', member)" title="Open invitation link">
                            mdi-plus-circle-outline
                        </v-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ToolTip from "@/components/ui/ToolTip.vue";

export default {
    components: { ToolTip },
    props: {
        member: {
            type: Object,
            required: true
        },
        platformLogoCache: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        fullStars() {
            return Math.floor(this.member.averageRating);
        },
        decimalPart() {
            return this.member.averageRating - this.fullStars;
        }
    },
    methods: {
        formatWebsite(website) {
            return website?.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '') || '';
        },
        getProfileStrengthColor(strength) {
            if (strength >= 85) return '#4CAF50';
            if (strength >= 70) return '#8BC34A';
            if (strength >= 50) return '#FFA726';
            if (strength >= 30) return '#FF7043';
            return '#EF5350';
        },
        getProfileHealthText(strength) {
            if (strength >= 85) return 'Excellent';
            if (strength >= 70) return 'Good';
            if (strength >= 50) return 'Average';
            if (strength >= 30) return 'Poor';
            return 'Critical';
        },
        openVerificationGuide() {
            window.open(
                "https://support.google.com/business/answer/2911778?hl=en&co=GENIE.Platform%3DDesktop",
                "_blank"
            );
        }
    }
};
</script>