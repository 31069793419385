<template>
    <div class="bg-[#F2F6FA] h-full">
      <div class="flex flex-col items-center justify-center w-full pb-20">
        <section class="flex flex-col items-center w-full justify-center xl:w-[1285px]">
          <div class="flex items-center justify-between md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]">
            <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
              <img src="@/assets/img/sidebar/reviews_g.png" class="cursor-pointer w-6 md:w-[28px]" alt="post" />
              <div class="flex items-center gap-1">
                <p class="text-base md:text-[22px] font-bold">Reviews</p>
              </div>
            </div>
          </div>
          <div class="min-h-screen w-full overflow-x-hidden rounded-2xl mt-5">
            <v-tabs v-model="activeTab">
              <v-tab value="dashboard">Dashboard</v-tab>
              <v-tab value="respond">
                <img
                  :src="activeTab === 1 ? require('@/assets/img/sidebar/icons8-google-48.png') : require('@/assets/img/sidebar/icons8-google-48-unselect.png')"
                  alt="Google"
                  class="tab-icon"
                />
                Reviews
              </v-tab>
              <v-tab value="insights">Insights</v-tab>
              <v-tab value="insights">Locations without Reviews</v-tab>
              <v-tab value="insights">Settings</v-tab>
            </v-tabs>
            <v-tabs-items v-model="activeTab" class="rounded-b-2xl">
              <!-- Dashboard Tab (default) -->
              <v-tab-item :value="0">
                <!-- Your existing ReviewDashboard content will go here -->
                <div>
                  <!-- Import and use your ReviewDashboard component here -->
                  <ReviewDashboardContent />
                </div>
              </v-tab-item>
              
              <!-- Review Respond Tab -->
              <v-tab-item :value="1">
                <!-- Import and use your GmbReviews component here -->
                <GmbReviews />
              </v-tab-item>
              
              <!-- Insights Tab -->
              <v-tab-item :value="2">
                <!-- Import and use your GmbReviewsReport component here -->
                <GmbReviewsReport />
              </v-tab-item>

              <v-tab-item :value="3">
                <!-- Import and use your GmbReviews component here -->
                <GmbLocationsWithoutReviews />
              </v-tab-item>

              <v-tab-item :value="4">
                <!-- Import and use your GmbReviews component here -->
                <Setting />
              </v-tab-item>

            </v-tabs-items>
          </div>
        </section>
      </div>
    </div>
  </template>
  
  <script>
  import GmbReviewsReport from '../GmbReviewsReport.vue';
  import GmbReviews from '../GmbReviews.vue';
  import ReviewDashboardContent from './ReviewDashboard.vue';
  import GmbLocationsWithoutReviews from "../GmbNoReviewLocations.vue";
  import Setting from "../../../components/reviews/Settings.vue"
  
  export default {
    components: {
      GmbReviewsReport,
      GmbReviews,
      ReviewDashboardContent,
      GmbLocationsWithoutReviews,
      Setting
    },
    data() {
      return {
        activeTab: 0,
      };
    },
    watch: {
  '$route.query.tab': {
    immediate: true,
    handler(tab) {
      switch (tab) {
        case 'gmb-reviews':
          this.activeTab = 1;
          break;
        case 'gmb-reviews-report':
          this.activeTab = 2;
          break;
        case 'gmb-no-locations':
          this.activeTab = 3;
          break;
        case 'settings':
          this.activeTab = 4;
          break;
        default:
          this.activeTab = 0;
      }
    }
  }
}
  };
  </script>
  <style scoped>
  .tab-icon {
    width: 20px; /* Adjust width */
    height: 20px; /* Adjust height */
    margin-right: 6px; /* Space between icon and text */
    vertical-align: middle; /* Align it properly with text */
  }
  
  </style>
  