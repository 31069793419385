<template>
  <form-wrapper ref="form">
    <div class="flex justify-between">
      <short-hands-menu
      @onSummarisePost="onSummarisePost"
      @updatePostContent="updatePostContent"
      @insertPostIdea="insertPostIdea"
      @addGeneratedImage="addGeneratedImage"
      />
      <special-post-menu/>
    </div>
    <br />
    <div class="social-media-selector mb-4">
      <label class="text-base font-semibold leading-6 text-gray-900 input-label ">
        {{ whereToPost }}
      </label>
      <div class="icon-container mb-2" v-if="connectionAppsList?.length > 0">
        <div
          class="icon-wrapper cursor-pointer"
          v-for="app in connectionAppsList"
          :key="app.value"
          @click="toggleSelection(app)"
        >
          <div class="relative">
            <div
              :class="{
                'icon-circle-selected': isSelected(app),
                'icon-circle': !isSelected(app),
                deselected: !isSelected(app),
              }"
            >
              <img
                class="social-icon"
                :src="app.picture"
                @error="onProfileError"
              />
              <img
                class="platform-logo"
                :class="{
                  'white-background':
                    app.name === 'X / Twitter' || app.name === 'Wordpress',
                }"
                :src="app.logo_url"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="icon-container mb-3" v-else>
        <div class="no-data-available">No social handles connected</div>
      </div>
      <!-- <div
        v-if="typeof isMultiselectValid === 'string'"
        class="validation-error text-red-600 text-xs -mt-1 mb-3"
      >
        {{ isMultiselectValid }}
      </div> -->
    </div>
    <!-- <v-select
      item-value="value"
      item-text="name"
      :rules="[rules.multiselect]"
      outlined
      dense
      multiple
      v-model="form.social_media"
      :items="connectionAppsList"
    >
      <template v-slot:selection="data">
        <div
          v-bind="data.attrs"
          :input-value="data.selected"
          outlined
          class="relative gap-1 my-1 whitespace-nowrap"
          @click="data.select"
        >
          <div class="relative">
            <div class="relative pr-4">
              <img
                class="w-[40px] md:w-[50px] flex flex-wrap relative md:h-[50px] rounded-full"
                :src="data.item.picture"
                @error="onProfileError"
              />

              <img
                class="w-[18px] absolute bottom-1 right-3 rounded-full"
                :src="data.item.logo_url"
              />
            </div>

            <div class="absolute top-0 left-0">
              <div @click="removeSelection(data.item)">
                <v-icon class="bg-black rounded-full" color="white" size="13"
                  >mdi-close</v-icon
                >
              </div>
            </div>
          </div> -->
    <!-- <v-img class="absolute" :src="data.item.logo_url"></v-img> -->

    <!-- <span class="hidden-xs-only">{{ data.item.name }}</span> -->
    <!-- </div>
      </template>
<template v-slot:item="data">
        <v-list-item-avatar>
          <img v-if="data.item.picture" :src="data.item.picture" />
          <v-icon
            size="60"
            class="w-[40px] md:w-[50px] flex flex-wrap relative md:h-[50px] rounded-full"
            v-else
          >
            mdi-account-circle
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
        </v-list-item-content>
      </template>
</v-select> -->
  <div v-if="showLocationDropdownBasedOnPlan && hasStoreWorkspaces" ref="dropdownContainer" class="relative flex items-center gap-2 grow w-[50%] mb-2">
    <button
      @click="toggleLocationDropdown"
      class="flex items-center gap-2 p-2 border border-gray-300 rounded"
      style="border: 1px solid #ccc;"
    >
      Post to Locations 
      <span v-if="form.locations.length" class="ml-1 text-sm text-gray-600">
        ({{ form.locations.length }} selected)
      </span>
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
      </svg>
    </button>
    <!-- Platform Logos -->
    <div v-if="form.locations.length > 0" class="flex gap-2 platform-icons">
      <img
        v-for="platform in uniquePlatforms"
        :key="platform"
        :src="platformLogoCache[platform] || ''"
        :alt="platform"
        class="w-5 h-5 object-contain"
        :title="platform"
      />
    </div>

    <!-- Locations Dropdown Content -->
    <div v-if="showLocationDropdown" class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-[26rem] max-w-[800px] overflow-y-auto px-5">
      <!-- Filter Input -->
      <div class="relative flex items-center mb-2 border border-gray-300 rounded mt-2 w-full">
        <svg xmlns="http://www.w3.org/2000/svg" class="absolute left-3 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 4a7 7 0 100 14 7 7 0 000-14zM21 21l-4.35-4.35" />
        </svg>
        
        <input
          type="text"
          v-model="locationFilter"
          placeholder="Filter by name, city, or state"
          class="w-full pl-10 pr-3 py-2 border-none focus:outline-none rounded"
        />
      </div>

      <div
      v-for="(stateData, state) in filteredLocations"
      :key="state"
      class="state-section p-2"
    >
        <!-- State Checkbox -->
        <div class="flex items-center gap-2">
          <input
            type="checkbox"
            :checked="stateData.checked"
            @change="toggleAllCities(state)"
          />
          <span class="font-medium">{{ state }}</span>
        </div>       
        <!-- Cities List -->
        <ul class="pl-6 mt-1">
          <li
          v-for="city in stateData.cities"
          :key="city.id"
          class="flex items-center gap-2 relative group justify-between"
        >
            <div class="flex items-center gap-2">
              <input
                type="checkbox"
                v-model="city.checked"
                @change="toggleCitySelection(state, city)"
              />
              <span class="truncate">{{ city.name }} - {{ city.sub_locality }} {{ city.city }}</span>
            </div>
            <!-- "Only" Button -->
            <a
            href="#"
            class="text-blue-500 text-xs opacity-0 group-hover:opacity-100 transition-opacity ml-auto"
            @click.prevent="retainOnlyThisCity(state, city)"
          >
              Only
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

<!-- Location Post Preference Toggle - Added right after locations dropdown -->
<div v-if="showLocationDropdownBasedOnPlan && form.locations.length > 0" class="mb-4 mt-2">
  <label class="text-sm text-gray-600 mb-1 block"></label>
  <v-btn-toggle
    v-model="form.location_post_pref"
    mandatory
    dense
    class="location-pref-toggle"
  >
    <v-btn value="gmb_only" small text>
      GMB Only
    </v-btn>
    <v-btn value="all" small text>
      All Platforms
    </v-btn>
    <v-btn value="ig_only" small text>
      Instagram Only
    </v-btn>
  </v-btn-toggle>
</div>

  
    <!-- <p class="mt-4">Selected Locations: {{ form.selectedLocations.map(item => item.name).join(', ') }}</p> -->

    <radio-group
      v-for="(item, i) in form.social_media"
      :key="i"
      :row="true"
      v-model="configuration[platformConfigurations[item]]"
      rules="required"
      v-if="hasMorePostTypes(item)"
      :items="getPostTypes(item)"
      :label="getPostTypeLabel(item)"
      class="mb-2"
    >
    </radio-group>
    <label v-if="hasPinterest" class="text-base input-label font-semibold leading-6 text-gray-900 mt-[10px]">Post title (Required for Pinterest)</label>
    <v-textarea
      v-if="hasPinterest"
      outlined
      v-model="form.name"
      auto-grow
      rows="1"
      counter="100"
      row-height="15"
      class="mt-1"
    ></v-textarea>
    <label class="text-base input-label font-semibold leading-6 text-gray-900">Post media</label>
    <v-card outlined class="mt-2 mb-5 sm:mb-3">
      <v-card-text class="flex-col gap-2 px-0 d-flex">
        <!-- <span
          class="px-4 mb-2 text-sm black--text font-weight-medium d-none d-sm-flex"
        >
          Share photos or a video or design your own.
        </span> -->
        <div
          v-if="form.images?.length || form.videos?.length"
          class="flex-col gap-2 d-flex"
        >
          <h3 class="px-4 text-sm black--text font-weight-medium">
            Selected Assets
          </h3>
          <div v-if="form.images?.length >= 1" class="px-4">
            <draggable
              v-model="form.images"
              :options="{ animation: 300 }"
              @change="handleReorder"
              class="flex-row flex-wrap d-flex max-w-1/2"
            >
              <v-col
                v-for="(n, index) in form.images"
                :key="index"
                class="d-flex child-flex"
                cols="12"
                sm="6"
                md="4"
              >
                <v-card class="pa-4 mb-2">
                  <div class="flex-col d-flex">
                    <v-img
                      :src="getThumbnail(index)"
                      aspect-ratio="1"
                      class="relative grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                      <v-btn
                        icon
                        @click="removeAssets(form.images, index, 'image')"
                        @touchstart="removeAssets(form.images, index, 'image')"
                        class="absolute right-0"
                      >
                        <v-icon color="red">mdi-close</v-icon>
                      </v-btn>
                      <v-icon color="primary" class="absolute top-1 left-2"
                        >mdi-image-outline</v-icon
                      >
                    </v-img>
                    <div v-if="n.size">
                      <p
                        class="py-1 mb-0 text-sm"
                        :class="{ 'text-red-500': n.size > 8 * 1024 * 1024 }"
                      >
                        Size: {{ formatFileSize(n.size) }}
                      </p>
                    </div>
                    <div class="flex justify-between">
                      <div>
                        <button
                          width="50%"
                          depressed
                          text
                          color="secondary"
                          @click.prevent="openPreview(index)"
                          @touchstart.prevent="openPreview(n)"
                          class="mr-1"
                        >
                          <img
                            src="@/assets/img/preview.svg"
                            alt="translate"
                            class="mt-3"
                          />
                        </button>
                      </div>
                      <!-- <div>
                        <button
                          block
                          depressed
                          text
                          color="primary"
                          @touchstart.prevent="editMedia(form.images[index])"
                          @click.prevent="editMedia(form.images[index])"
                          class="-mr-4 pr-2"
                        >
                          <img
                            src="@/assets/img/pencil.svg"
                            alt="translate"
                            width="20px"
                            style="max-width: 100%; height: auto"
                            class="mt-2 mr-3"
                          />
                        </button>
                        <edit-image-dialog
                          :id="n.id"
                          v-if="n.id"
                          @onEdit="onEdit(index, $event)"
                        />
                      </div> -->
                      <v-btn
                        icon
                        color="primary"
                        :loading="imgIndex === index ? true : false"
                        v-if="currentUser.fly_ai_text_credits > 0"
                        @click.prevent="generateImageCaptions(n, index)"
                        @touchstart.prevent="generateImageCaptions(n, index)"
                      >
                        <v-icon class="target-element-4" color="#8056de"
                          >mdi-card-text-outline</v-icon
                        >
                      </v-btn>
                      <v-btn
                        icon
                        color="primary"
                        v-else-if="currentUser.fly_ai_text_credits === 0"
                        @click.prevent="showUpgradeDialog = true"
                        @touchstart.prevent="showUpgradeDialog = true"
                      >
                        <v-icon class="target-element-4" color="#8056de"
                          >mdi-card-text-outline</v-icon
                        >
                      </v-btn>

                      <v-dialog v-model="showUpgradeDialog" max-width="600">
                        <v-card>
                          <v-card-title
                            >You have run out of Fly AI Writer credits.Please
                            <br />renew or upgrade to proceed.</v-card-title
                          >

                          <v-card-actions>
                            <v-btn color="primary" @click="upgrade"
                              >Upgrade</v-btn
                            >
                            <v-btn text @click="closeDialog">Cancel</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-btn
                        icon
                        color="primary"
                        :loading="hashIndex === index ? true : false"
                        @click.prevent="generateImageHashtagss(n, index)"
                        @touchstart.prevent="generateImageHashtagss(n, index)"
                        v-if="currentUser.fly_ai_text_credits > 0"
                      >
                        <v-icon class="target-element-4" color="#8056de"
                          >mdi-pound</v-icon
                        >
                      </v-btn>
                      <v-btn
                        icon
                        color="primary"
                        v-else-if="currentUser.fly_ai_text_credits === 0"
                        @click.prevent="showUpgradeDialog = true"
                        @touchstart.prevent="showUpgradeDialog = true"
                      >
                        <v-icon class="target-element-4" color="#8056de"
                          >mdi-pound</v-icon
                        >
                      </v-btn>

                      <v-dialog v-model="showUpgradeDialog" max-width="600">
                        <v-card>
                          <v-card-title
                            >You have run out of Fly AI Writer credits. Please
                            <br />
                            renew or upgrade to proceed.</v-card-title
                          >

                          <v-card-actions>
                            <v-btn color="primary" @click="upgrade"
                              >Upgrade</v-btn
                            >
                            <v-btn text @click="closeDialog">Cancel</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </draggable>
          </div>
          <v-row v-if="form.videos?.length" class="px-4 mt-2 mb-4">
            <v-col
              v-for="(video, index) in form.videos"
              :key="video.id"
              cols="12"
              sm="6"
            >
              <v-card class="pa-2" outlined>
                <div class="relative h-full">
                  <video-auto-play
                    :autoplay="false"
                    class="mt-4"
                    :videoSrc="video.url || video"
                    @fetchedDuration="handleFetchedDuration"
                  />
                  <div class="absolute top-0 justify-between w-full d-flex">
                    <v-icon color="primary" class="left-2"
                      >mdi-video-outline</v-icon
                    >
                    <v-btn
                      icon
                      @click="removeAssets(form.videos, index, 'video')"
                      class="right-0 z-20"
                    >
                      <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div>
                  <edit-video-dialog
                    :id="video.id"
                    v-if="false"
                    @onEdit="onVideoEdit(index, $event)"
                  />
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-divider />
        </div>
        <v-dialog v-model="captionDialog" max-width="800px" persistent>
          <v-card class="pa-2">
            <v-card-title class="pt-3 pb-3 primary--text">
              <div class="items-center gap-2 d-flex">
                <img
                  src="../../assets/img/icons8-ai.gif"
                  :width="isSmallAndUp ? '30px' : '25px'"
                  :height="isSmallAndUp ? '30px' : '25px'"
                  max-height="100%"
                />
                <span :class="!isSmallAndUp ? 'text-base' : ''" class=""
                  >AI generated captions from image</span
                >
              </div>
              <v-spacer />
              <v-btn
                icon
                color="red"
                @click="handleCaptionDialogClose()"
                :disabled="loading"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="pb-0">
              <v-row class="justify-center w-full d-flex">
                <template v-if="openaiCaptions?.length">
                  <template v-for="(item, i) in openaiCaptions">
                    <v-col cols="12" :key="i">
                      <div
                        class="flex-col w-full gap-4 p-4 mb-2 border rounded-md d-flex"
                      >
                        <span class="black--text">
                          {{ item }}
                        </span>
                        <div
                          class="flex-row flex-wrap justify-between w-full gap-2 d-flex"
                        >
                          <v-btn
                            @click="copyLink(item)"
                            height="30px"
                            outlined
                            color="primary"
                          >
                            Copy
                          </v-btn>
                          <v-btn
                            @click="selectCaption(item)"
                            outlined
                            color="primary"
                            height="30px"
                          >
                            Add to editor
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-card-text>
            <v-card-actions class="pt-4 mr-2">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="generateImageCaptions(imgUrl)"
                >Regenerate</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="flex-wrap items-center gap-3 px-4 pt-2 d-flex">
          <template>
            <v-menu
              :close-on-content-click="false"
              v-model="menu"
              rounded="lg"
              offset-y
              class="relative z-50 p-4 rounded-lg menuu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#8056DE"
                  v-bind="attrs"
                  v-on="on"
                  :icon="!isSmallAndUp"
                  :outlined="isSmallAndUp"
                >
                  <v-icon
                    class="target-element-2"
                    :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
                    >mdi-image-plus-outline</v-icon
                  >
                  <span class="d-none d-sm-flex"> Add Image </span>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <div class="title">Add Images</div>
                  <v-spacer></v-spacer>
                  <v-icon @click="menu = false" color="red">mdi-close</v-icon>
                </v-card-title>

                <v-list min-width="200px" class="pa-0">
                  <v-list-item class="p-4 pt-0">
                    <v-list-item-title v-if="isEditing || this.isRepost">
                      <GalleryPicker
                        v-model="newlyImages"
                        @input="validateOnContentChange()"
                        :isEditing="isEditing"
                        :isRepost="isRepost"
                        type="image"
                        @onNewGalleryImage="form.images.push($event)"
                        @thumbnailUrl="thumbnail.push($event)"
                        @closemenu="handleMenuClose"
                        @newlyselected="handleAssets('image')"
                        @upload-start="handleUploadStart"
                        @upload-finish="handleUploadFinish"
                        @onImageSelected="$refs.imageCropper.dialog = true"
                        ref="galleryImagePicker"
                      />
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      <GalleryPicker
                        v-model="form.images"
                        :isEditing="isEditing"
                        @input="validateOnContentChange()"
                        :isRepost="isRepost"
                        type="image"
                        @onNewGalleryImage="form.images.push($event)"
                        @thumbnailUrl="thumbnail.push($event)"
                        @closemenu="handleMenuClose"
                        @newlyselected="handleAssets('image')"
                        @upload-start="handleUploadStart"
                        @upload-finish="handleUploadFinish"
                        @onImageSelected="$refs.imageCropper.dialog = true"
                        ref="galleryImagePicker"
                      />
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </template>
          <v-menu
            :close-on-content-click="false"
            v-model="vidMenu"
            offset-y
            rounded="lg"
            class="relative z-50 p-4 rounded-lg menuu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#8056DE"
                v-bind="attrs"
                v-on="on"
                :icon="!isSmallAndUp"
                :outlined="isSmallAndUp"
              >
                <v-icon
                  class="target-element-3"
                  :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
                  >mdi-video-plus-outline</v-icon
                >
                <span class="d-none d-sm-flex"> Add Video </span>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <div class="title">Add Video</div>
                <v-spacer></v-spacer>
                <v-icon @click="vidMenu = false" color="red">mdi-close</v-icon>
              </v-card-title>
              <v-list min-width="200px" class="pa-0">
                <v-list-item class="p-4 pt-0">
                  <v-list-item-title v-if="isEditing">
                    <GalleryPicker
                      class=""
                      v-model="newlyVideos"
                      :isVideo="VideoCount"
                      type="video"
                      @closemenu="handleMenuClose"
                      :isEditing="isEditing"
                      @newlyselected="handleAssets('video')"
                      @upload-start="handleUploadStart"
                      @upload-finish="handleUploadFinish"
                      @duration="setVideoDuration"
                    />
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    <GalleryPicker
                      class=""
                      v-model="form.videos"
                      type="video"
                      @closemenu="handleMenuClose"
                      :isEditing="isEditing"
                      @newlyselected="handleAssets('video')"
                      @upload-start="handleUploadStart"
                      @upload-finish="handleUploadFinish"
                      @duration="setVideoDuration"
                    />
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <!-- <openai-image
            @addGeneratedImage="$emit('addGeneratedImage', $event)"
            btnText="Generate Image with AI"
            @uploader="$emit('uploader', $event)"
          /> -->
          <!-- <vista-options
            @selectedVistaAsset="handleSelectedVistaAsset"
            btnText="Design with Vista Create"
          /> -->
        </div>
      </v-card-text>
    </v-card>

    <!-- * Important: Post text section -->
    <div
      class="flex-col items-start justify-start w-full gap-4 px-2 mb-6 d-flex sm:gap-0 sm:flex-row sm:justify-end sm:items-center sm:px-0"
    >
      <v-tabs
        v-model="currentItem"
        show-arrows
        color="#8056DE"
        class="w-full override-tab sm:w-[70%]"
      >
        <v-tab
          v-for="item in selectedOverridePlatformList"
          :key="item.value"
          :value="'tab-' + item.value"
          @click="makeTabActive(item)"
          class="px-3 py-0"
        >
          <span style="text-transform: capitalize" class="text-violet-500">
            {{ item.value === "PostText" ? "Text / Caption" : item.name }}
          </span>
          <v-btn
            icon
            v-if="item.value !== 'PostText'"
            @click.stop="removeOverridePlatform(item.value)"
            color="red"
            ><v-icon size="20">mdi-close</v-icon></v-btn
          >
        </v-tab>
        <!-- <v-spacer /> -->
      </v-tabs>
      <div class="w-full d-flex sm:justify-end justify-start sm:max-w-[25%]">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              class="px-3 py-2 align-self-center"
              height="100%"
              v-bind="attrs"
              v-on="on"
              color="#8056DE"
            >
              Override for
              <v-icon end> mdi-menu-down </v-icon>
            </v-btn>
          </template>

          <v-list class="px-5 bg-grey-lighten-3" scrollable>
            <v-list-item
              v-for="item in availableOverridePlatforms"
              :key="item.value"
              @click="addOverridePlatformsToTab(item)"
              class="justify-start w-full gap-2 px-1 d-flex"
            >
              <img
                style="max-height: 30px; max-width: 30px"
                class="mr-2"
                :src="item.logo_url"
              />
              {{ item.name }}
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="">
      <div class="items-center mt-0 mb-2 ml-1 text-xs d-flex">
        <div
          class="items-center cursor-pointer d-flex"
          @click="languageDialog = true"
        >
          <span class="pr-2"> Post Language: </span> {{ aiOutputLanguage }}
          <v-icon small>mdi-chevron-down</v-icon>
          <v-img
            style="cursor: pointer !important"
            src="../../assets/img/web.gif"
            max-height="20"
            max-width="20"
            class="mr-1"
          ></v-img>
        </div>

        <v-tooltip bottom v-model="showTooltip">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="grey lighten-1"
              small
              @click.stop="showTooltip = !showTooltip"
              >mdi-information</v-icon
            >
          </template>
          <span>All AI writer output will be produced in this language</span>
        </v-tooltip>
      </div>
      <v-dialog v-model="languageDialog" max-width="600" persistent>
        <form-wrapper ref="languageForm" @submit="saveAIOutputLanguage()">
          <v-card>
            <v-card-title :class="!isSmallAndUp ? 'text-base' : ''">
              Please select post output language
              <v-spacer />
              <v-btn
                icon
                color="red"
                @click="languageDialog = false"
                :disabled="loading"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <span class="ml-1 text-xs text-black"
                >All AI writer output will be produced in this language</span
              >
              <v-select
                label="Select Language"
                :items="languages"
                class="mt-1"
                outlined
                dense
                item-text="englishName"
                v-model="language"
                item-value="englishName"
              >
                <template v-slot:item="{ item, on }">
                  <v-list-item v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.englishName
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.localName
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-card-text>
            <v-card-actions class="mr-2">
              <v-spacer></v-spacer>
              <slot name="footer">
                <v-btn :loading="loading" text type="submit" color="primary"
                  >Save</v-btn
                >
              </slot>
            </v-card-actions>
          </v-card>
        </form-wrapper>
      </v-dialog>
    </div>
    <div class="relative flex-col gap-2 mt-0 mb-6 border rounded-lg d-flex">
      <!-- * text editor for override platforms -->
      <v-tabs-items v-model="currentItem" v-show="newEditor">
        <v-tab-item
          v-for="handle in selectedOverridePlatformList"
          :key="handle.value"
          :eager="true"
        >
          <v-card flat v-if="handle.value === activeItem">
            <v-card-text class="p-0" style="font-size: inherit; color: inherit">
              <div class="flex-col gap-2 mt-2 mb-6 d-flex">
                <!-- * it should be 'activeItem' and not handle.value because handle.value will send all override platform instead of active one -->
                <draftjs-editor
                  v-show="newEditor"
                  @mentionDataLoadingStep="handleMentionDataLoading"
                  :editorId="handle.value"
                  v-model="platformEditors[activeItem]"
                  :ref="'textarea-' + activeItem"
                  class="target-element-1"
                  :activeItem="activeItem"
                />
              </div>
              <div
                class="text-end"
                v-if="activeChip?.constraints?.text.maxLength"
              >
                <div
                  class="items-center justify-end gap-2 pr-3 text-caption d-flex"
                  :class="
                    platformEditors[activeItem]?.length >
                    activeChip?.constraints?.text?.maxLength
                      ? 'red--text'
                      : ''
                  "
                >
                  {{ platformEditors[activeChip?.value]?.length }} /
                  {{ activeChip?.constraints?.text?.maxLength }}
                  <v-avatar
                    right
                    v-if="activeChip?.logo_url"
                    style="
                      min-width: 10px !important;
                      max-width: 26px !important;
                      height: 26px;
                      max-height: 26px !important;
                    "
                    class="rounded-full"
                  >
                    <v-img
                      :src="activeChip?.logo_url"
                      class="h-full min-w-[10px]"
                    ></v-img>
                  </v-avatar>
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    size="25"
                    v-if="isMentionDataLoading"
                  ></v-progress-circular>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <!-- * Because we are using v-show that is why whenever we are adding hashtags then it is calling this editor too and hashtags are applied in this one too -->
      <draftjs-editor
        v-model="form.text"
        v-show="!newEditor"
        ref="textarea"
        editorId="post-editor"
        class="target-element-1"
        activeItem="PostText"
      />
      <div class="text-end" v-if="!newEditor">
        <div class="pr-3 text-caption">
          {{ form.text?.length }}
        </div>
      </div>
      <v-divider />
      <hashtag-text-area
        class="mt-3"
        v-if="false"
        :option="option"
        v-model="form.text"
        ref="textarea"
      />
      <div class="flex-col gap-2 px-3 d-flex">
        <!-- <div class="flex-wrap gap-2 px-3 d-flex">
          <v-chip
            v-for="(hashtag, index) in form.hashtags"
            :key="index"
            class="mb-1"
            close
            @click:close="removeHashtag(index)"
          >
            #{{ hashtag }}
          </v-chip>
        </div> -->
        <div v-if="form.residential?.Address" class="gap-2 px-3 d-flex">
          <v-icon>mdi-map-marker</v-icon>
          {{ form.residential.Address }}
          <v-icon @click="removeAddress" class="cursor-pointer"
            >mdi-close</v-icon
          >
        </div>
        <!-- text ai options -->
        <div class="flex-row items-center gap-6 px-3 pb-4 d-flex align-center">
          <div
            class="flex-row flex-wrap items-center gap-2 d-flex align-center"
          >
            <ai-menu
              icon="mdi-pencil-outline"
              header="AI shorthands"
              :activeItem="activeItem"
              :platformEditors="platformEditors"
              :form="form"
              :language="aiOutputLanguage"
              @openaiChangedTone="applyChangedTone"
              @textWithEmoji="addTextWithEmoji"
            />
            <!-- <post-ideas @insertPostIdea="insertPostIdea"/> -->
            <openai-generate-caption
              :form="form"
              :platformEditors="platformEditors"
              :activeItem="activeItem"
              @insertCaption="insertCaption"
            />
            <v-btn icon color="primary" @click="handleGenerateHashtags()">
              <img
                src="@/assets/img/hashtag.svg"
                alt="translate"
                width="22px"
              />
            </v-btn>
            <v-dialog v-model="showOverlay" max-width="600">
              <v-card>
                <v-card-title>
                  You have run out of Fly AI Writer credits. Please<br />renew
                  or upgrade to proceed
                </v-card-title>
                <v-card-actions>
                  <v-btn color="primary" @click="upgrade">Upgrade</v-btn>
                  <v-btn text @click="closeOverlay">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <openai-change-tone
              :form="form"
              @updatePost="updatePost"
              :platformEditors="platformEditors"
              :activeItem="activeItem"
            />
            <openai-translate
              :form="form"
              @updateText="updateText"
              :platformEditors="platformEditors"
              :activeItem="activeItem"
            />
            <v-icon color="#8056DE" @click="clearText()">mdi-undo</v-icon>
          </div>
          <v-progress-circular
            indeterminate
            v-if="isAssetLoading"
            color="grey"
          ></v-progress-circular>
        </div>
      </div>
    </div>
    <hashtags-reach :hashtags="extractedHashtags" />
    <openai-suggested-hashtags
      :hashtags="openaiHashtags"
      @insertHashtag="insertHashtag"
    />
    <label class="text-base input-label font-semibold leading-6 text-gray-900">When to post?</label>
    <v-radio-group
      :rules="[rules.required]"
      row
      class="mt-0"
      dense
      v-model="form.when_to_post"
    >
      <v-radio label="Immediate" value="immediate"></v-radio>
      <v-radio label="Schedule" value="schedule"></v-radio>
    </v-radio-group>
    <v-datetime-picker
      v-if="form.when_to_post == 'schedule'"
      :textFieldProps="{
        outlined: true,
      }"
      class="mt-3"
      label="Select Datetime"
      v-model="form.scheduled_at"
    >
      <v-icon slot="dateIcon">mdi-calendar-month</v-icon>
      <v-icon slot="timeIcon">mdi-clock-outline</v-icon>
    </v-datetime-picker>
    <v-expansion-panels
      v-model="warningExpansion"
      flat
      v-if="warnings?.length"
      class="warning-expansion"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            {{ warnings?.length }} Warnings
            <v-icon>
              {{
                warningExpansion === 0 ? "mdi-chevron-up" : "mdi-chevron-down"
              }}
            </v-icon>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-alert dense outlined type="warning">
            <p v-for="(warning, i) of warnings" :key="i" class="mb-0">
              {{ warning }}
            </p>
          </v-alert>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-alert dense outlined type="error" v-if="errors.length">
      <p v-for="(error, i) of errors" :key="i" class="mb-0">
        {{ error }}
      </p>
    </v-alert>
    <div class="d-flex flex-md-row flex-column">
      <v-spacer></v-spacer>
      <v-btn
        depressed
        large
        text
        :loading="loading"
        :disabled="isBtnDisabled"
        v-if="(!isRepost || !isEditing) && !isDraftPost"
        class="mt-4 bg-slate-100 md:mt-0 mr-md-3"
        @click="savePostToDraft"
        ><span class="">Save as draft</span></v-btn
      >
      <v-btn
        depressed
        large
        :loading="draftLoading"
        :disabled="isBtnDisabled"
        v-if="isDraftPost"
        class="mt-4 bg-violet-500 md:mt-0 mr-md-3"
        @click="updateDraft"
        >Update Draft</v-btn
      >
      <!-- <v-btn
        depressed
        large
        :loading="loading"
        v-if="errors.length == 0 && warnings.length != 0"
        :disabled="isBtnDisabled"
        class="mt-4 bg-violet-500 md:mt-0 mr-md-3"
        @click="proceedWithWarnings()"
        ><span style="color: black !important"
          >Proceed with warnings</span
        ></v-btn
      > -->
      <v-tooltip 
        top
        :disabled="!isConfigureButtonDisabled"
      >
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              depressed
              large
              text
              :loading="loading"
              :disabled="isConfigureButtonDisabled"
              class="mt-4 bg-violet-500 md:mt-0"
              @click="proceedWithoutWarnings()"
            >
              <span class="text-white">Configure</span>
            </v-btn>
          </div>
        </template>
        <span>
          No platform chosen for posting
        </span>
      </v-tooltip>
    </div>
    <!-- <image-cropper
      v-if="isCarListingPost"
      :items="newImageArray"
      @onImageCropped="onImageCropped"
      ref="imageCropper"
      @discard="discardImages"
    /> -->
    <image-cropper
      :items="post.images"
      @overrideImagesCropped="handleOverrideCroppedImages"
      @onImageCropped="onImageCropped"
      ref="imageCropper"
      @discard="discardImages"
    />
  </form-wrapper>
</template>

<script>
import GalleryPicker from "@/components/fly/GalleryPicker.vue";
import rulesConstants from "@/assets/constants/rules.constants";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import hopscotch from "hopscotch";
import "hopscotch/dist/css/hopscotch.min.css"; // Import Hopscotch CSS

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  GeoPoint,
  setDoc,
} from "firebase/firestore";
import {
  db,
  colGallery,
  colUsers,
  platforms,
  storage,
} from "@/utils/firebase.utils";
import AddressField from "@/components/ui/form/AddressField.vue";
import VideoAutoPlay from "@/components/public/VideoAutoPlay.vue";
import InputField from "../ui/form/InputField.vue";
import ToolTip from "../ui/ToolTip.vue";
import HashtagTextArea from "./HashtagTextArea.vue";
import EditImageDialog from "./EditImageDialog.vue";
import draggable from "vuedraggable";
import openaiMixin from "@/mixins/openai.mixin.js";
import OpenaiSuggestedHashtags from "./OpenaiSuggestedHashtags.vue";
import HashtagsReach from "./HashtagsReach.vue";
import OpenaiGenerateCaption from "./OpenaiGenerateCaption.vue";
import OpenaiChangeTone from "./OpenaiChangeTone.vue";
import OpenaiImage from "./OpenaiImage.vue";
import VistaOptions from "./VistaOptions.vue";
import EditVideoDialog from "./EditVideoDialog.vue";
import OpenaiTranslate from "./OpenaiTranslate.vue";
import ShortHandsMenu from "./ShortHandsMenu.vue";
import SpecialPostMenu from "./SpecialPostMenu.vue";
import AiMenu from "./AiMenu.vue";
import { compressImage, MAX_IMAGE_SIZE } from "@/utils/image.utils";
import { getDate } from "@/utils/common.utils";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import DraftjsEditor from "../ui/draftjs/DraftjsEditor.vue";
import _ from "lodash";
import RadioGroup from "../ui/form/RadioGroup.vue";
import ImageCropper from "../fly/ImageCropperGrid.vue";
const isLocalhost = location.hostname == "localhost";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    isOlxPost: Boolean,
    isCarListingPost: Boolean,
    post: Object,
    postId: String,
    workspaceRole: String,
    isRepost: Boolean,
    duration: Number,
    isEditing: Boolean,
    isDraftPost: Boolean,
    draftLoading: Boolean,
    social_media: Array,
    imageArray: Array,
  },
  mixins: [openaiMixin],
  watch: {
    locations: {
    deep: true,
    handler() {
      this.updateSelectedLocations();
    }
  },
  'form.locations': {
    handler: async function() {
      await this.fetchUniquePlatforms();
    },
    deep: true
  },
  'form.location_post_pref': function (newVal) {
      this.handleLocationPostPrefChange(newVal);
    },
    currentUID() {
      this.fetchConnections();
    },
    WID() {
      this.fetchConnections();
    },
    connectionAppsList() {
      this.addOverridePlatformsInDraftPost();
    },
    currentWorkspace(){
      this.fetchConnections();
    },
    "form.name": {
      handler(newVal) {
        // this.handleDeselect();
      },
    },
    platformEditors: {
      handler(newVal) {
        this.saveToHistory();
      },
      deep: true, // Watch changes deeply in nested objects/arrays
    },
    "form.text": {
      handler(newVal, oldVal) {
        // this.deselectYouTubeIfNoVideo();
        if (newVal !== oldVal) {
          this.saveToHistory();
          const lastChar = newVal?.slice(-1);
          if (lastChar === " " || lastChar === "\n") {
            this.extractHashtags();
          }
        }
        this.textLength = newVal?.length;
        if (this.textLength > 280) {
          this.twitterTextConstraint = true;
        } else if (this.textLength <= 280) {
          this.twitterTextConstraint = false;
        }
        if (this.textLength > 2200) {
          this.igTextConstraint = true;
        } else if (this.textLength <= 2200) {
          this.igTextConstraint = false;
        }
        if (this.imageLength === 0) {
          this.igConstraint = true;
          //   this.disableIG = true;
          //   const index = this.form.social_media.indexOf("instagram");
          //   if (index != -1) {
          //     this.form.social_media.splice(index, 1);
          //   }
        }
      },
    },
    "form.videos": {
      handler(newVal) {
        this.updateVideoPlatformSelection();
        this.videoCount = newVal?.length;
        if (this.videoCount >= 1) {
          this.disableIG = false;
        }
      },
    },
    "form.images": {
      handler(newVal) {
        this.updateVideoPlatformSelection();
        // if (this.isOlxPost) {
        //   const imageExists = this.imageArray.some(image => image.url === newVal);

        //   if (!imageExists) {
        //     this.newImageArray.push(newVal);
        //   }
        // }

        // if (this.isOlxPost || this.isCarListingPost) {
        //   const isObjectArray =
        //     Array.isArray(newVal) &&
        //     newVal.length > 0 &&
        //     typeof newVal[0] === "object";
        //   const isStringArray =
        //     Array.isArray(newVal) &&
        //     newVal.length > 0 &&
        //     typeof newVal[0] === "string";

        //     if (isObjectArray) {
        //       for (let newItem of newVal) {
        //         this.imageArray.map((image) => {
        //           if (image.url !== newItem.url) {
        //             this.newImageArray.push(newItem);
        //           }

        //         })
        //         // if (!this.imageArray.some((image) => image.url === newItem.url)) {
        //         //   console.log('newitem.url:', newItem.url)
        //         //   this.newImageArray.push(newItem);
        //         // }
        //       }
        //     } else if (isStringArray) {
        //         for (let newItem of newVal) {
        //           if (!this.imageArray.some((image) => image.url === newItem)) {
        //             this.newImageArray.push(newItem);
        //           }
        //         }
        //   } else {
        //       console.error("newVal should be an array of objects or strings");
        //     }
        //   }

        // console.log("newImageArray", this.newImageArray);

        // this.deselectYouTubeIfNoVideo();
        this.imageLength = newVal?.length;
        // if (this.isSocial("twitter")) {
        if (this.imageLength >= 1) {
          this.handleFileSize(newVal);
          this.disableIG = false;
        } else if (this.imageLength === 0) {
          this.disableIG = true;
          // const index = this.form.social_media.indexOf("instagram");
          // if (index != -1) {
          //   this.form.social_media.splice(index, 1);
          // }
        }
        if (this.imageLength > 4) {
          this.twitterImageConstraint = true;
        } else if (this.imageLength <= 4) {
          this.twitterImageConstraint = false;
        }
        // }
        if (this.imageLength > 10) {
          this.fbImageConstraint = true;
          this.igImageConstraint = true;
        } else if (this.imageLength <= 10) {
          this.fbImageConstraint = false;
          this.igImageConstraint = false;
        }
      },
    },
    "form.social_media": {
      handler(newVal, oldVal) {
        const addedItem = newVal.find((item) => !oldVal.includes(item));
        const removedItem = oldVal.find((item) => !newVal.includes(item));
        if (this.removedItem) {
          if (this.platformEditors[this.removedItem]?.length) {
            this.platformEditors[this.removedItem] = "";
          }
          const removedIndex = this.selectedOverridePlatformList.findIndex(
            (item) => item.value === this.removedItem
          );
          if (removedIndex !== -1) {
            // if(this.activeItem === removedItem){
            this.activeItem = "PostText";
            this.newEditor = false;
            if (this.form[this.removedItem + "_text"]?.length) {
              delete this.form[this.removedItem + "_text"];
            }
            // }
            this.selectedOverridePlatformList.splice(removedIndex, 1);
          }
          this.activeChip =
            this.selectedOverridePlatformList[
              this.selectedOverridePlatformList.length - 1
            ];
          this.activeItem =
            this.selectedOverridePlatformList[
              this.selectedOverridePlatformList.length - 1
            ].value;
        }
        this.overridePlatformList = Array.from(
          new Set(
            this.overridePlatformList?.filter((item) =>
              newVal.includes(item.value)
            )
          )
        );

        if (
          this.overridePlatformList.indexOf(addedItem) == -1 &&
          addedItem !== "around"
        ) {
          const correspondingObject = this.connectionAppsList.find(
            (item) => item.value === addedItem
          );
          if (correspondingObject) {
            // Check if the object is not already in overridePlatformList
            if (
              this.overridePlatformList.every(
                (item) => item.value !== correspondingObject.value
              )
            ) {
              this.overridePlatformList.push(correspondingObject);
            }
          }
        }
        if (this.isRepost || this.isEditing) {
          if (newVal.indexOf("twitter") !== -1) {
            if (this.duration > 140) {
              this.twitterVidConstraint = true;
            } else if (this.duration < 140) {
              this.twitterVidConstraint = false;
            }
          }
        }
      },
    },
    "form.scheduled_at": {
      handler(val) {
        const currentTime = new Date();
        const scheduledTime = new Date(val);
        // console.log("cureent , sched", currentTime, scheduledTime);
        this.isPastTime = scheduledTime <= currentTime;
        // console.log("ispasttime", this.isPastTime);
      },
    },
    videoDuration(newDuration) {
      if (!this.isRepost || !this.isEditing) {
        this.setVideoDuration(newDuration);
        this.$emit("duration", newDuration);
        // if (this.isSocial("twitter")) {
        if (this.videoDuration > 140) {
          this.twitterVidConstraint = true;
        } else this.twitterVidConstraint = false;
      }
    },
    uniquePlatforms: {
      immediate: true,
      handler(platforms) {
        if (platforms?.length) {
          platforms.forEach(platform => this.fetchPlatformLogo(platform));
        }
      }
    }
  },
  components: {
    GalleryPicker,
    FormWrapper,
    AddressField,
    VideoAutoPlay,
    InputField,
    ToolTip,
    HashtagTextArea,
    EditImageDialog,
    draggable,
    OpenaiSuggestedHashtags,
    HashtagsReach,
    EditVideoDialog,
    OpenaiTranslate,
    ShortHandsMenu,
    SpecialPostMenu,
    DraftjsEditor,
    RadioGroup,
    VistaOptions,
    OpenaiGenerateCaption,
    OpenaiChangeTone,
    AiMenu,
    OpenaiImage,
    ImageCropper,
  },
  data() {
    return {
      thumbnail: [],
      locations: [
      ],
      locationFilter: '',
       // Initially empty
      deselectedPlatforms: [],
      showUpgradeDialog: false,
      languageDialog: false,
      language: "English",
      aiOutputLanguage: "English",
      showTooltip: false,
      languageSaved: false,
      showOverlay: false,
      warningExpansion: -1,
      configureClicked: false,
      openaiCaptions: [],
      captionDialog: false,
      isCaptionLoading: false,
      imgUrl: "",
      imgIndex: "",
      hashIndex: "",
      activeChip: null,
      platformEditors: {
        facebook: "",
        instagram: "",
        twitter: "",
        youtube: "",
        pinterest: "",
        reddit: "",
        tumblr: "",
        linkedin: "",
        tiktok: "",
        google_business_profile: "",
        mastodon: "",
        threads: "",
        bluesky: ""
      },
      localtext: "",
      // selectedOverridePlatformList: ["Post Text"],
      selectedOverridePlatformList: [{ value: "PostText" }],
      overridePlatformList: [],
      activeItem: "PostText",
      tab: null,
      newEditor: false,
      option: {
        isEditMode: true,
        font: '"Montserrat", sans-serif',
        placeholder: "",
        hashtagColor: "blue",
      },
      openaiHashtags: [],
      allOpenaiHashtags: [],
      extractedHashtags: [],
      videoCount: 0,
      form: {
        config_social_media: [],
        thumbnails: [],
        overrideImages: [],
        name: isLocalhost ? "Test" : "",
        text: "",
        images: [],
        videos: [],
        hashtags: [],
        when_to_post: "immediate",
        scheduled_at: "",
        social_media: [],
        residential: [],
        locations: [],
        location_post_pref: 'gmb_only', // Add default value
      },
      showLocationDropdown: false,
      maxDisplay: 5,
      historyStack: [],
      configuration: {
        ig_post_type: "Feed",
        yt_post_type: "Video",
        fb_post_type: "Feed",
      },
      platformConfigurations: {
        instagram: "ig_post_type",
        youtube: "yt_post_type",
        facebook: "fb_post_type",
      },
      textLength: 0,
      disableIG: false,
      imageLength: 0,
      loading: false,
      isAssetLoading: false,
      userData: [],
      videoDuration: 0,
      rules: rulesConstants,
      connectedApps: [],
      socialAppLists: [],
      connectionAppsList: [],
      connectedHandles: [],
      imageDialog: false,
      videoDialog: false,
      menu: false,
      rulesw: {
        multiselect: [(v) => !!v.length || "Connect Now"],
      },
      vidMenu: false,
      addrMenu: false,
      existingImages: [],
      newlyImages: [],
      assets: [],
      durationFromGallery: 0,
      newlyVideos: [],
      isBtnDisabled: false,
      isTwitterDisabled: false,
      twitterTextConstraint: false,
      igTextConstraint: false,
      twitterVidConstraint: false,
      twitterImageConstraint: false,
      twitterSizeConstraint: false,
      igSizeConstraint: false,
      fbImageConstraint: false,
      igImageConstraint: false,
      igConstraint: false,
      isPastTime: false,
      warnings: [],
      errors: [],
      allConnectedPlatforms: [],
      currentItem: "tab-PostText",
      whereToPost: "Where to post?",
      removedItem: "",
      uniquePlatforms: [],
      newImageArray: [],
      isMentionDataLoading: false,
      platformLogoCache: {},
      hasStoreWorkspaces: false,
    };
  },
  async created() {
    await this.fetchUniquePlatforms();
    if (this.isOlxPost || this.isCarListingPost) {
      // this.imageArray = this.$route.params.imageDataArray;
      this.$nextTick(() => {
        this.$refs.imageCropper.dialog = true;
      });
    }
    this.form.text = this.$route.params.text;
    if (this.$route.params?.scheduledTime) {
      this.form.scheduled_at = new Date(this.$route.params.scheduledTime);
      this.form.when_to_post = "schedule";
    }
    if (this.$route.params?.title) {
      this.form.name = this.$route.params.title;
    }
    if (this.$route.params?.content) {
      this.form.text = this.$route.params.content;
      this.$refs.textarea.updateValue(this.form.text);
    }
    this.assets = this.$route.params.assets?.map((asset) => asset);
    if (this.assets?.length) {
      this.assets.map((asset) => {
        if (asset.type === "video") {
          this.form.videos.push(asset);
          this.durationFromGallery = this.$route.params.duration;
        } else if (asset.type === "image") {
          this.form.images.push(asset.url);
          this.form.thumbnails.push(asset.thumbnail);
        }
      });
    }
    if (this.isEditing || this.isRepost || this.isDraftPost) {
      this.form = { ...this.post };
    }
    let image = this.$route.params.cloudinary_url;
    if (image) {
      this.form.images = [image];
      this.$emit("input", this.form);
      this.$nextTick(() => {
        this.$refs.imageCropper.dialog = true;
      });
    }
    this.form.config_social_media = this.getConfigSocialMedia();
    await this.fetchLocations();
  },
  computed: {
    hasPinterest(){
      if(this.form.social_media?.includes('pinterest') || this.uniquePlatforms?.includes('pinterest')) return true;
      return false;
    },
    showLocationDropdownBasedOnPlan() {
      // Ensure the environment variable is available
      const requiredPlan = process.env.VUE_APP_BROADCAST_PLAN ;
      return this.authUser.fly_plan === requiredPlan;
    },
    filteredLocations() {
      // Filter locations based on the input
      if (!this.locationFilter) return this.locations;

      const filterText = this.locationFilter.toLowerCase();
      const filtered = {};

      for (const [state, stateData] of Object.entries(this.locations)) {
        const filteredCities = stateData.cities.filter(city =>
          city.name.toLowerCase().includes(filterText) ||
          city.city.toLowerCase().includes(filterText) ||
          state.toLowerCase().includes(filterText)
        );

        if (filteredCities.length) {
          filtered[state] = {
            ...stateData,
            cities: filteredCities,
          };
        }
      }

      return filtered;
    },
    availableOverridePlatforms() {
      return this.connectionAppsList?.filter((item) => {
        return (
          this.form.social_media.includes(item.value) &&
          !this.selectedOverridePlatformList.some(
            (selected) => selected.value === item.value
          )
        );
      });
    },
    isMultiselectValid() {
      const rule = this.rulesw.multiselect[0];
      return rule(this.form.social_media);
    },
    isConfigureButtonDisabled() {
      return this.isBtnDisabled || (!this.form.social_media.length && !this.uniquePlatforms.length);
    },
    connections() {
      let list = [];
      list = list?.filter((i) => {
        let item = this.connectedApps.find(
          (item) => item.id == i.value && item.status == true
        );
        return item != null;
      });

      return list;
    },
    shouldSelectYouTube() {
      return this.form.videos?.length > 0;
    },
    isYouTubeDisabled() {
      return !this.form.videos || this.form.videos?.length === 0;
    },
    VideoCount() {
      return this.form.videos?.length > 0;
    },
  },
  methods: {
    async handleLocationPostPrefChange(newVal){
      if (newVal === "gmb_only" || newVal === "ig_only") {
          const aliasToSelect = newVal === "gmb_only" ? "google_business_profile" : "instagram";
          this.form.social_media = this.connectionAppsList
              .filter(platform => platform.alias === aliasToSelect)
              .map(platform => platform.alias);
      } else {
          this.form.social_media = this.connectionAppsList.map(platform => platform.alias);
      }
      await this.fetchUniquePlatforms();
    },
    async fetchPlatformLogo(platform) {
      if (this.platformLogoCache[platform]) {
        return;
      }

      try {
        const platformDoc = await getDoc(doc(db, 'fly_platforms', platform.toLowerCase()));
        if (platformDoc.exists()) {
          const logoUrl = platformDoc.data().logo_url;
          this.$set(this.platformLogoCache, platform, logoUrl);
        }
      } catch (error) {
        console.error('Error fetching platform logo:', error);
      }
    },
    getConfigSocialMedia() {
      const socialMedia = new Set(this.form.social_media || []);
      const uniquePlatforms = this.uniquePlatforms || [];
      const uniqueValues = uniquePlatforms.filter(
        (platform) => !socialMedia.has(platform)
      );
      return uniqueValues;
    },
    async fetchUniquePlatforms() {
      try {
        
        this.uniquePlatforms = [];
        if (this.form.locations && this.form.locations.length) {
          for (let i = 0; i < this.form.locations.length; i++) {
            const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.form.locations[i]);
            const workspaceDoc = await getDoc(workspaceRef);
            
            if (workspaceDoc.exists()) {
              const connectedApps = workspaceDoc.data().connected_apps || [];
              
              // Add any new platforms that aren't already in uniquePlatforms
              connectedApps.forEach(app => {
                if (!this.uniquePlatforms.includes(app)) {
                  this.uniquePlatforms.push(app);
                }
              });
            }
            let store_platforms = this.uniquePlatforms;
            if(this.form.location_post_pref == 'gmb_only'){
              this.uniquePlatforms =  [];
              if(store_platforms.includes('google_business_profile')){
                this.uniquePlatforms.push('google_business_profile');
              }
            }  
            if(this.form.location_post_pref == 'ig_only'){
              this.uniquePlatforms =  [];
              if(store_platforms.includes('instagram')){
                this.uniquePlatforms.push('instagram');
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching unique platforms:", error);
      }
    },
    toggleLocationDropdown() {
    this.showLocationDropdown = !this.showLocationDropdown;
  },
     handleClickOutside(event) {
      const dropdown = this.$refs.dropdownContainer;
      if (dropdown && !dropdown.contains(event.target)) {
        this.showLocationDropdown = false;
      }
    },
    applySelectedLocations() {
  // Populate selectedLocations with checked cities (IDs only)
  this.form.locations = [];
  Object.values(this.locations).forEach(stateData => {
    stateData.cities.forEach(city => {
      if (city.checked) {
        this.form.locations.push(city.id); // Push only the id
      }
    });
  });
  this.showLocationDropdown = false;
},


  toggleAllCities(state) {
    const newValue = !this.locations[state].checked;
    this.locations[state].checked = newValue;
    this.locations[state].cities.forEach(city => (city.checked = newValue));
  },
  toggleCitySelection(state, city) {
    // Update state checkbox based on individual city selections
    const allSelected = this.locations[state].cities.every(city => city.checked);
    this.locations[state].checked = true;
  if( this.locations[state].cities.every(city => !city.checked)){
    this.locations[state].checked = false;
  }
  },
  retainOnlyThisCity(state, city) {
    // Uncheck all cities in all states
    Object.keys(this.locations).forEach(stateName => {
      // Uncheck the state
      this.locations[stateName].checked = false;
      // Uncheck all cities in this state
      this.locations[stateName].cities.forEach(c => {
        c.checked = false;
      });
    });

    // Check only the selected city
    city.checked = true;
    
    // Update the state's checked status
    this.locations[state].checked = this.locations[state].cities.some(c => c.checked);
    
    // Update the form.locations array
    this.updateSelectedLocations();
  },
  async updateSelectedLocations() {
  this.form.locations = [];
  Object.values(this.locations).forEach(stateData => {
    stateData.cities.forEach(city => {
      if (city.checked) {
        this.form.locations.push(city.id); // Push only the id
      }
    });
  });
  await this.fetchUniquePlatforms();
},
    toggleLocation(item) {
    const name = item.name;
    const index = this.form.locations.indexOf(name);

    if (index > -1) {
      // If item is already selected, remove it
      this.form.locations.splice(index, 1);
    } else {
      // Otherwise, add the item name
      this.form.locations.push(name);
    }
  },
  customFilter(item, queryText, itemText) {
    if (!queryText) return true;

    const text = queryText.toLowerCase();

    return (
      item.name.toLowerCase().includes(text) ||
      (item.city && item.city.toLowerCase().includes(text)) ||
      (item.state && item.state.toLowerCase().includes(text))
    );
  },
    async fetchLocations() {
      const workspaceRef = collection(db, "users", this.currentUID, "fly_workspaces");

      try {
        const querySnapshot = await getDocs(workspaceRef);
        const locationsArray = querySnapshot.docs
          .filter((doc) => {
            const data = doc.data();
            return (
              data.admin === this.WID &&
              data.storeWorkspace === true && // Check for storeWorkspace true
              data.status && 
              data.status === "connected"
            );
          })
          .map(doc => ({
            id: doc.id,
            name: doc.data().name,
            city: doc.data().city,
            state: doc.data().state,
            sub_locality: doc.data().sub_locality,
            checked: true,
          }));

        // Set hasStoreWorkspaces based on whether we found any valid workspaces
        this.hasStoreWorkspaces = locationsArray.length > 0;

        // Group locations by state
        this.locations = locationsArray.reduce((acc, location) => {
          const state = location.state;
          if (!acc[state]) {
            acc[state] = { checked: true, cities: [] };
          }
          acc[state].cities.push(location);
          return acc;
        }, {});

        console.log("Transformed location data:", this.locations);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    },

    onLocationSelect(selected) {
      console.log('Selected Locations:', selected);
    },
    removeItem(item) {
      // Remove the selected item from the array
      this.form.locations = this.form.locations.filter(
        (location) => location !== item.name
      );
    },
    handleReorder(event) {
      const { oldIndex, newIndex } = event.moved;
      const movedThumbnail = this.form.thumbnails.splice(oldIndex, 1)[0];
      const movedOverride = this.form.overrideImages.splice(oldIndex, 1)[0];
      this.form.thumbnails.splice(newIndex, 0, movedThumbnail);
      if (this.form.overrideImages && this.form.overrideImages.length > 0) {
        this.form.overrideImages.splice(newIndex, 0, movedOverride);
      }
    },
    handleImageEvent(img) {
      this.form.images.push(img);
      // this.$refs.imageCropper.dialog = true
    },
    handleOverrideCroppedImages(images) {
      this.form.overrideImages = images;
    },
    handleMentionDataLoading(isLoading) {
      // console.log("Loading state from mentionsfbhfhf: ", isLoading);
      this.isMentionDataLoading = isLoading;
    },
    async toggleSelection(item) {
      const index = this.form.social_media.indexOf(item.value);
      this.removedItem = item.value;
      if (index !== -1) {
        this.form.social_media.splice(index, 1);
      } else {
        this.form.social_media.push(item.value);
      }
      // await this.fetchUniquePlatforms();
    },
    isSelected(item) {
      return this.form.social_media.includes(item.value);
    },
    onProfileError(e) {
      e.target.src = require("@/assets/img/no-profile.jpg");
    },
    extractHashtags() {
      const regex = /#[^\s#]+/g;
      const extractedHashtags = this.form.text?.match(regex);
      if (extractedHashtags) {
        // Filter out duplicates from extractedHashtags
        const uniqueExtractedHashtags = [...new Set(extractedHashtags)];
        uniqueExtractedHashtags.forEach((tag) => {
          const lowerCaseTag = tag.toLowerCase();
          if (this.allOpenaiHashtags?.length > 0) {
            // Check if the hashtag is already present in allOpenaiHashtags array
            const exists = this.allOpenaiHashtags.some(
              (hashtag) => lowerCaseTag === hashtag.toLowerCase()
            );
            // if doent exists then add the hashtag to extractedHashtags to show the reach
            if (!exists) {
              let index = this.extractedHashtags.indexOf(tag);
              if (index === -1) {
                this.extractedHashtags.push(tag);
              }
            }
          }
          // If allOpenaiHashtags is empty, then add the hashtag to extractedHashtags to show the reach
          else {
            let index = this.extractedHashtags.indexOf(tag);
            if (index === -1) {
              this.extractedHashtags.push(tag);
            }
          }
        });
      }
    },
    closeDialog() {
      this.showUpgradeDialog = false;
    },
    upgrade() {
      this.closeUpgradeDialog();
    },
    handleGenerateHashtags() {
      if (this.currentUser.fly_ai_text_credits === 0) {
        this.showOverlay = true;
      } else {
        this.generateHashtags();
      }
    },
    upgrade() {
      this.dialog = false; // Close the current dialog
      // Navigate to the subscription page
      this.$router.push("/subscription");
    },
    closeOverlay() {
      this.showOverlay = false;
    },
    saveAIOutputLanguage() {
      this.languageSaved = true;
      this.aiOutputLanguage = this.language;
      this.languageDialog = false;
    },
    async generateImageCaptions(item, index) {
      this.imgIndex = index;
      this.imgUrl = item;
      await this.generateImageCaption();
      if (!this.isCaptionLoading) {
        this.captionDialog = true;
        this.imgIndex = null;
      }
    },
    async generateImageHashtagss(item, index) {
      this.hashIndex = index;
      this.imgUrl = item;
      await this.generateImageHashtags();
      if (!this.isCaptionLoading) {
        this.hashIndex = null;
      }
    },
    handleCaptionDialogClose() {
      this.captionDialog = false;
      this.imgUrl = "";
      this.openaiCaptions = [];
    },
    copyLink(item) {
      navigator.clipboard.writeText(item);
      this.$snackbar.show("Caption Copied.");
    },
    selectCaption(item) {
      if(this.form.text == undefined){
        this.form.text = "";
      }
      if (this.activeItem === "PostText") {
        this.form.text += ` ${item}`;
        this.$refs.textarea.updateValue(this.form.text);

        this.platformEditors[this.activeItem] = this.form.text;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      } else {
        this.platformEditors[this.activeItem] += ` ${item}`;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      }
      this.captionDialog = false;
      this.openaiCaptions = [];
    },
    makeTabActive(item) {
      const index = this.selectedOverridePlatformList.findIndex(
        (selectedItem) => selectedItem.value === item.value
      );
      this.currentItem = index;
      // * This method is used to make tab active when clicked on override platforms
      if (item.value) {
        if (item.value === "PostText") {
          this.newEditor = false;
          // * activeItem tells which platform is active and contains only platform names
          this.activeItem = "PostText";
          // * activeItem contains active tab's platform data (name, logo_url, etc)
          this.activeChip = null;
        } else {
          this.newEditor = true;
          this.activeItem = item.value;
          this.activeChip = item;
        }
      }
    },
    removeOverridePlatform(platform) {
      this.$confirm.show({
        message: `Are you sure you want to remove the override text for <span style="text-transform: capitalize">${platform}</span>?`,
        onConfirm: async () => {
          // * This method is used to remove override platforms from selectedOverridePlatformList
          const index = this.selectedOverridePlatformList.findIndex(
            (item) => item.value === platform
          );
          const overrideItemIndex = this.overridePlatformList.findIndex(
            (overrideItem) => overrideItem.value === platform
          );
          if (index !== -1) {
            this.$set(this.platformEditors, platform, "");
            if (this.form[platform + "_text"]?.length) {
              delete this.form[platform + "_text"];
            }
            this.activeItem = "PostText";
            this.newEditor = false;
            this.selectedOverridePlatformList.splice(index, 1);
            if (overrideItemIndex === -1) {
              const correspondingObject = this.connectionAppsList.find(
                (item) => item.value === platform
              );
              if (correspondingObject) {
                this.overridePlatformList.push(correspondingObject);
              }
            }
          }
        },
      });
    },
    addOverridePlatformsToTab(item) {
      // * This method is used to add override platforms to tabs list (selectedOverridePlatformList)
      if (item.value !== "around") {
        const overrideItemIndex = this.overridePlatformList.findIndex(
          (overrideItem) => overrideItem.value === item.value
        );
        const index = this.selectedOverridePlatformList.findIndex(
          (selectedItem) => selectedItem.value === item.value
        );
        if (index !== -1) {
          // Remove item from selectedOverridePlatformList when clicked again
          this.$set(this.platformEditors, item.value, "");
          if (this.form[item.value + "_text"]?.length) {
            delete this.form[item.value + "_text"];
          }
          this.activeItem = "PostText";
          this.newEditor = false;
          this.selectedOverridePlatformList.splice(index, 1);
        } else {
          // Add item to selectedOverridePlatformList if not already present
          this.$set(this.platformEditors, item.value, this.form.text);
          this.selectedOverridePlatformList.push(item);
          if (overrideItemIndex !== -1) {
            this.overridePlatformList.splice(overrideItemIndex, 1);
          }
        }
      }
    },
    insertHashtag(hashtag) {
      let index = this.openaiHashtags.indexOf(hashtag);
      if (index != -1) {
        this.openaiHashtags.splice(index, 1);
      }
      if (this.activeItem === "PostText") {
        this.form.text += ` ${hashtag}`;
        this.$refs.textarea.updateValue(this.form.text);

        this.platformEditors[this.activeItem] += this.form.text;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      } else {
        this.platformEditors[this.activeItem] += ` ${hashtag}`;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      }
    },
    handleNewVal(val) {
      if (this.activeItem === "PostText") {
        this.form.text = val;
        this.$refs.textarea.updateValue(this.form.text);
      } else {
        this.platformEditors[this.activeItem] = val;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      }
    },
    removePlatform(data) {
      let index = this.selectedOverridePlatformList.indexOf(data.value);
      if (index !== -1) {
        // Remove the element at the found index
        this.selectedOverridePlatformList.splice(index, 1);
      }
    },
    handlePostText() {
      this.newEditor = false;
      this.activeChip = {
        value: "fly",
      };
    },
    hasMorePostTypes(item) {
      let connection =
        this.connectionAppsList.find((i) => i.alias == item) || {};
      return (connection.post_types || []).length > 1;
    },
    getPostTypes(item) {
      let connection =
        this.connectionAppsList.find((i) => i.alias == item) || {};
      return (connection.post_types || []).map((name) => ({
        name,
        value: name,
      }));
    },
    getPostTypeLabel(item) {
      let connection =
        this.connectionAppsList.find((i) => i.alias == item) || {};
      return `${connection.name} - Post Type`;
    },
    removeSelection(item) {
      let index = this.form.social_media.indexOf(item.value);
      this.$set(this.form.social_media, index, item.value);
      this.form.social_media = this.form.social_media?.filter(
        (el) => el !== item.value
      );
    },
    // startTour() {
    //   const tour = {
    //     id: "my-awesome-tour",
    //     steps: [
    //       {
    //         title: "Step 1",
    //         content: "Type Post Here",
    //         target: ".target-element-1",
    //         placement: "bottom",
    //       },
    //       {
    //         title: "Step 2",
    //         content: "Add images from here.",
    //         target: ".target-element-2",
    //         placement: "top",
    //         xOffset: "center",
    //         arrowOffset: "center",
    //         showPrevButton: true,
    //       },
    //       {
    //         title: "Step 3",
    //         content: "Add Videos from here.",
    //         target: ".target-element-3",
    //         placement: "top",
    //         xOffset: "center",
    //         arrowOffset: "center",
    //         showPrevButton: true,
    //       },
    //       {
    //         title: "Step 4",
    //         content: "AI with Fly. Generate hashtags, captions from here!",
    //         target: ".target-element-4",
    //         placement: "top",
    //         xOffset: "center",
    //         arrowOffset: "center",
    //         showPrevButton: true,
    //       },
    //       {
    //         title: "Step 5",
    //         content: "Tap here to translate your post",
    //         target: ".target-element-5",
    //         placement: "top",
    //         xOffset: "center",
    //         arrowOffset: "center",
    //         showPrevButton: true,
    //       },

    //       {
    //         title: "Step 6",
    //         content: " Choose your social platforms to post",
    //         target: ".target-element-6",
    //         placement: "top",
    //         xOffset: "center",
    //         arrowOffset: "center",
    //         showPrevButton: true,
    //       },

    //     ],
    //     onClose: () => {

    //       console.log("Tour completed or closed");
    //     },
    //   };

    //   hopscotch.startTour(tour);
    // },
    onSummarisePost(text) {
      this.$mixpanel.track("Shorthands", {
        Shorthand: "Generate post for article/text",
      });
      if (this.activeItem === "PostText") {
        this.form.text = text;
        this.$refs.textarea.updateValue(this.form.text);

        this.platformEditors[this.activeItem] = this.form.text;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      } else {
        this.platformEditors[this.activeItem] = text;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      }
    },
    handleSelectedVistaAsset(imageData) {
      if (imageData.type === "image") {
        this.form.images.push(imageData);
      } else if (imageData.type === "video") {
        this.form.videos.push(imageData);
      }
    },
    async updatePostContent({ image, title, description }) {
      this.$mixpanel.track("ShortHands", {
        Shorthand: "Generate Post for Web Page",
      });
      const vm = this;
      vm.form.name = title?.split(/\s+/).join(" ");
      if (this.activeItem === "PostText") {
        this.form.text = title + `\n` + description;
        this.$refs.textarea.updateValue(this.form.text);

        this.platformEditors[this.activeItem] = this.form.text;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      } else {
        this.platformEditors[this.activeItem] = title + description;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      }
      if (image) {
        try {
          vm.$loader.show();
          let uid = vm.authUser.default_uid || vm.uid;
          let blob = await fetch(image).then((res) => res.blob());
          let imageSize = blob.size;
          if (blob.size > MAX_IMAGE_SIZE) {
            let { base64, size } = await compressImage(image);
            image = base64;
            imageSize = size;
            console.log("File Compressed....");
          }
          let id = new Date().getTime().toString();
          let data = {
            created_at: getDate(),
            updated_at: getDate(),
            type: "image",
            size: imageSize,
            uid: vm.currentUID,
            wid: vm.WID,
            name: this.currentUID + "/" + id,
            json: {},
          };
          let imageRef = ref(storage, `gallery/${uid}/${id}.png`);
          await uploadString(imageRef, image, "data_url");
          data.url = await getDownloadURL(imageRef);
          data.thumbnail = data.url;
          await setDoc(doc(colGallery, id), data);
          vm.form.images = [
            {
              ...data,
              id,
            },
          ];
          vm.$loader.hide();
        } catch (error) {
          vm.handleError(error);
        }
      }
    },
    updatePost(post) {
      if (this.activeItem === "PostText") {
        this.form.text = post;
        this.$refs.textarea.updateValue(this.form.text);

        this.platformEditors[this.activeItem] = this.form.text;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      } else {
        this.platformEditors[this.activeItem] = post;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      }
    },

    insertPostIdea(post) {
      if (this.activeItem === "PostText") {
        this.form.text = post;
        this.$refs.textarea.updateValue(this.form.text);

        this.platformEditors[this.activeItem] = this.form.text;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      } else {
        this.platformEditors[this.activeItem] = post;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      }

      //if want to insert single image
      // this.form.images.push(data.images[0])

      //if want to insert multiple image

      // data.images.map((img) => {
      //   this.form.images.push(img);
      // });
      console.log(this.form.text);
    },
    addGeneratedImage(responseImage) {
      if (responseImage) {
        this.form.images.push(responseImage);
      }
    },
    applyChangedTone(post) {
      // this.form.text = post;
      // this.$refs.textarea.updateValue(this.form.text);
      if (this.activeItem === "PostText") {
        this.form.text = post[0];
        this.$refs.textarea.updateValue(this.form.text);

        this.platformEditors[this.activeItem] = this.form.text;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      } else {
        this.platformEditors[this.activeItem] = post[0];
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      }
    },
    addTextWithEmoji(text) {
      if (this.activeItem === "PostText") {
        this.form.text = text;
        this.$refs.textarea.updateValue(this.form.text);

        this.platformEditors[this.activeItem] = this.form.text;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      } else {
        this.platformEditors[this.activeItem] = text;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      }
    },
    insertCaption(event) {
      this.form.name = event;
    },
    updateText(evt) {
      if (this.activeItem === "PostText") {
        this.form.text = evt;
        this.$refs.textarea.updateValue(this.form.text);

        this.platformEditors[this.activeItem] = this.form.text;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      } else {
        this.platformEditors[this.activeItem] = evt;
        this.$refs["textarea-" + this.activeItem][0].updateValue(
          this.platformEditors[this.activeItem]
        );
      }
    },
    onConfigureClick() {
      this.configureClicked = true; // Set the flag to true when the button is clicked
      this.$emit("configureClicked", true);
      this.$mixpanel.track("Configure Clicked", { step: "Build" });
    },
    onEdit(index, event) {
      this.$set(this.form.images, index, event);
    },
    onVideoEdit(index, event) {
      this.form.videos = [];
      setTimeout(() => {
        this.form.videos = [event];
      }, 100);
    },
    getThumbnail(index) {
  // Ensure form, thumbnails, and images are valid arrays, and index is within bounds
    if (!this.form || index < 0) return "";

      const thumbnails = this.form.thumbnails || [];
      const images = this.form.images || [];

  // Return the thumbnail if it exists, otherwise return the image
      return thumbnails[index] || images[index] || "";
    },
    openPreview(n) {
      let index = this.form.images.findIndex((i) => i.id == n.id);
      let images = this.form.images.map((i) => i);
      // console.log('images, this.form.images: ' , images, this.form.images)
      this.$imagePreview({
        initIndex: n,
        images,
      });
    },
    onHashtag() {
      console.log(this.form.hashtags);
      let html = this.form.text;
      var regex = /\B#\w+\b/g;
      let hashtags = html?.match(regex) || [];
      let hashtagsList = this.form.hashtags.map((i) => {
        if (i.startsWith("#")) return i;
        return `#${i}`;
      });
      hashtagsList.forEach((i) => {
        let hashtag = hashtags.find((h) => h == i);
        if (hashtag == null) {
          html += " " + i;
        }
      });
      this.form.text = html;
      this.$refs.textarea.updateValue(html);
    },
    dragStart(index, event) {
      this.draggedIndex = index;
      if (this.isTouchDevice) {
        event.preventDefault();
        // Handle touch drag start
      } else {
        // Handle mouse drag start
      }
    },
    dragOver(index, event) {
      if (this.draggedIndex !== index) {
        const draggedImage = this.form.images[this.draggedIndex];
        this.form.images.splice(this.draggedIndex, 1);
        this.form.images.splice(index, 0, draggedImage);
        this.draggedIndex = index;
      }
      event.preventDefault();
    },
    dragEnd() {
      this.draggedIndex = -1;
    },
    setVideoDuration(duration) {
      this.$nextTick(() => {
        this.videoDuration = duration;
        if (duration != 0) {
          this.validateOnContentChange();
        }
      });
    },
    handleFetchedDuration(data) {
      this.videoDuration = data.duration;
      this.$emit("fetchedDuration", data);
    },
    handleUploadStart() {
      this.isBtnDisabled = true;
      this.isAssetLoading = true;
    },
    handleUploadFinish() {
      this.isBtnDisabled = false;
      this.isAssetLoading = false;
    },
    async handleFileSize(images) {
      let MAX_SIZE = 5 * 1024 * 1024;
      let IG_MAX_SIZE = 8 * 1024 * 1024;
      this.twitterSizeConstraint = false;
      this.igSizeConstraint = false;
      for (const image of images) {
        if (image.size > MAX_SIZE) {
          this.twitterSizeConstraint = true;
        }
        if (image.size > IG_MAX_SIZE) {
          this.igSizeConstraint = true;
        }
      }
    },
    handleDeselect() {
      this.deselectYouTubeIfNoVideo();
    },
    deselectYouTubeIfNoVideo() {
      if (!this.form.videos?.length) {
        const index = this.form.social_media.indexOf("youtube");
        if (index !== -1) {
          this.form.social_media.splice(index, 1);
        }
      }
    },
    updateVideoPlatformSelection() {
      const hasVideos = this.form.videos?.length > 0;
      this.updateSocialMedia("vimeo", hasVideos);
      this.updateSocialMedia("tiktok", hasVideos);
      this.updateSocialMedia("youtube", hasVideos);
    },

    updateSocialMedia(platform, hasVideos) {
      const index = this.form.social_media.indexOf(platform);
      if (hasVideos && index === -1 && this.isSocialHandle(platform)) {
        this.form.social_media.push(platform);
      } else if (!hasVideos && index !== -1) {
        this.form.social_media.splice(index, 1);
      }
    },

    updateYouTubeSelection() {
      const hasVideos = this.form.videos?.length > 0;
      const youtubeIndex = this.form.social_media.indexOf("youtube");
      if (hasVideos && youtubeIndex === -1 && this.isSocialHandle("youtube")) {
        // If videos added and "YouTube" not selected, add it.
        this.form.social_media.push("youtube");
      } else if (!hasVideos && youtubeIndex !== -1) {
        // If videos removed and "YouTube" is selected, deselect and disable it.
        this.form.social_media.splice(youtubeIndex, 1);
      }
    },
    handleYouTubeDeselection() {
      const isYouTubeSelected = this.form.social_media.includes("youtube");
      if (
        isYouTubeSelected &&
        (!this.form.videos || this.form.videos.length === 0)
      ) {
        const index = this.form.social_media.indexOf("youtube");
        this.form.social_media.splice(index, 1);
      }
    },
    removeHashtag(index) {
      console.log("index", index);
      if (this.form.hashtags) {
        let hashtag = this.form.hashtags[index];
        if (!hashtag.startsWith("#")) hashtag = `#${hashtag}`;
        let text = this.form.text?.split(hashtag).join("");
        this.form.text = text;
        this.$refs.textarea.updateValue(text);

        this.form.hashtags?.splice(index, 1);
      }
      // console.log(this.form)
    },
    removeAddress() {
      this.form.residential = {};
      this.$refs.addressField.input = "";
    },
    handleMenuClose() {
      this.menu = false;
      this.vidMenu = false;
    },
    handleAssets(type) {
      console.log("adding images...");
      if (type === "image") {
        this.newlyImages.forEach((image) => {
          this.form.images.push(image);
        });
        this.newlyImages.splice(0);
      } else if (type === "video") {
        this.newlyVideos?.forEach((video) => {
          this.form.videos.push(video);
        });
        this.newlyVideos.splice(0);
      }
    },
    onAddress(e) {
      if (e) {
        console.log("e", e);
        const geop = new GeoPoint(e.latitude, e.longitude);
        console.log("geo", geop, typeof geop);
        this.form.residential = {
          State: e.administrative_area_level_1,
          Address: `${e.name}, ${e.locality}, ${e.country}`,
          Country: e.country,
          City: e.locality,
          zipcode: e.postal_code || "",
          Latlang: new GeoPoint(e.latitude, e.longitude),
        };
        this.addrMenu = false;
      } else {
        this.form.residential = undefined;
      }
    },
    async validatePost() {
      let allPlatformsList = await this.getAllPlatforms();
      return new Promise((resolve) => {
        let validVideoTypes = {};
        let hasText = this.form.text != "";
        let hasImage = this.form.images?.length > 0;
        let hasVideo = this.form.videos?.length > 0;
        let platforms = this.connectionAppsList?.filter((i) => i.constraints);
        platforms = platforms?.filter(
          (i) => this.form.social_media.indexOf(i.alias) != -1
        );
        let modifiedUniquePlatforms = [];
        if(this.uniquePlatforms){
          modifiedUniquePlatforms = allPlatformsList?.filter((i) => this.uniquePlatforms.some(p => p === i.alias));
          if (modifiedUniquePlatforms) {
            modifiedUniquePlatforms.forEach((uniquePlatform) => {
              const isPresent = platforms.some(
                (platform) => platform.alias === uniquePlatform.alias
              );
              if (!isPresent) {
                platforms.push(uniquePlatform);
              }
            });
          }
        }
        let warnings = [];
        let errors = [];
        if (hasVideo && hasImage) {
          errors.push("Select either images or a video");
        }
        if(this.form.social_media.includes('pinterest') || this.uniquePlatforms.includes('pinterest')){
          if (
            this.form.name?.length === 0 ||
            !this.form.name ||
            this.form.name == ""
          ) {
            errors.push(`Post name is required`);
          }
        }
        for (let platform of platforms) {
          let constraints = platform.constraints;
          // text validation
          let textAllowed = _.get(constraints, "text.allowed", false);
          let titleAllowed = _.get(constraints, "titleRequired", false);
          let textLimit = _.get(constraints, "text.maxLength", 0);
          let titleLimit = _.get(constraints, "text.titleMaxLength", 0);
          // text not required warning message;
          let overrideHasText =
            this.platformEditors[platform.alias]?.length > 0;
          if (platform.alias == "youtube") {
            if (overrideHasText) {
              if (
                this.platformEditors[platform.alias]?.includes("<") ||
                this.platformEditors[platform.alias]?.includes(">")
              ) {
                errors.push(
                  `${platform.name} : Post text should not contain '<' or '>' characters`
                );
              }
            }
            if (!overrideHasText && hasText) {
              if (
                this.form.text?.includes("<") ||
                this.form.text?.includes(">")
              ) {
                errors.push(
                  `${platform.name} : Post text should not contain '<' or '>' characters`
                );
              }
            }
          }
          // console.log("overrideHasText, textAllowed, platform.alias:", overrideHasText, textAllowed, platform.alias)
          if (textAllowed === false) {
            if (overrideHasText || (!overrideHasText && hasText)) {
              warnings.push(`${platform.name} : Post text would be ignored`);
            }
          }

          // text is required and checking text length
          if (textAllowed === true) {
            if (
              (overrideHasText &&
                this.platformEditors[platform.alias]?.length > textLimit) ||
              (!overrideHasText &&
                hasText &&
                textLimit < this.form.text?.length)
            ) {
              errors.push(
                `${platform.name} : Post content cannot be more than ${textLimit} characters`
              );
            }
          }
          // title is required and checking title length
          if (
            titleAllowed === true &&
            titleLimit &&
            titleLimit !== 0 &&
            titleLimit < this.form.name?.length
          ) {
            errors.push(
              `${platform.name} : Post title cannot be more than ${titleLimit} characters`
            );
          }

          // image validation
          let imageAllowed = _.get(constraints, "image.allowed", false);
          let imageLimit = _.get(constraints, "image.maxAllowed", 0);
          let imageRequired = _.get(constraints, "image.required", false);
          let imageSize = _.get(constraints, "image.maxSize", 0);
          let imageMinSize = _.get(constraints, "image.minSize", 0);
          let hasHigherSizeImages = this.form.images?.filter((i) => {
            let size = i.size / (1024 * 1024);
            return imageSize < size;
          });
          let hasLesserSizeImages = this.form.images?.filter((i) => {
            let size = i.size / (1024 * 1024);
            return size < imageMinSize;
          });
          if(platform.alias == "google_business_profile") {
            if (
              imageRequired &&
              !hasImage && !hasVideo
            ) {
              errors.push(`${platform.name} : Image is required`);
            }
            else if (hasVideo) {
              errors.push(`${platform.name} : Video is not allowed`);
            }
          }
          
          if (imageRequired && hasLesserSizeImages?.length != 0) {
            errors.push(
              `${platform.name} : Images must be greater than ${imageMinSize}MB`
            );
          }
          // image not required warning message;
          if (imageAllowed === false && hasImage) {
            // warnings.push(`${platform.name} : Post image would be ignored`);
          }
          // image is required and checking image length
          if (imageAllowed === true && this.form.images?.length > imageLimit) {
            if(imageLimit==1){
              warnings.push(
              `${platform.name} : First ${imageLimit} image will be posted`
            );
            }
            else{
              warnings.push(
                `${platform.name} : First ${imageLimit} image/s will be posted`
              );
            }
          }
          // image is required and checking image size
          if (imageAllowed === true && hasHigherSizeImages?.length != 0) {
            errors.push(
              `${platform.name} : Images must be less than ${imageSize}MB`
            );
          }
          // video validation
          let videoAllowed = _.get(constraints, "video.allowed", false);
          let videoMaxLength = _.get(constraints, "video.maxLength", {});
          let videoSize = _.get(constraints, "video.maxSize", 0);
          let hasHigherSizeVideos = this.form.videos?.filter((i) => {
            let size = i.size / (1024 * 1024);
            return videoSize < size;
          });
          // video not required warning message;
          if (videoAllowed === false && hasVideo) {
            warnings.push(`${platform.name} : Post video would be ignored`);
          }
          // video is required and checking image size
          if (videoAllowed === true && hasHigherSizeVideos?.length != 0) {
            errors.push(
              `${platform.name} : Video must be less than ${videoSize}MB`
            );
          }
          let postType = Object.keys(videoMaxLength)[0];
          if (platform.alias == "instagram")
            postType = this.configuration.ig_post_type;
          if (platform.alias == "youtube")
            postType = this.configuration.yt_post_type;
          if (platform.alias == "facebook")
            postType = this.configuration.fb_post_type;
          //  video duration validation
          if (videoMaxLength[postType]) {
            let maxLength = videoMaxLength[postType].max;
            let minLength = videoMaxLength[postType].min || 0;
            let maxImages = videoMaxLength[postType].maxImage || null;
            if(hasImage && maxImages) {
              if(this.form.images?.length > maxImages) {
                warnings.push(
                  `${platform.name} : First image will be posted as ${postType}`
                );
              }
            }

            let type = postType.charAt(0).toUpperCase() + postType.slice(1);
            if (hasVideo && maxLength < Math.floor(this.videoDuration)) {
              if (maxLength > 60) {
                errors.push(
                  `${
                    platform.name
                  } (${type}) :  Video must be less than ${Math.round(
                    maxLength / 60
                  )} Minutes`
                );
              } else {
                errors.push(
                  `${platform.name} (${type}) :  Video must be less than ${maxLength} seconds`
                );
              }
            }
            if (hasVideo && minLength > Math.floor(this.videoDuration)) {
              errors.push(
                `${platform.name} (${type}) :  Video must be greater than ${minLength} seconds`
              );
            }
          }

          let mediaRequired = _.get(constraints, "mediaRequired", []);
          let medias = mediaRequired.filter((i) => {
            if (i == "text") return hasText;
            if (i == "image") return hasImage;
            if (i == "video") return hasVideo;
            if (i == "text,image" || i == "image,text") {
              return hasText && hasImage;
            }
            if (i == "text,video" || i == "video,text") {
              return hasText && hasVideo;
            }
            if (
              i == "text,video,image" ||
              i == "video,image,text" ||
              i == "image,text,video"
            ) {
              return hasText && hasVideo && hasImage;
            }
            return false;
          });
          if (medias?.length == 0) {
            if (
              mediaRequired.indexOf("text") != -1 &&
              mediaRequired.indexOf("image") != -1 &&
              mediaRequired.indexOf("video") != -1
            ) {
              errors.push(
                `${platform.name} : Image or Video or Text is required`
              );
            } else if (
              mediaRequired.indexOf("image") != -1 &&
              mediaRequired.indexOf("video") != -1
            ) {
              errors.push(`${platform.name} : Image or Video is required`);
            } else if (mediaRequired.indexOf("text") != -1) {
              errors.push(`${platform.name} : Text is required`);
            } else if (mediaRequired.indexOf("image") != -1) {
              errors.push(`${platform.name} : Image is required`);
            } else if (mediaRequired.indexOf("video") != -1) {
              errors.push(`${platform.name} : Video is required`);
            }
          }
          if (this.form.scheduled_at && this.form.when_to_post === "schedule") {
            const currentTime = new Date();
            const scheduledTime = new Date(this.form.scheduled_at);
            if (scheduledTime <= currentTime) {
              errors.push("Please select valid scheduled time");
            }
          }
        }
        this.errors = errors;
        this.warnings = warnings;
        resolve({ warnings, errors, validVideoTypes });
      });
    },
    validateOnContentChange() {
      if (!this.$refs.form.validate()) return;
      this.validatePost();
    },
    async proceedWithWarnings() {
      const vm = this;
      if (!vm.$refs.form.validate()) return;
      let { errors } = await vm.validatePost();
      if (errors?.length != 0) return;
      this.form.config_social_media = this.getConfigSocialMedia();
      vm.onConfigureClick();
      this.submit();
    },
    async proceedWithoutWarnings() {
      const vm = this;
      if(!this.form.name || (!this.form.social_media.includes('pinterest') && !this.uniquePlatforms.includes('pinterest'))){
        this.form.name=""
      }
      console.log("form::", this.form);
      if (!vm.$refs.form.validate()) return;
      let { errors, warnings } = await vm.validatePost();
      if (errors?.length != 0) {
        return;
      }
      this.form.config_social_media = this.getConfigSocialMedia();
      vm.onConfigureClick();
      vm.submit();
    },
    savePostToDraft() {
      const vm = this;
      this.form.config_social_media = this.getConfigSocialMedia();
      this.handleOverridePlatformEditorsText();
      this.$emit("savePostToDraft", vm.form);
      this.$mixpanel.track("Save as Draft Clicked", { step: "Build" });
    },
    updateDraft() {
      const vm = this;
      this.form.config_social_media = this.getConfigSocialMedia();
      this.handleOverridePlatformEditorsText();
      this.$emit("updateDraft", vm.form);
    },
    handleOverridePlatformEditorsText() {
      const vm = this;
      if (
        this.platformEditors.twitter &&
        this.platformEditors.twitter?.length
      ) {
        vm.form.twitter_text = this.platformEditors.twitter;
      }
      if (
        this.platformEditors.facebook &&
        this.platformEditors.facebook?.length
      ) {
        vm.form.facebook_text = this.platformEditors.facebook;
      }
      if (
        this.platformEditors.mastodon &&
        this.platformEditors.mastodon?.length
      ) {
        vm.form.mastodon_text = this.platformEditors.mastodon;
      }
      if (
        this.platformEditors.threads &&
        this.platformEditors.threads?.length
      ) {
        vm.form.threads_text = this.platformEditors.threads;
      }
      if (
        this.platformEditors.bluesky &&
        this.platformEditors.bluesky?.length
      ) {
        vm.form.bluesky_text = this.platformEditors.bluesky;
      }
      if (
        this.platformEditors.instagram &&
        this.platformEditors.instagram?.length
      ) {
        vm.form.instagram_text = this.platformEditors.instagram;
      }
      if (
        this.platformEditors.youtube &&
        this.platformEditors.youtube?.length
      ) {
        vm.form.youtube_text = this.platformEditors.youtube;
      }
      if (
        this.platformEditors.pinterest &&
        this.platformEditors.pinterest?.length
      ) {
        vm.form.pinterest_text = this.platformEditors.pinterest;
      }
      if (this.platformEditors.tumblr && this.platformEditors.tumblr?.length) {
        vm.form.tumblr_text = this.platformEditors.tumblr;
      }
      if (
        this.platformEditors.google_business_profile &&
        this.platformEditors.google_business_profile?.length
      ) {
        vm.form.google_business_profile_text =
          this.platformEditors.google_business_profile;
      }
      if (this.platformEditors.reddit && this.platformEditors.reddit?.length) {
        vm.form.reddit_text = this.platformEditors.reddit;
      }
      if (
        this.platformEditors.linkedin &&
        this.platformEditors.linkedin?.length
      ) {
        vm.form.linkedin_text = this.platformEditors.linkedin;
      }
      if (this.platformEditors.tiktok && this.platformEditors.tiktok?.length) {
        vm.form.tiktok_text = this.platformEditors.tiktok;
      }
      vm.$emit("input", vm.form);
    },
    async submit() {
      const vm = this;
      console.log("form:", this.form);
      // this.$emit("platformEditors", this.platformEditors);
      if (this.form.when_to_post == "schedule") {
        if (!this.form.scheduled_at || this.form.scheduled_at == null) {
          this.$alert.show("Please select scheduled time");
          return;
        }
      }
      this.handleOverridePlatformEditorsText();
      vm.$emit("configuration", this.configuration);
      let { validVideoTypes } = await vm.validatePost();
      vm.$emit("validVideoTypes", validVideoTypes);
      let { videos } = vm.form;
      if (videos.length === 1 && this.durationFromGallery !== 0) {
        this.$emit("duration", this.durationFromGallery);
      }
      if (vm.isEditing || vm.isRepost || vm.isDraftPost) {
        this.form.social_media.map((handle, index) => {
          let ind = this.connectedHandles.indexOf(handle);
          if (ind === -1 && handle !== "around") {
            this.form.social_media.splice(index, 1);
          }
        });
        vm.$emit("input", vm.form);
        vm.$emit("submit");
      } else {
        vm.$emit("input", vm.form);
        vm.$emit("submit");
      }
      if (vm.videoCount) {
        vm.$emit("videoselected");
      }
    },
    isSocial(key) {
      return this.form.social_media.indexOf(key) != -1;
    },
    isSocialHandle(key) {
      return this.connectedHandles.indexOf(key) != -1;
    },
    async fetchConnections() {
      const vm = this;
      try {
        let uid = vm.currentUID || vm.uid;
        if (uid && vm.WID) {
          // change 'where to post' label for boost users to display the current workspace name
          const userDoc = (await getDoc(doc(colUsers, uid))).data();
          const userFlyPlan = await userDoc.fly_plan;
          const allowedPlans = process.env.VUE_APP_WK_PLANS.split(",").map(
            (plan) => plan.trim()
          );
          let isBoostAndAbove = allowedPlans.includes(userFlyPlan);
          if (isBoostAndAbove) {
            this.whereToPost = `Where to post? `;
          } else this.whereToPost = "Where to post?";
          // let q = query(collection(doc(colUsers, uid), "connected-apps"));
          let q = query(
            collection(
              doc(colUsers, uid, "fly_workspaces", vm.WID),
              "connected-apps"
            )
          );
          // let connectedApps = (await getDocs(q)).docs.map((i) => ({
          //   ...i.data(),
          //   id: i.id,
          // }));

          // remove olx
          let connectedApps = (await getDocs(q)).docs
          .filter(doc => doc.id !== 'olx')  // Filter out documents where id is 'olx'
          .map(doc => ({
            ...doc.data(),
            id: doc.id
          }));

          let userData = connectedApps.map((app) => {
            return {
              picture: app.picture,
              name: app.name,
              id: app.id,
            };
          });
          this.$emit("userData", userData);
          vm.connectedApps = connectedApps;
          vm.$emit("connectedApps", connectedApps);
          let checkedApps = [];
          connectedApps.map((item) => {
            checkedApps.push(item.id);
            this.connectedHandles.push(item.id);
          });
          // if (!vm.isEditing && !vm.isRepost && !vm.isDraftPost) {
          //   vm.form.social_media = checkedApps;
          // }

          let allPlatformsList = await vm.getAllPlatforms();

          let connectionsList = [];
          allPlatformsList.map((i) => {
            connectedApps.map((item) => {
              if (item.id == i.alias && item.status == true) {
                connectionsList.push({ ...i, picture: item.picture || "" });
              }
            });
          });
          if (vm.WID == "default") {
            let around = allPlatformsList.find((i) => i.alias == "around");
            if (around) {
              if (!this.isEditing && !this.isRepost) {
                let index = vm.form.social_media.indexOf("around");
                if (index === -1) {
                  //vm.form.social_media.push("around");
                }
              }
              //connectionsList.push(around);
            }
          }
          vm.overridePlatformList = connectionsList
            .filter((i) => i.alias !== "around")
            .map((i) => ({
              ...i,
              value: i.alias,
            }));
          // vm.connectionAppsList = connectionsList.map((i) => ({
          //   ...i,
          //   value: i.alias,
          // }));

          // this.flyPlatformsType?.includes(i.type) && ((!this.flyPlatformsCategoryType || this.flyPlatformsCategoryType == undefined) || (this.flyPlatformsCategoryType && i.category && this.flyPlatformsCategoryType.includes(i.category))).map(i => ({
          // console.log("connection",connectionsList, "playtforms",this.flyPlatformsType)

          if (
            this.$route.name == "create-post" &&
            this.flyPlatformsType?.length > 0
          ) {
            vm.connectionAppsList = connectionsList
              .filter((i) => this.flyPlatformsType?.includes(i.type))
              .map((i) => ({
                ...i,
                value: i.alias,
              }));
          } else {
            vm.connectionAppsList = connectionsList.map((i) => ({
              ...i,
              value: i.alias,
            }));
          }
          // Only update the form.social_media if it's not a repost or editing
          if (!this.isRepost && !this.isEditing) {
            this.connectionAppsList.forEach((platform) => {
              if(platform.alias=='google_business_profile') this.form.social_media=['google_business_profile'];
            });
          } else {
            // For reposts or editing, ensure only relevant platforms are maintained
            this.form.social_media = this.form.social_media.filter((platform) =>
              this.connectionAppsList.some((app) => app.alias === platform)
            );
          }

          vm.$emit("input", vm.form);
        }
      } catch (error) {
        vm.handleError(error);
      }
    },
    async getAllPlatforms() {
      const platformsRef = await getDocs(platforms);

      let allPlatforms = [];

      platformsRef.forEach((doc) => {
        allPlatforms.push(doc.data());
      });

      allPlatforms.sort((a, b) => a.display_priority - b.display_priority);

      this.socialAppLists = allPlatforms;
      this.$store.commit("SET_FLY_PLATFORMS", allPlatforms);
      return allPlatforms;
    },
    // extractPublicId(url) {
    //   const regex = /upload\/(?:.+\/)*(.+)/;
    //   const matches = regex.exec(url);
    //   if (matches && matches.length > 1) {
    //     return matches[1];
    //   } else {
    //     return null; // or handle error appropriately
    //   }
    // },
    editMedia(image) {
      console.log("image:", image);
      let public_id = this.extractPublicId(image);
      public_id = "users/" + public_id;
      console.log("public_id:", public_id);

      if (!public_id) {
        console.log("Invalid image URL");
        return;
      }

      const myEditor = cloudinary.mediaEditor();
      myEditor.update({
        publicIds: [public_id],
        cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
        image: {
          steps: ["resizeAndCrop", "imageOverlay", "textOverlays", "export"],
        },
      });
      myEditor.show();
      myEditor.on("export", function (data) {
        console.log("data", data);
      });
    },
    // editMedia(image) {
    //   console.log("image:", image);
    //   let public_id = this.extractPublicId(image);
    //   console.log("public_id::", public_id);

    //   const myEditor = cloudinary.mediaEditor();
    //   myEditor.update({
    //     publicIds: [public_id],
    //     cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
    //   });
    //   myEditor.show();
    //   myEditor.on("export", function (data) {
    //     console.log("data", data);
    //   });
    // },
    extractPublicId(url) {
      // Regular expression to ignore everything between 'upload/' and 'users/'
      const regex = /upload\/.*?users\/(.+)/;
      const match = url.match(regex);

      console.log("match:", match);

      // Return the public ID if match is found, which includes everything after 'users/'
      return match ? match[1] : "";
    },
    // extractPublicId(url) {
    //   // console.log("ext url:", url);
    //   const regex = /upload\/(?:v\d+\/)?(.+)(\.\w+)$/;
    //   const match = url.match(regex);
    //   console.log("match:", match);
    //   return match ? match[1] + (match[2] ? match[2] : "") : "";
    //   // return match ? match[1] : ""; // Extract the full path without the extension
    // },
    removeAssets(assetArray, index, type) {
      if (type == "image") {
        if (this.form.thumbnails && this.form.thumbnails[index]) {
          this.form.thumbnails.splice(index, 1);
        }
        this.form.images.splice(index, 1);
      } else {
        assetArray.splice(index, 1);
        this.form.overrideImages.splice(index, 1);
        if (this.isSocial("twitter") && this.form.videos?.length === 0) {
          this.twitterVidConstraint = false;
        }
      }
      this.$emit("videoremoved");
      this.$emit("input", this.form);
    },
    saveToHistory() {
      const platformHistoryIndex = this.historyStack.findIndex(
        (item) => item.platform === this.activeItem
      );
      const newText =
        this.activeItem === "PostText"
          ? this.form.text
          : this.platformEditors[this.activeItem];

      // Check if the platform exists in historyStack
      if (platformHistoryIndex !== -1) {
        const platformHistory = this.historyStack[platformHistoryIndex].history;
        // Check if the new text is different from the last item in history
        if (
          platformHistory.length === 0 ||
          platformHistory[platformHistory.length - 1] !== newText
        ) {
          // Push the new text to the history stack
          platformHistory.push(newText);
        }
      } else {
        // Platform doesn't exist, add it to the historyStack
        this.historyStack.push({
          platform: this.activeItem,
          history: [newText],
        });
      }
    },

    clearText() {
      const platformHistoryIndex = this.historyStack.findIndex(
        (item) => item.platform === this.activeItem
      );
      if (
        platformHistoryIndex !== -1 &&
        this.historyStack[platformHistoryIndex].history.length > 0
      ) {
        const lastCompleteActionIndex =
          this.historyStack[platformHistoryIndex].history.length - 2;
        if (lastCompleteActionIndex >= 0) {
          const lastCompleteAction =
            this.historyStack[platformHistoryIndex].history[
              lastCompleteActionIndex
            ];
          // Remove the last complete action from history stack
          this.historyStack[platformHistoryIndex].history.splice(
            lastCompleteActionIndex,
            2
          );

          if (this.activeItem === "PostText") {
            this.form.text = lastCompleteAction || "";
            this.$refs.textarea.updateValue(this.form.text);
          } else {
            this.platformEditors[this.activeItem] = lastCompleteAction || "";
            this.$refs["textarea-" + this.activeItem][0].updateValue(
              this.platformEditors[this.activeItem]
            );
          }
        } else {
          // If there are no complete actions left, clear the text
          if (this.activeItem === "PostText") {
            this.form.text = "";
            this.$refs.textarea.updateValue(this.form.text);
          } else {
            this.platformEditors[this.activeItem] = "";
            this.$refs["textarea-" + this.activeItem][0].updateValue(
              this.platformEditors[this.activeItem]
            );
          }
          // Clear the history stack for this platform
          this.historyStack[platformHistoryIndex].history = [];
        }
      }
    },
    onImageCropped(event) {
      if (!this.isOlxPost || !this.isCarListingPost) {
        // this.$refs.galleryImagePicker.fetchGallery();
      }
      this.form.images = event;
      this.$emit("input", this.form);
    },

    discardImages(urls) {
      this.form.images = this.form.images.filter(imageUrl => 
        urls.includes(imageUrl)
      );
      this.$emit("input", this.form);
    },

    async addOverridePlatformsInDraftPost() {
      for (const platform in this.platformEditors) {
        const handleTextKey = `${platform}_text`;
        const handleText = this.post[handleTextKey]; // Get the handle text from postObject
        // Check if handle text exists and is not empty
        if (handleText && handleText.trim() !== "") {
          // Find the corresponding object in connectionAppsList
          const correspondingObject = await this.connectionAppsList.find(
            (item) => item.value === platform
          );
          // If found, add it to selectedOverridePlatformList
          if (correspondingObject) {
            this.selectedOverridePlatformList.push(correspondingObject);
            this.$set(this.platformEditors, platform, handleText);

            const overrideItemIndex = this.overridePlatformList.findIndex(
              (overrideItem) => overrideItem.value === platform
            );
            if (overrideItemIndex !== -1) {
              this.overridePlatformList.splice(overrideItemIndex, 1);
            }
          }
        }
      }
    },
    selectAllLocations() {
      // Select all states and cities
      Object.keys(this.locations).forEach(state => {
        this.locations[state].checked = true;
        this.locations[state].cities.forEach(city => {
          city.checked = true;
        });
      });
      this.updateSelectedLocations();
    },

    clearAllLocations() {
      // Uncheck all states and cities
      Object.keys(this.locations).forEach(state => {
        this.locations[state].checked = false;
        this.locations[state].cities.forEach(city => {
          city.checked = false;
        });
      });
      this.updateSelectedLocations();
    }
  },

  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.fetchLocations();
    this.$store.commit("CLEAR_FLY_PLATFORMS");
    setTimeout(() => {
      let type = ["social"];
      this.$store.commit("SET_FLY_PLATFORMS_TYPE", type);
    }, 800);
    this.addOverridePlatformsInDraftPost();
    // this.newImageArray.push({...this.imageArray})
    this.imageArray.forEach((item) => {
      this.newImageArray.push(item);
    });
    // this.imageArray.map((img) => {

    // })
    // console.log("mounted newImageArray:", this.newImageArray);
    // console.log("mounted imageArray:", this.imageArray);

    //   if (this.isOlxPost) {
    //   this.$refs.imageCropper.dialog = true;
    // }
    const tourCompleted = this.$store.state.tourCompleted;
    const tourComplete = localStorage.getItem("tourCompleted");

    // if (!tourCompleted && !tourComplete && window.innerWidth > 768) {
    //   setTimeout(() => {
    //     this.startTour();
    //     this.$store.dispatch("setTourCompleted", true);
    //     localStorage.setItem("tourCompleted", "true");
    //   }, 2000);
    // }

    if (
      this.isOlxPost ||
      this.isCarListingPost ||
      this.isEditing ||
      this.isRepost ||
      this.isDraftPost
    ) {
      // this.imageArray = this.$route.params.imageDataArray;
      this.$nextTick(() => {
        if (this.post.images?.length && this.$refs.imageCropper) {
          this.$refs.imageCropper.dialog = true;
        }
      });
    }
  },
  beforeMount() {
     document.removeEventListener("click", this.handleClickOutside);
    this.fetchConnections();
  },
};
</script>

<style lang="scss">
.override-tab .v-slide-group__next,
.override-tab .v-slide-group__prev {
  min-width: 20px !important;
  flex: 0 1 20px !important;
  justify-content: flex-end !important;
}

.override-tab button {
  width: 25px !important;
  height: 25px !important;
  margin-left: 4px !important;
}

.draggable-item,
.v-image {
  cursor: move !important;
}

.warning-expansion {
  .v-expansion-panel-header {
    min-height: 30px !important;
  }

  .v-expansion-panel-header,
  .v-expansion-panel-content__wrap {
    padding: 0px;
  }

  .v-expansion-panel-header__icon {
    display: none;
  }
}

.social-media-selector {
  overflow-x: auto;
  white-space: nowrap;
}

.icon-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  border: 1px solid #ccc;
  border-radius: 9px;
  padding: 5px;
}

.icon-circle,
.icon-circle-selected {
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
  border: 2px solid transparent;
}

.icon-circle-selected {
  border-color: #8056de;
}

.social-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.platform-logo {
  width: 18px;
  position: absolute;
  bottom: 1px;
  right: -3px;
  border-radius: 50%;
}

.text-gray-500 {
  color: gray;
}

.icon-circle,
.icon-circle-selected {
  display: inline-block;
  padding: 3px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
  border: 2px solid transparent;
}

.icon-circle-selected {
  border-color: blue;
}

.icon-circle.deselected {
  filter: grayscale(100%);
}

.social-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.platform-logo {
  width: 22px;
  position: absolute;
  bottom: 1px;
  right: -5px;
  border-radius: 50%;
}

.no-data-available {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 9px;
  padding: 2px;
}

.white-background {
  background-color: rgba(255, 255, 255, 0.826);
}

.location-pref-toggle {
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
}

.location-pref-toggle .v-btn {
  height: 36px;
  min-width: 120px;
}

.location-pref-toggle .v-btn--active {
  background-color: #8056DE !important;
  color: white !important;
}
.platform-icons img {
  transition: transform 0.2s;
}
.platform-icons img:hover {
  transform: scale(1.1);
}
</style>
