<template>
  <div class="feedback-page">
    <h1 class="feedback-title">Share your feedback</h1>
    <div class="feedback-container">
      <nav class="feedback-nav mt-5">
        <button 
          :class="{ active: isSubmitFeedbackVisible }" 
          @click="showSubmitFeedback">Submit a Feedback
        </button>
        <button 
          :class="{ active: !isSubmitFeedbackVisible }" 
          @click="showMyFeedback">My Feedbacks
        </button>
      </nav>

      <!-- Feedback Submission Form -->
      <div v-if="isSubmitFeedbackVisible" class="feedback-form">
        <p class="feedback-text">At Fly, we value your feedback and continually improve our tool based on your insights.</p>
        <textarea v-model="feedback" placeholder="Describe your suggestion" rows="8"></textarea>
        
        <div v-if="errorMessage" class="error-message text-red-500">{{ errorMessage }}</div>
        
        <button class="submit-button" @click="submitFeedback" :disabled="loading">
           <v-icon v-if="loading" small class="mr-2">mdi-loading</v-icon>
           Submit <v-icon small color="white" class="ml-2">mdi-send</v-icon>
        </button>
      </div>

      <!-- Feedback Table -->
      <div v-else class="feedback-list">
        <div v-if="feedbackList.length === 0" class="no-feedback flex flex-col items-center justify-center">
          <v-icon size="48" color="gray">mdi-alert-circle-outline</v-icon>
          <p class="no-data-text mt-2">No Previous Records Found</p>
        </div>
        <div v-else>
          <h3>My Feedbacks</h3>
          <table class="feedback-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Feedback</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in feedbackList" :key="item.id">
                <td>{{ formatDate(item.created_at) }}</td>
                <td>{{ item.text }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Snackbar Notification -->
    <v-snackbar v-model="snackbar.show" :timeout="3000" :color="snackbar.color">
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { db, auth } from "@/utils/firebase.utils"; // Ensure you have Firebase initialized
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";

export default {
  data() {
    return {
      isSubmitFeedbackVisible: true,
      feedback: '',
      feedbackList: [],
      errorMessage: '',
      loading: false,
      snackbar: {
        show: false,
        message: '',
        color: ''
      },
    };
  },
  methods: {
    showSubmitFeedback() {
      this.isSubmitFeedbackVisible = true;
    },
    showMyFeedback() {
      this.isSubmitFeedbackVisible = false;
      this.fetchFeedback();
    },
    async fetchFeedback() {
      try {

        const uid = this.currentUID;
        const feedbackRef = collection(db, "users", uid, "Feedback");
    
      // Fetch all documents first (without sorting)
      const querySnapshot = await getDocs(feedbackRef);

      if (querySnapshot.empty) {
        console.warn("No feedback found!");
        this.feedbackList = [];
        return;
      }

      // Debugging: Log each document ID and data
      querySnapshot.forEach(doc => {
        console.log("Doc ID:", doc.id, "Data:", doc.data());
      });

      // Convert Firestore documents to array
      this.feedbackList = querySnapshot.docs.map(doc => {
        let data = doc.data();
        console.log("date ",data.created_at);
        return {
          id: doc.id,
          created_at: data.created_at?.seconds
            ? new Date(data.created_at.seconds * 1000 + data.created_at.nanoseconds / 1000000)
            : new Date(), // Fallback to current date if missing
          text: data.text || "No feedback text"
        };
      });

        console.log("this data ",this.feedbackList);
      } catch (error) {
        console.error("Error fetching feedback:", error);
      }
    },
    async submitFeedback() {
      if (this.feedback.trim().length === 0) {
        this.errorMessage = 'Please enter some suggestion';
        return;
      }

      this.loading = true;

      try {
        const sendFeedback = httpsCallable(functions, "sendFeedBackEmailToTeam");

        const response = await sendFeedback({ uid: this.currentUID, text: this.feedback });

        if (response.data.success) {
          this.snackbar = {
            show: true,
            message: "Thank you! Your feedback has been received.",
            color: "green"
          };
          this.feedback = '';
          this.fetchFeedback(); // Refresh feedback list after submission
        } else {
          throw new Error("Failed to submit feedback.");
        }
      } catch (error) {
        console.error("Error submitting feedback:", error);
        this.snackbar = {
          show: true,
          message: "Sorry, we couldn't receive your feedback.",
          color: "red"
        };
      } finally {
        this.loading = false;
      }
    },
    formatDate(timestamp) {
      if (!timestamp) return "N/A";

      let date;
      if (timestamp.seconds !== undefined) {
        // Firestore Timestamp: Convert seconds to a JavaScript Date
        date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
      } else if (timestamp instanceof Date) {
        date = timestamp; // Already a Date object
      } else {
        return "Invalid Date";
      }

      return date.toLocaleString("en-US", {
        weekday: "short", // Mon, Tue, etc.
        year: "numeric",
        month: "short",  // Jan, Feb, etc.
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false   // 24-hour format
      });
    }

  },
  mounted() {
    this.fetchFeedback(); // Fetch feedback on mount
  }
};
</script>

<style scoped>
.feedback-page {
  padding: 20px;
  max-width: 840px;
  margin: auto;
}
.feedback-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.feedback-nav {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  flex-wrap: nowrap;
  margin-bottom: 15px;
}
.feedback-nav button {
  min-width: 140px;
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
.feedback-nav button.active {
  background-color: #007bff;
  color: white;
}
.feedback-nav button:not(.active) {
  background-color: #e0e0e0;
  color: black;
}
.feedback-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.feedback-form {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.feedback-text {
  font-size: 16px;
  margin-bottom: 10px;
}
textarea {
  width: 100%;
  min-height: 120px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
}
.submit-button {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.submit-button i {
  margin-right: 5px;
}
.feedback-list {
  margin-top: 20px;
}
.feedback-table {
  width: 100%;
  border-collapse: collapse;
}
.feedback-table th, .feedback-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}
.feedback-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 600px) {
  .feedback-container {
    padding: 15px;
  }
  .feedback-title {
    font-size: 20px;
  }
  .feedback-nav {
    flex-direction: column;
    align-items: center;
  }
  .feedback-nav button {
    width: 100%;
  }
  textarea {
    min-height: 100px;
  }
}
</style>
