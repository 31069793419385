<template>
  <div class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]">
    <section class="flex flex-col items-center w-full justify-center xl:w-[1285px]">
      <div class="flex items-center justify-start md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]">
        <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
          <img src="@/assets/img/sidebar/content_n.svg" class="cursor-pointer w-6 md:w-[28px]" alt="post" />
          <div class="flex items-center gap-1">
            <p class="text-base md:text-[22px] font-bold">Google Search Insight</p>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-start mb-3 ml-[48px] md:mb-0 px-2 md:px-0 w-full xl:w-[1285px]">
        <div class="items-center gap-2 px-4  d-flex">
          <div class="flex items-center gap-1">
            <p class="text-xs md:text-[18px] text-gray-600">
              See traffic by keywords for your locations from Google
            </p>
          </div>
        </div>
      </div>
      <div class="w-full mx-auto mt-3">
        <div class="bg-white rounded-lg shadow-sm p-4 md:p-6 mb-6 min-h-[80vh]">
          <div class="w-full mx-auto -mt-5">
            <div class="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 w-full">
              <!-- Location Dropdown -->
              <div class="relative w-full sm:w-auto flex-1 md:w-1/3 mr-2 max-w-[300px]">
                <div @click="toggleStoreDropdown($event)">
                  <v-text-field v-model="locationSearch" :label="selectedStore ? selectedStore.sub_locality : 'Select Location'"
                    hide-details dense outlined class="location-input min-w-[300px] mt-10"
                    :error="showValidation && !selectedStore"
                    color="rgba(0, 0, 0, 0.6)" background-color="white">
                    <template v-slot:append>
                      <button 
                        class="absolute inset-y-0 right-0 flex items-center px-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                      </button>
                    </template>
                  </v-text-field>
                </div>
                <div class="h-5">
                  <span v-if="showValidation && !selectedStore" class="text-red-500 text-xs">Please select a location</span>
                </div>
                <div ref="showStoreDropdown" v-if="showDropdown"
                  class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-60 min-w-[300px] max-w-[800px] overflow-y-auto py-2 px-5">
                  <div v-for="profile in filteredStores" :key="profile.wid"
                    class="platform p-2 hover:bg-gray-100 cursor-pointer" @click="selectStore(profile)"
                    :class="{ 'bg-gray-200': selectedWID === profile.wid }">
                    <div class="flex items-center gap-2">
                      <div class="flex flex-col">
                        <span class="font-medium text-base" v-if="profile.gmbProfile?.title">
                          {{ truncateText(profile.gmbProfile?.title, 30) }}
                        </span>
                        <span class="text-xs text-gray-600" v-if="profile.sub_locality">
                          {{ profile.sub_locality }}
                        </span>
                        <span class="text-[11px] text-gray-500">{{ profile.city }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Year Dropdown -->

              <!-- Start Month Dropdown -->
              <div class="sm:w-auto flex-1" style="max-width: 250px;">
                <v-select outlined v-model="selectedStartMonthYear" :items="startMonthYearOptions"
                  label="Select Start Month" 
                  hide-details
                  dense
                  class="start-month-filter mt-10"
                  :error="showValidation && !selectedStartMonthYear"
                  :disabled="isGenerating" />
                <div class="h-5">
                  <span v-if="showValidation && !selectedStartMonthYear" class="text-red-500 text-xs">Please select start month</span>
                </div>
              </div>

              <!-- End Month Dropdown -->
              <div class="sm:w-auto flex-1" style="max-width: 250px;">
                <v-select outlined v-model="selectedEndMonthYear" :items="endMonthYearOptions" 
                  label="Select End Month"
                  hide-details
                  dense
                  class="end-month-filter mt-10"
                  :error="showValidation && !selectedEndMonthYear"
                  :disabled="isGenerating" />
                <div class="h-5">
                  <span v-if="showValidation && !selectedEndMonthYear" class="text-red-500 text-xs">Please select end month</span>
                </div>
              </div>

              <!-- Generate Report Button -->
              <div class="flex justify-end">
                <v-btn color="primary" @click="generateReport" :loading="isGenerating"
                  class="px-6 h-[40px] w-full sm:w-auto mt-5">
                  <v-icon left>mdi-file-chart</v-icon>
                  Generate Report
                </v-btn>
              </div>
            </div>
            <div v-if="lastGeneratedAt" class="text-center flex justify-end mb-4">
              <p class="text-sm text-gray-500">Generated {{ lastGeneratedAt }}</p>
            </div>
            <!-- Report Content -->
            <div v-if="hasData" class="space-y-6">
              <div class="bg-white rounded-lg shadow-sm overflow-hidden">
                <div class="overflow-x-auto">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Keyword</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Impressions</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="(item, index) in reportData" :key="index">
                        <td class="px-6 py-4 ">{{ item.searchKeyword }}</td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          {{ formatImpressions(item.insightsValue.value || item.insightsValue.threshold) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else-if="isGenerating" class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <v-skeleton-loader v-for="n in 4" :key="n" type="card" class="bg-white rounded-lg"></v-skeleton-loader>
            </div>
            <div v-else class="text-center py-12 mt-[20%]">
              <v-icon size="64" color="grey lighten-1">mdi-alert-circle-outline</v-icon>
              <p class="mt-4 text-gray-600">No data available</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { collection, query, where, getDocs } from "firebase/firestore";
import { db, functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";

export default {
  name: "GmbSearchReport",
  data() {
    return {
      selectedStartMonthYear: null,
      selectedEndMonthYear: null,
      startMonthYearOptions: [],
      endMonthYearOptions: [],
      isGenerating: false,
      reportData: null,
      locationSearch: "",
      selectedStore: null,
      stores: [],
      selectedWID: null,
      showDropdown: false,
      lastGeneratedAt: null,
      showValidation: false,
    };
  },
  computed: {
    hasData() {
      return this.reportData?.length;
    },
    filteredStores() {
      if (!this.locationSearch) return this.stores;
      const searchTerm = this.locationSearch.toLowerCase();
      return this.stores.filter((store) =>
        store.sub_locality?.toLowerCase().includes(searchTerm)
      );
    },
  },
  methods: {
    generateMonthYearOptions(isEndMonth = false) {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1; // Months are 0-indexed
      const options = [];
      const startMonthYear = this.selectedStartMonthYear ? this.selectedStartMonthYear.split('-') : null;

      for (let year = currentYear; year > currentYear - 3; year--) {
        for (let month = 1; month <= 12; month++) { // Iterate from January to December
          // Logic for disabling options
          if (isEndMonth) {
            if (!startMonthYear) {
              continue; // Skip if start month is not selected
            }
            const selectedStartMonth = parseInt(startMonthYear[0]);
            const selectedStartYear = parseInt(startMonthYear[1]);

            // Disable months before the selected start month
            if (year < selectedStartYear || (year === selectedStartYear && month < selectedStartMonth)) {
              continue; // Skip months before the selected start month
            }

            // Ensure end month does not exceed the current month
            if (year === currentYear && month > currentMonth) {
              continue; // Skip future months for end month
            }
          } else {
            // For start month, skip future months if the current month is not completed
            if (year === currentYear && month > currentMonth) {
              continue; // Skip future months for start month
            }
          }
          options.push({
            text: new Date(year, month - 1).toLocaleString("default", { month: "long" }) + ` ${year}`,
            value: `${month}-${year}`, // Format: month-year
          });
        }
      }

      // Sort options in reverse order by year and month
      options.sort((a, b) => {
        const [monthA, yearA] = a.value.split('-').map(Number);
        const [monthB, yearB] = b.value.split('-').map(Number);
        return yearB - yearA || monthB - monthA; // Sort by year (descending), then by month (descending)
      });

      return options;
    },
    async fetchStores() {
      try {
        const storeRef = collection(db, "users", this.currentUID, "fly_workspaces");
        const storeQuery = query(
          storeRef,
          where("admin", "==", this.WID),
          where("storeWorkspace", "==", true)
        );

        const querySnapshot = await getDocs(storeQuery);
        this.stores = querySnapshot.docs.map((docSnapshot) => ({
          ...docSnapshot.data(),
          wid: docSnapshot.id,
        }));
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    },
    toggleStoreDropdown(event) {
      if (event) {
        event.stopPropagation();
      }
      this.showDropdown = !this.showDropdown;
    },
    handleSearchInput() {
      this.showDropdown = true;
    },
    handleClickOutside(event) {
      const profileDropdown = this.$refs.showStoreDropdown;
      if (profileDropdown && !profileDropdown.contains(event.target)) {
        this.closeProfileDropdown();
      }
    },
    closeProfileDropdown() {
      if (this.showDropdown) {
        this.showDropdown = false;
      }
    },
    selectStore(store) {
      this.selectedStore = store;
      this.showDropdown = false;
      this.selectedWID = store.wid
    },
    async generateReport() {
      this.showValidation = true;
      
      if (!this.selectedStore || !this.selectedStartMonthYear || !this.selectedEndMonthYear) {
        return;
      }
      
      this.showValidation = false;
      this.isGenerating = true;
      
      try {
        const localStartMonth = JSON.stringify(this.selectedStartMonthYear);
        const localEndMonth = JSON.stringify(this.selectedEndMonthYear);
        sessionStorage.setItem('gmbsearchReportStartMonthYear', localStartMonth);
        sessionStorage.setItem('gmbsearchReportEndMonthYear', localEndMonth);
        sessionStorage.setItem('gmbsearchReportSelectedStore', JSON.stringify(this.selectedStore));
        
        const generateSearchReport = httpsCallable(functions, "gmbSearchReportFunction");
        const [startMonth, startYear] = this.selectedStartMonthYear.split('-');
        const [endMonth, endYear] = this.selectedEndMonthYear.split('-');
        const payload = {
          uid: this.currentUID,
          wid: this.selectedStore.wid,
          locationId: this.selectedStore.gmbProfile?.name?.replace("locations/", ""),
          startYear: startYear,
          endYear: endYear,
          startMonth: startMonth,
          endMonth: endMonth,
        };
        const result = await generateSearchReport(payload);
        this.reportData = result.data?.searchKeywordsCounts || [];
        sessionStorage.setItem('gmbsearchReportData', JSON.stringify(this.reportData));
        
        const timestamp = new Date().toISOString();
        sessionStorage.setItem('gmbsearchReportLastGeneratedAt', timestamp);
        this.lastGeneratedAt = this.formatTimeAgo(timestamp)
        console.log(this.reportData, "this is report data");
      } catch (error) {
        console.error("Error generating report:", error);
      } finally {
        this.isGenerating = false;
      }
    },
    formatImpressions(value) {
      if (value <= 15) {
        return `${value} (approx.)`;
      }
      return value; // Return the value as is if greater than 15
    },
    formatTimeAgo(timestamp) {
        const now = new Date();
        const past = new Date(timestamp);
        const diffInSeconds = Math.floor((now - past) / 1000);

        const intervals = [
            { label: 'year', seconds: 31536000 },
            { label: 'month', seconds: 2592000 },
            { label: 'day', seconds: 86400 },
            { label: 'hour', seconds: 3600 },
            { label: 'minute', seconds: 60 },
            { label: 'second', seconds: 1 }
        ];

        for (const interval of intervals) {
            const count = Math.floor(diffInSeconds / interval.seconds);
            if (count > 0) {
                return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
            }
        }
        return 'just now';
    },
    truncateText(text, maxLength) {
      if (!text) return '';
      return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    },
  },
  mounted() {
    this.startMonthYearOptions = this.generateMonthYearOptions();
    this.endMonthYearOptions = this.generateMonthYearOptions(true);
    this.fetchStores();
    const cachedStartMonthYear = sessionStorage.getItem('gmbsearchReportStartMonthYear');
    const cachedEndMonthYear = sessionStorage.getItem('gmbsearchReportEndMonthYear');
    const cachedSelectedStore = sessionStorage.getItem('gmbsearchReportSelectedStore');
    const cachedReportData = sessionStorage.getItem('gmbsearchReportData');
    const cachedLastGeneratedAt = sessionStorage.getItem('gmbsearchReportLastGeneratedAt');

    if (cachedStartMonthYear) {
        this.selectedStartMonthYear = JSON.parse(cachedStartMonthYear);
    }
    if (cachedEndMonthYear) {
        this.selectedEndMonthYear = JSON.parse(cachedEndMonthYear);
    }
    if (cachedSelectedStore) {
        this.selectedStore = JSON.parse(cachedSelectedStore);
    }
    if (cachedReportData) {
        this.reportData = JSON.parse(cachedReportData);
    }
    if (cachedLastGeneratedAt) {
        this.lastGeneratedAt = this.formatTimeAgo(cachedLastGeneratedAt);
    }

    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  watch: {
    selectedStartMonthYear(newValue) {
      this.endMonthYearOptions = this.generateMonthYearOptions(true);
    },
  },
};
</script>

<style scoped>
/* Add any specific styles for this page */
</style>
